import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { EmptyListFallback } from '../../components/EmptyListFallback/EmptyListFallback';
import Loader from '../../components/Loader/Loader';
import { MeetingRoomItem } from '../../components/MeetingRoomItem/MeetingRoomItem';
import { useAppDispatch } from '../../hooks';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { translate } from '../../utils/translate';
import { SList, SPage } from './MeetingRoomList.styles';
import { getIsLoading, getMeetingRooms } from './meetingRoomSlice';
import { fetchMeetingRooms, resetMeetingRoomStoreData } from './meetingRoomSlice/actionCreator';

export const MeetingRoomList = () => {
  const dispatch = useAppDispatch();
  const confirm = useConfirmation();
  const isLoading = useSelector(getIsLoading);
  const meetingRooms = useSelector(getMeetingRooms);

  useEffect(() => {
    dispatch(fetchMeetingRooms());
    return () => {
      dispatch(resetMeetingRoomStoreData());
    };
  }, []);

  const onMeeatingRoomClick = (meetingRoomLink: string): void => {
    const description = translate('meeting_rooms__externalLinkWarning', {
      url: meetingRoomLink,
    });
    confirm({
      title: translate('external_link'),
      description,
      confirmText: 'ok',
      cancelText: 'cancel',
      descriptionStyle: 'fit-big-text',
      onSubmit: () => window.open(meetingRoomLink, '_blank'),
      onCancel: () => {},
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <SPage>
      <SList>
        {meetingRooms?.length ? (
          meetingRooms.map((meetingRoom, index) => (
            <MeetingRoomItem
              key={index}
              meetingRoom={meetingRoom}
              onMeeatingRoomClick={onMeeatingRoomClick}
            ></MeetingRoomItem>
          ))
        ) : (
          <EmptyListFallback
            isLoading={false}
            emptyListTx={'meeting_rooms__empty'}
            listLength={meetingRooms?.length}
          />
        )}
      </SList>
    </SPage>
  );
};
