import { batch } from 'react-redux';
import { setGDIsLoading, setGroupDetail, setGroupMembers, setIsError } from '.';
import { getGroupById, getGroupMembers } from '../../../apis/groupsAPI';
import { AppThunk } from '../../../store';
import { FetchGroupDetailParams } from './types';

export const fetchGroupDetail =
  (params: FetchGroupDetailParams): AppThunk =>
  async dispatch => {
    const { id } = params;
    try {
      dispatch(setGDIsLoading({ all: true }));
      const group = await getGroupById(id);
      const members = await getGroupMembers({ id });
      batch(() => {
        dispatch(setGroupDetail(group));
        dispatch(setGroupMembers(members));
        dispatch(setGDIsLoading({ all: false }));
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setGDIsLoading({ all: false }));
        dispatch(setIsError(`${error}`));
      });
    }
  };
