import { Layout } from '../components/Layout/Layout';
import { Login2fa } from '../containers/Login2fa/Login2fa';

export const Login2faPage = () => {
  return (
    <Layout isAuthLayout={false} backBtn showCoSafeLogo to="/login">
      <Login2fa />
    </Layout>
  );
};
