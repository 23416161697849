import { Checklist, ChecklistItem } from '../../containers/Checklists/checklistsSlice/types';
import { objectToQueryParams } from '../../utils/format';
import { getAntiForgeryToken } from '../authApi/authAPI';
import { ApiCore } from '../utils/core';
import { GetChecklistProps } from './types';

const url = 'Checklists';

const ApiChecklists = new ApiCore({
  getAll: false,
  getSingle: false,
  post: true,
  put: false,
  patch: false,
  remove: true,
  singleExtra: true,
  url: url,
});

export const createNewChecklist = async (name: string, userIds?: number[], groupIds?: number[]) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiChecklists.performExtra<Checklist>({
    extraResource: '',
    method: 'post',
    model: {
      name,
      userIds,
      groupIds,
    },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const changeItemStatus = async (id: number, itemId: number, completed: boolean) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiChecklists.performExtra<any>({
    extraResource: `${id}/items/${itemId}`,
    method: 'post',
    model: {
      complete: completed,
    },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const addComment = async (id: number, itemID: number, model: { text: string | null }) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiChecklists.performExtra<any>({
    extraResource: `${id}/items/${itemID}/comment`,
    method: 'post',
    model: model,
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const saveItems = async (id: number, items: { name: string; sortIndex: number }[]) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiChecklists.performExtra<any>({
    extraResource: `${id}/edititems`,
    method: 'post',
    model: { items },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const getChecklists = (props: GetChecklistProps) => {
  return ApiChecklists.performExtra<Checklist[]>({
    method: 'GET',
    extraResource: `?${objectToQueryParams(props)}`,
  });
};

export const getChecklistsSummary = (searchText: string | null, selectedFilters: any) => {
  return ApiChecklists.performExtra<any>({
    method: 'GET',
    extraResource: `summary?search=${searchText || ''}&my=${
      // addedFilter?.includes("my") ? "true" : "false"
      !!selectedFilters?.my
    }&others=${
      // addedFilter?.includes("others") ? "true" : "false"
      !!selectedFilters?.others
    }&shared=${
      // addedFilter?.includes("shared") ? "true" : "false"
      !!selectedFilters?.shared
    }`,
  });
};

export const getChecklist = async (id: number) => {
  return ApiChecklists.performExtra<any>({
    extraResource: `${id}`,
    method: 'get',
  });
};

export const getChecklistItems = async (id: number) => {
  return ApiChecklists.performExtra<ChecklistItem[]>({
    extraResource: `${id}/items`,
    method: 'get',
  });
};

export const deleteTemplate = async (checklistId: number) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiChecklists.performExtra({
    method: 'DELETE',
    extraResource: `${checklistId}`,
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const renameChecklist = async (checklistId: number, newName: string) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiChecklists.performExtra({
    method: 'POST',
    id: checklistId,
    extraResource: 'rename',
    model: { name: newName },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const endChecklist = async (checklistId: number) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiChecklists.performExtra({
    method: 'POST',
    id: checklistId,
    extraResource: 'end',
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const checklistContinue = async (checklistId: number) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiChecklists.performExtra<any>({
    method: 'POST',
    id: checklistId,
    extraResource: `reactivate`,
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const startChecklist = async (id: number, groupIDs: number[], newName: string) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiChecklists.performExtra<any>({
    method: 'POST',
    extraResource: `${id}/start/${groupIDs}`,
    headers: { 'X-XSRF-Token': csrfToken },
    model: {
      newName,
    },
  });
};

export interface StartChecklistGroupsAndUsersParams {
  groupIds: number[];
  userIds: number[];
  newName: string;
}
export const startChecklistGroupsAndUsers = async (
  id: number,
  params: StartChecklistGroupsAndUsersParams
) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiChecklists.performExtra<any>({
    method: 'POST',
    extraResource: `${id}/startWithUsersAndGroups`,
    headers: { 'X-XSRF-Token': csrfToken },
    model: params,
  });
};

export const shareChecklist = async (id: number, groupID: number) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiChecklists.performExtra<any>({
    method: 'POST',
    extraResource: `${id}/sharelive/${groupID}`,
    headers: { 'X-XSRF-Token': csrfToken },
    model: {},
  });
};
export const shareChecklistWithUsers = async (id: number, userIds: number[]) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiChecklists.performExtra<any>({
    method: 'POST',
    extraResource: `${id}/sharelivewithusers`,
    headers: { 'X-XSRF-Token': csrfToken },
    model: { userIds },
  });
};
export const unShareChecklistWithUsers = async (id: number, userIds: number[]) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiChecklists.performExtra<any>({
    method: 'POST',
    extraResource: `${id}/unsharewithusers`,
    headers: { 'X-XSRF-Token': csrfToken },
    model: { userIds },
  });
};

export const unshareChecklist = async (id: number, groupID: number) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiChecklists.performExtra<any>({
    method: 'POST',
    extraResource: `${id}/unshare/${groupID}`,
    headers: { 'X-XSRF-Token': csrfToken },
    model: {},
  });
};

export const editChecklist = async (checklistID: number, items: any) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiChecklists.performExtra<any>({
    method: 'POST',
    id: checklistID,
    extraResource: 'edititems',
    model: { items },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};
