import _ from 'lodash';
import { getFragmentParams } from '../Login/helpers';
import { SsoLogin } from '../Login/LoginSlice/actionCreators';
import { Dispatch } from 'react';

export const isMobileBrowser = () => {
  return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
};

export const didAskedToGoMobileApp = () => {
  sessionStorage.setItem('didAskedToGoMobileApp', 'Y');
};

export const getSsoRedirectLink = (
  searchParams: URLSearchParams,
  isDispatch = false,
  dispatch: Dispatch<unknown> | null = null
) => {
  const fragmentParams = getFragmentParams();
  if (!searchParams && _.isEmpty(fragmentParams)) {
    return null;
  }
  let sso = searchParams.get('sso'); // Email address
  const ssoProvider = searchParams.get('sso_provider');
  let code = searchParams.get('code');
  const error = searchParams.get('error');
  let mobile: string | number | null = searchParams.get('mobile');
  let customUrlScheme: string | boolean | null = searchParams.get('customUrlScheme');

  if (!code) {
    if (fragmentParams && fragmentParams.id_token) {
      code = fragmentParams.id_token;
    }
  }

  if (!sso) {
    if (!_.isEmpty(fragmentParams) && fragmentParams.state) {
      // This is intentional because it was encoded twice.
      sso = decodeURIComponent(decodeURIComponent(fragmentParams.state)).substring(4);
    }
  }

  const mobileEnding = '&mobile=1';
  const mobileCustomUrlSchemeEnding = '&mobile=1&customUrlScheme=true';

  // It does not get encoded correctly in some cases
  if (sso && sso.endsWith(mobileEnding)) {
    mobile = 1;
    sso = sso.slice(0, -mobileEnding.length);
  }

  if (sso && sso.endsWith(mobileCustomUrlSchemeEnding)) {
    mobile = 1;
    customUrlScheme = true;
    sso = sso.slice(0, -mobileCustomUrlSchemeEnding?.length);
  }

  if (isDispatch && !!dispatch) {
    if (sso && code && !mobile) {
      dispatch(SsoLogin({ code, email: sso }));
    }

    if (ssoProvider && code && !mobile) {
      dispatch(SsoLogin({ email: null, code, provider: ssoProvider }));
    }
  }
  if (sso && mobile) {
    // Redirect to mobile app
    let path = '&sso=' + sso;

    if (code) {
      path += '&code=' + code;
    }

    if (ssoProvider) {
      path += '&sso_provider=' + ssoProvider;
    }

    if (error) {
      path += '&error=' + error;
    }

    const { REACT_APP_OPEN_MOBILE_APP, REACT_APP_OPEN_MOBILE_APP_BY_CUSTOM_SCHEME } = process.env;
    const redirectBaseUrl = customUrlScheme
      ? REACT_APP_OPEN_MOBILE_APP_BY_CUSTOM_SCHEME
      : REACT_APP_OPEN_MOBILE_APP;

    return `${redirectBaseUrl}${encodeURIComponent(path)}`;
  } else {
    return null;
  }
};
