import React, { useEffect, useState } from 'react';
import { palette } from '../../theme/colors';
import { translate } from '../../utils/translate';
import { ActionButton } from '../ActionButtons/ActionButtons.style';
import { Backdrop } from '../Backdrop/Backdrop';
import {
  SContainer,
  SBottomSheet,
  SCurtainHandle,
  STitleText,
  SDateRow,
} from './DateSelectionBottomSheet.styles';
import VanillaCalendar from '../VanillaCalendar/VanillaCalendar';

interface DateSelectionBottomSheetProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initialDate?: string | undefined;
  titleTx?: string;
  handleConfirm: (date: string | undefined) => void;
}

export const DateSelectionBottomSheet = (props: DateSelectionBottomSheetProps) => {
  const {
    isOpen,
    setIsOpen,
    initialDate = undefined,
    titleTx = 'profile_select_date',
    handleConfirm,
  } = props;
  const [date, setDate] = useState(initialDate);
  useEffect(() => {
    setDate(initialDate);
  }, [initialDate, isOpen]);

  const handleDateSelected = (dateString: string) => {
    setDate(dateString);
  };

  if (!isOpen) return <></>;

  return (
    <>
      <SBottomSheet role="dateSelectionBottomSheet">
        <SCurtainHandle role="header">
          <rect width="400" height="100" fill={palette.silver} />
        </SCurtainHandle>
        <SContainer role="content">
          <STitleText role="title">{translate(titleTx)}</STitleText>
          <SDateRow role="timeRow">
            <VanillaCalendar initialDate={date} handleSelected={handleDateSelected} />
          </SDateRow>
          <ActionButton
            type="button"
            tx={'confirm'}
            onClick={() => handleConfirm(date)}
            role="confirmButton"
          />
        </SContainer>
      </SBottomSheet>
      <Backdrop setModal={setIsOpen} />
    </>
  );
};
