import { compact, isEmpty, isNil } from 'lodash';
import type { TFunction } from 'react-i18next';
import { AppDispatch } from '../../store';
import { isDateSmallerThanOrEqual, sameDay } from '../../utils/date';
import { MessageSubType, ReplyPurpose } from '../../utils/enums';
import { getImageLink } from '../../utils/formatImageLink';
import { translate } from '../../utils/translate';
import { readMessage } from '../ChatsList/chatListSlice/actionCreators';
import { Chat, Reply } from '../ChatsList/chatListSlice/types';
import { ReplyToMessageIcon, ReplyToMessageShorted } from './Chat';

export type ChecklistSubTypes = Exclude<MessageSubType, MessageSubType.personalAlarm>;

export const checklistMessageTitleTX: Record<ChecklistSubTypes, string> = {
  [MessageSubType.startedChecklist]: 'checklist_started',
  [MessageSubType.sharedChecklist]: 'checklist_shared',
  [MessageSubType.reactivatedChecklist]: 'checklist_reactivated',
};

export const fromPurposeChecklistMessageTitleTX: Record<number, string> = {
  [ReplyPurpose.ChecklistEnded]: 'checklist_started',
  [ReplyPurpose.ChecklistReactivated]: 'checklist_reactivated',
};

export const getMessageHeaderSubTitle = (
  message: Chat,
  t: TFunction<'translation', undefined>,
  seperator = ', '
): string => {
  if (message.type === 3) {
    //Broadcast
    const organizations = message?.organizations;
    const text =
      !isNil(organizations) && organizations.length > 1
        ? `${organizations.length} ${t('messages_title_info_label')}`
        : !isNil(organizations) && !isNil(organizations[0])
          ? organizations[0].name
          : '';
    if (!isEmpty(text) && !isNil(text)) {
      return text;
    }
  } else if (message.type === 10) {
    return message.senderName;
  }
  const groupsCount = message.groupIDs?.length || message.groupNames?.length;
  const recipentsCount = message.recipientCount;
  let groupsCountText = '';
  let recipentsCountText = '';
  if (groupsCount) {
    groupsCountText =
      groupsCount > 1 ? `${groupsCount} ${t('groups_title')}` : `${groupsCount} ${t('group')}`;
  }
  if (recipentsCount) {
    recipentsCountText =
      recipentsCount > 1
        ? `${recipentsCount} ${t(`groups_members`)}`
        : `${recipentsCount} ${t('member')}`;
  }

  return compact([groupsCountText, recipentsCountText]).join(seperator);
};

export const checkHasReply = (_reply: Reply): boolean => {
  const { replyId, replyToInitialMessage } = _reply;
  if (replyToInitialMessage) return true;
  if (replyId === undefined || replyId === null) return false;
  return true;
};

export const generateReplyToMessageBodyText = (_message: Chat | Reply, isReply = false): string => {
  if (isReply) {
    const replyData = _message as Reply;
    const initialReplyPhotoFileNamesCount = replyData.initialReplyPhotoFileNames
      ? new Set(replyData.initialReplyPhotoFileNames).size
      : 0;
    const initialReplyDocumentsFileNamesCount = replyData.initialReplyDocumentFileNames
      ? new Set(replyData.initialReplyDocumentFileNames).size
      : 0;

    if (replyData.initialReplyLocation) return translate('messages_location');
    else if (
      replyData.initialReplyText &&
      !initialReplyDocumentsFileNamesCount &&
      !initialReplyPhotoFileNamesCount
    )
      return replyData.initialReplyText;
    else if (initialReplyPhotoFileNamesCount)
      return translate(initialReplyPhotoFileNamesCount > 1 ? 'photos' : 'photo');
    else if (initialReplyDocumentsFileNamesCount)
      return translate(
        initialReplyDocumentsFileNamesCount > 1 ? 'documents_documents' : 'documents_document'
      );
    else if (replyData.initialReplyAudioFileNames?.length) return translate('messages_voicemail');
    else return translate('message_a_deleted');
  } else {
    const photosCount = _message.photoFileNames?.length || 0;
    const documentsCount = _message.documentFileNames?.length || 0;

    if (_message.type === 2) return _message.emergencyTypeName || '';
    else if (_message.locationID && _message.type !== 2) return translate('messages_location');
    else if (!photosCount && _message.text && !documentsCount) return _message.text;
    else if (_message.photoFileNames?.length !== 0) return translate('photo');
    else if (_message.documentFileNames?.length !== 0) return translate('documents_documents');
    else if (_message.audioFileNames) return translate('messages_voicemail');
    else return translate('message_a_deleted');
  }
};

export const generateReplyToMessageIcon = (
  _message: Chat | Reply,
  isReply = false
): ReplyToMessageIcon | undefined => {
  if (isReply) {
    const replyData = _message as Reply;
    if (replyData.initialReplyPhotoFileNames?.length)
      return {
        src: getImageLink({
          imageName: replyData.initialReplyPhotoFileNames[0],
          size: 'small',
        }),
        classPresets: 'photoMessageIcon',
      };
  } else {
    if (_message.type === 2 && _message.emergencyTypeIconFileName)
      return {
        src: getImageLink({ imageName: _message?.emergencyTypeIconFileName }),
        classPresets: 'alarmMessageIcon',
      };
    if (_message.photoFileNames && _message.photoFileNames.length !== 0)
      return {
        src: getImageLink({
          imageName: _message.photoFileNames[0],
          size: 'small',
        }),
        classPresets: 'photoMessageIcon',
      };
  }
  return undefined;
};

export const generateMessageReplyToMessage = (
  _reply: Reply,
  message: Chat
): ReplyToMessageShorted | undefined => {
  const { senderName, id } = message;
  const { replyId, replyToInitialMessage } = _reply;
  if (replyToInitialMessage) {
    // reply to main message
    return (
      message && {
        messageIcon: generateReplyToMessageIcon(message),
        messageText: generateReplyToMessageBodyText(message),
        senderName: senderName,
        messageId: id,
        isReplyToInitialMessage: true,
      }
    );
  }
  // no reply
  if (replyId === undefined || replyId === null) return undefined;
  if (replyId !== undefined && replyId !== null) {
    return {
      messageIcon: generateReplyToMessageIcon(_reply, true),
      messageText: generateReplyToMessageBodyText(_reply, true),
      senderName: _reply.initialReplySender || '',
      messageId: replyId,
      isReplyToInitialMessage: false,
      isDeleted: _reply.isInitialReplyDeleted ?? false,
    };
  }
};

const getDateFormat = (date: string) => {
  return new Date(date.replace(' ', 'T') + 'Z');
};

export const isReplyInSameDay = (
  message: Chat,
  reply: Reply,
  replyIndex: number,
  replies?: Reply[]
): boolean => {
  const thisReplyDate = getDateFormat(reply.sent);
  const messageSentDate = getDateFormat(message.sent);
  const repliesList = replies || message.replies;
  const prevReplyDate = replyIndex > 0 && getDateFormat(repliesList[replyIndex - 1].sent);
  const isSameDay = prevReplyDate
    ? sameDay(prevReplyDate, thisReplyDate)
    : sameDay(messageSentDate, thisReplyDate);
  return isSameDay;
};

export const checkIfNeedRead = (currentChat: Chat, replies: Reply[], dispatch: AppDispatch) => {
  const replyIds = replies.map(reply => reply.id);
  const lastReplySentTime = replies[replies.length - 1]?.sent;
  if (currentChat.lastRead) {
    if (lastReplySentTime) {
      // check & make a read request - if the lastRead time is before last reply sent time
      if (isDateSmallerThanOrEqual(currentChat.lastRead, lastReplySentTime)) {
        dispatch(readMessage(currentChat.id, replyIds));
      }
    } else {
      // check & make a read request (to be 100% sure - could be redundant) - if the lastRead time is before the message sent time
      if (isDateSmallerThanOrEqual(currentChat.lastRead, currentChat.sent)) {
        dispatch(readMessage(currentChat.id, replyIds));
      }
    }
  } else {
    // make a read request - if user has never read the message,
    // that means the user has already sent a new message
    dispatch(readMessage(currentChat.id, replyIds));
  }
};

export const removeDuplicatesByProperty = <T,>(items: T[], propertyName: keyof T): T[] => {
  const uniqueItems = new Map<unknown, T>();

  items.forEach(item => {
    const key = item[propertyName];
    uniqueItems.set(key, item);
  });

  return Array.from(uniqueItems.values());
};
