export function getDeviceToken(): string | null {
  return localStorage.getItem('pushyToken');
}

export function saveDeviceToken(token: string): void {
  localStorage.setItem('pushyToken', token);
}

export function getLoggedUser(): string | null {
  return sessionStorage.getItem('user');
}
