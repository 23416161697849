import * as React from 'react';
import { SDotsContainers, SDot } from './NavDots.styles';

interface INavDotsProps {
  count: number;
  currentIndex: number;
}

export const NavDots = (props: INavDotsProps) => {
  const { count, currentIndex } = props;
  return (
    <SDotsContainers>
      {Array.from(Array(count).keys()).map(index => (
        <SDot
          key={index.toString()}
          className={index === currentIndex ? 'dot-active' : undefined}
        />
      ))}
    </SDotsContainers>
  );
};
