import { Page } from '../../components/Page/Page';
import { SearchFilterBar } from '../../components/SearchFilterBar/SearchFilterBar';
import Loader from '../../components/Loader/Loader';
import { palette } from '../../theme/colors';
import styled from 'styled-components';

export const SPage = styled(Page)`
  padding: 1.25rem 0 1.5rem 0;
  /* to make only the list overflow */
  position: relative;
  height: 100%;
`;

export const SSearchFilterBar = styled(SearchFilterBar)`
  width: 90%;
  margin: auto;
  input {
    ::placeholder {
      color: ${palette.silver};
      font-size: 1rem;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: ${palette.silver};
    }

    ::-ms-input-placeholder {
      color: ${palette.silver};
    }
  }
`;

export const SList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.25rem;

  overflow-y: auto;
  height: calc(100% - 4.5rem);

  padding-bottom: 4.375rem;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SLoader = styled(Loader)`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
`;
