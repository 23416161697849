import { CreateMessageModel } from '../../containers/Chat/models';
import { Chat } from '../../containers/ChatsList/chatListSlice/types';
import { FileOrganization } from '../../containers/Documents/documentsSlice/types';
import { getAntiForgeryToken } from '../authApi/authAPI';
import { ApiCore } from '../utils/core';

const url = '';

const broadcastAPI = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: false,
  patch: true,
  remove: false,
  singleExtra: true,
  url,
});

export const getOrganizations = async () => {
  return broadcastAPI.performExtra<FileOrganization[]>({
    method: 'GET',
    extraResource: 'messages/broadcast/organizations',
  });
};

export const postBroadcastMessage = async (data: CreateMessageModel) => {
  const csrfToken = await getAntiForgeryToken();
  return broadcastAPI.performExtra<Chat>({
    method: 'POST',
    extraResource: 'messages/broadcast/organization',
    model: data,
    headers: { 'X-XSRF-Token': csrfToken },
  });
};
