import { decode } from 'html-entities';
import React, { ReactNode } from 'react';
import { useLocation } from '../../../utils/customHooks/useLocation';
import { MessageType } from '../../../utils/enums';
import { MapMessageView, SMapMessageView } from '../../MapMessageView/MapMessageView';
import MessageContainer from '../../MessageContainer/MessageContainer';
import PlaceholderMap from '../../../assets/imgs/chats/map-placeholder2.png';
import { t } from 'i18next';
import {
  SMessageContent,
  SLocationAndTime,
  SLocationDescription,
  SMapMessageViewContainer,
  SNoMapContainer,
  SReplyContainer,
  STextContainer,
  STime,
} from './LocationMessage.styles';

interface LocationMessageProps {
  text: string;
  isSent: boolean;
  sentDateTime: string;
  senderName: string;
  senderImage: string | null;
  locationId: number | null;
  isSameSender?: boolean;
  messageTo: string | null;
  toggleModal?: () => void;
  onMapClick: (longitude: number, latitude: number, address: string) => void;
  showOnlyAttachment?: boolean;
  forwarded?: boolean;
  edited?: boolean;
  renderReplyToMessage?: () => ReactNode;
  id?: number;
}

function LocationMessage(props: LocationMessageProps) {
  const {
    text,
    isSent,
    sentDateTime,
    senderName,
    senderImage,
    locationId,
    messageTo,
    toggleModal,
    onMapClick,
    forwarded,
    edited,
    showOnlyAttachment = false,
    renderReplyToMessage,
    isSameSender,
    id,
  } = props;

  const { location, isLocationLoading } = useLocation(locationId);

  const messageType = isSent
    ? MessageType.SentLocationMessage
    : MessageType.ReceivedLocationMessage;

  return (
    <div role="locationMessage">
      <MessageContainer
        hasReply={!!renderReplyToMessage}
        messageType={messageType}
        sentDateTime={text?.length ? sentDateTime : undefined}
        senderImage={senderImage}
        senderName={senderName}
        messageTo={messageTo}
        toggleModal={toggleModal}
        showOnlyAttachment={showOnlyAttachment}
        forwarded={forwarded}
        edited={edited}
        isSameSender={isSameSender}
        id={id}
      >
        <SMessageContent role="messageContent">
          <div role="messageContentInner">
            <SReplyContainer role="infoContainer">
              <>{renderReplyToMessage && renderReplyToMessage()}</>
            </SReplyContainer>
            {!isLocationLoading && location ? (
              <SMapMessageViewContainer>
                <MapMessageView
                  longitude={Number(location?.longitude)}
                  latitude={Number(location?.latitude)}
                  isSent={isSent}
                  isSameSender={isSameSender || isSent}
                  gestureHandling="none"
                  onClick={() =>
                    onMapClick(
                      Number(location?.longitude),
                      Number(location?.latitude),
                      location.name!
                    )
                  }
                />
                {!text?.length && <STime>{sentDateTime}</STime>}
              </SMapMessageViewContainer>
            ) : (
              <SMapMessageView role="mapView">
                <SNoMapContainer role="noMapContainer">
                  <img src={PlaceholderMap} alt="" role="mapPlaceholder" />
                </SNoMapContainer>
              </SMapMessageView>
            )}
            <STextContainer role="infoContainer">
              <SLocationAndTime role="locationAndTimeText">
                {location && location.name ? location.name : ''}
              </SLocationAndTime>
              {text && (
                <SLocationDescription role="description">
                  {decode(text)}
                  {/* Necessary to get space */}
                  <span className="editedReplacer">
                    {' '}
                    {sentDateTime} {edited && `${t('messages_edited')}`}
                  </span>
                </SLocationDescription>
              )}
            </STextContainer>
          </div>
        </SMessageContent>
      </MessageContainer>
    </div>
  );
}

export default LocationMessage;
