import { useLocation, useParams } from 'react-router-dom';

import { ChatMedia } from '../../containers/ChatMedia/ChatMedia';
import { Layout } from '../../components/Layout/Layout';
import { useState } from 'react';

interface NavigatedPageState {
  prevPath?: string;
}
export const ChatMediaPage = () => {
  const location = useLocation();
  const locationState = location.state as NavigatedPageState;
  const { id } = useParams();
  const [optionsOpen, setOptionsOpen] = useState(false);

  const onClickOptions = () => {
    setOptionsOpen(!optionsOpen);
  };
  return (
    <Layout
      to={locationState?.prevPath || `/message/${id}`}
      isMessageLayout
      backBtn
      message="chat_media"
      dotsCallBack={onClickOptions}
      showBottomTabs={false}
    >
      <ChatMedia
        optionsOpen={optionsOpen}
        setOptionsOpen={setOptionsOpen}
        onClickOptions={setOptionsOpen}
      />
    </Layout>
  );
};
