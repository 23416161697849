import { useMemo, useState } from 'react';
import { Layout } from '../../components/Layout/Layout';
import { Page } from '../../components/Page/Page';
import Loader from '../../components/Loader/Loader';
import { t } from 'i18next';
import Arrow from '../../assets/imgs/chats/arrow.svg';
import { SDrawerWrapper } from '../../components/cec/CecTextTemplatesBottomSheet/CecTextTemplatesBottomSheet';
import { TimeSelectionBottomSheet } from '../../components/TimeSelectionBottomSheet/TimeSelectionBottomSheet';
import { DateSelectionBottomSheet } from '../../components/DateSelectionBottomSheet/DateSelectionBottomSheet';
import {
  SActivateButton,
  SActivateButtonContainer,
  SCheckBoxWithSubTitle,
  SDescription,
  SIcon,
  SInputContainer,
  SLabel,
  SlidingContainer,
  SPage,
} from './SetAvailability.styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { editAvailabilityStatus } from '../../apis/authApi/authAPI';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { refreshUserInfo } from '../Login/LoginSlice/actionCreators';
import { selectUser } from '../Login/LoginSlice';
import { translate } from '../../utils/translate';
import {
  canDisplayConfirm,
  parseUnavailableDate,
  parseUnavailableTime,
} from './SetAvailability.helpers';
import { getDateTextByLanguage } from '../../components/SidebarAvailabilityButton/helpers';
import { getNumbersBasedDateTextByLanguage } from '../../utils/date';

export interface Time {
  hours: number;
  minutes: number;
}

export const SetAvailability = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const location = useLocation();
  const prevPath = location.state?.prevPath;

  const [isLoading, setIsLoading] = useState(false);
  const [isAvailable, setIsAvailable] = useState(user?.available ?? true);

  const [date, setDate] = useState<string | undefined>(
    user?.available ? undefined : parseUnavailableDate(user?.unavailableDateTo)
  );
  const [time, setTime] = useState<Time>(
    user?.available ? { hours: 0, minutes: 0 } : parseUnavailableTime(user?.unavailableDateTo)
  );

  const [isTimeSelectionOpen, setIsTimeSelectionOpen] = useState(false);
  const [isDateSelectionOpen, setIsDateSelectionOpen] = useState(false);

  const dateText = useMemo(() => {
    if (!date) return t('date');
    const dateParts = date.split('-');
    const dateTime = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2]),
      12,
      0
    );

    return getNumbersBasedDateTextByLanguage({
      dateText: dateTime.toISOString(),
      excludeTime: true,
    });
  }, [date]);

  const displayConfirm = useMemo(
    () => canDisplayConfirm(isAvailable, date, time, user),
    [date, time, isAvailable, user?.available, user?.unavailableDateTo]
  );

  const timeText = `${time?.hours > 9 ? time?.hours : `0${time.hours}`}:${
    time?.minutes > 9 ? time?.minutes : `0${time.minutes}`
  }`;

  const handleSetTime = (hours: number, minutes: number) => {
    setTime({ hours, minutes });
    setIsTimeSelectionOpen(false);
  };

  const handleSetDate = (dateString: string | undefined) => {
    setDate(dateString);
    setIsDateSelectionOpen(false);
  };

  const handleConfirmation = async () => {
    try {
      setIsLoading(true);
      if (isAvailable) {
        await editAvailabilityStatus({ unavailable: false, unavailableDateTo: null });
      } else {
        if (date) {
          const dateTime = new Date(`${date}T00:00:00`);
          dateTime.setHours(time.hours, time.minutes, 0, 0);
          const unavailableDateTo = dateTime.toISOString();
          await editAvailabilityStatus({ unavailable: true, unavailableDateTo });
        }
      }
      await dispatch(refreshUserInfo());
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      navigate(prevPath ?? '/sidebar');
    }
  };

  const handleSelect = (is: boolean) => {
    setIsAvailable(is);
  };

  if (isLoading || !user) {
    return (
      <Layout isMessageLayout message="profile_not_available_mode_title" to="/sidebar/settings">
        <Page>
          <Loader />
        </Page>
      </Layout>
    );
  }

  return (
    <Layout isMessageLayout message="profile_not_available_mode_title" to={prevPath ?? '/sidebar'}>
      <SPage>
        <div className="mainContainer">
          <SDescription>
            {user?.available
              ? t<string>('profile_not_available_mode_description')
              : t<string>('profile_not_available_until', {
                  dateTime: getDateTextByLanguage(user?.unavailableDateTo as string),
                })}
          </SDescription>

          <SCheckBoxWithSubTitle
            selected={isAvailable}
            title={translate('profile_available')}
            onToggleCheck={() => handleSelect(true)}
            valueId={0}
            checkBoxType={'circle'}
            withoutSeparator
            clickAll={true}
            startWithCheckBox
          />
          <SCheckBoxWithSubTitle
            selected={!isAvailable}
            title={translate('profile_not_available')}
            onToggleCheck={() => handleSelect(false)}
            valueId={0}
            checkBoxType={'circle'}
            withoutSeparator
            clickAll={true}
            startWithCheckBox
          />
          {!isAvailable && (
            <SlidingContainer>
              <SLabel>{t<string>('profile_end_date')}:</SLabel>
              <SInputContainer onClick={() => setIsDateSelectionOpen(true)}>
                <p>{dateText}</p>
                <SIcon src={Arrow} alt="" role="arrowIcon" />
              </SInputContainer>
              <SLabel>{t<string>('profile_end_time')}:</SLabel>
              <SInputContainer onClick={() => setIsTimeSelectionOpen(true)}>
                <p>{timeText}</p>
                <SIcon src={Arrow} alt="" role="arrowIcon" />
              </SInputContainer>
            </SlidingContainer>
          )}
        </div>
        {displayConfirm && (
          <SActivateButtonContainer role="activateButtonContainer">
            <SActivateButton onClick={handleConfirmation} role="activateButton">
              {t<string>('confirm')}
            </SActivateButton>
          </SActivateButtonContainer>
        )}
        <SDrawerWrapper role="drawerWrapper">
          <TimeSelectionBottomSheet
            isOpen={isTimeSelectionOpen}
            setIsOpen={setIsTimeSelectionOpen}
            activeCountdownSetup={handleSetTime}
            initialHours={time?.hours}
            initialMinutes={time?.minutes}
            titleTx="profile_select_time"
          />
        </SDrawerWrapper>
        <SDrawerWrapper role="drawerWrapper">
          <DateSelectionBottomSheet
            isOpen={isDateSelectionOpen}
            setIsOpen={setIsDateSelectionOpen}
            handleConfirm={handleSetDate}
            initialDate={date}
          />
        </SDrawerWrapper>
      </SPage>
    </Layout>
  );
};
