import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../hooks';
import { useConfirmation } from '../../../utils/ConfirmationServiceContext/confirmationContext';
import BigFloatButton from '../../BigFloatButton/BigFloatButton';
import { Layout } from '../../Layout/Layout';
import Loader from '../../Loader/Loader';
import { Page } from '../../Page/Page';
import { STitle, SText, SButtonContainer } from './TestAlarm.styles';
import { getIsLoading } from './testAlarmSlice';
import { startTestAlarm } from './testAlarmSlice/actionCreators';

function TestAlarm() {
  const confirm = useConfirmation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLoading = useAppSelector(getIsLoading);
  const [isAlarmSent, setIsAlarmSent] = React.useState(false);

  const testAlarmSteps = [
    'sidebar_settings_testModalDescription_2',
    'sidebar_settings_testModalDescription_3',
    'sidebar_settings_testModalDescription_4',
  ];

  const handleStartTestAlarm = () => {
    setIsAlarmSent(true);
    dispatch(startTestAlarm());
    setTimeout(() => {
      setIsAlarmSent(false);
    }, 15000);
  };

  const alarmModal = () => {
    confirm({
      title: 'warning',
      description: 'alarm_test_description',
      onSubmit: () => handleStartTestAlarm(),
      onCancel: () => {},
      confirmText: 'alarm_test_trigger',
      cancelText: 'cancel',
    });
  };

  return (
    <Layout isMessageLayout message="alarm_test_screen" to="/sidebar" showBottomTabs>
      <>
        {isLoading && <Loader />}
        {!isLoading && (
          <Page>
            <STitle role="title">{t('alarm_test_title')}</STitle>
            {testAlarmSteps.map((step, i) => (
              <SText role={`step-${step}`} key={i}>
                {t(step)}
              </SText>
            ))}
            <SButtonContainer role="buttonContainer">
              <BigFloatButton
                className="test-alarm-btn"
                tx="alarm_test_button"
                disabled={isAlarmSent}
                onClick={alarmModal}
              />
            </SButtonContainer>
          </Page>
        )}
      </>
    </Layout>
  );
}

export default TestAlarm;
