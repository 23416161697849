import { ReactNode } from 'react';
import { MessageType } from '../../../utils/enums';
import MessageContainer from '../../MessageContainer/MessageContainer';
import Document from '../../../assets/imgs/chats/document-icon--white.svg';
import { decode } from 'html-entities';
import { Attachment } from '../../../containers/ChatsList/chatListSlice/types';
import { formatBytes } from '../../../utils/formatBytes';
import { BASE_URL } from '../../../apis/utils/provider';
import { t } from 'i18next';
import {
  SFilesMessageContainer,
  SPdfInfoContainer,
  SFileName,
  SDescription,
  SPdfSizeText,
  SPdfContainer,
} from './FileMessage.styles';

interface FileMessageProps {
  text: string;
  documents: Attachment[];
  isSent: boolean;
  sentDateTime?: string;
  senderImage: string | null;
  senderName: string;
  isSameSender?: boolean;
  messageTo: string | null;
  toggleModal?: () => void;
  showOnlyAttachment?: boolean;
  forwarded?: boolean;
  edited?: boolean;
  renderReplyToMessage?: () => ReactNode;
  id?: number;
}

function FileMessage(props: FileMessageProps) {
  const {
    text,
    toggleModal,
    documents,
    isSent,
    sentDateTime,
    senderImage,
    senderName,
    isSameSender = false,
    messageTo,
    showOnlyAttachment = false,
    forwarded,
    edited,
    renderReplyToMessage,
    id,
  } = props;

  const messageType = isSent ? MessageType.SentFileMessage : MessageType.ReceivedFileMessage;
  const filteredDocs = documents.filter(d => !!d?.fileName);

  return (
    <MessageContainer
      hasReply={!!renderReplyToMessage}
      messageType={messageType}
      sentDateTime={sentDateTime}
      senderImage={senderImage}
      senderName={senderName}
      messageTo={messageTo}
      toggleModal={toggleModal}
      isSameSender={isSameSender}
      showOnlyAttachment={showOnlyAttachment}
      forwarded={forwarded}
      edited={edited}
      id={id}
    >
      {renderReplyToMessage && renderReplyToMessage()}
      <SFilesMessageContainer role="fileMessageContainer" haveText={!!text}>
        {filteredDocs.map((doc, key) => (
          <SPdfInfoContainer
            key={`pdfContainer-${id}-${key}`}
            marginBottom={filteredDocs.length > 1 && key < filteredDocs.length - 1}
            isSent={isSent}
          >
            <SPdfContainer
              href={`${BASE_URL}/media/file/${doc.fileName}`}
              target="_blank"
              role="pdfContainer"
            >
              <img src={Document} alt="" width={25} role="icon" />
              <div>
                <SFileName role="fileName">{doc.fileName}</SFileName>
                <SPdfSizeText role="fileSize">
                  {doc.fileSize ? formatBytes(doc.fileSize) : ''}
                </SPdfSizeText>
              </div>
            </SPdfContainer>
          </SPdfInfoContainer>
        ))}
        {text && (
          <SDescription role="description">
            {decode(text)}
            {/* Necessary to get space */}
            <span className="editedReplacer">--------{edited && `${t('messages_edited')}`}</span>
          </SDescription>
        )}
      </SFilesMessageContainer>
    </MessageContainer>
  );
}

export default FileMessage;
