import * as React from 'react';
import Like from '../../assets/icons/warning-triangle.svg';
import Arrow from '../../assets/icons/chevron-right.svg';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { palette } from '../../theme/colors';

const SContainer = styled.div<{ $isGrayColor?: boolean }>`
  cursor: pointer;

  padding: 1.6875rem 1.5rem 0.6875rem 2rem;

  position: absolute;
  top: -1rem;

  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 5;

  .margin-right {
    margin-right: 1rem;
  }
  p {
    flex-grow: 1;
  }

  background-color: ${palette.tartOrange};
  ${props =>
    props.$isGrayColor &&
    css`
      background-color: ${palette.stormGray};
    `}
`;

interface IIamOkLinkTopBarProps {
  link: string;
  title: string;
  isGrayColor?: boolean;
  onclick?: () => void;

  endSrc?: string;
}

export const ChatScreenTopBar = (props: IIamOkLinkTopBarProps) => {
  const { link, title, isGrayColor, onclick, endSrc } = props;
  const navigate = useNavigate();

  const handlClick = () => {
    if (onclick) {
      onclick();
    } else {
      navigate(link);
    }
  };

  return (
    <SContainer onClick={handlClick} $isGrayColor={isGrayColor} role="chatScreenTopBar">
      <img className="margin-right" src={endSrc || Like} alt="i am okay" role="icon" />
      <p className="margin-right" role="title">
        {title}
      </p>
      <img src={Arrow} alt="i am okay" role="arrowIcon" />
    </SContainer>
  );
};
