import { includes, isEmpty } from 'lodash';
import { MessageInList } from '../../containers/ChatsList/chatListSlice/types';
import { Group } from '../../containers/GroupsList/groupsSlice/types';

export const filterStatement = (
  statements: MessageInList[],
  onlyIds: number[] | undefined,
  groups: Group[]
): MessageInList[] => {
  if (!onlyIds) {
    return statements;
  }
  return statements.filter(
    messageItem => includes(onlyIds, messageItem.groupID) || isEmpty(groups)
  );
};
