import * as React from 'react';
import { ReactComponent as DateIcon } from '../../assets/icons/clock.svg';
import { NewsItem } from '../../containers/News/newsSlice/types';
import { dateFormats, getDateFormatCustom } from '../../utils/date';
import { sanitizeText } from '../../utils/sanitizeText';
import { translate } from '../../utils/translate';
import { trunctateText } from '../../utils/truncate';
import HyperLinkText from '../HyperlinkText/HyperLinkText';
import {
  SNewsItem,
  SNewsItemContent,
  SNewsItemInfo,
  SNewsItemInfoDate,
  SNewsItemInfoSource,
  SNewsItemRead,
  SNewsItemTitle,
} from './NewsItemCard.styles';

interface INewsItemCardProps extends React.HTMLAttributes<HTMLDivElement> {
  item: NewsItem;
  currentItemIndex: number;
  selectedItemIndex: number;
  onSelectItem: (index: number) => void;
  initialIsOpen?: boolean;
}

export const NewsItemCard = (props: INewsItemCardProps) => {
  const { item, currentItemIndex, selectedItemIndex, onSelectItem, initialIsOpen = false } = props;
  const [isitemOpened, setIsItemOpened] = React.useState<boolean>(initialIsOpen);
  const limitedWords = 120;

  const onToggleContent = () => {
    onSelectItem(currentItemIndex);
    setIsItemOpened(prevState => !prevState);
  };

  React.useEffect(() => {
    // To avoid click twice for expanding news item.
    // This issue happens when one news item is aleady expanded and need to open another news item and rest need to be contacted.
    if (selectedItemIndex === currentItemIndex) {
      setIsItemOpened(true);
    }
  }, [selectedItemIndex]);

  const getCharacterLength = (text: string): number => {
    return sanitizeText(text, 'html').length;
  };

  return (
    <SNewsItem role={`news-card-${item?.event}`}>
      <SNewsItemTitle role="title" target="_blank" href={item?.externalURL}>
        {item?.event}
      </SNewsItemTitle>
      <SNewsItemInfo role="infoContainer">
        <SNewsItemInfoSource role="source">{item?.feedName}</SNewsItemInfoSource>
        <SNewsItemInfoDate role="dateTextContainer">
          <DateIcon role="dateIcon" />
          <p role="dateText">
            {getDateFormatCustom(item?.date, dateFormats.monthNameShortDateTimeNoComma24)}
          </p>
        </SNewsItemInfoDate>
      </SNewsItemInfo>
      <SNewsItemContent role="newsTextContainer">
        {selectedItemIndex === currentItemIndex &&
        getCharacterLength(item?.text) > limitedWords &&
        isitemOpened ? (
          <HyperLinkText text={sanitizeText(item?.text, 'html')} />
        ) : (
          <HyperLinkText text={trunctateText(sanitizeText(item?.text, 'html'), limitedWords)} />
        )}

        <SNewsItemRead role="readMoreOrLessButton" onClick={onToggleContent}>
          {selectedItemIndex === currentItemIndex &&
          getCharacterLength(item?.text) > limitedWords &&
          isitemOpened
            ? translate('news_read_less')
            : selectedItemIndex === currentItemIndex &&
                getCharacterLength(item?.text) > limitedWords &&
                !isitemOpened
              ? translate('news_read_more')
              : selectedItemIndex !== currentItemIndex &&
                  getCharacterLength(item?.text) > limitedWords
                ? translate('news_read_more')
                : ''}
        </SNewsItemRead>
      </SNewsItemContent>
    </SNewsItem>
  );
};
