import isElectron from 'is-electron';
import React, { useEffect, useRef, useState } from 'react';
import Camera from '../../assets/icons/camera.svg';
import Options, { OptionItemProps } from '../Options/Options';
import Gallery from '../../assets/imgs/chats/gallery.svg';
import Pin from '../../assets/imgs/chats/pin.svg';
import NewDoc from '../../assets/imgs/chats/new-doc.svg';
import SendIcon from '../../assets/icons/paper-plane--yellow.svg';
import _ from 'lodash';
import { saveImageToServer } from '../../apis/mediaAPI';
import { useAppDispatch } from '../../hooks';
import { setIsLoading } from '../../containers/CreateMessage/createMessageSlice';
import { ReactComponent as PDF } from '../../assets/imgs/chats/pdf.svg';
import Delete from '../../assets/imgs/chats/delete-round.svg';
import { trunctateText } from '../../utils/truncate';
import { Wrapper } from '@googlemaps/react-wrapper';
import { Map } from '../Map/Map';
import { Marker } from '../Map/MapMarker/MapMarker';
import { saveLocation } from '../../apis/locationAPI';
import MapDisplay from '../Map/MapDisplay';
import Geocoder, { apiKey, defaultLat, defaultLng } from '../../utils/geocoder';
import { ReactComponent as Edit } from '../../assets/imgs/chats/edit.svg';
import { ReactComponent as Close } from '../../assets/imgs/chats/close.svg';
import ReplyClose from '../../assets/imgs/chats/close-circle.svg';
import { translate } from '../../utils/translate';
import { ReplyToMessageShorted } from '../../containers/Chat/Chat';
import { useTranslation } from 'react-i18next';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import HyperLinkText from '../HyperlinkText/HyperLinkText';
import ResizeObserver from 'resize-observer-polyfill';
import { setAreFilesSending } from '../../containers/ChatsList/chatListSlice';
import {
  SFileInput,
  SChatBoxWrapper,
  SChatBox,
  STop,
  SBottom,
  YellowAdd,
  SImageContainer,
  SImg,
  SPdf,
  SAddMoreImages,
  STextContainer,
  STextInput,
  SIcon,
  SAttachedMap,
  EditContainer,
  SReplyContainer,
  SReplyTextContainer,
  SFakeFooter,
} from './ChatBox.styles';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { acceptedImageUploadFormatsText } from '../../utils/global';
import { getUnsupportedImageUploadWarningText } from '../../utils/helperFunctions';

interface ChatBoxProps {
  editText?: string;
  tabBar: boolean;
  setTabBar: React.Dispatch<React.SetStateAction<boolean>>;
  setEditText?: React.Dispatch<React.SetStateAction<string | undefined>>;
  className?: string;

  isDependencyAdded?: boolean;
  dependencyRequired?: boolean;
  onDependencyReFocus?: () => void;

  replyMessage?: ReplyToMessageShorted;
  dismissReplyToMessage?: () => void;

  onTextSend: (textMessage: string) => void;
  onPhotosSend: (photosList: string[], textMessage: string) => void;
  onDocumentsSend: (documentFiles: File[], textMessage: string) => void;
  onLocationSend: (locationId: number, text?: string) => void;
  hideGalleryOption?: boolean;
  hideLocationOption?: boolean;
  hideFileOption?: boolean;
}

function ChatBox(props: ChatBoxProps) {
  const {
    editText,
    className,
    setEditText,
    onTextSend,
    onPhotosSend,
    onDocumentsSend,
    onLocationSend,
    isDependencyAdded,
    dependencyRequired,
    onDependencyReFocus,
    replyMessage,
    dismissReplyToMessage,
    hideGalleryOption = false,
    hideLocationOption = false,
    hideFileOption = false,
  } = props;

  const dispatch = useAppDispatch();
  const layout = useLayoutContext();
  const { t } = useTranslation();
  const confirm = useConfirmation();

  const [optionsOpen, setOptionsOpen] = useState(false);
  const [text, setText] = useState('');
  const [documents, setDocuments] = useState<File[] | null>(null);
  const [images, setImages] = useState<File[]>([]);
  const [imagesPreview, setImagesPreview] = useState<{ name: string; id: number }[]>([]);
  const [keyReload, setKeyReload] = useState(0);
  const [attachedMap, setAttachedMap] = useState(false);
  const [location, setLocation] = useState<google.maps.LatLngLiteral>({
    lat: defaultLat,
    lng: defaultLng,
  });
  const [fakeFooterHeight, setFakeFooterHeight] = useState(0);

  const fileUploadRef = useRef<any>(null);
  const imageUploadRef = useRef<any>(null);
  const imageContainerRef = useRef<any>(null);
  const chatBoxElRef = useRef(null);

  const updateFakeFooterHeight = () => {
    if (chatBoxElRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setFakeFooterHeight(chatBoxElRef.current.clientHeight);
    }
  };

  useEffect(() => {
    if (isElectron()) {
      // Initial update fakeFooter height
      updateFakeFooterHeight();

      // Track chatBox height changes
      const resizeObserver = new ResizeObserver(updateFakeFooterHeight);
      if (chatBoxElRef.current) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resizeObserver.observe(chatBoxElRef.current);
      }

      // Cleanup
      return () => resizeObserver.disconnect();
    }
  }, [layout.isMapOpen]);

  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const limitedSize = attachedMap ? 69 : !_.isEmpty(images) || !_.isNull(documents) ? 115 : 186;

  const handleTextInputResize = () => {
    if (
      textInputRef.current !== undefined &&
      textInputRef.current !== null &&
      textInputRef.current.style !== undefined &&
      textInputRef.current.style.height !== undefined
    ) {
      textInputRef.current.style.height = '2.5rem';

      if (textInputRef.current.scrollHeight >= limitedSize) {
        textInputRef.current.style.height = `${limitedSize}px`;
        textInputRef.current.style.borderRadius = `${20}px`;
      } else if (textInputRef.current.scrollHeight !== undefined) {
        textInputRef.current.style.height = `${textInputRef.current.scrollHeight}px`;
      }
    }
  };

  const onCancelImages = () => {
    setImages([]);
    setImagesPreview([]);
    if (imageUploadRef.current) imageUploadRef.current.value = null;
    handleTextInputResize();
  };

  const onCancelDocuments = () => {
    setDocuments(null);
    if (fileUploadRef.current) fileUploadRef.current.value = null;
    handleTextInputResize();
  };

  const onCancelLocation = () => {
    setAttachedMap(false);
    setLocation({ lat: 0, lng: 0 });
  };

  useEffect(() => {
    if (editText) {
      onCancelImages();
      onCancelDocuments();
      onCancelLocation();
      setText(editText);
      setTimeout(() => {
        handleTextInputResize();
      }, 50);
    }
  }, [editText]);

  const onImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageArr = Array.prototype.slice.call(e.target.files);
    if (_.isEmpty(imageArr)) return;

    const newImagesPreview = [...imagesPreview];
    const newImages = [...images];

    for (const image of imageArr) {
      const imageToPush = URL.createObjectURL(image);
      newImagesPreview.push({
        name: imageToPush,
        id: newImagesPreview.length,
      });
      newImages.push(image);
    }

    if (!_.isEmpty(newImagesPreview)) {
      setImages(newImages);
      setImagesPreview(newImagesPreview);
      setOptionsOpen(false);
    }
  };

  const onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileArr = Array.prototype.slice.call(e.target.files);
    if (_.isEmpty(fileArr)) return;

    if (!_.isNull(documents)) {
      setDocuments([...documents, ...fileArr]);
    } else {
      setDocuments(fileArr);
    }
    setOptionsOpen(false);
  };
  const options: OptionItemProps[] = [];
  if (!hideGalleryOption)
    options.push({
      name: 'messages_photo_or_video',
      icon: Gallery,
      callback: () => {
        imageUploadRef?.current?.click();
      },
    });
  if (!hideLocationOption)
    options.push({
      name: 'messages_location',
      icon: Pin,
      callback: () => {
        layout.setMapOpen(true);
        setOptionsOpen(false);
      },
    });
  if (!hideFileOption)
    options.push({
      name: 'messages_file',
      icon: NewDoc,
      callback: () => {
        fileUploadRef?.current?.click();
      },
    });

  const onClickAdd = () => {
    setOptionsOpen(!optionsOpen);
  };

  const saveImage = async (res: Blob) => {
    try {
      const formData = new FormData();
      formData.append('image', res);
      const result = await saveImageToServer(formData);
      if (result) {
        return result;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveImages = async () => {
    const imagesFilesNamesArray = [];
    let index = 0;

    for await (const image of images) {
      if (_.find(imagesPreview, e => e.id === index)) {
        const result = await saveImage(image);
        if (result) {
          imagesFilesNamesArray.push(result);
        }
      }
      index++;
    }
    return imagesFilesNamesArray;
  };

  const sendMessage = async () => {
    if (!_.isEmpty(images) && !_.isEmpty(imagesPreview)) {
      dispatch(setAreFilesSending(true));
      const imagesFilesNamesArray = await saveImages();
      if (!imagesFilesNamesArray?.length) {
        confirm({
          title: 'unsupported_image_format',
          description: getUnsupportedImageUploadWarningText(),
          onSubmit: () => {},
          confirmText: 'ok',
          confirmStyle: 'red',
        });
        dispatch(setAreFilesSending(false));
        dispatch(setIsLoading(false));
        return;
      }
      onPhotosSend(imagesFilesNamesArray, text);
    } else if (!_.isNull(documents)) {
      dispatch(setAreFilesSending(true));
      onDocumentsSend(documents, text);
    } else if (attachedMap) {
      dispatch(setAreFilesSending(true));
      const result = await Geocoder({
        latitude: location.lat,
        longitude: location.lng,
      });
      const locName = result?.addressName
        ? result?.addressName
        : `${location.lat} | ${location.lng}`;
      const loc = {
        name: locName,
        latitude: location.lat,
        longitude: location.lng,
      };
      const createLocationResponse = await saveLocation(loc);
      onLocationSend(createLocationResponse.id, text);
    } else {
      onTextSend(text);
    }
  };

  const resetTextInputResize = () => {
    if (
      textInputRef.current !== undefined &&
      textInputRef.current !== null &&
      textInputRef.current.style !== undefined &&
      textInputRef.current.style.height !== undefined
    ) {
      textInputRef.current.style.height = '2.5rem';
    }
  };

  const onCancelEdit = () => {
    if (setEditText) {
      setEditText(undefined);
    }
    setText('');
    resetTextInputResize();
  };

  const onMessageSend = async () => {
    if (dependencyRequired) {
      if (isDependencyAdded) {
        dispatch(setIsLoading(true));
        sendMessage();
        onCancelImages();
        onCancelDocuments();
        onCancelLocation();
        onCancelEdit();
      } else if (onDependencyReFocus) {
        onDependencyReFocus();
      }
    } else {
      dispatch(setIsLoading(true));
      sendMessage();
      onCancelImages();
      onCancelDocuments();
      onCancelLocation();
      onCancelEdit();
    }
  };

  const onWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    const containerScrollPosition = imageContainerRef.current.scrollLeft;
    imageContainerRef.current.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY * 0.1,
    });
  };

  const handleRemoveImage = (id: number) => {
    if (_.isEmpty([imagesPreview, images])) return;
    const newPreviewState = imagesPreview;

    newPreviewState.splice(
      newPreviewState.findIndex(e => e.id === id),
      1
    );

    setImagesPreview(newPreviewState);
    setKeyReload(keyReload + 1);
    if (_.isEmpty(newPreviewState)) {
      onCancelImages();
    }
  };

  const handleRemoveDocument = (id: number) => {
    if (_.isNull(documents)) return;

    const newDocState = [...documents];
    newDocState.splice(id, 1);
    setDocuments(newDocState);

    if (_.isEmpty(newDocState)) {
      onCancelDocuments();
    }
  };

  useEffect(() => {
    if (textInputRef.current) handleTextInputResize();
    if (!textInputRef.current) setTimeout(handleTextInputResize, 5);
  }, [optionsOpen, layout.isMapOpen]);

  if (layout.isMapOpen) {
    return (
      <MapDisplay
        backLink="#"
        handleClickBack={() => layout.setMapOpen(false)}
        buttonColor="yellow"
        buttonTx="messages_attach_map"
        location={location}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setLocation={setLocation}
        onButtonClick={() => {
          layout.setMapOpen(false);
          setAttachedMap(true);
        }}
      />
    );
  }

  return (
    <>
      {props.tabBar && (
        <>
          {editText && (
            <EditContainer role="editContainer">
              <Edit role="editIcon" />
              <p className="editText" role="editText">
                {translate('message_editing')}
              </p>
              <Close onClick={onCancelEdit} className="close" role="close" />
            </EditContainer>
          )}
          {replyMessage && (
            <SReplyContainer role="replyContainer">
              {replyMessage.messageIcon && (
                <img
                  role="messageIcon"
                  src={replyMessage.messageIcon.src}
                  alt={'messageIcon'}
                  className={replyMessage.messageIcon.classPresets}
                />
              )}
              <SReplyTextContainer role="replyTextContainer">
                <p className="senderTitle" role="senderTitle">
                  {t('message_reply_to', { sender: replyMessage.senderName })}
                </p>
                <p className="messageBody" role="messageBody">
                  <HyperLinkText text={replyMessage.messageText} />
                </p>
              </SReplyTextContainer>
              <img
                role="closeIcon"
                src={ReplyClose}
                alt={'close'}
                onClick={dismissReplyToMessage}
                className="close"
              />
            </SReplyContainer>
          )}
          <SChatBoxWrapper
            ref={chatBoxElRef}
            className={className}
            map={attachedMap}
            margin={!_.isEmpty(images) || !_.isNull(documents)}
            role="chatBoxWrapper"
          >
            <SChatBox role="chatBox">
              <STop role="contentContainer">
                {!_.isEmpty(images) || !_.isNull(documents) || attachedMap ? (
                  <SIcon
                    onClick={
                      !_.isNull(documents)
                        ? onCancelDocuments
                        : attachedMap
                          ? onCancelLocation
                          : onCancelImages
                    }
                    src={Delete}
                    alt="Delete"
                    role="deleteIcon"
                  />
                ) : (
                  <YellowAdd
                    role="addIcon"
                    onClick={editText ? () => {} : onClickAdd}
                    $disable={!!editText}
                  />
                )}
                <STextContainer
                  role="textContainer"
                  onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}
                >
                  <STextInput
                    role="textInput"
                    ref={textInputRef}
                    value={text}
                    placeholder={translate('messages_writeYourMessage')}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      handleTextInputResize();
                      setText(e.target.value);
                    }}
                  />
                </STextContainer>
                {!_.isEmpty(text.trim()) ||
                !_.isNull(documents) ||
                !_.isEmpty(images) ||
                attachedMap ? (
                  <SIcon role="sendIcon" onClick={onMessageSend} src={SendIcon} alt="send" />
                ) : (
                  <SIcon
                    onClick={() => imageUploadRef?.current?.click()}
                    src={Camera}
                    alt="camera"
                    role="cameraIcon"
                  />
                )}
              </STop>
              {!_.isEmpty(imagesPreview) && (
                <SBottom role="imagesRow">
                  <SImageContainer onWheel={onWheel} ref={imageContainerRef} role="imagesContainer">
                    <div className="scrollableImages" key={keyReload} role="scrollableImages">
                      {imagesPreview.map(image => (
                        <SImg key={image.id} role="imageContainer">
                          <img role="image" className="picture" src={image.name} alt="" />
                          <img
                            className="del"
                            src={Delete}
                            alt=""
                            onClick={() => handleRemoveImage(image.id)}
                            role="deleteIcon"
                          />
                        </SImg>
                      ))}
                    </div>
                  </SImageContainer>
                  <SAddMoreImages
                    role="addMoreImages"
                    onClick={() => imageUploadRef?.current?.click()}
                  >
                    <YellowAdd role="add" />
                  </SAddMoreImages>
                </SBottom>
              )}
              {documents !== null && (
                <SBottom role="documentsRow">
                  <SImageContainer
                    pdf={true}
                    onWheel={onWheel}
                    ref={imageContainerRef}
                    role="documentsContainer"
                  >
                    <div className="scrollableImages" role="scrollableDocuments">
                      {documents.map((doc, key) => (
                        <SPdf key={key} role="pdfContainer">
                          <PDF role="pdfIcon" />
                          <img
                            className="del"
                            src={Delete}
                            alt=""
                            onClick={() => {
                              handleRemoveDocument(key);
                            }}
                            role="deleteIcon"
                          />
                          <p role="documentName">{trunctateText(doc.name, 20)}</p>
                        </SPdf>
                      ))}
                    </div>
                  </SImageContainer>
                  <SAddMoreImages
                    role="addMoreImages"
                    onClick={() => fileUploadRef?.current?.click()}
                  >
                    <YellowAdd role="add" />
                  </SAddMoreImages>
                </SBottom>
              )}
              {attachedMap && (
                <SAttachedMap role="attachedMap">
                  <Wrapper apiKey={apiKey!} libraries={['places']}>
                    <Map
                      center={{
                        lat: location.lat,
                        lng: location.lng,
                      }}
                      zoom={18}
                      className="fullHeightMap"
                      disableDefaultUI
                      clickableIcons={false}
                    >
                      <Marker
                        position={{
                          lat: location.lat,
                          lng: location.lng,
                        }}
                      />
                    </Map>
                  </Wrapper>
                </SAttachedMap>
              )}
            </SChatBox>
          </SChatBoxWrapper>
        </>
      )}
      <Options items={options} isOpen={optionsOpen} setIsOpen={setOptionsOpen} />
      <SFileInput
        onChange={onImageUpload}
        ref={imageUploadRef}
        id="imageUpload"
        type="file"
        multiple
        accept={acceptedImageUploadFormatsText}
      />
      <SFileInput
        onChange={onFileUpload}
        ref={fileUploadRef}
        id="fileUpload"
        type="file"
        multiple
        accept="application/pdf"
      />
      {isElectron() && (
        <SFakeFooter
          role="fakeFooter"
          $height={fakeFooterHeight ? `${fakeFooterHeight}px` : undefined}
        ></SFakeFooter>
      )}
    </>
  );
}

export default ChatBox;
