import { translate } from './translate';

export interface ValidateProps {
  value: string;
  values: any;
  validator: (input: any, values: any) => string | null;
}

export const validate = (props: ValidateProps): string | null => {
  const { validator, value, values } = props;
  return validator(value, values);
};

export const emailSchema = (input: string): string | null => {
  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^([\w\.\-\+]+)@([\w\-]+(\.[\w\-]+)*\.(\w{2,20}))$/;
  if (!input) {
    return translate('login_enterEmail');
  } else if (!emailRegex.test(input)) {
    return translate('login_enterValidEmail');
  } else {
    return null;
  }
};

export const PasswordMessagesTx = {
  Missing: 'login_PasswordMissing',
  MissingUppercase: 'login_passwordMissingUppercase',
  MissingLowercase: 'login_passwordMissingLowercase',
  MissingSpecialChar: 'login_passwordMissingSpecialChar',
  MissingNumber: 'login_passwordMissingNumber',
  MinimumLength: 'login_password10Char',
};

export const hasUppercase = (input: string): boolean => /^(?=.*[A-Z]).+$/.test(input);
export const hasLowercase = (input: string): boolean => /^(?=.*[a-z]).+$/.test(input);
export const hasSpecialCharacter = (input: string): boolean => /^(?=.*[^a-zA-Z0-9]).+$/.test(input);
export const hasDigit = (input: string): boolean => /^(?=.*\d).+$/.test(input);
export const hasMinimumLength = (input: string, minLength: number): boolean =>
  input.length >= minLength;

export const passwordSchema = (input: string): string | null => {
  if (!input) {
    return translate(PasswordMessagesTx.Missing);
  }
  if (!hasUppercase(input)) {
    return translate(PasswordMessagesTx.MissingUppercase);
  }
  if (!hasLowercase(input)) {
    return translate(PasswordMessagesTx.MissingLowercase);
  }
  if (!hasSpecialCharacter(input)) {
    return translate(PasswordMessagesTx.MissingSpecialChar);
  }
  if (!hasDigit(input)) {
    return translate(PasswordMessagesTx.MissingNumber);
  }
  if (!hasMinimumLength(input, 10)) {
    return translate(PasswordMessagesTx.MinimumLength);
  }
  return null;
};

export const validateConfirmPassword = (
  confirmedPassword: string,
  originalPassword: string
): string | null => {
  if (!originalPassword) {
    return translate('login_PasswordMissing');
  } else if (!confirmedPassword) {
    return translate('login_confirmPassword');
  } else if (confirmedPassword !== originalPassword) {
    return translate('login_passwordsDoNotMatch');
  } else {
    return null;
  }
};
