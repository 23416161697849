import React, { SyntheticEvent, useEffect, useState } from 'react';
import BigFloatButton from '../../components/BigFloatButton/BigFloatButton';
import NameChecklistModal from '../../components/Checklists/NameChecklistModal';
import { ReactComponent as Burger } from '../../assets/icons/hamburger-icon.svg';
import { ReactComponent as Dots } from '../../assets/icons/arrow-right-bent--grey.svg';
import Options, { OptionItemProps } from '../../components/Options/Options';
import Pencil from '../../assets/imgs/chats/edit-white.svg';
import Delete from '../../assets/icons/trash-can--white.svg';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  createChecklist,
  editChecklistItems,
  fetchChecklistItems,
  fetchOneChecklist,
  renameAChecklist,
} from './checklistsSlice/actionCreators';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getActiveChecklist,
  getChecklistItems,
  getTempChecklistTasks,
  reset,
  selectGroupsSelection,
  selectUserSelection,
  setActiveTab,
  setTempChecklistTasks,
} from './checklistsSlice';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import { ChecklistTask } from './checklistsSlice/types';
import { translate } from '../../utils/translate';
import UserAdd from '../../assets/icons/silhouette-with-plus.svg';
import { ChecklistStatus } from '../../utils/enums';
import { SPage } from './Checklists.styles';
import {
  SNewChecklist,
  SNewCheclistItemContainer,
  SNewCheclistItem,
  SBigFloatButton,
} from './NewChecklist.styles';
import { cloneDeep } from 'lodash';

interface NewChecklistProps {
  id?: string;
  edit?: boolean;
  isHeaderOptionsOpen: boolean;
  setHeaderOptionsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function NewChecklist(props: NewChecklistProps) {
  const { id, edit, isHeaderOptionsOpen, setHeaderOptionsOpen } = props;

  const confirm = useConfirmation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const layout = useLayoutContext();

  const checklist = useAppSelector(getActiveChecklist);
  const items = useAppSelector(getChecklistItems);
  const preSelectedTasks = useAppSelector(getTempChecklistTasks);
  const { checklistName } = useParams();
  const encodedChecklistName = encodeURIComponent(checklistName ?? '');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [tasks, setTasks] = useState<ChecklistTask[]>([]);
  const [dragId, setDragId] = useState('');
  const [selectedList, setSelectedList] = useState('');
  const [text, setText] = useState('');
  const [disabled, setDisabled] = useState(false);
  const { singleSelected } = useAppSelector(selectUserSelection);
  const { selectedGroups } = useAppSelector(selectGroupsSelection);
  const started = checklist?.status === 'Started';

  useEffect(() => {
    if (preSelectedTasks) {
      setTasks([...preSelectedTasks]);
    }
  }, []);

  useEffect(() => {
    if (!isOptionsOpen && !isModalOpen) setSelectedList('');
  }, [isOptionsOpen, isModalOpen]);

  useEffect(() => {
    if (!id || !edit) return;

    dispatch(fetchOneChecklist(Number(id)));
    dispatch(fetchChecklistItems(Number(id)));
  }, [id]);

  useEffect(() => {
    if (!id || !edit) return;
    layout.setMessage(checklist?.name);
  }, [checklist]);

  useEffect(() => {
    if (!id || !edit) return;

    const newState = [];
    for (const item of items) {
      newState.push({
        id: item.id.toString(),
        sortIndex: item.sortIndex,
        name: item.name,
      });
    }

    setTasks(newState);
  }, [items]);

  const handleDelete = () => {
    const foundIndex = tasks.findIndex(item => item.id === selectedList);
    const newList = [...tasks];
    newList[foundIndex].deleted = true;
    newList
      .filter(a => !a.deleted)
      .forEach((item, key) => {
        item.sortIndex = key + 1;
      });
    setTasks(newList);
    setIsOptionsOpen(false);
  };

  const onDeleteTemplate = () => {
    dispatch(reset());
    navigate('/checklists');
  };

  const handleDeleteTemplate = () => {
    confirm({
      title: 'messages_confirmation',
      description: 'checklist_delete_confirmation',
      onSubmit: onDeleteTemplate,
      onCancel: () => {},
      confirmText: 'delete',
      confirmStyle: 'red',
    });
  };

  const handleEdit = () => {
    const newText = tasks.find(element => element.id === selectedList)?.name || '';
    setIsModalOpen(true);
    setIsOptionsOpen(false);
    setText(newText);
  };

  const handleAddRecipients = () => {
    setHeaderOptionsOpen(false);
    dispatch(setTempChecklistTasks(tasks));
    if (edit) {
      navigate(`/checklist/${id}/edit-access`);
    } else {
      navigate(`/checklists/create/${encodedChecklistName}`, { state: { fromNew: true } });
    }
  };

  const handleRenameChecklist = () => {
    setHeaderOptionsOpen(false);
    confirm({
      description: 'checklist_name_checklist_template',
      onSubmit: (textOnSubmit?: string) => {
        if (textOnSubmit && textOnSubmit.length > 0) {
          if (edit) {
            layout.setMessage(textOnSubmit);
          } else {
            navigate(`/checklists/new/${encodeURIComponent(textOnSubmit)}`, { replace: true });
          }
        }
      },
      onCancel: () => {},
      confirmText: 'proceed',
      inputBox: true,
      placeholderTx: 'checklist_name_checklist_template',
      inputBoxIntialValue: edit ? checklist?.name : checklistName,
      isConfirmDisabledWhenEmpty: true,
    });
  };

  const CHECKLIST_OPTIONS: OptionItemProps[] = [
    {
      name: 'messages_edit',
      icon: Pencil,
      callback: handleEdit,
    },
    {
      name: 'messages_delete',
      icon: Delete,
      callback: handleDelete,
    },
  ];

  const CHECKLIST_HEADER_OPTIONS: OptionItemProps[] = [
    {
      name: 'messages_add_recipients',
      icon: UserAdd,
      callback: handleAddRecipients,
    },
    {
      name: 'checklist_edit_name',
      icon: Pencil,
      callback: handleRenameChecklist,
    },
    {
      name: 'messages_delete',
      icon: Delete,
      callback: handleDeleteTemplate,
    },
  ];

  if (started) {
    CHECKLIST_HEADER_OPTIONS.splice(0);
  }

  const handleDrag = (e: SyntheticEvent) => {
    setDragId(e.currentTarget.id);
  };

  const handleDrop = (e: SyntheticEvent) => {
    const dragItem = tasks.find(item => item.id === dragId);
    const dropItem = tasks.find(item => item.id === e.currentTarget.id);
    if (!dragItem || !dropItem) return;

    const dragItemOrder = dragItem.sortIndex;
    const dropItemOrder = dropItem.sortIndex;

    const newItemState = cloneDeep(tasks);

    newItemState.forEach(item => {
      if (item.id === dragId) {
        item.sortIndex = dropItemOrder;
      }
      if (item.id === e.currentTarget.id) {
        item.sortIndex = dragItemOrder;
      }
      return item;
    });

    setTasks(newItemState);
  };

  const handleAddNew = () => {
    if (selectedList) {
      const newList = tasks;
      const indx = newList.findIndex(item => item.id === selectedList);
      newList[indx].name = text;
      setTasks(newList);
      setSelectedList('');
    } else {
      setTasks(updatedTasks => [
        ...updatedTasks,
        {
          id: `${text}-${updatedTasks.length + 1}`,
          name: text,
          sortIndex: updatedTasks.filter(task => !task.deleted).length + 1,
          new: true,
        },
      ]);
    }
    setIsModalOpen(false);
  };

  const handleSaveEdit = () => {
    const currentChecklistMessage = layout.currentTitle;
    const isNameChanged = currentChecklistMessage !== checklist?.name;
    dispatch(setTempChecklistTasks(null));
    const activeTasks = tasks.filter(task => !task.deleted);
    const deletedTasks = tasks.filter(task => task.deleted === true);
    const taskList = activeTasks.map((task, index) => {
      return {
        name: task.name,
        sortIndex: index + 1,
        delete: false,
        id: task.new ? null : task.id,
      };
    });
    deletedTasks.forEach((task, index) => {
      taskList.push({
        name: task.name,
        sortIndex: index + 1,
        delete: true,
        id: task.id,
      });
    });

    if (isNameChanged) dispatch(renameAChecklist(Number(id), currentChecklistMessage));
    dispatch(setActiveTab(ChecklistStatus.NotStarted));
    dispatch(editChecklistItems(Number(id), taskList, () => navigate(`/checklist/${id}`)));
  };

  const handleCreate = () => {
    setDisabled(true);
    dispatch(setTempChecklistTasks(null));
    const activeTasks = tasks.filter(task => !task.deleted);

    const newList = activeTasks.map(task => ({
      sortIndex: task.sortIndex,
      name: task.name,
    }));
    dispatch(
      createChecklist({
        checklistName: checklistName ?? '',
        groupIds: selectedGroups,
        userIds: singleSelected,
        list: newList,
        onSuccess: () => {
          setDisabled(false);
          navigate('/checklists');
        },
      })
    );
  };

  return (
    <SPage>
      <SNewChecklist role="newChecklistContainer">
        <SNewCheclistItemContainer role="itemsList">
          {tasks
            .sort((a, b) => a.sortIndex - b.sortIndex)
            .filter(a => !a.deleted)
            .map(item => (
              <SNewCheclistItem
                $isFirst={item.sortIndex === 1}
                key={item.id}
                id={item.id || ''}
                draggable={true}
                onDragOver={e => (!started ? e.preventDefault() : '')}
                onDragStart={handleDrag}
                onDrop={handleDrop}
                role={`checklistItem-${item.name}`}
              >
                {!started && <Burger role="burgerIcon" />}
                <div className="nameContainer">
                  <p role="name">{`${item.sortIndex}. ${item.name}`}</p>
                </div>
                {!started && (
                  <Dots
                    className="dots"
                    onClick={() => {
                      setIsOptionsOpen(!isOptionsOpen);
                      setSelectedList(item.id || '');
                    }}
                    role="moreIcon"
                  />
                )}
              </SNewCheclistItem>
            ))}
        </SNewCheclistItemContainer>

        <SBigFloatButton
          tx={translate('checklist_add_task') + ' +'}
          $tasksEmpty={!tasks.length}
          onClick={() => {
            setIsModalOpen(!isModalOpen);
          }}
        />

        {!!tasks?.length && (
          <BigFloatButton
            onClick={edit ? handleSaveEdit : handleCreate}
            tx={'save_and_proceed'}
            disabled={disabled}
          />
        )}
      </SNewChecklist>
      <NameChecklistModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onAdd={handleAddNew}
        text={text}
        setText={setText}
      />
      <Options
        items={CHECKLIST_OPTIONS}
        isOpen={isOptionsOpen}
        setIsOpen={setIsOptionsOpen}
        setTabBar
      />

      <Options
        items={CHECKLIST_HEADER_OPTIONS}
        isOpen={isHeaderOptionsOpen}
        setIsOpen={setHeaderOptionsOpen}
        setTabBar
      />
    </SPage>
  );
}

export default NewChecklist;
