// Groups filter
export enum GroupsToShow {
  All = 'labelAll',
  MemberOfGroup = 'messages_groups_where_member',
  NotMemberOfGroup = 'messages_groups_where_not_member',
}

export const acceptedImageUploadFormats = ['png', 'bmp', 'gif', 'jpg', 'jpeg'];

export const acceptedImageUploadFormatsText = acceptedImageUploadFormats
  .map(format => `image/${format}`)
  .join(',');
