/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Loader from '../../components/Loader/Loader';
import MapDisplay from '../../components/Map/MapDisplay';
import { Page } from '../../components/Page/Page';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { AlarmStep } from '../../utils/enums';
import { defaultLat, defaultLng } from '../../utils/geocoder';
import { Location } from '../GroupDetail/groupDetailSlice/types';
import { selectUser } from '../Login/LoginSlice';
import {
  getAlarmCreateModel,
  getAlarmCustomText,
  isAlarmLoading,
  selectAlarmGroups,
  setIsLoading,
  setSelectedGroups,
} from './AlarmSlice';
import { handleNavigate, showWarningConfirmationModal } from './AlarmSlice/actionCreators';
import { isNil } from 'lodash';
function AlarmMap() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(isAlarmLoading);
  const [location, setLocation] = useState<google.maps.LatLngLiteral>();

  const user = useAppSelector(selectUser);
  const selectedGroups = useAppSelector(selectAlarmGroups);
  const alarmType = useAppSelector(getAlarmCreateModel);
  const customText = useAppSelector(getAlarmCustomText);
  const confirm = useConfirmation();
  const formatLocation = (locationObject?: google.maps.LatLngLiteral | null) => {
    return locationObject
      ? ({
          name: null,
          latitude: locationObject.lat,
          longitude: locationObject.lng,
        } as Location)
      : null;
  };

  useEffect(() => {
    if (alarmType?.NoGroup) {
      if (!alarmType.Groups || alarmType === null) {
        navigate(`/alarm`);
      }
    } else {
      if (!selectedGroups || alarmType === null) {
        navigate(`/alarm`);
      }
    }
  }, []);

  const callHandleNavigate = (locationItem?: google.maps.LatLngLiteral | null) => {
    dispatch(
      handleNavigate(
        user?.id,
        selectedGroups,
        alarmType,
        navigate,
        AlarmStep.SelectLocation,
        confirm,
        formatLocation(locationItem),
        customText
      )
    );
  };

  const onButtonClick = () => {
    if (alarmType?.warningStep) {
      dispatch(
        showWarningConfirmationModal(
          user?.id,
          selectedGroups,
          alarmType,
          navigate,
          confirm,
          location?.lat ? formatLocation(location) : null,
          customText
        )
      );
    } else {
      dispatch(setIsLoading(true));
      if (!location || isNil(location.lat)) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            position => {
              callHandleNavigate({ lat: position.coords.latitude, lng: position.coords.longitude });
            },
            error => {
              callHandleNavigate({ lat: defaultLat, lng: defaultLng });
              console.error('Geolocation Error' + error.code, error.message);
            },
            { enableHighAccuracy: true, timeout: 15000 }
          );
        } else {
          callHandleNavigate({ lat: defaultLat, lng: defaultLng });
          console.warn("Browser doesn't support Geolocation");
        }
      } else {
        callHandleNavigate(location);
      }
    }
  };

  useEffect(() => {
    if (alarmType?.NoGroup && alarmType.Groups) {
      const groupIds = alarmType.Groups.map(grp => grp.id);
      dispatch(setSelectedGroups(groupIds));
    }
  }, [alarmType]);

  if (isLoading) return <Loader />;

  return (
    <SPage>
      <MapDisplay
        location={location}
        setLocation={setLocation}
        buttonColor="red"
        onButtonClick={() => onButtonClick()}
        buttonTx="alarm_send_alarm"
      />
    </SPage>
  );
}

const SPage = styled(Page)`
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0 0 0;
`;

export default AlarmMap;
