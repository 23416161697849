import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmptyListFallback } from '../../components/EmptyListFallback/EmptyListFallback';
import { GroupFilter } from '../../components/GroupFilter/GroupFilter';
import GroupListItem from '../../components/GroupListItem/GroupListItem';
import Loader from '../../components/Loader/Loader';
import { Page } from '../../components/Page/Page';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  LocalStorageFiltersKeys,
  checkIfFiltersApplied,
  useLocalStorageFilters,
} from '../../utils/customHooks/useLocalStorageFilters';
import { GroupType } from '../../utils/enums';
import { selectUserRoles } from '../Login/LoginSlice';
import { SGroupsList, SSearchBarBase, SSearchFilterBar } from './GroupsList.styles';
import { selectGroups, selectGroupsIsLoading, selectGroupsWithFilters } from './groupsSlice';
import { fetchGroups } from './groupsSlice/actionCreators';
import {
  GroupsFilters,
  generateGroupsListDefaultFilters,
  generateGroupsListDefaultFiltersSections,
} from './helpers';

export const GroupsList = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const rowGroups = useAppSelector(selectGroups);
  const isLoading = useAppSelector(selectGroupsIsLoading);

  const roles = useAppSelector(selectUserRoles);

  const [filterOpen, setFilterOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const groupListRef = useRef<HTMLDivElement>(null);
  const [limit, setLimit] = useState(12);

  const [filters, setFilters] = useLocalStorageFilters<GroupsFilters>(
    LocalStorageFiltersKeys.groups,
    generateGroupsListDefaultFilters()
  );

  const groups = useAppSelector(selectGroupsWithFilters(filters, searchText));

  const groupIncrement = 12;

  useEffect(() => {
    dispatch(fetchGroups());
  }, []);

  const handleOpenFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const handleFilter = (_filters: GroupsFilters) => {
    setFilters(_filters);
    setFilterOpen(false);
  };

  const onSearch = (value: string) => {
    setSearchText(value);
  };

  const onGroupClick = (id: number) => {
    navigate(`../groups/${id}`, {});
  };

  const handler = useCallback(
    debounce((groupLimit: number) => {
      if (groupListRef.current) {
        groupListRef.current.scrollBy(0, -100);
      }
      setLimit(groupLimit + groupIncrement);
    }, 300),
    [limit]
  );

  const scrolled = () => {
    if (!groupListRef.current) {
      return;
    }
    if (limit >= groups.length) {
      return;
    }
    if (
      groupListRef.current.offsetHeight + groupListRef.current.scrollTop + 50 >=
      groupListRef.current.scrollHeight
    ) {
      handler(limit);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  const haveSeeOrgGroupsRole = roles?.includes('SeeOrgGroups') ?? false;
  const haveMultipleGroupTypes =
    Array.from(new Set(rowGroups.map(group => group.groupType))).filter(groupType =>
      [GroupType.Normal, GroupType.Hidden, GroupType.CrossOrg, GroupType.CoAlert].includes(
        groupType
      )
    )?.length > 1;
  const seeFilter = haveSeeOrgGroupsRole || haveMultipleGroupTypes;
  const nameArr = groups.map(item => item.subOrganizationname || item.organizationName);
  const showAccNames = !nameArr.every((val, i, arr) => val === arr[0]);

  return (
    <Page noBottomPadding>
      {seeFilter ? (
        <SSearchFilterBar
          onSearch={onSearch}
          handleOpenFilter={handleOpenFilter}
          value={searchText}
          isFilterApplied={checkIfFiltersApplied({
            defaultFilters: generateGroupsListDefaultFilters(),
            filters,
          })}
        />
      ) : (
        <SSearchBarBase
          placeholderTx="documents_search"
          placeholder="Search..."
          fallback={onSearch}
          value={searchText}
        />
      )}
      <GroupFilter
        label={'messages_filter'}
        isOpen={filterOpen}
        setIsOpen={handleOpenFilter}
        filterSections={generateGroupsListDefaultFiltersSections(haveSeeOrgGroupsRole, rowGroups)}
        initialSelected={filters}
        onFilter={handleFilter}
      />
      <SGroupsList ref={groupListRef} onScroll={scrolled} id="groupsList">
        {groups.slice(0, limit).map(group => (
          <GroupListItem
            key={String(group.id)}
            name={group.name}
            groupID={group.id}
            memberCount={group.groupMembersCount}
            onClick={onGroupClick}
            hidden={group.hidden}
            imageFileName={group.imageFileName || ''}
            account={group.subOrganizationname || group.organizationName || ''}
            showAccName={showAccNames}
            searchText={searchText}
          />
        ))}
        {groups.length === 0 && (
          <EmptyListFallback
            listLength={groups.length}
            isLoading={false}
            searchTerm={searchText}
            emptyListTx={'groups_emptyGroups'}
            ignoreSearchTermInFallbackText
          />
        )}
      </SGroupsList>
    </Page>
  );
};
