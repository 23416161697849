import { compact, flatten } from 'lodash';
import { AccountFromGroup, OrgAndSubOrgInGroupType } from '../../common/groupsHelpers';
import {
  DocumentFilters,
  DocumentFiltersV2,
} from '../../components/DocumentFilter/DocumentFilterV2';
import { DocumentSortingType } from '../../components/DocumentsSortingOptions/DocumentsSortingOptions';
import { FormValues as IndexedType } from '../../utils/customHooks/useForm';
import { Group } from '../GroupsList/groupsSlice/types';
import { FileItem, FolderItem } from './documentsSlice/types';

interface FilterGroupDocumentsParams {
  filters: DocumentFilters;
  allGroups: Group[];
  folders: FolderItem[];
  documents: FileItem[];
}
interface FilterDynamicGroupDocumentsParams extends Omit<FilterGroupDocumentsParams, 'filters'> {
  filters: DocumentFiltersV2;
  searchTerm?: string;
  showDocumentsFromFolders?: boolean;
  groups?: Group[];
}

export type FilterFoldersByGroupsParams = Pick<
  FilterDynamicGroupDocumentsParams,
  'filters' | 'searchTerm' | 'showDocumentsFromFolders' | 'groups'
> &
  Pick<FilterGroupDocumentsParams, 'documents' | 'folders'>;

export type EssentialGroupProps = Pick<Group, 'id' | 'name' | 'imageFileName'> &
  OrgAndSubOrgInGroupType;

interface GroupInAccount extends Pick<AccountFromGroup, 'id' | 'name'> {
  imageFileName?: string;
}
export interface AccountList {
  // TS:index signature
  [accountID: number]: {
    accountName: string;
    groups: GroupInAccount[];
  };
}

export const getGroupsOrganizedByAccount = <T extends EssentialGroupProps>(
  groups: T[]
): AccountList => {
  return groups.reduce((accumulated, currentGroup) => {
    const accountName = currentGroup.subOrganizationID
      ? currentGroup.subOrganizationname
      : currentGroup.organizationName; // should never be 0
    const accountID = currentGroup.subOrganizationID ?? currentGroup.organizationID ?? 0; // should never be 0
    const lastAccumulatedAccount = accumulated[accountID] || { accountName: accountName ?? '' };
    return {
      ...accumulated,
      [accountID]: {
        ...lastAccumulatedAccount,
        groups: [
          ...(lastAccumulatedAccount.groups || []),
          {
            id: currentGroup.id,
            name: currentGroup.name,
            imageFileName: currentGroup.imageFileName ?? undefined,
          },
        ],
      },
    };
  }, {} as AccountList);
};

export const filterGroupsByMember = <T extends Pick<DocumentFilters, 'memberFilter'>>(
  filters: T,
  groups: Group[]
): Group[] => {
  const clonedGroups = [...groups];
  if (!filters.memberFilter || filters.memberFilter.length === 0) {
    return clonedGroups;
  }

  if (filters.memberFilter.includes('NOT_MEMBER') && !filters.memberFilter.includes('MEMBER')) {
    return clonedGroups.filter(g => !g.member);
  } else if (
    filters.memberFilter.includes('MEMBER') &&
    !filters.memberFilter.includes('NOT_MEMBER')
  ) {
    return clonedGroups.filter(g => g.member);
  } else {
    return clonedGroups;
  }
};

export const filterEmptyGroups = (
  folders: FolderItem[],
  documents: FileItem[],
  groups: Group[]
): Group[] => {
  let clonedGroups: Group[] = [...groups];
  const GroupIDsFromFolders = folders.map(folder => folder.GroupID);
  const GroupIDsFromDocuments = flatten(
    documents.map(file => file.groups.map(group => group.GroupID))
  );
  const documentGroupIDs = Array.from(new Set([...GroupIDsFromFolders, ...GroupIDsFromDocuments]));
  clonedGroups = compact(documentGroupIDs.map(id => clonedGroups.find(group => group.id === id)));
  return clonedGroups;
};

// used on old documents filters (when we was showing the groups folder and filtering them by account and subAccount)
// ( still on pages like manageOffline page )
export const filterGroupDocuments = (params: FilterGroupDocumentsParams): Group[] => {
  const { filters, allGroups, folders, documents } = params;

  let clonedGroups: Group[] = [...allGroups];

  if (!filters.showEmptyFolders) {
    clonedGroups = filterEmptyGroups(folders, documents, clonedGroups);
  }

  clonedGroups = filterGroupsByMember(filters, clonedGroups);

  if (
    filters.selectedData !== undefined &&
    filters.selectedData !== 'SELECTED_ALL' &&
    filters.selectedData !== 'UNSELECTED_ALL'
  ) {
    const filterIds = Array.from(filters.selectedData);
    clonedGroups = clonedGroups.filter(group => {
      const id = group.subOrganizationID || group.organizationID || 0;
      return filterIds.includes(id);
    });
  }

  return clonedGroups;
};

export const filterDynamicGroupDocuments = (params: FilterDynamicGroupDocumentsParams): Group[] => {
  const { documents, folders, allGroups, filters } = params;

  let clonedGroups: Group[] = filterEmptyGroups(folders, documents, [...allGroups]);

  clonedGroups = filterGroupsByMember(filters, clonedGroups);

  return clonedGroups;
};

export const filterFilesByGroups = (params: FilterFoldersByGroupsParams): FileItem[] => {
  const { documents, filters, searchTerm = '', showDocumentsFromFolders = false, groups } = params;

  let clonedFiles: FileItem[] = [...documents];

  if (
    filters.selectedData !== undefined &&
    filters.selectedData !== 'SELECTED_ALL' &&
    filters.selectedData !== 'UNSELECTED_ALL'
  ) {
    const filterIds = Array.from(filters.selectedData);
    clonedFiles = clonedFiles.filter(file => {
      return file.groups.some(group => filterIds.includes(group.GroupID));
    });
  } else if (groups) {
    const groupsIds = groups.map((group: Group) => group.id);
    clonedFiles = clonedFiles.filter(file => {
      return file.groups.some(group => groupsIds.includes(group.GroupID));
    });
  }
  if (!showDocumentsFromFolders && !searchTerm?.length) {
    clonedFiles = clonedFiles.filter(file => !file.folderids?.length);
  }

  return clonedFiles;
};

export const filterFoldersByGroups = (params: FilterFoldersByGroupsParams): FolderItem[] => {
  const { folders, documents, filters, groups } = params;

  if (filters.showFilesOnly) return [];

  let clonedFolders: FolderItem[] = [...folders];

  if (!filters.showEmptyFolders) {
    const folderIDsFromDocuments = flatten(documents.map(file => file.folderids));
    clonedFolders = clonedFolders.filter(folder => folderIDsFromDocuments.includes(folder.ID));
  }

  if (
    filters.selectedData !== undefined &&
    filters.selectedData !== 'SELECTED_ALL' &&
    filters.selectedData !== 'UNSELECTED_ALL'
  ) {
    const filterIds = Array.from(filters.selectedData);
    clonedFolders = clonedFolders.filter(folder => {
      return filterIds.includes(folder.GroupID);
    });
  } else if (groups) {
    const groupsIds = groups.map((group: Group) => group.id);
    clonedFolders = clonedFolders.filter(folder => groupsIds.includes(folder.GroupID));
  }

  return clonedFolders;
};

export const isGroupFolderOffline = (
  groupId: number,
  folders: FolderItem[],
  files?: FileItem[]
): boolean => {
  const online: 'Online'[] = [];

  const groupsFiles = files?.filter(file => file.groups.map(g => g.id).includes(groupId));
  const groupsFolders = folders?.filter(folder => folder.GroupID === groupId);

  if (groupsFiles?.length === 0 && groupsFolders.length === 0) return false;

  groupsFiles?.forEach(file => {
    // check if the file is online use only not offline
    const fileGroup = file.groups.find(group => group.id === groupId);
    if (!fileGroup?.offline) {
      online.push('Online');
    }
  });

  groupsFolders?.forEach(folder => {
    if (!folder.Offline) {
      online.push('Online');
    }
  });

  return online.length === 0;
};

export const isDocumentOffline = (file: FileItem, groupId?: number, folderId?: number): boolean => {
  if (!groupId && !folderId) return false;
  if (folderId) {
    const fileInThatFolderState = file.folders.find(folder => folder.ID === folderId);
    return !!fileInThatFolderState?.Offline;
  }
  const fileInThatGroupDocumentState = file.groups.find(
    groupDocument => groupDocument.GroupID === groupId
  );
  return !!fileInThatGroupDocumentState?.offline;
};

export const isAtLeastOneInstanceFileOffline = (file: FileItem): boolean => {
  const isOfflineInOneOfFolders = file.folders.some(folder => folder.Offline);
  const isOfflineInOneOfGroups = file.groups.some(group => group.offline);
  return isOfflineInOneOfFolders || isOfflineInOneOfGroups;
};

const findProperty = <T extends IndexedType, K extends keyof T>(accessors: K[], item: T) =>
  accessors.length === 1
    ? item[accessors[0]]
    : item[
        Object.keys(item).find(propertyName =>
          accessors.some(_accessorName => _accessorName === propertyName)
        ) as keyof T
      ];

// [TODO]: refactor this function to be more readable
// [TODO]: ask about nordic character & special cases And numbers sorting
const comparator = <T extends IndexedType, K extends keyof T>(
  a: T,
  b: T,
  accessors: K[],
  type: 'asc' | 'desc'
) => {
  const A = findProperty(accessors, a);
  const B = findProperty(accessors, b);
  return type === 'asc' ? (A > B ? -1 : A < B ? 1 : 0) : A < B ? -1 : A > B ? 1 : 0;
};

const sortAtoZ = <T extends IndexedType, K extends keyof T>(data: T[], accessors: K[]) =>
  data.sort((a, b) => comparator(a, b, accessors, 'desc'));
const sortZtoA = <T extends IndexedType, K extends keyof T>(data: T[], accessors: K[]) =>
  data.sort((a, b) => comparator(a, b, accessors, 'asc'));
const sortNewToOld = <T extends IndexedType, K extends keyof T>(data: T[], accessors: K[]) =>
  data.sort((a, b) => comparator(a, b, accessors, 'asc'));
const sortOldToNew = <T extends IndexedType, K extends keyof T>(data: T[], accessors: K[]) =>
  data.sort((a, b) => comparator(a, b, accessors, 'desc'));

export const sortData = <T extends IndexedType>(
  data: T[],
  sortBy: DocumentSortingType,
  extractingKeys: { Alphabetically: string[]; date: string[] }
): T[] => {
  switch (sortBy) {
    case DocumentSortingType.AtoZ:
      return sortAtoZ(data.slice(), extractingKeys.Alphabetically);
    case DocumentSortingType.ZtoA:
      return sortZtoA(data.slice(), extractingKeys.Alphabetically);
    case DocumentSortingType.NewToOld:
      return sortNewToOld(data.slice(), extractingKeys.date);
    case DocumentSortingType.OldToNew:
      return sortOldToNew(data.slice(), extractingKeys.date);
    default:
      return sortNewToOld(data.slice(), extractingKeys.date);
  }
};

export const searchFilesOrFolders = <T extends { name?: string; Name?: string }>(
  searchTerm: string,
  data: T[]
): T[] => {
  return searchTerm
    ? data.filter(item => {
        if (item.name) return item.name.toLowerCase().includes(searchTerm.toLowerCase());
        if (item.Name) return item.Name.toLowerCase().includes(searchTerm.toLowerCase());
      })
    : data;
};

export const getOnFavChangeAlertTx = (isFav: boolean, isFolder: boolean): string => {
  return isFav
    ? isFolder
      ? 'documents_folders_added_fav'
      : 'documents_added_fav'
    : isFolder
      ? 'documents_folders_removed_fav'
      : 'documents_removed_fav';
};
