import styled from 'styled-components';
import { palette } from '../../../theme/colors';
import InfoCircleDark from '../../../assets/icons/exclamation-mark-circle--dark.svg';
import React, { useEffect } from 'react';
import isElectron from 'is-electron';
import { translate } from '../../../utils/translate';
import { PushyBannerState, selectPushyConnectionBanner } from '../../../containers/App/AppSlice';
import { useSelector } from 'react-redux';

const SContainer = styled.div`
  background-color: ${palette.honeyYellow};
  margin: 1rem 7% 0 7%;
  border-radius: 0.75rem;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  p {
    font-family: 'Roboto-Regular';
    font-weight: 400;
    font-size: 12px;
    line-height: 1rem;
    color: ${palette.raisinBlack3};
  }
  .row {
    display: flex;
    align-items: center;
    p {
      padding-right: 10px;
      font-weight: 500;
    }
  }
  .alarmIcon {
    padding-right: 10px;
  }
`;

export interface PushyConnectionResponse {
  isConnected: boolean;
}

export const PushyConnectionBanner = () => {
  const pushyConnectionBanner = useSelector(selectPushyConnectionBanner);
  const [showPushyNotification, setShowPushyNotification] = React.useState<PushyBannerState | null>(
    null
  );

  const connectionChangeEventHandler = () => {
    if (!isElectron()) {
      return;
    }
    if (pushyConnectionBanner === PushyBannerState.CONNECTED) {
      if (showPushyNotification === PushyBannerState.NOT_CONNECTED) {
        setShowPushyNotification(PushyBannerState.CONNECTED);
        setTimeout(() => {
          setShowPushyNotification(null);
        }, 3000);
      } else {
        setShowPushyNotification(null);
      }
    } else if (pushyConnectionBanner === PushyBannerState.NOT_CONNECTED) {
      setShowPushyNotification(PushyBannerState.NOT_CONNECTED);
    }
  };

  useEffect(() => {
    connectionChangeEventHandler();
  }, [pushyConnectionBanner]);

  return (
    <>
      {showPushyNotification && (
        <SContainer role="lwtBanner">
          <div className="row" role="bannerInfoContainer">
            <img role="icon" src={InfoCircleDark} className="alarmIcon" />
            <p>
              {translate(
                showPushyNotification === PushyBannerState.CONNECTED
                  ? 'pushy_connecttion_restored'
                  : 'pushy_error_notification'
              )}
            </p>
          </div>
        </SContainer>
      )}
    </>
  );
};
