import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { EmptyListFallback } from '../../components/EmptyListFallback/EmptyListFallback';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { palette } from '../../theme/colors';
import { useSelectlist } from '../../utils/customHooks/useSelectList';
import { translate } from '../../utils/translate';
import { useCreateCecMessageCtx } from '../CreateCecMessageContainer/CreateCecMessageContext';
import {
  selectExternalContacts,
  selectIsExternalContactsLoading,
} from '../ExternalContacts/externalContactsSlice';
import { getExternalContacts } from '../ExternalContacts/externalContactsSlice/actionCreators';
import { SCheckBoxWithSubTitle, SList, SProceedButton } from './styles';

export const CreateCecMessage = () => {
  const isLoading = useAppSelector(selectIsExternalContactsLoading);
  const externalContacts = useAppSelector(selectExternalContacts);

  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const { contactListIds: selectedContactList, setContactListIds } = useCreateCecMessageCtx();

  const { handleSelect, selectedItems } = useSelectlist({
    data: externalContacts,
    initialSelected: selectedContactList,
  });
  const canProceed = selectedItems.size !== 0;

  React.useEffect(() => {
    try {
      if (externalContacts.length === 0) {
        dispatch(getExternalContacts());
      }
    } catch (ignoreError) {
      // No need to handle error
    }
  }, []);

  const handleProceed = () => {
    const selectedContactsListIds = new Set<number>(Array.from(selectedItems).map(item => +item));
    setContactListIds(selectedContactsListIds);
    navigation('options');
  };

  if (isLoading) return <Loader />;
  return (
    <>
      <SList role="contactsList">
        {externalContacts.map((contact, index) => (
          <SCheckBoxWithSubTitle
            selected={selectedItems.has(contact.id)}
            title={contact.name}
            subTitle={
              contact.numberOfContacts
                ? contact.numberOfContacts === 1
                  ? `${contact.numberOfContacts} ${translate('member')}`
                  : `${contact.numberOfContacts} ${translate('groups_members')}`
                : translate('cec_noMembers')
            }
            valueId={contact.id}
            key={`${contact.id}-${index}`}
            separatorColor={palette.tinyBorder}
            checkBoxType="box"
            onToggleCheck={handleSelect}
            clickAll
          />
        ))}
        <EmptyListFallback
          listLength={externalContacts.length}
          isLoading={isLoading}
          emptyListTx={'cec_noMembers'}
        />
      </SList>
      {canProceed && <SProceedButton tx="proceed" onClick={handleProceed} />}
    </>
  );
};
