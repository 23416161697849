import React from 'react';
import Loader from '../../Loader/Loader';
import { SGridItem, SImageContainer, SImage, SMoreImagesText } from './ImagesMessagesItem.styles';

interface ImagesMessagesItemProps {
  isLoading?: boolean;
  photo: string;
  setIsLightBoxOpen: (img: string | null) => void;
  handleExpand: () => void;
  imagesLength: number;
  showMoreImages: boolean;
  isLastInList: boolean;
  expanded: boolean;
}

function ImagesMessagesItem(props: ImagesMessagesItemProps) {
  const {
    photo,
    setIsLightBoxOpen,
    imagesLength,
    showMoreImages,
    isLastInList,
    expanded,
    handleExpand,
    isLoading,
  } = props;

  const onImagesTap = () => {
    if (showMoreImages && !expanded) {
      handleExpand();
    } else {
      setIsLightBoxOpen(photo);
    }
  };

  const singleImage = imagesLength === 1;
  const twoImages = imagesLength === 2;
  const haveMoreThanThree = imagesLength > 3;
  const moreImagesLength = imagesLength - 4;
  const showWideImage = (imagesLength % 2 !== 0 && isLastInList) || imagesLength === 1;

  return (
    <SGridItem
      singleImage={singleImage}
      twoImages={twoImages}
      showWideImage={showWideImage}
      haveMoreThanThree={haveMoreThanThree}
      onClick={onImagesTap}
      isLoading={isLoading}
      role="imagesMessagesItem"
    >
      {photo && !isLoading && (
        <SImageContainer role="imageContainer">
          <SImage src={photo} alt="" backdrop={showMoreImages} role="image" />
          {showMoreImages && (
            <SMoreImagesText role="moreImagesText">{`+${moreImagesLength.toString()}`}</SMoreImagesText>
          )}
        </SImageContainer>
      )}
      {isLoading && (
        <SImageContainer role="loaderContainer">
          <Loader />
        </SImageContainer>
      )}
    </SGridItem>
  );
}

export default ImagesMessagesItem;
