/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/naming-convention */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import AlarmGroupSelectItem from '../../components/AlarmItem/AlarmGroupSelectItem';
import BigFloatButton from '../../components/BigFloatButton/BigFloatButton';
import Loader from '../../components/Loader/Loader';
import { Page } from '../../components/Page/Page';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { AlarmStep, GroupType } from '../../utils/enums';
import { decideIfGroupDisabled } from '../CreateHoldingStatement/helpers';
import { checkGroupsType } from '../CreateMessage/helpers';
import { selectGroups } from '../GroupsList/groupsSlice';
import { fetchGroups } from '../GroupsList/groupsSlice/actionCreators';
import { Group } from '../GroupsList/groupsSlice/types';
import { selectUser } from '../Login/LoginSlice';
import {
  getAlarmCreateModel,
  getSelectedGroupType,
  isAlarmLoading,
  selectAlarmGroups,
  setIsLoading,
  setSelectedGroups,
  setSelectedGroupType,
} from './AlarmSlice';
import { handleNavigate } from './AlarmSlice/actionCreators';
import { GroupsArr } from './AlarmSlice/types';

function AlarmSelectGroup() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const groups = useAppSelector(selectGroups);
  const alarmType = useAppSelector(getAlarmCreateModel);
  const selectedGroups = useAppSelector(selectAlarmGroups);
  const isLoading = useAppSelector(isAlarmLoading);
  const selectedGroupType = useAppSelector(getSelectedGroupType);

  const confirm = useConfirmation();
  const [groupsState, setGroupsState] = useState<GroupsArr[]>([]);
  useEffect(() => {
    dispatch(setIsLoading(true));

    if (alarmType === null) {
      navigate(`/alarm`);
    }
    if (alarmType?.NoGroup) {
      navigate('/alarm', { replace: true });
    }
    dispatch(fetchGroups());
  }, [dispatch]);

  useEffect(() => {
    if (groups.length !== 0) {
      getAllGroups();
    }
  }, [groups, alarmType]);

  useEffect(() => {
    if (selectedGroups.length === 0) {
      dispatch(setSelectedGroups(alarmType?.Groups!.map((group: GroupsArr) => group.GroupID)!));
    }
  }, [alarmType]);

  const isSubAccountAlarm = !!alarmType?.SuborganizationID;
  const getAllGroups = () => {
    if (!alarmType) return;

    // Find groups were we are member while excluding pre-selected ones
    const groupsWhereMember = _.filter(groups, function <T extends Group>(group: T): group is T {
      const foundGroup = _.find(alarmType.Groups, g => g.id === group.id);
      if (group.member && _.isNil(foundGroup) && group.groupType !== 3) {
        return true;
      }
      return false;
    });

    const organizationGroups = _.filter(groupsWhereMember, function <
      T extends Group,
    >(group: T): group is T {
      if (isSubAccountAlarm) {
        if (
          !_.isNil(group.subOrganizationID) &&
          _.isEqual(group.subOrganizationID, alarmType.SuborganizationID)
        ) {
          return true;
        }
        return false;
      } else {
        if (!group.subOrganizationID && _.isEqual(group.organizationID, alarmType.OrganizationID)) {
          return true;
        }
        return false;
      }
    });

    organizationGroups.sort((a, b) => a.name.localeCompare(b.name));

    const fullGroupsList = alarmType.Groups
      ? _.concat(organizationGroups, alarmType.Groups)
      : organizationGroups;

    setGroupsState(fullGroupsList);
    dispatch(setIsLoading(false));
  };

  const onGroupClick = (groupId: number, isCoAlertOrHidden: boolean) => {
    if (!isCoAlertOrHidden) {
      dispatch(setSelectedGroupType([GroupType.Normal]));
    } else {
      dispatch(setSelectedGroupType([GroupType.Hidden, GroupType.CoAlert]));
    }
    const newState = [...selectedGroups];
    if (newState.includes(groupId)) {
      newState.splice(newState.indexOf(groupId), 1);
    } else {
      newState.push(groupId);
    }

    if (newState.length === 0 || newState.length === alarmType?.Groups?.length) {
      dispatch(setSelectedGroupType([]));
    }

    dispatch(setSelectedGroups(newState));
  };

  const renderButton = () => {
    if (_.isEmpty(selectedGroups)) {
      return <></>;
    }

    return (
      <BigFloatButton
        onClick={() => {
          dispatch(
            handleNavigate(
              user?.id,
              selectedGroups,
              alarmType,
              navigate,
              AlarmStep.SelectGroups,
              confirm
            )
          );
        }}
        tx={'messages_proceed'}
        extraPadding={false}
      />
    );
  };

  const checkIfGroupPreselected = (groupItem: GroupsArr) => {
    return !!alarmType?.Groups?.find(group => groupItem.id === group.id);
  };

  if (isLoading) {
    return (
      <Page>
        <SGroupListContainer role="groupsListContainer">
          <Loader />
        </SGroupListContainer>
      </Page>
    );
  }

  return (
    <SPage>
      <SGroupListContainer role="groupsListContainer">
        {groupsState.map((group, key) => {
          const _groupType = group.groupType ?? group.type;
          const type = checkGroupsType(_groupType);
          const isCoAlertOrHidden =
            _groupType === GroupType.Hidden ||
            _groupType === GroupType.CrossOrg ||
            _groupType === GroupType.CoAlert;

          return (
            <AlarmGroupSelectItem
              id={group.id}
              name={group.name}
              membersCount={group.groupMembersCount!}
              key={key}
              checked={selectedGroups.includes(group.id)}
              onCardPress={() => {
                onGroupClick(group.id, isCoAlertOrHidden);
              }}
              type={type as string}
              disable={decideIfGroupDisabled({
                group,
                selectedGroupType,
                selectedGroups,
                checkPreSelectedGroups: {
                  groupsArrayToCheck: alarmType!.Groups ?? [],
                },
              })}
              isPreselected={checkIfGroupPreselected(group)}
            />
          );
        })}
        {renderButton()}
      </SGroupListContainer>
    </SPage>
  );
}

const SPage = styled(Page)`
  padding: 0 1.25rem;
`;

const SGroupListContainer = styled.div`
  padding: 1.25rem 0 6rem;
  height: 100%;

  /* vertical scrolling + hide scroller bar  */
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export default AlarmSelectGroup;
