import { Method } from 'axios';
import { apiProvider } from './provider';

interface Options {
  url: string;
  getAll?: boolean;
  getSingle?: boolean;
  post?: boolean;
  put?: boolean;
  patch?: boolean;
  remove?: boolean;
  singleExtra?: boolean;
  version?: string;
}

interface PerformExtra {
  method: Method;
  extraResource: string;
  returnErrorResponseData?: boolean;
  id?: number;
  model?: object;
  buffer?: boolean;
  params?: object;
  headers?: any; //AxiosRequestHeaders
  version?: string;
}

export class ApiCore {
  getAll!: <T>(version?: string) => Promise<T>;

  getSingle!: <T>(id: number, version?: string) => Promise<T>;

  post!: <T>(model: object, version?: string) => Promise<T>;

  put!: <T>(model: object, version?: string) => Promise<T>;

  patch!: <T>(model: object, version?: string) => Promise<T>;

  remove!: <T>(id: number, version?: string) => Promise<T>;

  performExtra!: <T>(props: PerformExtra) => Promise<T>;

  constructor(options: Options) {
    if (options.getAll) {
      this.getAll = <T>(version?: string) => {
        return apiProvider.getAll<T>(options.url, options.version || version);
      };
    }

    if (options.getSingle) {
      this.getSingle = <T>(id: number, version?: string) => {
        return apiProvider.getSingle<T>(options.url, id, options.version || version);
      };
    }

    if (options.post) {
      this.post = <T>(model: object, version?: string) => {
        return apiProvider.post<T>(options.url, model, options.version || version);
      };
    }

    if (options.put) {
      this.put = <T>(model: object, version?: string) => {
        return apiProvider.put<T>(options.url, model, options.version || version);
      };
    }

    if (options.patch) {
      this.patch = <T>(model: object, version?: string) => {
        return apiProvider.patch<T>(options.url, model, options.version || version);
      };
    }

    if (options.remove) {
      this.remove = <T>(id: number, version?: string) => {
        return apiProvider.remove<T>(options.url, id, options.version || version);
      };
    }
    if (options.singleExtra) {
      this.performExtra = <T>(props: PerformExtra) => {
        const {
          method,
          extraResource,
          returnErrorResponseData,
          id,
          model,
          buffer = false,
          headers,
          params,
          version,
        } = props;
        return apiProvider.performExtra<T>(
          method,
          options.url,
          extraResource,
          returnErrorResponseData,
          id,
          model,
          params,
          buffer,
          headers,
          options.version || version
        );
      };
    }
  }
}

export const userApi = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: false,
  patch: true,
  remove: false,
  singleExtra: true,
  url: 'users',
});

export const mediaAPI = new ApiCore({
  singleExtra: true,
  url: 'media',
});
