import isElectron from 'is-electron';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { OpenMobilePopup } from '../../components/OpenMobilePopup/OpenMobilePopup';
import { useAppDispatch } from '../../hooks';
import { PushNotifications } from '../../notifications/PushNotifications';
import { setAlarmData, setLocationData } from '../PanicButton/PanicButtonSlice';
import { didAskedToGoMobileApp, getSsoRedirectLink, isMobileBrowser } from './helpers';
import { RedirectToMobilePopup } from '../../components/RedirectToMobilePopup/RedirectToMobilePopup';

export const OpenMobileApp = ({ children }: { children: JSX.Element }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showRedirectToMobile, setshowRedirectToMobile] = useState(false);
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const from: string = location.state?.from?.pathname || location.pathname;

  useEffect(() => {
    // checking whether "panic button" session is running
    const panicButtonData = localStorage.getItem('panicButtonData');
    const panicButtonDataJSON = panicButtonData ? JSON.parse(panicButtonData) : null;
    if (isElectron() && panicButtonDataJSON) {
      if (panicButtonDataJSON.alarmData) {
        dispatch(setAlarmData(JSON.parse(panicButtonDataJSON.alarmData)));
      }
      if (panicButtonDataJSON.location) {
        dispatch(setLocationData(JSON.parse(panicButtonDataJSON.location)));
      }
      navigate('/panic/timer');
    }
  }, [dispatch]);

  useEffect(() => {
    const redirectLink = getSsoRedirectLink(searchParams, false);
    if (redirectLink == null) {
      setShowPopup(isMobileBrowser() && !sessionStorage.getItem('didAskedToGoMobileApp'));
    } else {
      setshowRedirectToMobile(true);
    }
  }, [searchParams]);

  const onOpenMobileApp = () => {
    didAskedToGoMobileApp();
    setShowPopup(false);
  };
  const onStayWebApp = () => {
    didAskedToGoMobileApp();
    setShowPopup(false);
  };

  return (
    <>
      {isElectron() && <PushNotifications />}
      {showPopup && (
        <OpenMobilePopup
          link={process.env.REACT_APP_OPEN_MOBILE_APP + from.substring(1)}
          onOpenMobileApp={onOpenMobileApp}
          onStayWebApp={onStayWebApp}
        />
      )}
      {showRedirectToMobile && (
        <RedirectToMobilePopup
          link={getSsoRedirectLink(searchParams, false)}
        ></RedirectToMobilePopup>
      )}
      {children}
    </>
  );
};
