import { t } from 'i18next';
import { batch } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import {
  setAlarmCreateModel,
  setAlarmAllSteps,
  setIsLoading,
  setTypes,
  resetAlarmSlice,
  setActiveAlarmsModalData,
} from '.';
import { getEmergencyTypes } from '../../../apis/authApi/authAPI';
import { createMessage, getActiveAlarms } from '../../../apis/chatAPI';
import { saveLocation } from '../../../apis/locationAPI';
import { AppThunk } from '../../../store';
import { ConfirmationOptions } from '../../../utils/ConfirmationServiceContext/confirmationContext';
import { AlarmStep, AlarmStepLinks } from '../../../utils/enums';
import Geocoder, { defaultLat, defaultLng } from '../../../utils/geocoder';
import { getItem } from '../../../utils/storage';
import { CreateMessageModel } from '../../Chat/models';
import { fetchChats } from '../../ChatsList/chatListSlice/actionCreators';
import { Location } from '../../GroupDetail/groupDetailSlice/types';
import { fetchGroups } from '../../GroupsList/groupsSlice/actionCreators';
import { setIsError, setIsInternalLoading } from '../../Support/supportSlice';
import { ActiveAlarmsModal, EmergencyType } from './types';
import { isNil } from 'lodash';
import { getAlarmName } from '../../../components/AlarmItem/helpers';
import { isTruthyIncludingZero } from '../../../utils/isTruthyIncludingZero';
import { getGroups } from '../../../apis/groupsAPI';

export const fetchActiveAlarms =
  (groupIds: number[], callback?: (data: ActiveAlarmsModal) => void): AppThunk =>
  async dispatch => {
    try {
      const now = new Date();
      now.setHours(now.getHours() - 1);
      const alarms = await getActiveAlarms({
        groupIds,
        take: 100,
        minSentDate: now.toISOString(),
      });
      if (alarms?.items?.length) {
        const newActiveAlarmsModalData = {
          isOpen: true,
          items: alarms.items,
        };
        dispatch(setActiveAlarmsModalData(newActiveAlarmsModalData));
        if (callback) {
          callback(newActiveAlarmsModalData);
        }
      }
    } catch (error) {
      console.log('error log ', error);
    }
  };

export const getEmergencyTypesAction = (): AppThunk => async dispatch => {
  try {
    dispatch(setIsLoading(true));
    // eslint-disable-next-line no-async-promise-executor
    const promise = new Promise<EmergencyType[]>(async resolve => {
      const res = await getEmergencyTypes();
      dispatch(fetchGroups(undefined, false, () => resolve(res)));
    });

    promise.then(res => {
      batch(() => {
        dispatch(setTypes(res));
        dispatch(setIsLoading(false));
      });
    });
  } catch (error) {
    console.log('error log ', error);
    batch(() => {
      dispatch(setIsInternalLoading(false));
      dispatch(setIsError(`${error}`));
    });
  }
};

export const sendAlarm =
  (
    messageModel: CreateMessageModel,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    location: Location | null | undefined = {
      latitude: defaultLat,
      longitude: defaultLng,
    } as Location,
    navigate: NavigateFunction,
    customText: string | ''
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));
      const messageModelObject = { ...messageModel };
      const activeAlarmsModalData = getState().alarm.activeAlarmsModalData;
      let stopAction = false;
      if (!activeAlarmsModalData.isOpen && messageModel.groupIds) {
        const allGroups = await getGroups();

        const allSelectedSubOrganizationGroups = allGroups.filter(
          group =>
            messageModel.groupIds?.includes(group.id) &&
            isTruthyIncludingZero(group.subOrganizationID)
        );

        if (allSelectedSubOrganizationGroups?.length) {
          await dispatch(
            fetchActiveAlarms(
              allSelectedSubOrganizationGroups.map(group => group.id),
              (data: ActiveAlarmsModal) => {
                dispatch(
                  setActiveAlarmsModalData({
                    ...data,
                    sendAlarmProps: {
                      messageModel,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      location,
                      customText,
                    },
                  })
                );
                stopAction = true;
              }
            )
          );
        }
      }
      if (stopAction) {
        dispatch(setIsLoading(false));
        return;
      }
      dispatch(setIsLoading(true));
      if (location && !isNil(location.latitude)) {
        const result = await Geocoder({
          latitude: location.latitude,
          longitude: location.longitude,
        });
        const locName = result?.addressName
          ? result?.addressName
          : `${location.latitude} | ${location.longitude}`;

        const loc = {
          name: locName,
          latitude: location.latitude,
          longitude: location.longitude,
        };

        const locationResult = await saveLocation(loc);
        messageModelObject.locationId = locationResult?.id;
      }
      messageModelObject.text = customText;
      const res = await createMessage(messageModelObject);
      if (res) {
        batch(async () => {
          dispatch(setIsLoading(false));
          dispatch(fetchChats({}));
        });
        navigate(`/message/${res.id}`);
        setTimeout(() => {
          dispatch(resetAlarmSlice());
        }, 3000);
      }
    } catch (error) {
      console.log(error);
      batch(() => {
        dispatch(setIsInternalLoading(false));
        dispatch(setIsError(`${error}`));
        dispatch(setIsLoading(false));
      });
    }
  };

const getLocationPromise = async () => {
  if (navigator.geolocation) {
    return new Promise(resolve => {
      navigator.geolocation.getCurrentPosition(
        position => {
          const pos = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            name: `${position.coords.latitude} | ${position.coords.longitude}`,
          };
          resolve(pos);
        },
        error => {
          console.log('Geolocation Error' + error.code, error.message);
          resolve(null);
        },
        { maximumAge: 5000, enableHighAccuracy: false, timeout: 5000 }
      );
    });
  } else {
    console.log('unknown error at: getLocation at: actionCreators.ts');
    return null;
  }
};

export const calculateAllAlarmSteps =
  (alarmType: EmergencyType): AppThunk =>
  async dispatch => {
    const allSteps = [AlarmStep.SelectType];
    if (!alarmType.NoGroup) allSteps.push(AlarmStep.SelectGroups);
    if (alarmType.allowCustomizedText) allSteps.push(AlarmStep.AddCustomText);
    if (!alarmType.SkipMap) allSteps.push(AlarmStep.SelectLocation);
    dispatch(setAlarmAllSteps(allSteps));
  };

export const handleNavigate =
  (
    userId: number | undefined,
    groupIds: number[],
    alarmTypeItem: EmergencyType | null,
    navigate: NavigateFunction,
    currentStep: AlarmStep,
    confirm: (options: ConfirmationOptions) => Promise<void>,
    location?: Location | null,
    customText?: string | ''
  ): AppThunk =>
  async dispatch => {
    if (!userId || !alarmTypeItem) {
      console.log('handleNavigate at actionCreators.ts: incorrect args');
      return;
    }
    dispatch(setAlarmCreateModel(alarmTypeItem));
    if (!alarmTypeItem.NoGroup && currentStep < AlarmStep.SelectGroups)
      navigate(AlarmStepLinks.SelectGroups);
    else if (alarmTypeItem.allowCustomizedText && currentStep < AlarmStep.AddCustomText)
      navigate(AlarmStepLinks.AddCustomText);
    else if (!alarmTypeItem.SkipMap && currentStep < AlarmStep.SelectLocation)
      navigate(AlarmStepLinks.SelectLocation);
    else {
      // if all other steps are finished proceed with sending
      dispatch(setIsLoading(true));
      // if location wasnt provided try to get it automatically
      if (!location) {
        location = (await getLocationPromise()) as Location | null;
      }
      //prepare messageModel
      const groupIdsList = groupIds?.length
        ? groupIds
        : alarmTypeItem?.Groups
          ? alarmTypeItem.Groups.map(g => g.id)
          : [];
      const messageModel: CreateMessageModel = {
        text: undefined,
        senderId: userId,
        groupIds: groupIdsList,
        emergencyTypeId: alarmTypeItem.ID,
        subOrganisationIDForEmergencyMessage: alarmTypeItem.subOrganisationIDForEmergencyMessage!,
        type: 2,
        recipientIds: null,
        subject: null,
      };
      //ask for user confirmation if needed and then send
      if (alarmTypeItem.warningStep) {
        const currentLang = getItem('language');
        const alarmName = getAlarmName(alarmTypeItem, currentLang);
        confirm({
          title: 'messages_confirmation',
          description: t('alarm_new_alarm_confirmation', {
            alarmName,
          }),
          onSubmit: () => {
            dispatch(sendAlarm(messageModel, location, navigate, customText || ''));
          },
          onCancel: () => {
            dispatch(setIsLoading(false));
          },
          confirmText: 'confirm',
          cancelText: 'cancel',
        });
      } else {
        dispatch(sendAlarm(messageModel, location, navigate, customText || ''));
      }
    }
  };

export const showWarningConfirmationModal =
  (
    userId: number | undefined,
    groupIds: number[],
    alarmTypeItem: EmergencyType | null,
    navigate: NavigateFunction,
    confirm: (options: ConfirmationOptions) => Promise<void>,
    location?: Location | null,
    customText?: string | ''
  ): AppThunk =>
  async dispatch => {
    if (!userId || !alarmTypeItem) {
      console.warn('handleNavigate at actionCreators.ts: incorrect args');
      return;
    }
    dispatch(setAlarmCreateModel(alarmTypeItem));
    //prepare messageModel
    const messageModel: CreateMessageModel = {
      text: undefined,
      senderId: userId,
      groupIds: groupIds,
      emergencyTypeId: alarmTypeItem.ID,
      subOrganisationIDForEmergencyMessage: alarmTypeItem.subOrganisationIDForEmergencyMessage!,
      type: 2,
      recipientIds: null,
      subject: null,
    };
    //ask for user confirmation if needed and then send
    const currentLang = getItem('language');
    const alarmName = getAlarmName(alarmTypeItem, currentLang);
    confirm({
      title: 'messages_confirmation',
      description: t('alarm_new_alarm_confirmation', {
        alarmName,
      }),
      onSubmit: () => {
        dispatch(setIsLoading(true));
        if (!location) {
          const defaultLocation = { latitude: defaultLat, longitude: defaultLng } as Location;
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              async position => {
                const coordinates = position.coords;
                const locationObject = {
                  latitude: coordinates.latitude,
                  longitude: coordinates.longitude,
                } as Location;
                dispatch(sendAlarm(messageModel, locationObject, navigate, customText || ''));
              },
              error => {
                dispatch(sendAlarm(messageModel, defaultLocation, navigate, customText || ''));
                console.error('Geolocation Error' + error.code, error.message);
              },
              { maximumAge: 5000, enableHighAccuracy: true, timeout: 10000 }
            );
          } else {
            dispatch(sendAlarm(messageModel, defaultLocation, navigate, customText || ''));
            console.warn("Browser doesn't support Geolocation");
          }
        } else {
          dispatch(sendAlarm(messageModel, location, navigate, customText || ''));
        }
      },
      onCancel: () => {
        dispatch(setIsLoading(false));
      },
      confirmText: 'confirm',
      cancelText: 'cancel',
    });
  };

/*

GUIDELINES ON HOW TO ADD PAGE TO ALARM CREATE PROCESS / CHANGE ALARM STEPS IN PLACES:

these steps should help you to quickly and easily add new pages to the alarm creation:

1. Create a component for the page you want to add.
You can extract alarm information and current step / all steps numbers using AppSelector and alarmSlice store (../index.ts)
3. Add the property provided by your page to received alarmType prop. Don't forget to also add it to the corresponding type.
4. Call the handleNavigate function on "proceed" click at your component.
5. Add link to the step component to AlarmStepLinks at enums.ts
6. Add the name of step to AlarmStep at enums.ts
7. add "if" check to handleNavigate function (two lines of code one of which is almost entirely copied).
   you should add it before "if" block where user is navigated to the step which should be next to your page.
   it should look like that:
    else if (alarmTypeItem.BACKENDPROPERTY && currentStep < AlarmStep.YOURALARMSTEPNAME)
      navigate(AlarmStepLinks.YOURALARMSTEPNAME);
    where BACKENDPROPERTY is property which should be checked to know if the step should be skipped and
    YOURALARMSTEPNAME is the property name which you have added to AlarmStep and AlarmStepLinks.
    this "if" check is used to properly navigate to your page.
8. Add the property provided by your page to messageModel declaration in handleNavigate.

the steps below should help you to quickly change alarm creation steps in places:

1. Change the order of steps in AlarmStep at core-enums.ts and write the corresponding property value numbers. 0 is the first step, 1 is second and so on.
2. Change the order of "if" checks the same way in handleNavigation function before that line:
        const allSteps = calculateAllStepsAmount(emergencyType)
3. Done!
*/
