import { SImgContainer, SImg, SLeft, SRight } from './MediaImagesModal.styles';
import { ReactComponent as Arrow } from '../../assets/icons/chevron-up.svg';
import { useEffect, useRef, useState } from 'react';
import { Modal } from '../../components/Modal/Modal';
import { useWindowSize } from '../../utils/customHooks/useWindowSize';
import { useImageSize } from '../../utils/customHooks/useImageSize';

interface MediaImagesModalProps {
  imgKey: number;
  isOpen: boolean;
  imagesList?: string[];
  closeModal: () => void;
  handleImageChange?: (imgKey: number, next: boolean) => void;
}

function MediaImagesModal(props: MediaImagesModalProps) {
  const { isOpen = false, imagesList = [], closeModal, handleImageChange, imgKey } = props;
  const [width] = useWindowSize();
  const { getImageHeightByWidth } = useImageSize();
  const [scrollStartPositionX, setScrollStartPositionX] = useState(0);
  const [translationX, setTranslationX] = useState(0);
  const isScrollingRef = useRef(false);
  const startScrollLeft = useRef(0);
  const scrollElementRef = useRef<HTMLDivElement>(null);
  const imageWidth = useRef(0);
  const isWidthChanging = useRef(false);
  const [imgHeight, setImageHeight] = useState('auto');
  const imgKeyRef = useRef(imgKey ?? 0);
  const hideNextButton = imgKey === imagesList.length - 1;
  const hidePrevButton = imgKey === 0;

  const getCurrentImageWidth = () => {
    return imageWidth.current ?? 0;
  };

  const calculateAndSetImageHeight = async () => {
    const currentImageWidth = getCurrentImageWidth();
    if (imgKey > -1 && imagesList[imgKey] && currentImageWidth) {
      try {
        const newImageHeight = await getImageHeightByWidth(imagesList[imgKey], currentImageWidth);
        const newImgHeightText =
          !newImageHeight || newImageHeight === 'auto' ? 'auto' : `${newImageHeight}px`;
        setImageHeight(newImgHeightText);
      } catch (error) {
        setImageHeight('auto');
      }
    } else {
      setImageHeight('auto');
    }
  };

  const handleChangeImage = (next: boolean) => {
    if (!handleImageChange) return;
    const currentImageIndex = imgKeyRef.current ?? 0;
    handleImageChange(currentImageIndex, next);
    isWidthChanging.current = false;
  };

  const handleScroll = () => {
    if (scrollElementRef.current && !isScrollingRef.current && !isWidthChanging.current) {
      const currentImageWidth = imageWidth?.current ?? 0;
      const scrollLeft = scrollElementRef.current.scrollLeft;
      const isScrollEnd = scrollLeft % currentImageWidth === 0;
      const startScrollLeftValue = startScrollLeft.current ?? 0;
      setTranslationX(Math.abs(startScrollLeftValue - scrollLeft));
      if (isScrollEnd) {
        const currentImageIndex = imgKeyRef.current ?? 0;
        const currentImageTranslationX = currentImageIndex * currentImageWidth;
        if (scrollLeft !== currentImageTranslationX) {
          handleChangeImage(scrollLeft > currentImageTranslationX);
        }
        setTranslationX(0);
        startScrollLeft.current = scrollLeft;
        isScrollingRef.current = false;
      }
    }
  };

  const handleWidthChange = () => {
    const maxWidth = 384;
    const currentImageWidth = imageWidth?.current ?? 0;
    if (maxWidth > width) {
      imageWidth.current = width;
      calculateAndSetImageHeight();
    } else if (maxWidth <= width && currentImageWidth < maxWidth) {
      imageWidth.current = maxWidth;
      calculateAndSetImageHeight();
    }
    if (scrollElementRef.current) {
      isWidthChanging.current = true;
      const currentImageIndex = imgKeyRef.current ?? 0;
      const currentImageTranslationX = currentImageIndex * currentImageWidth;
      scrollElementRef.current.scrollLeft = currentImageTranslationX;
      setTranslationX(0);
    }
  };
  const handleIsOpen = () => {
    if (imgKey > -1 && isOpen) {
      const scrollElement = scrollElementRef.current;
      const currentImageWidth = getCurrentImageWidth();
      if (scrollElement) {
        const positionX = imgKey * currentImageWidth;
        scrollElement.scrollLeft = positionX;
        setScrollStartPositionX(positionX);
      }

      if (scrollElementRef.current) {
        scrollElementRef.current.addEventListener('scroll', handleScroll);
      }
      return () => {
        if (scrollElementRef.current) {
          scrollElementRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onTouchStart = (e: any) => {
    isWidthChanging.current = false;
    const clientX = e.changedTouches?.length ? e?.changedTouches[0].clientX : e?.clientX;
    if (translationX > 0 || !clientX) {
      return;
    }
    isScrollingRef.current = true;
    const scrollElement = scrollElementRef.current;
    setScrollStartPositionX(clientX);
    if (scrollElement) {
      startScrollLeft.current = scrollElement.scrollLeft ?? 0;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onTouchMove = (e: any) => {
    const clientX = e.changedTouches?.length ? e?.changedTouches[0].clientX : e.clientX;
    if (!clientX || !isScrollingRef.current) {
      return;
    }
    const currentImageWidth = getCurrentImageWidth();
    const startScrollLeftValue = startScrollLeft.current ?? 0;
    const scrollElement = scrollElementRef.current;
    const swipeDistance = clientX - scrollStartPositionX;
    const canScrollToStart = startScrollLeftValue - swipeDistance > 0;
    const canScrollToEnd =
      startScrollLeftValue / currentImageWidth < imagesList.length - 1 || swipeDistance > 0;
    if (scrollElement && canScrollToStart && canScrollToEnd) {
      setTranslationX(swipeDistance);
      scrollElement.scrollLeft = startScrollLeftValue - swipeDistance;
    }
  };

  const onTouchEnd = () => {
    if (isScrollingRef.current) {
      isScrollingRef.current = false;
    }
    const scrollElement = scrollElementRef.current;
    if (scrollElement) {
      const currentImageWidth = getCurrentImageWidth();
      const currentScrollLeft = scrollElement.scrollLeft;
      const reminder = currentScrollLeft % currentImageWidth;
      const halfWidth = currentImageWidth / 2;
      scrollElement.style.scrollBehavior = 'smooth';
      let newScrollLeft = scrollElement.scrollLeft;
      if (reminder > halfWidth) {
        newScrollLeft = currentScrollLeft + (currentImageWidth - reminder);
      } else {
        newScrollLeft = currentScrollLeft - reminder;
      }
      scrollElement.scrollLeft = newScrollLeft;
      scrollElement.style.scrollBehavior = 'auto';
    }
  };

  const onChangeImageClick = (next: boolean) => {
    isWidthChanging.current = false;
    const scrollElement = scrollElementRef.current;
    if (scrollElement && !isScrollingRef.current) {
      const currentImageWidth = getCurrentImageWidth();
      scrollElement.style.scrollBehavior = 'smooth';
      let newScrollLeft = scrollElement.scrollLeft;
      if (next && imagesList.length !== imgKey + 1) {
        newScrollLeft = (imgKey + 1) * currentImageWidth;
      }
      if (!next && imgKey > 0) {
        newScrollLeft = (imgKey - 1) * currentImageWidth;
      }
      scrollElement.scrollLeft = newScrollLeft;
      scrollElement.style.scrollBehavior = 'auto';
    }
  };

  useEffect(() => {
    handleWidthChange();
  }, [width]);

  useEffect(() => {
    if (imgKey > -1) {
      imgKeyRef.current = imgKey;
      calculateAndSetImageHeight();
    }
  }, [imgKey]);

  useEffect(() => {
    setTranslationX(0);
    handleIsOpen();
  }, [isOpen]);

  return (
    <div role="MediaimagesModalContainer">
      <Modal isOpen={isOpen} setIsOpen={closeModal} backdropZIndex={2} role="modal">
        <SImgContainer
          onMouseDown={onTouchStart}
          onMouseMove={onTouchMove}
          onMouseUp={onTouchEnd}
          onMouseLeave={onTouchEnd}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
          ref={scrollElementRef}
          $maxHeight={!translationX ? imgHeight : null}
        >
          {imagesList.map((img, index) => (
            <SImg
              role="image"
              src={img}
              alt=""
              key={`image-${index}`}
              $scaleY={
                imgKey === index ? 1 - Math.abs(translationX / getCurrentImageWidth()) : undefined
              }
            />
          ))}
        </SImgContainer>
        {!hidePrevButton && !translationX && (
          <SLeft role="prevIcon" onClick={() => onChangeImageClick(false)}>
            <Arrow className="prevArrow" />
          </SLeft>
        )}
        {!hideNextButton && !translationX && (
          <SRight role="nextIcon" onClick={() => onChangeImageClick(true)}>
            <Arrow className="nextArrow" />
          </SRight>
        )}
      </Modal>
    </div>
  );
}

export default MediaImagesModal;
