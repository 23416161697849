import _ from 'lodash';
import { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ChecklistSubTypes, checklistMessageTitleTX } from '../../containers/Chat/helpers';
import { Chat } from '../../containers/ChatsList/chatListSlice/types';
import { selectUser } from '../../containers/Login/LoginSlice';
import { useAppSelector } from '../../hooks';
import { palette } from '../../theme/colors';
import { dateFormats, getDateFormatCustom } from '../../utils/date';
import { ReceivedMessageType } from '../../utils/enums';
import { AlertMessage } from './AlertMessage/AlertMessage';
import AudioMessage from './AudioMessage/AudioMessage';
import { ChecklistMessage } from './ChecklistMessage/ChecklistMessage';
import FileMessage from './FileMessage/FileMessage';
import ImageMessage from './ImageMessage/ImageMessage';
import LocationMessage from './LocationMessage.tsx/LocationMessage';
import TextMessage from './TextMessage/TextMessage';

interface MessageItemProps {
  message: Chat;
  onPlayClick: (id: number | null) => void;
  isAudioActive: boolean;
  alert: boolean;
  toggleOptions?: () => void;
  onMapClick: (
    longitude: number,
    latitude: number,
    address: string,
    date: string,
    isAlarm: boolean
  ) => void;
  didFetchPhoto?: () => void;
}

export const SMessageDateSeparator = styled.p`
  padding-top: 1rem;
  padding-bottom: 0.75rem;
  text-align: center;
  color: ${palette.silver};
  font-size: 13px;
  font-family: 'Roboto-Regular';
  &.reply-SMessageDateSeparator {
    padding-top: 1.5rem;
  }
`;

export const MessageItem = (props: MessageItemProps) => {
  const { message, toggleOptions, isAudioActive, onPlayClick, onMapClick, didFetchPhoto } = props;

  const location = useLocation();
  const user = useAppSelector(selectUser);
  const isChecklistMessage = !!message.checklistID;
  const isLocationMessage =
    message.locationID && message.type !== 2 && message.emergencyTypeId == null;
  const isPhotosMessage = !_.isEmpty(message.photoFileNames);
  const isAudioMessage = !_.isEmpty(message.audioFileNames);
  const isDocumentsMessage = !isPhotosMessage && !isAudioMessage && !_.isEmpty(message.attachments);
  const forwarded = message.type === 12;
  const isForwardedAlarm = !!(forwarded && message.emergencyTypeId);
  const isTextMessage =
    message.text &&
    !_.isEmpty(message.text.trim()) &&
    _.isEmpty(message.photoFileNames) &&
    message.type !== ReceivedMessageType.Alarm &&
    !isForwardedAlarm &&
    !isLocationMessage &&
    _.isEmpty(message.documentFileNames) &&
    !isChecklistMessage;
  const [isMessageSent, setIsMessageSent] = useState<boolean>(true);

  useLayoutEffect(() => {
    if (user) {
      if (user.id !== message.senderID) setIsMessageSent(false);
    }
  }, [user]);

  const getMessageToText = () => {
    if (message.groupNames.length) {
      return message.groupNames.join(', ');
    }
    if (message.type === ReceivedMessageType.LogFile) {
      return 'Log file';
    }
    if (message.type === ReceivedMessageType.Broadcast) {
      if (!_.isEmpty(message.organizations))
        return message.organizations.map(org => org.name).join(', ');
      if (message.Organization) return message.Organization.name;
    }
    if (message?.messageRecipients) {
      return message.messageRecipients.map(recip => recip.displayName).join(', ');
    }

    return null;
  };

  return (
    <>
      <SMessageDateSeparator
        className="messageItem-SMessageDateSeparator"
        role="messageDateSeperator"
      >
        {getDateFormatCustom(message.sent, dateFormats.monthNamePlusDate)}
      </SMessageDateSeparator>

      {isLocationMessage && (
        <LocationMessage
          id={message.id}
          text={message.text}
          isSent={isMessageSent}
          sentDateTime={getDateFormatCustom(message.sent, dateFormats.simpleTime)}
          senderName={message.senderName}
          locationId={message.locationID}
          senderImage={message.profilePictureFileName}
          toggleModal={toggleOptions}
          messageTo={getMessageToText()}
          forwarded={forwarded}
          edited={message.edited}
          onMapClick={(lat, lng, address) => {
            onMapClick(
              lat,
              lng,
              address,
              getDateFormatCustom(message.sent, dateFormats.yearMonthDayTimeNoComma24),
              false
            );
          }}
          key={`locationMessage-${message.id}`}
        />
      )}

      {(message.type === ReceivedMessageType.Alarm || isForwardedAlarm) && (
        <>
          <AlertMessage
            id={message.id}
            messageText={message.text}
            message={message}
            messageTo={getMessageToText()}
            senderName={message.senderName}
            senderImage={message.profilePictureFileName}
            isSent={isMessageSent}
            sentDateTime={getDateFormatCustom(message.sent, dateFormats.simpleTime)}
            toggleModal={toggleOptions}
            forwarded={forwarded}
            onMapClick={(lat, lng, address) => {
              onMapClick(
                lat,
                lng,
                address,
                getDateFormatCustom(message.sent, dateFormats.simpleTime),
                true
              );
            }}
            didFetchPhoto={!isForwardedAlarm ? didFetchPhoto : undefined}
            key={`alertMessage-${message.id}`}
          />
        </>
      )}

      {isTextMessage && (
        <TextMessage
          isSent={isMessageSent}
          sentDateTime={getDateFormatCustom(message.sent, dateFormats.simpleTime)}
          messageText={message.text}
          senderName={message.senderName}
          senderImage={message.profilePictureFileName}
          toggleModal={toggleOptions}
          hideMenu={false}
          messageTo={getMessageToText()}
          forwarded={forwarded}
          edited={message.edited}
          id={message.id}
          key={`textMessage-${message.id}`}
        />
      )}

      {isChecklistMessage && (
        <ChecklistMessage
          checklistLink={`/checklist/${message?.checklistID}`}
          LinkState={{ prevPath: location.pathname }}
          started
          startedTx={checklistMessageTitleTX[message.subType as ChecklistSubTypes]}
          isSent={isMessageSent}
          checklistName={message?.checklistName || ''}
          key={`checklists-${message?.checklistID}`}
        />
      )}
      {isPhotosMessage && (
        <ImageMessage
          photoFileNames={message.photoFileNames!}
          senderName={message.senderName}
          senderImage={message.profilePictureFileName}
          isSent={isMessageSent}
          sentDateTime={getDateFormatCustom(message.sent, dateFormats.simpleTime)}
          messageText={message.text}
          toggleModal={toggleOptions}
          messageTo={getMessageToText()}
          forwarded={forwarded}
          edited={message.edited}
          id={message.id}
          didFetchPhoto={didFetchPhoto}
          key={`images${message.id}`}
        />
      )}
      {isDocumentsMessage && (
        <FileMessage
          text={message.text}
          messageTo={getMessageToText()}
          key={`file${message.id}`}
          documents={message.attachments}
          senderName={message.senderName}
          senderImage={message.profilePictureFileName}
          isSent={isMessageSent}
          forwarded={forwarded}
          sentDateTime={getDateFormatCustom(message.sent, dateFormats.simpleTime)}
          toggleModal={toggleOptions}
          edited={message.edited}
          id={message.id}
        />
      )}
      {!_.isEmpty(message.audioFileNames) && (
        <AudioMessage
          messageId={message.id}
          messageTo={getMessageToText()}
          key={`audio${message.id}`}
          isSent={isMessageSent}
          sentDateTime={getDateFormatCustom(message.sent, dateFormats.simpleTime)}
          senderImage={message.profilePictureFileName}
          audioFile={_.head(message.audioFileNames)!}
          isAudioActive={isAudioActive}
          senderName={message.senderName}
          onPlayClick={onPlayClick}
          toggleModal={toggleOptions}
          forwarded={forwarded}
          edited={message.edited}
          id={message.id}
        />
      )}
    </>
  );
};
