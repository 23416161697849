import { GroupMember } from '../containers/GroupDetail/groupDetailSlice/types';
import { ApiCore } from './utils/core';

const url = 'Groups';

const chatAPI = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: false,
  patch: true,
  remove: false,
  singleExtra: true,
  url,
});

export const getSingleGroupsMembers = (id: number) => {
  return chatAPI.performExtra<GroupMember[]>({
    method: 'GET',
    extraResource: `${id}/members`,
  });
};
