import isElectron from 'is-electron';
import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const SSearch = styled.input`
  height: 3.25rem;
  width: 100%;
  min-width: 10rem;

  padding: 1rem 1rem 1rem 2.75rem;

  background-color: ${({ theme }) => theme.palette.background.searchBar};
  color: ${({ theme }) => theme.palette.text.documentPrimary};

  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-radius: 0.75rem;

  font-family: 'Roboto-Regular';
  font-size: 14px;
  :focus-visible {
    outline: none;
  }

  ::placeholder {
    color: ${palette.silver};
    opacity: 1;
    font-size: 14px;
  }

  :-ms-input-placeholder {
    color: ${palette.silver};
  }

  ::-ms-input-placeholder {
    color: ${palette.silver};
  }

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1.25rem;
    width: 1.25rem;
    background: url(${isElectron()
        ? 'icons/cross-white-background-grey.svg'
        : '/icons/cross-white-background-grey.svg'})
      no-repeat 50% 50%;
    background-size: contain;
    cursor: pointer;
    opacity: 1;
  }
  &::-webkit-search-cancel-button:hover {
    opacity: 0.7;
  }
`;

export const SLabel = styled.p`
  position: absolute;
  bottom: 4rem;
  font-family: 'Roboto-Regular';
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${palette.white};
`;

export const SContainer = styled.div`
  position: relative;
  svg {
    position: absolute;
    left: 1.18rem;
    top: calc(50% - 33% / 2);
  }
`;

export const SClear = styled.button.attrs({ type: 'reset' })`
  border: 0;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }

  position: absolute;
  right: 0.875rem;
  top: 50%;
  transform: translateY(-50%);
`;
