import React, { SyntheticEvent } from 'react';
import { ReactComponent as File } from '../../assets/icons/file--yellow.svg';
import { ReactComponent as FileEnded } from '../../assets/icons/file--grey.svg';
import { ReactComponent as FileTemplate } from '../../assets/icons/file-with-checkmark.svg';
import { ReactComponent as Dots } from '../../assets/icons/dots-three-vertical-wide.svg';
import { setActiveChecklist } from '../../containers/Checklists/checklistsSlice';
import { Checklist } from '../../containers/Checklists/checklistsSlice/types';
import { useAppDispatch } from '../../hooks';
import { ChecklistStatus } from '../../utils/enums';
import { useNavigate } from 'react-router-dom';
import {
  checkIfDateIsCurrentYear,
  checkIfDateIsToday,
  dateFormats,
  getDateFormatCustom,
} from '../../utils/date';
import { ReactComponent as Groups } from '../../assets/icons/silhouette-with-two.svg';
import { translate } from '../../utils/translate';
import { HighlightSearchTerm } from './HighlightSearchTerm/HighlightSearchTerm';
import { ChecklistDatePropertyNamesByStatus } from '../../containers/Checklists/Checklists.models';
import { SChecklistItem, SRow, SNameContainer, SLine } from './ChecklistItem.styles';

interface ChecklistItemProps {
  checklist: Checklist;
  onDotsClick: () => void;
  searchText?: string;
}

function ChecklistItem(props: ChecklistItemProps) {
  const { checklist, onDotsClick, searchText = '' } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/checklist/${checklist.id}`);
  };

  const handleDotsClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    dispatch(setActiveChecklist(checklist));
    onDotsClick();
  };

  const getGroupLength = () => {
    const length = checklist.sharedGroups.length;
    if (length === 1) {
      return `${length} ${translate('messages_group')}`;
    }
    return `${length} ${translate('messages_groups')}`;
  };

  const getChecklistDate = () => {
    const displayedDateProperty = ChecklistDatePropertyNamesByStatus[checklist.status];
    const displayedDate = checklist[displayedDateProperty];
    const isToday = displayedDate ? checkIfDateIsToday(displayedDate) : false;
    const isCurrentYear = displayedDate ? checkIfDateIsCurrentYear(displayedDate) : false;
    const { simpleTime24, monthNameShortDateTimeNoComma24, dayMonthNameShortYearSpace } =
      dateFormats;
    const dateFormat = isToday
      ? simpleTime24
      : isCurrentYear
        ? monthNameShortDateTimeNoComma24
        : dayMonthNameShortYearSpace;

    return getDateFormatCustom(displayedDate, dateFormat);
  };

  return (
    <>
      <SChecklistItem
        onClick={onClick}
        id={`checklistItem-${checklist.id}`}
        role={`checklistItem-${checklist.name}`}
      >
        <SRow role="checklistItemLeftContainer">
          {checklist.status === ChecklistStatus.Ended ? (
            <FileEnded role="fileItem" />
          ) : checklist.status === ChecklistStatus.NotStarted ? (
            <FileTemplate role="fileItem" />
          ) : (
            <File role="fileItem" />
          )}
          <SNameContainer role="infoContainer">
            <p className="checklist" role="name">
              {HighlightSearchTerm(checklist.name, searchText)}{' '}
            </p>
            <div className="groups" role="groupsRow">
              <Groups role="groupsIcon" />
              <p className="group" role="groupsText">
                {getGroupLength()}
              </p>
            </div>
          </SNameContainer>
        </SRow>
        <SRow className="right" role="checklistItemRightContainer">
          <div className="timeContainer" role="timeContainer">
            <p role="timeText">{getChecklistDate()}</p>
          </div>
          <div className="dotsContainer" role="dotsContainer">
            <Dots role="dotsIcon" onClick={handleDotsClick} />
          </div>
        </SRow>
      </SChecklistItem>
      <SLine />
    </>
  );
}

export default ChecklistItem;
