import React from 'react';
import { ReactComponent as FilterIcon } from '../../assets/icons/sliders-small.svg';
import { ReactComponent as FilterIconSelected } from '../../assets/icons/sliders-small--yellow.svg';
import { SContainer, SFilterButton, SSearchBarBase } from './SearchFilterBar.styles';

export interface SectionFilterItem {
  id: number;
  name: string;
  nameTx?: string;
  checked: boolean;
}

export interface FilterSection {
  title?: string;
  type: 'checkbox' | 'radio';
  content: SectionFilterItem[];
}

interface ISearchFilterBarProps extends React.HTMLAttributes<HTMLDivElement> {
  onSearch: (value: string) => void;
  handleOpenFilter?: () => void;
  withoutFilterButton?: boolean;
  forwardedRef?: any;
  // making the value required , in order not to forget it in future implementation
  // it's currently used only in counting the length of value , to update firefox clear button
  value: string | undefined;
  isFilterApplied?: boolean;
}

export const SearchFilterBar = (props: ISearchFilterBarProps) => {
  const {
    className,
    onSearch,
    handleOpenFilter,
    forwardedRef,
    value,
    withoutFilterButton = false,
    isFilterApplied,
  } = props;

  return (
    <SContainer className={className} role="searchFilterBarContainer">
      <SSearchBarBase
        className="SSearchBarBase"
        placeholderTx="documents_search"
        placeholder="Search..."
        fallback={onSearch}
        forwardedRef={forwardedRef}
        value={value}
      />
      {!withoutFilterButton && (
        <SFilterButton
          $isFilterApplied={isFilterApplied}
          onClick={handleOpenFilter}
          role="filterButton"
        >
          {isFilterApplied ? <FilterIconSelected role="icon" /> : <FilterIcon role="icon" />}
        </SFilterButton>
      )}
    </SContainer>
  );
};
