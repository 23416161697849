import {
  BottomRow,
  Column,
  GroupsTitle,
  LeftContainer,
  MessageItemContainer,
  MessagesTextContainer,
  Row,
  SBubble,
  SDots,
  SimpleText,
  Subject,
  WhiteSpan,
  SBroadcastImage,
} from './ChatListItem.styles';
import { MessageReplyType, MessageSubType, ReceivedMessageType } from '../../utils/enums';
import React, { useEffect, useState } from 'react';
import {
  checkIfDateIsCurrentYear,
  checkIfDateIsToday,
  dateFormats,
  getDateFormatCustom,
} from '../../utils/date';
import Broadcast from '../../assets/imgs/chats/broadcast.svg';
import { MessageInList } from '../../containers/ChatsList/chatListSlice/types';
import Dots from '../../assets/icons/dots-three-vertical.svg';
import Highlighter, { FindChunks } from 'react-highlight-words';
import { ProfilePictureRow } from '../ProfilePicture/ProfilePictureRow';
import _ from 'lodash';
import { decode } from 'html-entities';
import { messageReplyTextGenerator } from '../../containers/ChatsList/chatListSlice/actionCreators';
import { palette } from '../../theme/colors';
import { t } from 'i18next';
import { translate } from '../../utils/translate';

interface ChatListItemProps {
  message: MessageInList;
  userId: number;
  handleDots: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: number) => void;
  searchText: string;
  groupPictures: string[];
  onClick: (id: number) => void;
}

export const ChatListItem = (props: ChatListItemProps) => {
  const { message, userId, handleDots, searchText, groupPictures, onClick } = props;

  const [usersImages, setUsersImages] = useState<string[]>([]);

  const isIamOkMessage = !!message?.musterID;
  const isOnCallAlertMessage = !!message?.onCallAlertID;
  const isChecklist = !!message?.checklistID;
  const isAlarm = message.type === ReceivedMessageType.Alarm;
  const alert = isAlarm && !message.recalled;
  const broadcast = message.type === ReceivedMessageType.Broadcast;

  useEffect(() => {
    const getRecipientsImagesList = () => {
      const usersList = _.filter(message.messageRecipients, recipient => {
        if (recipient.photoFileName && recipient.photoFileName.trim() !== '') {
          return recipient.photoFileName;
        }
      });
      const imagesList: string[] = _.map(usersList, 'photoFileName');
      setUsersImages(imagesList);
    };

    getRecipientsImagesList();
  }, [message.messageRecipients]);

  const handleDate = (time?: string | null) => {
    if (!time) {
      return '';
    }

    const requiredFormat = checkIfDateIsToday(time)
      ? dateFormats.simpleTime
      : !checkIfDateIsCurrentYear(time)
        ? dateFormats.dayMonthNameYearSpace
        : dateFormats.monthNameShortDateTimeNoComma24;
    return getDateFormatCustom(time, requiredFormat);
  };

  const getMessageSender = (messageData: MessageInList) => {
    const { lastReplySenderId, senderID, lastReplySender, senderName } = messageData;
    const lastSenderId = lastReplySenderId || senderID;
    if (userId === lastSenderId) {
      const sender = t('messages_you');
      return sender;
    } else {
      return lastReplySender || senderName;
    }
  };

  const messageShowGenerator = () => {
    if (!searchText?.length && message.isLastReplyDeleted) {
      return t('reply_deleted');
    } else {
      return (
        messageReplyTextGenerator(MessageReplyType.Reply, message, userId) ||
        messageReplyTextGenerator(MessageReplyType.Message, message, userId)
      );
    }
  };

  const getRecipientsNamesList = () => {
    const recipientsNames = [];
    _.forEach(message.messageRecipients, recipient => {
      if (
        recipient.userID !== userId &&
        !_.isNil(recipient.displayName) &&
        !_.isEmpty(recipient.displayName.trim())
      ) {
        recipientsNames.push(recipient.displayName);
      }
    });
    recipientsNames.unshift(translate('messages_you'));
    return _.join(recipientsNames, ', ');
  };

  const findChunksAtBeginningOfWords = ({
    searchWords,
    textToHighlight,
  }: {
    searchWords: string[];
    textToHighlight: string;
  }) => {
    const searchWord = _.head(searchWords) || '';

    const textLow: string = textToHighlight.toLowerCase();
    if (!_.isEmpty(textLow) && !_.isEmpty(searchWord)) {
      const startIndex = textLow.indexOf(searchWord);
      const endIndex = startIndex + searchWord.length;
      if (startIndex === -1 || endIndex === -1) return [];
      return [{ start: startIndex, end: endIndex }];
    } else {
      return [];
    }
  };

  const itemTitle = broadcast
    ? message.subject || translate('broadcast')
    : message.groupName || getRecipientsNamesList();

  const getRole = (): string => {
    if (message.subject) return message.subject;
    else if (alert && message.subType === MessageSubType.personalAlarm) return 'personalAlarm';
    else if (alert && message.subType !== MessageSubType.personalAlarm)
      return message.emergencyTypeName || message.subject || 'alarm';
    else if (message.recalled && isAlarm) return 'recalledAlert';
    else if (broadcast) return 'broadcast';
    else if (isIamOkMessage) return 'muster';
    else if (isOnCallAlertMessage) return 'onCallAlert';
    else if (isChecklist) return 'checklist';
    return 'imOk';
  };

  return (
    <MessageItemContainer
      selected={false}
      alert={alert}
      onClick={() => onClick(message.id)}
      id={`message-${message.id}`}
      role={`messageItem-${getRole()}`}
    >
      <Column role="contentContainer" className="left">
        <Row padding role="topRow">
          {!isAlarm && message.subject && (
            <Subject role="subject" hasSubject={true} ended={true}>
              {message.subject}
            </Subject>
          )}
          {alert && (
            <Subject role="alertSubject" ended={false}>
              {message.subType === MessageSubType.personalAlarm
                ? translate('alarm_personal_alarm')
                : message.emergencyTypeName || message.subject || translate('alert')}
            </Subject>
          )}
          {message.recalled && isAlarm && (
            <Subject role="recalledAlertSubject" ended={true}>
              {t<string>('messages_alert_canceled')}
            </Subject>
          )}
          {!message.subject && !isAlarm && (
            <Subject role="staticSubject" hasSubject={true} ended={true}>
              {broadcast
                ? t<string>(`broadcast`)
                : isIamOkMessage
                  ? t<string>(`home_muster`)
                  : isOnCallAlertMessage
                    ? t<string>(`onCallAlert_screen`)
                    : isChecklist
                      ? t<string>(`checklist_checklist`)
                      : t<string>(`imOk_message`)}
            </Subject>
          )}

          <SimpleText role="dateTime" fontSize={'0.7rem'}>
            {handleDate(message.sent)}
          </SimpleText>
        </Row>
        <BottomRow role="bottomRow">
          <LeftContainer role="leftContainer">
            {broadcast && <SBroadcastImage role="profileImage" src={Broadcast} alt="" />}
            {!_.isEmpty(message.groupIDs) && !broadcast && (
              <ProfilePictureRow profilePictureFileNames={groupPictures} isGroup />
            )}
            {_.isEmpty(message.groupIDs) && !broadcast && (
              <ProfilePictureRow profilePictureFileNames={usersImages} />
            )}
          </LeftContainer>
          <MessagesTextContainer role="messagesTextContainer">
            <Row padding role="topRow">
              <GroupsTitle role="title">{itemTitle}</GroupsTitle>
              {message.unreadRepliesCount > 0 && (
                <SBubble role="unnreadBubble">
                  <p role="unreadCount">{message.unreadRepliesCount}</p>
                </SBubble>
              )}
            </Row>
            <Row role="bottomRow" padding={false}>
              <SimpleText role="senderName" fontSize={'0.7rem'} gray>
                {getMessageSender(message)}:{' '}
                <WhiteSpan role="messageText">
                  {/* @ts-expect-error The types for Highlighter seem to be incorrect; likely solved by package upgrade */}
                  <Highlighter
                    highlightStyle={{
                      backgroundColor: 'transparent',
                      color: palette.honeyYellow,
                    }}
                    findChunks={(options: FindChunks) =>
                      findChunksAtBeginningOfWords({
                        searchWords: options.searchWords as string[],
                        textToHighlight: options.textToHighlight,
                      })
                    }
                    searchWords={[searchText]}
                    textToHighlight={decode(messageShowGenerator())}
                    autoEscape
                  />
                </WhiteSpan>
              </SimpleText>
              {(message.lastReplySentTime || message.lastReplySent) && (
                <SimpleText fontSize={'0.6rem'} role="lastReplySentText">
                  <WhiteSpan role="date">
                    {handleDate(message.lastReplySentTime ?? message.lastReplySent)}
                  </WhiteSpan>
                </SimpleText>
              )}
            </Row>
          </MessagesTextContainer>
        </BottomRow>
      </Column>
      {!alert && (
        <Column role="moreContainer" className="right" onClick={e => handleDots(e, message.id)}>
          <SDots role="moreIcon" src={Dots} alt="" />
        </Column>
      )}
    </MessageItemContainer>
  );
};
