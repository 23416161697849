import { GroupType } from '../../utils/enums';
import { SelectListUser } from '../CreateMessage/createMessageSlice/types';
import { GroupMember } from '../GroupDetail/groupDetailSlice/types';
import { Group } from '../GroupsList/groupsSlice/types';
import { mapGroupMemberToSelectUser } from '../MemberSettings/helpers';
import { PanicAlarmData, PanicAlarmState } from './PanicButtonSlice/types';

/* Currently only CoAlert group type, but could be extended in the future to GroupType.CrossOrg and GroupType.Hidden
From Nathalie: "We have some customers that uses hidden groups, and have the need to send a panic button to 1 hidden group and also other coworkers. 
                Do you need to change it now? Or can we just focus on CoAlert now and instead just remove hidden groups and CrossOrg when we are ready"
*/
export const singleSelectableGroupTypes = [GroupType.CoAlert];

export interface PanicPreperationData {
  selectedGroups?: Group[];
  selectedUsers?: SelectListUser[];
  messageText?: string;
}

export const preparePanicData = (
  prepData: PanicPreperationData,
  authUserId: number
): PanicAlarmData | null => {
  const { selectedGroups = [], selectedUsers = [], messageText = '' } = prepData;
  const groupIds = selectedGroups.map(group => group.id);
  const recipientIds = selectedUsers.map(rec => rec.id);
  const recipientsIdsWithoutAuthUser = recipientIds.filter(rec => rec !== authUserId);
  if (!recipientsIdsWithoutAuthUser?.length || !messageText.length) {
    return null;
  }

  return {
    groupIds,
    recipientIds,
    alarmStatus: PanicAlarmState.Active,
    text: messageText,
  };
};

export const getRecipientsText = (
  selectedGroups: Group[],
  selectedUsers: SelectListUser[],
  authUserId: number
) => {
  let text = '';
  if (selectedGroups.length || selectedUsers.length) {
    const groupsNames = selectedGroups.map(group => group.name);
    const usersNames = selectedUsers
      .filter(user => !user.groupIds?.length && authUserId !== user.id)
      .map(user => user.displayName);
    text = [...groupsNames, ...usersNames].join(', ');
    if (text?.length) {
      text = text.replace(/,\s*$/, '');
    }
  }
  return text;
};

export const getIfCanActivatePanic = (
  messageText: string,
  selectedUsers: SelectListUser[],
  authUserId: number
) => {
  return Boolean(messageText.length && selectedUsers.filter(user => authUserId !== user.id).length);
};

export const addGroupMembersToSelectedAndGetList = (
  members: GroupMember[],
  selectedUsersList: SelectListUser[]
) => {
  const selectedUsers = [...selectedUsersList];
  members.forEach(member => {
    const userIndex = selectedUsers.findIndex(user => user.id === member.userID);
    if (!member.groupID) {
      return;
    }
    if (userIndex > -1) {
      const newUser = { ...selectedUsers[userIndex] };
      newUser.groupIds = newUser.groupIds
        ? [...newUser.groupIds, member.groupID]
        : [member.groupID];
      selectedUsers[userIndex] = newUser;
    } else {
      const selectedUser: SelectListUser = mapGroupMemberToSelectUser(member, {
        creatorId: null,
        isSelected: true,
        groupIds: [member.groupID],
      });
      selectedUsers.push(selectedUser);
    }
  });
  return selectedUsers;
};

export const getSelectedUsersAfterGroupUnselection = (
  selectedUsersList: SelectListUser[],
  groupId: number
) => {
  const usersInSelectedGroup = selectedUsersList.filter(user => user.groupIds?.includes(groupId));
  const usersNotInSelectedGroup = selectedUsersList.filter(
    user => !user.groupIds?.includes(groupId)
  );
  const newSelectedUsers = [...usersNotInSelectedGroup];
  usersInSelectedGroup.forEach(user => {
    const newUser = { ...user };
    newUser.groupIds = user.groupIds?.filter(id => id !== groupId);
    if (newUser.groupIds?.length) {
      newSelectedUsers.push(newUser);
    }
  });
  return newSelectedUsers;
};

export const getActiveGroupsAfterUserUnselection = (
  selectedUsers: SelectListUser[],
  selectedGroups: Group[],
  authUserId: number
) => {
  let activeGroupsIds: number[] = [];
  selectedUsers.forEach(user => {
    if (user.groupIds && user.id !== authUserId) {
      activeGroupsIds = [...activeGroupsIds, ...user.groupIds];
    }
  });
  const uniqueActiveGroupsIds = Array.from(new Set(activeGroupsIds));
  return selectedGroups.filter(group => uniqueActiveGroupsIds.includes(group.id));
};

export const getNotSelectableGroupsIds = (allGroups: Group[], selectedGroups: Group[]) => {
  if (!selectedGroups.length) return [];

  const haveGroupsWithSingleSelectableTypes = !!selectedGroups.filter(group =>
    singleSelectableGroupTypes.includes(group.groupType)
  ).length;

  return allGroups
    .filter(group => {
      if (haveGroupsWithSingleSelectableTypes) {
        return !selectedGroups.find(g => g.id === group.id);
      } else {
        return singleSelectableGroupTypes.includes(group.groupType);
      }
    })
    .map(group => group.id);
};
