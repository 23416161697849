import React, { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { changePassword, verifyPassword } from '../../apis/authApi/authAPI';
import Loader from '../../components/Loader/Loader';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { translate } from '../../utils/translate';
import { passwordSchema, validateConfirmPassword } from '../../utils/validate';
import { selectIsUserVerified, setIsLoginLoading } from '../Login/LoginSlice';
import { setIsVerifyAction } from '../Login/LoginSlice/actionCreators';
import {
  SButton,
  SContainer,
  SError,
  SForm,
  SStretchedSpace,
  SSubTitle,
  STextField,
  STitle,
} from './ChangePassword.styles';
import { TextFieldType } from '../../components/TextField/TextField';

export const ChangePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const confirm = useConfirmation();
  const dispatch = useDispatch();
  const isVerified = useSelector(selectIsUserVerified);

  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setShowWarnings] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [passwordConfirmationErrorMessage, setPasswordConfirmationErrorMessage] = useState('');

  const currentPasswordPage = location.pathname === '/change_password';
  const newPasswordPage = location.pathname === '/change_password/new';

  React.useEffect(() => {
    dispatch(setIsVerifyAction(true));
  }, []);

  React.useEffect(() => {
    if (newPasswordPage && !currentPassword) {
      navigate('/change_password');
    }
  }, []);

  const validate = () => {
    const passErr = passwordSchema(password);
    if (passErr) {
      setPasswordErrorMessage(passErr);
      setShowWarnings(true);
      return false;
    }

    const confirmPassErr = validateConfirmPassword(passwordConfirmation, password);
    if (confirmPassErr) {
      setPasswordConfirmationErrorMessage(confirmPassErr);
      setShowWarnings(true);
      return false;
    }
    return true;
  };

  const handleBlur = () => {
    setShowWarnings(false);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setPasswordErrorMessage('');
    setPasswordConfirmationErrorMessage('');

    if (currentPasswordPage) {
      try {
        await verifyPassword({
          password: currentPassword,
        });
        batch(() => {
          dispatch(setIsVerifyAction(true));
          dispatch(setIsLoginLoading(false));
        });
        navigate('new');
      } catch (error) {
        setShowWarnings(true);
        dispatch(setIsVerifyAction(false));
      }
    }
    if (newPasswordPage) {
      const valid = validate();
      if (!valid) return;

      dispatch(setIsVerifyAction(false));
      try {
        setIsLoading(true);

        await changePassword({
          oldPassword: currentPassword,
          newPassword: password,
        });

        setIsLoading(false);
        confirm({
          title: 'login_success',
          description: 'profile_changed_password_info',
          onSubmit: () => {
            navigate(-2);
          },
          confirmText: 'done',
        });
      } catch (error) {
        setIsLoading(false);
        confirm({
          title: 'warning',
          description: 'general_network_error',
          onSubmit: () => {
            handleSubmit(event);
          },
          confirmText: 'retry',
        });
      }
    }
  };

  if (isLoading) return <Loader />;

  return (
    <SContainer role="changePasswordContainer">
      {currentPasswordPage && (
        <>
          <STitle role="title">{translate('profile_current_password')}</STitle>
          <SSubTitle role="subtitle">{translate('profile_current_password_title')}</SSubTitle>
        </>
      )}
      {newPasswordPage && (
        <>
          <STitle role="title">{translate('profile_password_title')}</STitle>
          <SSubTitle role="subtitle">{translate('login_passwordInstruction')}</SSubTitle>
        </>
      )}
      <SForm onSubmit={handleSubmit} role="changePasswordForm">
        {currentPasswordPage && (
          <>
            <STextField
              isShadow
              error={!isVerified && showWarning}
              type={TextFieldType.PASSWORD}
              name="currentPassword"
              placeHolderTx={'profile_current_password'}
              value={currentPassword}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentPassword(event.target.value)
              }
              onBlur={handleBlur}
            />
            {!isVerified && showWarning && (
              <SError role="errorText">{translate('pw_verify_error')}</SError>
            )}
          </>
        )}

        {newPasswordPage && (
          <>
            <STextField
              isShadow
              error={!!passwordErrorMessage && showWarning}
              type={TextFieldType.PASSWORD}
              name="password"
              placeHolderTx="login_password"
              value={password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(event.target.value)
              }
              onBlur={handleBlur}
            />
            {passwordErrorMessage && showWarning && <SError>{passwordErrorMessage}</SError>}
            <br />
            <STextField
              isShadow
              error={!!passwordConfirmationErrorMessage && showWarning}
              type={TextFieldType.PASSWORD}
              name="repeatPssword"
              placeHolderTx="login_renterPass"
              value={passwordConfirmation}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setPasswordConfirmation(event.target.value)
              }
              onBlur={handleBlur}
            />
            {passwordConfirmationErrorMessage && showWarning && (
              <SError role="errorText">{passwordConfirmationErrorMessage}</SError>
            )}
          </>
        )}
        <SStretchedSpace role="stretchedSpace" />
        <SButton disabled={showWarning} type="submit" tx={currentPasswordPage ? 'next' : 'save'} />
      </SForm>
    </SContainer>
  );
};
