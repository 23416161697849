import * as React from 'react';
import { SImage, GreyOutlinedBtn, RecipientButtonWrapper } from './RecipientButton.styles';
import Remove from '../../assets/icons/cross--yellow.svg';

interface IRecipientButtonProps {
  name: string;
  hideRemove?: boolean;
  onClick?: () => void;
}

export const RecipientButton = (props: IRecipientButtonProps) => {
  const { name, onClick, hideRemove } = props;

  return (
    <RecipientButtonWrapper role="recipientButton">
      <GreyOutlinedBtn role="name">{name}</GreyOutlinedBtn>
      {!hideRemove && <SImage role="removeIcon" src={Remove} alt="removeGroup" onClick={onClick} />}
    </RecipientButtonWrapper>
  );
};
