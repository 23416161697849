import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { editOnCallAlertMessage } from '../../apis/onCallAlertApi';
import Pencil from '../../assets/icons/pen-with-side-line--yellow.svg';
import Loader from '../../components/Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { translate } from '../../utils/translate';
import {
  SIcon,
  SInput,
  SItem,
  SPage,
  SSaveButton,
  STextArea,
  STitle,
} from './EditOnCallAlertMessage.styles';
import { selectOnCallAlertDocument, selectOnCallAlertIsLoading } from './onCallAlertSlice';
import { fetchOnCallAlertDocument } from './onCallAlertSlice/actionCreators';
import { resizeTextArea } from './createMessageOnCallAlertSummary.helpers';

export const EditOnCallAlertMessage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const confirm = useConfirmation();

  const document = useAppSelector(selectOnCallAlertDocument);
  const isOnCallAlertLoading = useAppSelector(selectOnCallAlertIsLoading);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const textInputRef = React.useRef<HTMLInputElement>(null);

  const [subject, setSubject] = React.useState<string | undefined>(document?.subject || '');
  const [message, setMessage] = React.useState<string>(document?.text || '');

  React.useEffect(() => {
    textInputRef.current?.focus();
  }, []);

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value);
  };
  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const goToOnCallAlert = (onCallAlertId: number) => {
    navigate(`/oncall/${onCallAlertId}`);
  };

  const fetchOnCallAlert = async () => {
    dispatch(fetchOnCallAlertDocument(+(id || 0)));
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      if (id) {
        await editOnCallAlertMessage({
          id: +id,
          message: { text: message, subject },
        });
      }
      setIsLoading(false);
      fetchOnCallAlert();
      if (id) goToOnCallAlert(+id);
    } catch (e) {
      const description = typeof e === 'string' ? e : translate('onCallAlert_changes_not_saved');
      setIsLoading(false);
      confirm({
        title: 'warning',
        description,
        onCancel: () => {},
        cancelText: 'cancel',
      });
    }
  };

  React.useEffect(() => {
    setSubject(document?.subject || '');
    if (document?.text) setMessage(document?.text);
  }, [document]);

  React.useEffect(() => {
    fetchOnCallAlert();
  }, []);

  React.useEffect(() => {
    if (textAreaRef.current) resizeTextArea(textAreaRef, 18);
  }, [message]);

  if (!document && isOnCallAlertLoading && isLoading) return <Loader />;

  return (
    <SPage>
      <SItem className="input" role="subjectContainer">
        <STitle role="subjectLabel">{translate(`messages_subject_add`)}</STitle>
        <SInput
          role="subjectInput"
          ref={textInputRef}
          onChange={handleSubjectChange}
          value={subject}
          type="text"
          placeholder={translate(`messages_subject_add_placeholder`)}
        />
        <SIcon role="editIcon" src={Pencil} alt="" />
      </SItem>
      <SItem $textArea role="messageContainer" $padding={'0.6rem 0 0 0'}>
        <STitle role="messageLabel">{translate(`imOk_message`)}:</STitle>
        <STextArea
          role="messageTextArea"
          ref={textAreaRef}
          onChange={handleMessageChange}
          value={message}
          placeholder={translate(`imOk_message`)}
        />
        <SIcon role="editIcon" src={Pencil} alt="" />
      </SItem>
      <SSaveButton tx="saveChanges" onClick={handleSave} />
    </SPage>
  );
};
