import styled from 'styled-components';
import { palette } from '../../../theme/colors';

import { translate } from '../../../utils/translate';

const SContainer = styled.div`
  background-color: ${palette.honeyYellow};
  margin: 1rem 1rem 0 1rem;
  border-radius: 0.75rem;
  padding: 0.5rem;

  display: flex;
  align-items: center;
  img {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.625rem;
  }

  p {
    font-family: 'Roboto-Regular';
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${palette.raisinBlack3};
  }

  &.dangerTheme {
    background-color: ${palette.danger} !important;

    p,
    .learnMore {
      color: ${palette.white};
    }
  }

  .learnMore {
    font-weight: 700;
    font-family: 'Roboto-Medium';
    cursor: pointer;
    text-decoration: none;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    display: inline-block;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
    }
    &:active {
      opacity: 0.8;
    }
  }
`;

interface IBlockedStateBannerProps {
  titleTx: string;
  linkTx: string;
  iconSource: string;
  onLinkClick: () => void;
  className?: string | undefined;
}

export const BlockedStateBanner = (props: IBlockedStateBannerProps) => {
  const { linkTx, titleTx, onLinkClick, className, iconSource } = props;
  return (
    <SContainer className={className} role="blockedBannerContainer">
      <img role="bannerIcon" src={iconSource} alt="BlockedAlarmIcon" />
      <p role="text">
        {translate(titleTx)}{' '}
        <button onClick={onLinkClick} className="learnMore" role="moreButton">
          {translate(linkTx)}
        </button>
      </p>
    </SContainer>
  );
};
