import styled, { css, keyframes } from 'styled-components';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

const flash = keyframes`
   25% {
     background-color: ${palette.BlueBayoux};
   } 
   75% {
     background-color: ${palette.BlueBayoux};
  }
`;

export const SContentAboveMessage = styled.div<{ isTextMessage: boolean; needMinHeight: boolean }>`
  min-height: ${props =>
    props.isTextMessage ? toRem(14) : props.needMinHeight ? toRem(24) : undefined};
  padding-bottom: ${toRem(6)};
`;

export const SImageReplacer = styled.div`
  width: 2rem;
`;

export const SMainMessageContainer = styled.div<{
  haveCursorPointer?: boolean;
  isMessageSent?: boolean;
  haveReactions?: boolean;
}>`
  position: relative;
  display: flex;
  padding-bottom: ${props => (props.haveReactions ? toRem(22) : '0.5rem')};
  flex-direction: row;
  justify-content: ${props => props.isMessageSent && 'flex-end'};
`;

export const SProfilePictureWrapper = styled.div`
  align-self: flex-end;
  width: 2rem;
`;

export const SMessageTop = styled.div`
  position: absolute;
  top: -50px;
`;

export const SSenderNameContainer = styled.div<{
  haveMarginLeft: boolean;
  toContainerAlarm: boolean;
}>`
  display: flex;
  flex-direction: row;
  margin-top: 0;
  max-width: 50vw;
  padding-right: 1.4rem;
  margin-left: ${props => (props.haveMarginLeft ? toRem(10) : undefined)};
  margin-right: ${props => props.toContainerAlarm && '1.5rem'};
`;

export const SSenderName = styled.p`
  font-family: 'Roboto-Medium';
  font-size: ${toRem(14)};
  line-height: ${toRem(18.2)};
  font-weight: 500;
  color: ${palette.whisperGray};
  flex-shrink: 1;
`;

export const SAdditionalInfo = styled.p`
  font-family: 'Roboto-Regular';
  font-size: 12px;
  font-weight: 400;
  color: ${palette.navy20};
  flex-shrink: 1;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SMessageContainer = styled.div<{
  haveReactions: boolean;
  haveCursorPointer: boolean;
  audioContainer: boolean;
  sentMessage: boolean;
  messageContainerRounded: boolean;
  fileContainer: boolean;
  imageContainer: boolean;
  alarmContainer: boolean;
  locationContainer: boolean;
  recalledContainer: boolean;
}>`
  position: relative;
  background-color: ${palette.cloudBurst};
  padding: ${toRem(6)} ${toRem(10)};
  border-top-left-radius: ${toRem(12)};
  border-top-right-radius: ${toRem(12)};
  border-bottom-right-radius: ${toRem(12)};
  max-width: calc(100% - 3.5rem);
  min-width: ${props => (props.haveReactions ? toRem(140) : '5rem')};
  cursor: ${props => props.haveCursorPointer && 'pointer'};
  transition: transform 0.2s ease-in-out;
  ${props =>
    props.audioContainer &&
    css`
      width: 100%;
    `}
  ${props =>
    props.sentMessage &&
    css`
      background-color: ${palette.charcoal2};
      border-top-right-radius: ${toRem(12)};
      border-top-left-radius: ${toRem(12)};
      border-bottom-right-radius: 0;
      border-bottom-left-radius: ${toRem(12)};
      max-width: calc(100% - 1.5rem);
    `}
  ${props =>
    props.messageContainerRounded &&
    css`
      border-radius: ${toRem(12)};
    `}
     ${props =>
    props.fileContainer &&
    css`
      padding: ${toRem(6)} ${toRem(4)} ${toRem(5)};
      width: 100%;
    `}
    ${props =>
    props.imageContainer &&
    css`
      width: 20rem;
      padding: ${toRem(6)} ${toRem(4)};
    `}
    ${props =>
    props.alarmContainer &&
    css`
      padding: 0.5rem 0 0;
    `}
    ${props =>
    props.locationContainer &&
    css`
      padding: 0.5rem 0 0;
    `}
    ${props =>
    props.recalledContainer &&
    css`
      background-color: ${palette.stormGray};
    `}

  &.flash {
    animation: ${flash} 0.8s linear;
    animation-delay: 0.5s;
  }
`;

export const SForwarded = styled.div<{ haveName: boolean; extraForwardPadding: boolean }>`
  display: flex;
  flex-direction: row;
  min-width: 90px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${toRem(4)};
  padding-top: ${props => props.haveName && toRem(8)};
  padding-left: ${props => props.extraForwardPadding && '10px'};

  p {
    margin-left: 6px;
    font-style: italic;
    font-family: 'Roboto-Medium';
    color: ${palette.navy20};
    font-size: ${toRem(11)};
    line-height: ${toRem(14.3)};
  }
`;

export const SEditMessageButtonContainer = styled.div`
  z-index: 1;
  height: ${toRem(30)};
  width: ${toRem(30)};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0px;
`;

export const SSentMessageDateContainer = styled.div<{
  haveReactions: boolean;
  showTimeOnMap: boolean;
  isAlarmMessage: boolean;
}>`
  flex-direction: row;
  justify-content: flex-end;
  color: ${palette.white};
  position: absolute;
  right: ${toRem(10)};
  bottom: ${props => toRem(props.haveReactions ? 14 : 9)};
  ${props =>
    props.showTimeOnMap &&
    css`
      padding: ${toRem(3)} ${toRem(6)};
      border-radius: ${toRem(18)};
      font-size: ${toRem(10)};
      justify-content: center;
      align-items: center;
      background-color: ${palette.background};
    `}
  ${props =>
    props.isAlarmMessage &&
    css`
      margin: 0;
      bottom: 0.5rem;
      right: 0.5rem;
    `};
`;

export const Time = styled.span<{ showTimeOnMap: boolean }>`
  font-family: 'Roboto-Regular';
  font-size: ${toRem(11)};
  line-height: ${toRem(14.3)};
  font-weight: 500;
  color: ${palette.navy20};
  ${props =>
    props.showTimeOnMap &&
    css`
      color: ${palette.white};
      line-height: ${toRem(11)};
    `}
`;

export const SDots = styled.img`
  cursor: pointer;
`;

export const SReactionsRowContainer = styled.div`
  position: absolute;
  bottom: ${toRem(-14)};
  left: ${toRem(7)};
`;

export const SReactionsRowPlaceholder = styled.div`
  width: 100%;
  height: ${toRem(4)};
`;
