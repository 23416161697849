import styled from 'styled-components';

export const STutorialText = styled.p`
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
`;

export const STabContainer = styled.div`
  min-height: 0;
  height: 100%;
`;

export const SButtonWrapper = styled.div``;
