import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { extractAccountsFromGroups, searchGroups } from '../../../common/groupsHelpers';
import {
  DocumentFilters,
  DocumentFiltersV2,
} from '../../../components/DocumentFilter/DocumentFilterV2';
import { DocumentSortingType } from '../../../components/DocumentsSortingOptions/DocumentsSortingOptions';
import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';
import { Group } from '../../GroupsList/groupsSlice/types';
import {
  filterDynamicGroupDocuments,
  filterFilesByGroups,
  filterFoldersByGroups,
  filterGroupDocuments,
  searchFilesOrFolders,
  sortData,
} from '../helpers';
import { DocumentsState, FileItem, FolderItem } from './types';
import { ActiveDocumentsTab } from '../../../utils/enums';

const initialState: DocumentsState = {
  files: [],
  folders: [],
  groupDocuments: [],
  groupDocumentsFilter: undefined,
  isLoading: false,
  error: null,
  activeTab: ActiveDocumentsTab.Documents,
};

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setFiles: (state, action: PayloadAction<FileItem[]>) => {
      state.files = action.payload;
    },
    updateFav: (
      state,
      action: PayloadAction<{
        id: number;
        newFavValue: boolean;
        isFolder?: boolean;
        isFallbackUpdate?: boolean;
      }>
    ) => {
      const { id, newFavValue, isFolder, isFallbackUpdate } = action.payload;
      const isUnFavoringAction = !newFavValue;
      const canKeepShallowCopy = isUnFavoringAction && !isFallbackUpdate;
      if (isFolder) {
        state.folders = [...state.folders].map(item => {
          if (item.ID === id) {
            const updatedItem: FolderItem = {
              ...item,
              favorited: newFavValue,
              shallowFavorite: canKeepShallowCopy ? item.favorited : newFavValue,
            };
            return updatedItem;
          }
          return item;
        });
        return;
      }
      state.files = [...state.files].map(item => {
        if (item.id === id) {
          const updatedItem: FileItem = {
            ...item,
            favorite: newFavValue,
            shallowFavorite: canKeepShallowCopy ? item.favorite : newFavValue,
          };

          return updatedItem;
        }

        return item;
      });
    },
    removeShallowFavorites: state => {
      //START Remove shallow favorites from folders
      state.folders = [...state.folders].map(item => {
        if (item.shallowFavorite) {
          const updatedItem: FolderItem = {
            ...item,
            shallowFavorite: false,
          };
          return updatedItem;
        }
        return item;
      });
      //END Remove shallow favorites from folders
      //START Remove shallow favorites from files
      state.files = [...state.files].map(item => {
        if (item.shallowFavorite) {
          const updatedItem: FileItem = {
            ...item,
            shallowFavorite: false,
          };
          return updatedItem;
        }
        return item;
      });
      //END Remove shallow favorites from files
    },
    setFolders: (state, action: PayloadAction<FolderItem[]>) => {
      state.folders = action.payload;
    },
    setGroupDocuments: (state, action: PayloadAction<Group[]>) => {
      state.groupDocuments = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<ActiveDocumentsTab>) => {
      state.activeTab = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    resetDocumentSlice: state => {
      state.error = null;
      state.files = [];
      state.folders = [];
      state.groupDocuments = [];
    },
  },
});

export const {
  setFiles,
  setFolders,
  setGroupDocuments,
  setActiveTab,
  setIsLoading,
  setIsError,
  resetDocumentSlice,
  updateFav,
  removeShallowFavorites,
} = documentsSlice.actions;

// files selectors  ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
export const selectFiles = (state: RootState) => state.documents.files;

const selectFilesByGroupIdWithSearch =
  (id: number, searchTerm = '') =>
  (state: RootState) => {
    const data = state.documents.files.filter(file => file.groups.map(g => g.id).includes(id));
    return searchFilesOrFolders(searchTerm, data);
  };

const selectFilesByFolderIdWithSearch =
  (id: number, searchTerm = '') =>
  (state: RootState) => {
    const data = state.documents.files.filter(file => file.folderids.includes(id));
    return searchFilesOrFolders(searchTerm, data);
  };
// ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====

// folders selectors  ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
export const selectFolders = (state: RootState) => state.documents.folders;

const selectFoldersByGroupIdWithSearch =
  (id: number, searchTerm = '') =>
  (state: RootState) => {
    const data = state.documents.folders.filter(folder => folder.GroupID === id);

    return searchFilesOrFolders(searchTerm, data);
  };

export const selectFolderById = (id?: number) => (state: RootState) =>
  state.documents.folders.find(folder => folder.ID === id);

// ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====

// group documents ==== ==== ==== ==== ==== ==== ==== ==== ==== ====

export const selectDocumentGroupById = (id: number) => (state: RootState) =>
  state.documents.groupDocuments.find(g => g.id === id);

export const selectDocumentGroupsWithSearchFilterSort =
  (filters: DocumentFilters, sortBy: DocumentSortingType, searchTerm = '') =>
  (state: RootState) => {
    const { files: documents, folders, groupDocuments: allGroups } = state.documents;
    const filteredGroups = filterGroupDocuments({ allGroups, documents, folders, filters });
    return sortData(searchGroups(searchTerm, filteredGroups), sortBy, {
      Alphabetically: ['name'],
      date: ['created'],
    });
  };

// document groups used in the filters modal itself in order to be used in filtering the files and subfolders in the list
// empty group folders are filtered out of the list, and we filter the reset of groups by user membership in the groups, (passed as a dependency)
export const selectDocumentGroupsWithFilter =
  (filters: DocumentFiltersV2) => (state: RootState) => {
    const { files: documents, folders, groupDocuments: allGroups } = state.documents;
    return filterDynamicGroupDocuments({ allGroups, documents, folders, filters });
  };

// ==== ==== ==== ====
// group documents extras ==== ==== ==== ====

export const selectGroupsAccountsWithFilter = (filters: DocumentFilters) => (state: RootState) => {
  const { files: documents, folders, groupDocuments: allGroups } = state.documents;
  const filteredGroups = filterGroupDocuments({
    allGroups,
    documents,
    folders,
    filters: {
      showEmptyFolders: filters.showEmptyFolders,
      memberFilter: filters.memberFilter,
    },
  });
  return extractAccountsFromGroups(filteredGroups);
};

//==== ==== ==== ======== ==== ==== ======== ==== ==== ======== ==== ==== ====

// files and folders together ======== ==== ==== ======== ==== ==== ======== =

interface SelectFilesFoldersSortedProps {
  groupId: number;
  folderId?: number;
  sortBy: DocumentSortingType;
  searchTerm?: string;
}
export const selectFilesFoldersSorted = (props: SelectFilesFoldersSortedProps) => () => {
  const { folderId, groupId, searchTerm = '', sortBy } = props;
  const isFolderContentList = !!folderId;

  // [TO_RETHINK_ABOUT_IT] in another task
  const folders = isFolderContentList
    ? []
    : useAppSelector(selectFoldersByGroupIdWithSearch(+groupId, searchTerm));

  const files = useAppSelector(
    isFolderContentList
      ? selectFilesByFolderIdWithSearch(Number(folderId), searchTerm)
      : selectFilesByGroupIdWithSearch(+groupId, searchTerm)
  );

  const sortedFolderFiles = sortData([...folders, ...files], sortBy, {
    Alphabetically: ['Name', 'original_filen_name'],
    date: ['creationTime', 'upload_time'],
  });
  return { folders, files, sortedFolderFiles };
};

// folders grouped together (sorted - filtered) first then the files grouped together (sorted - filtered)
// used in Document list
interface SelectFoldersFilesSortedFilteredProps {
  filters: DocumentFiltersV2;
  sortBy: DocumentSortingType;
  searchTerm?: string;
  showDocumentsFromFolders?: boolean;
  groups?: Group[];
}

// select files and subfolders, sorted and filtered
// used in main documents list
export const selectFilesFoldersSortedFiltered =
  (props: SelectFoldersFilesSortedFilteredProps) => (state: RootState) => {
    const { searchTerm = '', sortBy, filters, showDocumentsFromFolders, groups } = props;
    const { files: documents, folders } = state.documents;
    const filterFolders = filterFoldersByGroups({ documents, filters, folders, groups });
    const files = filterFilesByGroups({
      documents,
      filters,
      folders,
      searchTerm,
      showDocumentsFromFolders,
      groups,
    });
    const sortedFolderFiles = [
      ...sortData(filterFolders, sortBy, { Alphabetically: ['Name'], date: ['creationTime'] }),
      ...sortData(files, sortBy, {
        Alphabetically: ['original_filen_name'],
        date: ['upload_time'],
      }),
    ];

    return {
      itemsList: searchFilesOrFolders(searchTerm, sortedFolderFiles),
      files,
      filterFolders,
    };
  };
//==== ==== ==== ======== ==== ==== ======== ==== ==== ======== ==== ==== ====

// could be sefely deleted and replaced selectFiles and selectFolders with after updating create folder ==== ==== ====
// ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
export const selectDocumentsIsListLoading = (state: RootState) => state.documents.isLoading;
export const selectActiveTab = (state: RootState) => state.documents.activeTab;

export default documentsSlice.reducer;
