import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';
import {
  SContainer,
  SMessage,
  SHeader,
  SHeaderContainer,
  SCrossIconContainer,
  SButton,
} from './UpdateAppRecommendationBanner.styles';
import { translate } from '../../utils/translate';
import { setIsAppUpdateRecommended } from '../../containers/CheckAppAvailable/checkAppAvailableSlice';
import { useAppDispatch } from '../../hooks';
import i18n, { SupportLanguageCodes } from '../../i18n';
import { ipcRendererSend } from '../../utils/electronHooks';

export const UpdateAppRecommendationBanner = () => {
  const dispatch = useAppDispatch();

  const close = () => {
    dispatch(setIsAppUpdateRecommended(false));
  };

  return (
    <SContainer>
      <SHeaderContainer>
        <SHeader>{translate('update_app_recommendation_header')}</SHeader>
        <SCrossIconContainer onClick={close}>
          <CrossIcon className="icon" />
        </SCrossIconContainer>
      </SHeaderContainer>
      <SMessage>{translate('update_app_recommendation_message')}</SMessage>
      <SButton
        aria-label="Update app"
        type="submit"
        onClick={() =>
          ipcRendererSend(
            'open_link',
            `${process.env.REACT_APP_SUPPORT_APP_UPDATE}?lang=${
              SupportLanguageCodes[i18n.language]
            }`
          )
        }
        tx="update_app_recommendation_button"
      />
    </SContainer>
  );
};
