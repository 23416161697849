import EndedDisclaimerIcon from '../../../assets/imgs/chats/alarm-disclaimer.svg';
import StartedDisclaimerIcon from '../../../assets/imgs/chats/checklists-started-disclaimer.svg';
import EndedIcon from '../../../assets/imgs/chats/checklist-ended.svg';
import StartedIcon from '../../../assets/imgs/chats/checklist-started.svg';
import { translate } from '../../../utils/translate';
import {
  SContainer,
  SContentContainer,
  STextContainer,
  STitle,
  STitleContainer,
} from './ChecklistMessage.styles';
import { useNavigate } from 'react-router-dom';

interface IChecklistMessageProps {
  started: boolean;
  startedTx?: string;

  checklistName: string;
  checklistLink: string;
  isSent: boolean;
  LinkState?: { [index: string]: string };
}

export const ChecklistMessage = (props: IChecklistMessageProps) => {
  const { started, checklistLink, LinkState, checklistName, startedTx, isSent } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    if (!checklistLink) return;
    navigate(checklistLink, { state: LinkState });
  };
  return (
    <SContainer $isSent={isSent}>
      <SContentContainer
        $isSent={isSent}
        $started={started}
        role="checklistMessage"
        onClick={handleClick}
      >
        <STextContainer role="infoContainer">
          <STitleContainer role="titleContainer">
            <img
              className="started-icon"
              src={started ? StartedDisclaimerIcon : EndedDisclaimerIcon}
              alt={`${started ? 'started' : 'ended'}`}
              role="icon"
            />

            <STitle $started={started} role="title">
              {translate(started ? startedTx || 'checklist_started' : 'checklist_end')}
            </STitle>
          </STitleContainer>
          {checklistName}
        </STextContainer>
        <img
          src={started ? StartedIcon : EndedIcon}
          alt={`${started ? 'started-link' : 'ended-link'}`}
          role="icon"
        />
      </SContentContainer>
    </SContainer>
  );
};
