import React from 'react';
import {
  ButtonGroup,
  ButtonGroupContainer,
  Icon,
  ProvideStatusText,
  ResponseButton,
  Text,
} from './OnCallAlert.styles';
import { translate } from '../../utils/translate';
import checkMark from '../../assets/icons/checkmark--white.svg';
import { useAppDispatch } from '../../hooks';
import {
  OnCallAlertDocumentSimple,
  OnCallAlertUser,
} from '../../containers/OnCallAlertList/onCallAlertSlice/types';
import { OnCallAlertStatusType } from '../../utils/enums';
import { sendOnCallAlertResponse } from '../../containers/OnCallAlertList/onCallAlertSlice/actionCreators';
import styled from 'styled-components';
import { Button } from '../Button/Button';
import { palette } from '../../theme/colors';
import { OnCallAlertkMessageMembersType } from '../../routes/OnCallAlert';

const SCreatMessageButton = styled(Button)`
  margin: auto;
  z-index: 11;

  width: 100%;
  button {
    max-width: 100rem;
    font-size: 1rem;
    padding: 0.8125rem 0;
    text-align: center;
    font-family: 'Roboto-Medium';
    z-index: 2;
    height: 3rem;

    color: ${palette.raisinBlack3};
  }
`;

interface OnCallAlertResponseButtonGroupProps {
  hideResponseButtonsForUser?: boolean;
  userFromList?: OnCallAlertUser;
  onCallAlertDocument: OnCallAlertDocumentSimple;
  reloadData: () => void;
  userIsImOkCreator: boolean;
  contactMemberByType: (type: OnCallAlertkMessageMembersType) => void;
  setCreateMessagesModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const OnCallAlertResponseButtonGroup = React.forwardRef<
  HTMLDivElement,
  OnCallAlertResponseButtonGroupProps
>((props, ref) => {
  const {
    userIsImOkCreator,
    hideResponseButtonsForUser,
    onCallAlertDocument,
    userFromList,
    setCreateMessagesModalOpen,
  } = props;
  const dispatch = useAppDispatch();

  const sendResponse = async (status: number) => {
    if (userFromList?.status !== status) {
      dispatch(sendOnCallAlertResponse(onCallAlertDocument.id, status));
    }
  };

  const didSelectAStatus = userFromList?.status !== OnCallAlertStatusType.NoStatus;
  const isNotAvailableSelected = userFromList?.status === OnCallAlertStatusType.NotAvailable;
  const isAvailableSelected = userFromList?.status === OnCallAlertStatusType.Available;
  const unHighlightAvailable = didSelectAStatus && !isAvailableSelected;
  const unHighlightNotAvailable = didSelectAStatus && !isNotAvailableSelected;

  return (
    <ButtonGroupContainer ref={ref} role="buttonGroupContainer">
      {!userIsImOkCreator && !hideResponseButtonsForUser && (
        <ProvideStatusText role="statusText">{translate('imOk_provideStatus')}</ProvideStatusText>
      )}
      {!hideResponseButtonsForUser && (
        <ButtonGroup role="buttonGroup">
          <ResponseButton
            red
            onClick={() => sendResponse(OnCallAlertStatusType.NotAvailable)}
            $unHighlight={unHighlightNotAvailable}
            role="notAvailableButton"
          >
            <Text role="buttonText">{translate('onCallAlert_unavailable')}</Text>
            {isNotAvailableSelected && <Icon role="buttonIcon" src={checkMark} />}
          </ResponseButton>
          <ResponseButton
            onClick={() => sendResponse(OnCallAlertStatusType.Available)}
            role="availableButton"
            $unHighlight={unHighlightAvailable}
          >
            <Text role="buttonText">{translate('onCallAlert_available')}</Text>
            {isAvailableSelected && <Icon role="buttonIcon" src={checkMark} />}
          </ResponseButton>
        </ButtonGroup>
      )}
      {userIsImOkCreator && (
        <SCreatMessageButton
          data-e2e="imOk_createMessage_button"
          onClick={() => setCreateMessagesModalOpen(true)}
          tx={'imOk_createMessage'}
        />
      )}
    </ButtonGroupContainer>
  );
});

export default OnCallAlertResponseButtonGroup;
