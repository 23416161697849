import { OnCallAlertkMessageMembersType } from '../../routes/OnCallAlert';
import { OnCallAlertStatusType } from '../../utils/enums';
import { OnCallAlertDocumentSimple } from './onCallAlertSlice/types';

export const getOnCallAlertInitiallSelected = (
  meesageType: OnCallAlertkMessageMembersType,
  onCallAlert: OnCallAlertDocumentSimple,
  withoutCurrentUserId?: number
): Set<number> => {
  switch (meesageType) {
    case 'ALL':
      return new Set(
        onCallAlert?.users
          .map(member => member.userId)
          .filter(id => {
            if (withoutCurrentUserId) return id !== withoutCurrentUserId;
            return true;
          })
      );
    case 'NOT_AVAILANLE':
      return new Set(
        onCallAlert?.users
          .filter(user => user.status === OnCallAlertStatusType.NotAvailable)
          .map(user => user.userId)
          .filter(id => {
            if (withoutCurrentUserId) return id !== withoutCurrentUserId;
            return true;
          })
      );
    case 'NO_STATUS':
      return new Set(
        onCallAlert?.users
          .filter(user => user.status === OnCallAlertStatusType.NoStatus)
          .map(user => user.userId)
      );
    case 'AVIALABLE':
      return new Set(
        onCallAlert?.users
          .filter(user => user.status === OnCallAlertStatusType.Available)
          .map(user => user.userId)
          .filter(id => {
            if (withoutCurrentUserId) return id !== withoutCurrentUserId;
            return true;
          })
      );
    default:
      return new Set(
        onCallAlert?.users
          .map(member => member.userId)
          .filter(id => {
            if (withoutCurrentUserId) return id !== withoutCurrentUserId;
            return true;
          })
      );
  }
};

export const checkOnCallAlertIfNotZeroMembers = (
  type: OnCallAlertkMessageMembersType,
  onCallAlert: OnCallAlertDocumentSimple
): boolean => {
  switch (type) {
    case 'ALL':
      return new Set(onCallAlert?.users.map(member => member.userId)).size === 0;
    case 'NOT_AVAILANLE':
      return (
        new Set(
          onCallAlert?.users
            .filter(user => user.status === OnCallAlertStatusType.NotAvailable)
            .map(user => user.userId)
        ).size === 0
      );
    case 'NO_STATUS':
      return (
        new Set(
          onCallAlert?.users
            .filter(user => user.status === OnCallAlertStatusType.NoStatus)
            .map(user => user.userId)
        ).size === 0
      );
    case 'AVIALABLE':
      return (
        new Set(
          onCallAlert?.users
            .filter(user => user.status === OnCallAlertStatusType.Available)
            .map(user => user.userId)
        ).size === 0
      );
    default:
      return new Set(onCallAlert?.users.map(member => member.userId)).size === 0;
  }
};

export const resizeTextArea = (
  textAreaRef: React.RefObject<HTMLTextAreaElement>,
  minHeight: number
) => {
  const textArea = textAreaRef.current;
  if (!textArea) return;
  textArea.style.height = `${minHeight}px`; // Explicitly reset the height to a min value to force recalculation
  if (textArea.scrollHeight) {
    const calculatedHeight = textArea.scrollHeight;
    textArea.style.height =
      calculatedHeight > minHeight ? `${calculatedHeight}px` : `${minHeight}px`;
  } else {
    textArea.style.height = `auto`;
  }
};
