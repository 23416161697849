import * as React from 'react';
import { Layout } from '../Layout/Layout';
import { ReactComponent as Sign } from '../../assets/images/warning-triangle.svg';
import { translate } from '../../utils/translate';
import { SPage } from './OfflineApp.styles';

export const OfflineApp = () => {
  return (
    <Layout isAuthLayout={false}>
      <SPage>
        <div className="center-container">
          <Sign />
          <div className="text-container">
            <h1>{translate('offline_text')}</h1>
            <p>{translate('connect_network_text')}</p>
          </div>
        </div>
      </SPage>
    </Layout>
  );
};
