import styled from 'styled-components';

export const EmptyListFallbackWrapper = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  min-width: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  p {
    font-family: 'Roboto-Medium';
    font-size: 1rem;
    margin-top: 1rem;
  }
`;
