import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { Group } from '../../GroupsList/groupsSlice/types';
import { LocationData, PanicAlarmData, PanicButtonState } from './types';
import { SelectListUser } from '../../CreateMessage/createMessageSlice/types';

const initialState: PanicButtonState = {
  selectedGroups: [],
  selectedUsers: [],
  groups: [],
  users: [],
  isLoading: false,
  usersLoading: false,
  groupsLoading: false,
  locationData: null,
  messageText: '',
  alarmData: null,
  error: null,
  isLocationDenied: false,
};

const panicButtonSlice = createSlice({
  name: 'panicButton',
  initialState,
  reducers: {
    setSelectedGroups: (state, action: PayloadAction<Group[]>) => {
      state.selectedGroups = action.payload;
    },
    setSelectedUsers: (state, action: PayloadAction<SelectListUser[]>) => {
      state.selectedUsers = action.payload;
    },
    setGroups: (state, action: PayloadAction<Group[]>) => {
      state.groups = action.payload;
    },
    setUsers: (state, action: PayloadAction<SelectListUser[]>) => {
      state.users = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setUsersLoading: (state, action: PayloadAction<boolean>) => {
      state.usersLoading = action.payload;
    },
    setGroupsLoading: (state, action: PayloadAction<boolean>) => {
      state.groupsLoading = action.payload;
    },
    setLocationData: (state, action: PayloadAction<LocationData | null>) => {
      state.locationData = action.payload;
    },
    setMessageText: (state, action: PayloadAction<string>) => {
      state.messageText = action.payload;
    },
    setAlarmData: (state, action: PayloadAction<PanicAlarmData | null>) => {
      state.alarmData = action.payload;
    },
    setIsLocationDenied: (state, action: PayloadAction<boolean>) => {
      state.isLocationDenied = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const {
  setSelectedGroups,
  setSelectedUsers,
  setGroups,
  setUsers,
  setIsLoading,
  setUsersLoading,
  setGroupsLoading,
  setLocationData,
  setMessageText,
  setAlarmData,
  setIsLocationDenied,
  setError,
} = panicButtonSlice.actions;

export const getSelectedGroups = (state: RootState) => state.panicButton.selectedGroups;
export const getSelectedUsers = (state: RootState) => state.panicButton.selectedUsers;
export const getGroups = (state: RootState) => state.panicButton.groups;
export const getUsers = (state: RootState) => state.panicButton.users;
export const getIsLoading = (state: RootState) => state.panicButton.isLoading;
export const getUsersLoading = (state: RootState) => state.panicButton.usersLoading;
export const getGroupsLoading = (state: RootState) => state.panicButton.groupsLoading;
export const getLocationData = (state: RootState) => state.panicButton.locationData;
export const getIsLocationDenied = (state: RootState) => state.panicButton.isLocationDenied;
export const getMessageText = (state: RootState) => state.panicButton.messageText;
export const getAlarmData = (state: RootState) => state.panicButton.alarmData;

export default panicButtonSlice.reducer;
