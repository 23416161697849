import isElectron from 'is-electron';
import styled from 'styled-components';
import { ReactComponent as SideMenu } from '../../../assets/icons/stacked-lines.svg';
import { Link } from 'react-router-dom';
import { palette } from '../../../theme/colors';

export const SHeaderWrapper = styled.header<{ isAlarmActive: boolean }>`
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  border-radius: 0 0 17px 17px;
  background-color: ${props =>
    isElectron()
      ? props.isAlarmActive
        ? palette.tartOrange
        : props.theme.palette.background.nav
      : palette.transparent};
`;

export const SHeader = styled.div<{ isAlarmActive: boolean; extra: boolean }>`
  background-color: ${props =>
    props.isAlarmActive ? palette.tartOrange : props.theme.palette.background.nav};
  color: ${palette.white};
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  display: flex;
  place-content: space-between;
  align-items: center;

  max-width: 26rem;
  margin: auto;
  padding: 2em;

  height: ${props => (props.extra ? '4.86rem' : '4.81rem')};

  svg {
    aspect-ratio: 1;
  }
`;

export const Logo = styled.img`
  height: inherit;
  padding: 0.5rem 0;
  object-fit: contain;
`;

export const SBalanceSpace = styled.div`
  aspect-ratio: 1;
  width: 2rem;
`;

export const SLink = styled(Link)`
  height: 2.5rem;
  min-width: 2rem;
  display: flex;
  align-items: center;
`;

export const SSideMenu = styled(SideMenu)`
  width: 2.25rem;
  height: 2.25rem;
`;

export const SDots = styled.img`
  cursor: pointer;
`;

export const SHeaderMessageWrapper = styled.div<{ pointer?: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 2.5rem;
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};
`;

export const HeaderMessageText = styled.h3`
  text-align: center;
`;

export const SSubMessageText = styled.p`
  text-align: center;
  font-family: 'Roboto-Medium';
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding-top: 0.25rem;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`;
