import { useEffect, useMemo, useState } from 'react';
import { UserSelectItem } from '../../components/Chat/UserSelectItem';
import { HighlightSearchTerm } from '../../components/Checklists/HighlightSearchTerm/HighlightSearchTerm';
import { EmptyListFallback } from '../../components/EmptyListFallback/EmptyListFallback';
import Loader from '../../components/Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectMessagesRecipients } from '../ChatsList/chatListSlice';
import { selectUser } from '../Login/LoginSlice';
import { AdditionalUsersLoaderContainer } from './CreateMessageSummary.styles';
import { SList } from './SelectGroupsList';
import {
  createMessageAdditionalUsersLoading,
  createMessageGetUsers,
  createMessageIsLoading,
  getSelectedGroups,
  getSelectedUsers,
  setSelectedGroups,
  setSelectedUsers,
} from './createMessageSlice';
import { SelectListUser } from './createMessageSlice/types';
import { getActiveGroupsAfterUserUnselection } from './helpers';
interface SelectUsersListProps {
  searchText: string;
  usersToHide?: number[];
  excludeRecipients?: boolean;
  onEndReached?: () => void;
}

export const SelectUsersList = (props: SelectUsersListProps) => {
  const { searchText, usersToHide, excludeRecipients, onEndReached } = props;

  const dispatch = useAppDispatch();

  const users = useAppSelector(createMessageGetUsers);
  const isLoading = useAppSelector(createMessageIsLoading);
  const additionalUsersLoading = useAppSelector(createMessageAdditionalUsersLoading);
  const selectedUsers = useAppSelector(getSelectedUsers);
  const selectedGroups = useAppSelector(getSelectedGroups);
  const excludedRecipients = useAppSelector(selectMessagesRecipients);
  const authUser = useAppSelector(selectUser);

  const [excludedRecipientsIds, setExcludedRecipientsIds] = useState<number[]>([]);
  const [lastReachedUserIndex, setLastReachedUserIndex] = useState<number>(0);
  const filteredUsers = useMemo(() => {
    return users ? [...users].filter(user => !excludedRecipientsIds.includes(user.id)) : [];
  }, [users, excludedRecipientsIds]);

  useEffect(() => {
    if (usersToHide?.length) setExcludedRecipientsIds(usersToHide);
  }, []);

  useEffect(() => {
    const getExcludedRecipientsIds = () => {
      const idsList = excludedRecipients.map(recipient => recipient.userID);
      setExcludedRecipientsIds(idsList);
    };

    if (excludeRecipients) getExcludedRecipientsIds();
  }, [excludedRecipients]);

  const onUserClick = (selectedUser: SelectListUser) => {
    const selectedUsersList = [...selectedUsers];
    const selectedGroupsList = [...selectedGroups];
    const foundUserIndex = selectedUsers.findIndex(user => user.id === selectedUser.id);
    if (foundUserIndex > -1) {
      const { groupIds } = selectedUsersList[foundUserIndex];
      selectedUsersList.splice(foundUserIndex, 1);
      if (groupIds?.length) {
        const newGroupsList = getActiveGroupsAfterUserUnselection(
          selectedUsersList,
          selectedGroupsList,
          authUser?.id ?? 0
        );
        dispatch(setSelectedGroups(newGroupsList));
      }
    } else {
      selectedUsersList.push({ ...selectedUser, isSelected: true });
    }
    dispatch(setSelectedUsers(selectedUsersList));
  };

  const isSelectedUser = (id: number) => {
    if (authUser?.id === id) {
      return true;
    }
    const foundUser = [...selectedUsers].find(user => user.id === id);
    return !!foundUser;
  };

  const isInViewport = (element: HTMLElement | null): boolean => {
    if (element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    } else {
      return false;
    }
  };

  const handleMouseWheel = () => {
    const lastUserIndex = filteredUsers.length - 1;
    const haveReachedEnd = isInViewport(document.getElementById(`user-${lastUserIndex}`));
    if (haveReachedEnd && !additionalUsersLoading && lastReachedUserIndex !== lastUserIndex) {
      setLastReachedUserIndex(lastUserIndex);
      if (onEndReached) onEndReached();
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <SList onWheel={handleMouseWheel} role="selectUsersList">
      {filteredUsers.map((user, index) => (
        <UserSelectItem
          id={`user-${index}`}
          key={user.id}
          user={user}
          name={HighlightSearchTerm(user.displayName, searchText)}
          isSelected={isSelectedUser(user.id)}
          onCardPress={() => onUserClick(user)}
          photoFileName={user.photoFileName || undefined}
          isLocked={user.id === authUser?.id}
        />
      ))}
      {additionalUsersLoading && (
        <AdditionalUsersLoaderContainer role="additionalUsersLoader">
          <Loader />
        </AdditionalUsersLoaderContainer>
      )}
      {!filteredUsers.length && (
        <EmptyListFallback
          listLength={filteredUsers.length ?? 0}
          isLoading={isLoading}
          searchTerm={searchText}
          emptyListTx={'users_not_found_by_search'}
          emptySearchTx={'users_not_found_by_search'}
        />
      )}
    </SList>
  );
};
