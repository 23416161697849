import * as React from 'react';
import { ReplyToMessageIcon } from '../../../containers/Chat/Chat';
import HyperLinkText from '../../HyperlinkText/HyperLinkText';
import DeletedMessage from '../DeletedMessage/DeletedMessage';
import { SReplyContainer, ReplyTextContainer, SLine } from './ReplyToMessage.styles';

interface IReplyToMessageProps {
  senderTitle: string;
  messageBody: string;
  messageIcon?: ReplyToMessageIcon;
  isSent: boolean;
  isDeleted: boolean;
  onClick: () => void;
}

export const ReplyToMessage = (props: IReplyToMessageProps) => {
  const { senderTitle, messageBody, isSent, onClick, messageIcon, isDeleted } = props;

  return (
    <SReplyContainer $isSent={isSent} $isDeleted={isDeleted} onClick={onClick}>
      <SLine />
      {messageIcon && (
        <img src={messageIcon.src} alt={'messageIcon'} className={messageIcon.classPresets} />
      )}
      <ReplyTextContainer>
        {isDeleted ? (
          <DeletedMessage isRepliedTo />
        ) : (
          <>
            {senderTitle && <p className="senderTitle">{senderTitle}</p>}
            <p className="messageBody">
              <HyperLinkText text={messageBody} />
            </p>
          </>
        )}
      </ReplyTextContainer>
    </SReplyContainer>
  );
};
