import styled from 'styled-components';
import { Page } from '../Page/Page';

export const SPage = styled(Page)`
  min-height: 100%;

  .center-container {
    margin-top: 8.5625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .text-container {
    margin-top: 1.625rem;
    font-family: 'Roboto-Regular';

    h1 {
      font-weight: 500;
      font-size: 1.625rem;
      line-height: 30px;
    }
    p {
      margin-top: 0.75rem;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 136.34%;
    }
  }
`;
