import { SelectItemContainer, Row, MessagesTextContainer } from './UserSelectItem.styles';
import { BottomRow, LeftContainer, SimpleText } from './SelectGroupList.styles';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';
import { SelectListUser } from '../../containers/CreateMessage/createMessageSlice/types';
import SCheckbox from '../FilterItem/SBoxButton';
import { trunctateText } from '../../utils/truncate';
import AvailabilityChip from '../AvailabilityChip/AvailabilityChip';

interface IUserProps {
  user: SelectListUser;
  onCardPress?: () => void;
  isSelected: boolean;
  isLocked?: boolean;
  photoFileName?: string;
  name?: string;
  id?: string;
  isNotSelectable?: boolean;
}

export const UserSelectItem = (props: IUserProps) => {
  const { user, onCardPress, isSelected, photoFileName, isLocked, name, id, isNotSelectable } =
    props;

  const isTitleNeedMargin = user.title || !user.available;
  return (
    <SelectItemContainer
      onClick={() => !isLocked && !isNotSelectable && onCardPress && onCardPress()}
      className="item-container"
      role={`userSelectItem-${name || user.displayName}`}
      id={id}
    >
      <BottomRow role="itemContainer">
        <LeftContainer role="imageContainer">
          <ProfilePicture profilePictureFileName={photoFileName || null} />
        </LeftContainer>
        <MessagesTextContainer role="nameTextContainer">
          <Row role="nameRow">
            <SimpleText
              margin={isTitleNeedMargin ? '0 0 6px 0' : ''}
              fontSize={'0.8rem'}
              role="name"
            >
              {name || user.displayName}
            </SimpleText>
            {user?.title && (
              <SimpleText margin={'0 0 6px 0'} fontSize={'0.75rem'} gray>
                {trunctateText(user.title, 50)}
              </SimpleText>
            )}
            {user.available === false && <AvailabilityChip isAvailable={false} />}
          </Row>
        </MessagesTextContainer>
        <SCheckbox isChecked={isSelected} disabled={isLocked || isNotSelectable} grey={isLocked} />
      </BottomRow>
    </SelectItemContainer>
  );
};
