import { useEffect, useMemo } from 'react';
import Loader from '../../components/Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import {
  SContactCard,
  SInternalSupport,
  SPage,
  SSupportAccountGroup,
} from './InternalSupport.styles';
import { selectIsInternalSupportLoading, selectSupports } from './supportSlice';
import { EmptyListFallback } from '../../components/EmptyListFallback/EmptyListFallback';
import { getSupports } from './supportSlice/actionCreators';
import { getSupportsByAccountGrouped } from './helper';

export const InternalSupport = () => {
  const dispatch = useAppDispatch();
  const confirm = useConfirmation();

  const supports = useAppSelector(selectSupports);
  const isLoading = useAppSelector(selectIsInternalSupportLoading);
  const supportsGrouped = useMemo(() => getSupportsByAccountGrouped(supports ?? []), [supports]);

  const handleError = () => {
    confirm({
      title: 'warning',
      description: 'general_network_error',
      onSubmit: () => {
        dispatch(getSupports(handleError));
      },
      confirmText: 'retry',
    });
  };

  useEffect(() => {
    dispatch(getSupports(handleError));
  }, [dispatch]);

  if (isLoading) return <Loader />;

  if (supportsGrouped.length === 0) {
    return (
      <SPage>
        <SInternalSupport role="internalSupportContainer">
          <EmptyListFallback
            listLength={supportsGrouped.length}
            isLoading={isLoading}
            emptyListTx={'internal_support_missing'}
          />
        </SInternalSupport>
      </SPage>
    );
  }

  return (
    <SPage>
      <SInternalSupport role="internalSupportContainer">
        {supportsGrouped.map(supportGrouped => (
          <SSupportAccountGroup key={`${supportGrouped.id}`}>
            <p className="groupName">{supportGrouped.name}</p>
            {supportGrouped.items.map((item, i) => (
              <SContactCard key={i}>
                <p className="name">{item.supportPersonName}</p>
                <p className="title">{item.supportPersonTitle}</p>
                {item.supportPhoneNumber && (
                  <a className="phone" href={`tel:${item.supportPhoneNumber}`} role="phone">
                    {item.supportPhoneNumber}
                  </a>
                )}
                {item.supportEmail && (
                  <a className="email" href={`mailto:${item.supportEmail}`} role="email">
                    {item.supportEmail}
                  </a>
                )}
              </SContactCard>
            ))}
          </SSupportAccountGroup>
        ))}
      </SInternalSupport>
    </SPage>
  );
};
