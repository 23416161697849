import { useEffect, useState } from 'react';
import { MessageFilter } from '../../components/Chat/MessageFilter';
import { FilterSection } from '../../components/SearchFilterBar/SearchFilterBar';
import { GroupsToShow } from '../../utils/global';
import { MessageFilterFilterBar } from '../ChatsList/ChatsList.styles';
import { SelectGroupsList } from '../CreateMessage/SelectGroupsList';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import BigFloatButton from '../../components/BigFloatButton/BigFloatButton';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  getSelectedGroups,
  getSelectedUsers,
  setSelectedGroupType,
  setUsers,
} from '../CreateMessage/createMessageSlice';
import { t } from 'i18next';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import { SearchBarBase } from '../../components/SearchBarBase/SearchBarBase';
import { STabs, STabList, STab, STabPanel } from '../CreateMessage/CreateMessageList';
import { addRecipients } from './chatDetailsSlice/actionCreators';
import { Page } from '../../components/Page/Page';
import { fetchGroups } from '../GroupsList/groupsSlice/actionCreators';
import { messageDetailsIsLoading } from './chatDetailsSlice';
import Loader from '../../components/Loader/Loader';
import { ActiveTab } from '../../utils/enums';
import { selectCurrentChat, selectMessagesRecipients } from '../ChatsList/chatListSlice';
import {
  fetchCurrentChat,
  getMessagesRecipientsAction,
} from '../ChatsList/chatListSlice/actionCreators';
import { AddRecipientsUsersList } from './AddRecipientsUsersList';
import { selectUser, selectUserRoles } from '../Login/LoginSlice';

const SPage = styled(Page)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 0;
`;

const CreateMessageListWrapper = styled.div`
  min-height: 0;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const SSearchBarBase = styled(SearchBarBase)`
  width: 100%;
`;

const GROUP_FILTER_SECTIONS: FilterSection = {
  title: 'messages_show_groups',
  type: 'checkbox',
  content: [
    {
      id: 0,
      name: GroupsToShow.MemberOfGroup,
      checked: true,
    },
    {
      id: 1,
      name: GroupsToShow.NotMemberOfGroup,
      checked: false,
    },
  ],
};

export default function AddGroups() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const prevPath = location.state?.prevPath;
  const { id } = useParams();
  const layout = useLayoutContext();

  const message = useAppSelector(selectCurrentChat);
  const selectedGroups = useAppSelector(getSelectedGroups);
  const selectedUsers = useAppSelector(getSelectedUsers);
  const isLoading = useAppSelector(messageDetailsIsLoading);
  const recipients = useAppSelector(selectMessagesRecipients);
  const authUser = useAppSelector(selectUser);
  const roles = useAppSelector(selectUserRoles);

  const [types, setTypes] = useState<string[]>([GROUP_FILTER_SECTIONS.content[0].name || '']);
  const [searchText, setSearchText] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [groupFilters, setGroupFilters] = useState<FilterSection[]>([GROUP_FILTER_SECTIONS]);
  const [activeTab, setActiveTab] = useState(0);
  const showProceed = !!selectedGroups?.length || !!selectedUsers?.length;
  const showFilter = isEqual(activeTab, ActiveTab.Groups) && roles?.includes('SeeOrgGroups');
  const selectedUsersCount =
    (selectedUsers?.length || selectedGroups?.length) &&
    !selectedUsers.find(user => user.id === authUser?.id)
      ? selectedUsers?.length + 1
      : selectedUsers?.length;
  const isFilterApplied = types.length !== 1 || types[0] !== GroupsToShow.MemberOfGroup;

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(setSelectedGroupType([]));
    dispatch(setUsers([]));
    if (!message) dispatch(fetchCurrentChat(id || ''));
    if (!recipients) dispatch(getMessagesRecipientsAction(Number(id)));
  }, [dispatch]);

  useEffect(() => {
    if (activeTab === ActiveTab.Groups) {
      dispatch(fetchGroups({ search: searchText }));
    }
  }, [searchText]);

  const handleOpenFilter = () => {
    layout.setTabsState(isFilterOpen);
    setIsFilterOpen(!isFilterOpen);
  };

  const handleFilter = (filters: FilterSection[]) => {
    const newTypes = filters[0]?.content.reduce((acc: string[], item) => {
      if (item.checked) {
        acc.push(item.name || '');
      }
      return acc;
    }, []);
    setGroupFilters(filters);
    setTypes(newTypes);
    layout.setTabsState(isFilterOpen);
    setIsFilterOpen(false);
  };

  const handleAddRecipients = () => {
    const selectedUsersIds = selectedUsers.map(usr => usr.id);
    dispatch(
      addRecipients(Number(id), selectedGroups, selectedUsersIds, () => {
        navigate(prevPath ?? `/message-details/${id}`);
      })
    );
  };

  const onTabClick = (tab: ActiveTab) => {
    if (tab === ActiveTab.Users && searchText?.length) {
      dispatch(fetchGroups());
    }
    if (searchText.length) {
      setSearchText('');
    }
  };

  if (isLoading) {
    return (
      <Page>
        <Loader />
      </Page>
    );
  }

  return (
    <SPage>
      {showFilter ? (
        <MessageFilterFilterBar
          onSearch={setSearchText}
          handleOpenFilter={handleOpenFilter}
          isFilterApplied={isFilterApplied}
          value={searchText}
        />
      ) : (
        <SSearchBarBase
          fallback={setSearchText}
          placeholderTx="documents_search"
          placeholder="Search..."
          value={searchText}
        />
      )}

      <CreateMessageListWrapper role="addRecipientsContainer">
        <STabs
          selectedTabClassName="is-selected"
          selectedTabPanelClassName="is-selected"
          forceRenderTabPanel
          selectedIndex={activeTab}
          onSelect={index => setActiveTab(index)}
        >
          <STabList>
            <STab className="groupTab" onClick={() => onTabClick(ActiveTab.Groups)}>
              {t('home_groups')} ({selectedGroups.length})
            </STab>
            <STab className="userTab" onClick={() => onTabClick(ActiveTab.Users)}>
              {t('messages_users')} ({selectedUsersCount})
            </STab>
          </STabList>
          <STabPanel>
            <SelectGroupsList
              searchText={searchText}
              types={types}
              onlyNormal
              groupsToHide={message?.groupIDs}
              isActive={activeTab == ActiveTab.Groups}
            />
          </STabPanel>
          <STabPanel>
            <AddRecipientsUsersList
              showActionButton={showProceed}
              searchText={activeTab == ActiveTab.Users ? searchText : ''}
              usersToHide={recipients.map(usr => usr.userID)}
              isActive={activeTab == ActiveTab.Users}
            />
          </STabPanel>
        </STabs>
      </CreateMessageListWrapper>

      {showProceed && (
        <ButtonWrapper role="proceedButtonContainer">
          <BigFloatButton tx={'messages_proceed'} onClick={handleAddRecipients} />
        </ButtonWrapper>
      )}

      <MessageFilter
        isOpen={isFilterOpen}
        label={'messages_proceed'}
        filters={groupFilters}
        setIsOpen={handleOpenFilter}
        setCheckedState={setGroupFilters}
        onFilter={handleFilter}
      />
    </SPage>
  );
}
