import {
  SActiveAlarmsModalItem,
  SAlarmIconContainer,
  SAlarmInfoContainer,
  SRowCenterAligned,
  SText,
} from './ActiveAlarmsModal.style';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';
import { getImageLink } from '../../utils/formatImageLink';
import { ActiveAlarm } from '../../containers/Alarm/AlarmSlice/types';
import { adjustToTimezoneOffset, getHumanReadableTimeDifference } from '../../utils/date';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
interface ActiveAlarmsModalItemProps {
  alarm: ActiveAlarm;
}

function ActiveAlarmsModalItem(props: ActiveAlarmsModalItemProps) {
  const { alarm } = props;
  const navigate = useNavigate();
  const getSendTimeAgo = () => {
    const newDateObj = adjustToTimezoneOffset(new Date(alarm.sent));
    return getHumanReadableTimeDifference(newDateObj);
  };

  const handleNavigate = () => {
    navigate(`/message/${alarm.id}`);
  };
  return (
    <SActiveAlarmsModalItem role={`activeAlarmsModalItem-${alarm.id}`} onClick={handleNavigate}>
      <SRowCenterAligned>
        <SAlarmIconContainer>
          <img
            role="alarmIcon"
            src={getImageLink({ imageName: alarm.emergencyTypeIconFileName })}
          />
        </SAlarmIconContainer>

        <SAlarmInfoContainer>
          <SText className="alarmTitle">{alarm.emergencyTypeName}</SText>
          <SText className="sendAt">{getSendTimeAgo()}</SText>
        </SAlarmInfoContainer>
      </SRowCenterAligned>
      <SRowCenterAligned className="marginTop">
        <ProfilePicture
          profilePictureFileName={null}
          readyPhotoSource={getImageLink({
            imageName: alarm.senderPhotoFileName,
            size: 'small',
          })}
          diameter={24}
        />
        <SAlarmInfoContainer>
          <SText className="sendBy">{alarm.senderName}</SText>
          <SText className="sendTo">
            {t<string>('imOk_to')}: {alarm.groups.map(g => g.name).join(', ')}
          </SText>
        </SAlarmInfoContainer>
      </SRowCenterAligned>
    </SActiveAlarmsModalItem>
  );
}

export default ActiveAlarmsModalItem;
