import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { CreateFolderState } from './types';

const initialState: CreateFolderState = {
  isLoading: false,
  isListLodaing: false,
  error: null,
};

const createFoldersSlice = createSlice({
  name: 'editDocuments',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { setIsLoading, setIsError } = createFoldersSlice.actions;

export default createFoldersSlice.reducer;

export const selectCreateFolderIsLoading = (state: RootState) => state.createFolder.isLoading;
