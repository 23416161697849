import React from 'react';
import { palette } from '../../theme/colors';
import { ActionButton } from '../ActionButtons/ActionButtons.style';
import { Backdrop } from '../Backdrop/Backdrop';
import DescriptionIcon from '../../assets/images/person-with-alarm.svg';
import {
  SContainer,
  SBottomSheet,
  SCurtainHandle,
  STitleText,
  SIntroIcon,
  SDescriptionTextContainer,
} from './LoneWorkerTimerDescriptionBottomSheet.styles';
import { t } from 'i18next';

interface LoneWorkerTimerDescriptionBottomSheetProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoneWorkerTimerDescriptionBottomSheet = (
  props: LoneWorkerTimerDescriptionBottomSheetProps
) => {
  const { isOpen, setIsOpen } = props;

  if (!isOpen) return <></>;

  return (
    <>
      <SBottomSheet>
        <SCurtainHandle>
          <rect width="400" height="100" fill={palette.silver} />
        </SCurtainHandle>
        <SContainer>
          <SIntroIcon src={DescriptionIcon} />
          <STitleText>{t<string>('work_alone_alarm__tutorial__header')}</STitleText>
          <SDescriptionTextContainer>
            <p className="number">1</p>
            <p>{t('work_alone_alarm__tutorial__desc1')}</p>
            <p className="number">2</p>
            <p>{t('work_alone_alarm__tutorial__desc2')}</p>
            <p className="number">3</p>
            <p>{t('work_alone_alarm__tutorial__desc3')}</p>
          </SDescriptionTextContainer>
          <ActionButton type="button" tx={'okay'} onClick={() => setIsOpen(false)} />
        </SContainer>
      </SBottomSheet>
      <Backdrop setModal={() => setIsOpen(false)} />
    </>
  );
};
