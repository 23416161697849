import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../../store';
import { BroadcastState } from './types';
import { FileOrganization } from '../../Documents/documentsSlice/types';
import { Chat, Org } from '../../ChatsList/chatListSlice/types';

const initialState: BroadcastState = {
  orgs: [],
  selectedOrgs: [],
  broadcastMsg: null,
  isLoading: false,
  error: null,
};

const broadcastSlice = createSlice({
  name: 'broadcast',
  initialState,
  reducers: {
    setOrgs: (state, action: PayloadAction<FileOrganization[]>) => {
      state.orgs = action.payload;
    },
    setSelectedOrgs: (state, action: PayloadAction<Org[]>) => {
      state.selectedOrgs = action.payload;
    },
    setBroadcastMsg: (state, action: PayloadAction<Chat>) => {
      state.broadcastMsg = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    removeOrg: (state, action: PayloadAction<number>) => {
      const selectedOrgs = state.selectedOrgs;
      state.selectedOrgs = _.filter(selectedOrgs, x => x.id !== action.payload);
    },
  },
});

export const { setIsLoading, setOrgs, setSelectedOrgs, setBroadcastMsg, removeOrg, setError } =
  broadcastSlice.actions;

export const getOrgs = (state: RootState) => state.broadcast.orgs;
export const getSelectedOrgs = (state: RootState) => state.broadcast.selectedOrgs;
export const getIsLoading = (state: RootState) => state.broadcast.isLoading;

export default broadcastSlice.reducer;
