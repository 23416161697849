import { ImOkDocumentSimple } from '../ImOkList/imOkSlice/types';
import { MemberSettingsOptionsStateType } from './MemberSettings';
import Copy from '../../assets/imgs/chats/copy.svg';
import Call from '../../assets/imgs/chats/call.svg';
import Email from '../../assets/icons/envelope--yellow.svg';
import { translate } from '../../utils/translate';
import { SelectListUser } from '../CreateMessage/createMessageSlice/types';
import { GroupMember } from '../GroupDetail/groupDetailSlice/types';

export const getIamOkMemberLocationName = (
  iamOk?: ImOkDocumentSimple | null,
  userId?: number | null
): string | 'NO_LOCATION' => {
  return iamOk?.users?.find(i => i.userid === userId)?.locationName || 'NO_LOCATION';
};
export const getIamOkMemberLocation = (iamOk: ImOkDocumentSimple, userId: number) => {
  const user = iamOk?.users?.find(i => i.userid === userId);
  if (user) {
    return {
      latitude: user.locationLatitude,
      longitude: user.locationLongitude,
      name: user.locationName,
      lastupdated: user.lastupdated,
    };
  }
};

export const copyText = (text: string, setOpen: (isOpen: boolean) => void) => () => {
  if (text?.length) {
    navigator.clipboard.writeText(text);
  }
  setOpen(false);
};

export const sendEmail = (mail: string, setOpen: (isOpen: boolean) => void) => () => {
  if (mail?.length) {
    window.open(`mailto:${mail}`, '_blank');
  }
  setOpen(false);
};

export const handleMemberSettingsPhoneClick =
  (
    phone: string,
    setOptionsState: (value: React.SetStateAction<MemberSettingsOptionsStateType>) => void,
    setOpen: (isOpen: boolean) => void
  ) =>
  () => {
    setOptionsState(prev => ({
      ...prev,
      options: [
        {
          name: `${translate('call')} ${phone}`,
          icon: Call,
          callback: () => {
            window.location.href = `tel:${phone}`;
          },
        },
        {
          name: 'copy_number',
          icon: Copy,
          callback: copyText(phone, setOpen),
        },
      ],
    }));

    setOpen(true);
  };

export const handleMemberSettingsEmailClick =
  (
    email: string,
    setOptionsState: (value: React.SetStateAction<MemberSettingsOptionsStateType>) => void,
    setOpen: (isOpen: boolean) => void
  ) =>
  () => {
    setOptionsState(prev => ({
      ...prev,
      options: [
        {
          name: 'copy_email',
          icon: Copy,
          callback: copyText(email, setOpen),
        },
        {
          name: 'send_email',
          icon: Email,
          callback: sendEmail(email, setOpen),
        },
      ],
    }));

    setOpen(true);
  };

export const mapGroupMemberToSelectUser = (
  member: GroupMember,
  partial: Partial<SelectListUser>
): SelectListUser => {
  return {
    id: member.userID || 0,
    displayName: member.userName || '',
    title: member.info || null,
    creatorId: null,
    email: member.userEmail || '',
    phoneNumber: member.phoneNumber || null,
    admin: member.admin === null ? undefined : member.admin,
    photoFileName: member.photoFileName || null,
    available: member.available,
    unavailable: member.unavailable,
    unavailableDateTo: member.unavailableDateTo || null,
    ...partial,
  };
};
