import { useCallback, useEffect, useRef, useState } from 'react';
import { ChatListItem } from '../../components/Chat/ChatListItem';
import {
  isChatsLoading,
  selectChatListFilters,
  selectChats,
  selectFilterGroups,
  selectIncomingUpdateMessageID,
  selectIsIncomingMessages,
  selectScrollTopPosition,
  selectSearchPhrase,
  setChatFilters,
  setIsChatsLoading,
  setScrollTopPosition,
  setSearchPhrase,
} from './chatListSlice';
import {
  hideAMessage,
  fetchChats,
  resetChat,
  fetchMessagesListFilterGroups,
} from './chatListSlice/actionCreators';
import { debounce } from 'lodash';
import { MessageInList } from './chatListSlice/types';
import { CreateMessageButtonWrapper } from '../CreateMessage/CreateMessageList';
import { ChatListFilter } from '../../components/Chat/ChatListFilter';
import Loader from '../../components/Loader/Loader';
import Options from '../../components/Options/Options';
import EyeSlash from '../../assets/imgs/chats/hide.svg';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectUser } from '../Login/LoginSlice';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import BigFloatButton from '../../components/BigFloatButton/BigFloatButton';
import { EmptyListFallback } from '../../components/EmptyListFallback/EmptyListFallback';
import { useNavigate } from 'react-router-dom';
import { GetChatsProps } from '../../apis/chatAPI';
import { MessageList, SPage, MessageFilterFilterBar } from './ChatsList.styles';

export const initialPagingValues: Required<Pick<GetChatsProps, 'skip' | 'take'>> = {
  take: 12,
  skip: 0,
};

export const ChatsList = () => {
  const confirm = useConfirmation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(selectUser);

  const chats: MessageInList[] = useAppSelector(selectChats);
  const activeChatGroups = useAppSelector(selectFilterGroups);

  const isLoading: boolean = useAppSelector(isChatsLoading);
  const incomingMessages = useAppSelector(selectIsIncomingMessages);
  const incomingMessageUpdateID = useAppSelector(selectIncomingUpdateMessageID);
  const scrollTopPosition = useAppSelector(selectScrollTopPosition);

  const searchPhrase = useAppSelector(selectSearchPhrase);
  const chatFilters = useAppSelector(selectChatListFilters);

  const [searchText, setSearchText] = useState('');
  const [selectedFilterGroups, setSelectedFilterGroups] = useState<number[]>([]);

  const isAllGroupsSelected =
    chatFilters.length === 0 || chatFilters.length === activeChatGroups.length;
  //UI states
  const [filterOpen, setFilterOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [selectedChatId, setSelectedChatId] = useState<number | undefined>(undefined);

  const [skip, setSkip] = useState<number>(initialPagingValues.skip);
  const resetSkip = () => setSkip(initialPagingValues.skip);

  const searchRef = useRef<HTMLInputElement | null>(null);
  const chatListContainer = useRef<HTMLDivElement>(null);

  const handleScrollToPosition = () => {
    if (scrollTopPosition) {
      chatListContainer.current?.scrollTo({
        top: scrollTopPosition,
        behavior: 'auto',
      });
      dispatch(setScrollTopPosition(null));
    }
  };

  useEffect(() => {
    dispatch(resetChat());
    if (chats.length === 0 && !incomingMessages) {
      dispatch(
        fetchChats({
          search: searchPhrase,
          groups: isAllGroupsSelected ? undefined : chatFilters,
          ...initialPagingValues,
        })
      );
      dispatch(fetchMessagesListFilterGroups());
    }

    setSearchText(searchPhrase);
    if (searchRef.current) {
      searchRef.current.value = searchPhrase;
    }

    if (chatFilters?.length) {
      setSelectedFilterGroups(chatFilters);
    }
    handleScrollToPosition();
  }, []);

  useEffect(() => {
    if (incomingMessages) {
      dispatch(
        fetchChats({
          search: searchPhrase,
          groups: isAllGroupsSelected ? undefined : chatFilters,
          ...initialPagingValues,
        })
      );
      resetSkip();
      if (chatFilters?.length) {
        setSelectedFilterGroups(chatFilters);
      }
    }
  }, [incomingMessages]);

  useEffect(() => {
    if (incomingMessageUpdateID) {
      if (!chats.length) return;

      const incomingMessageUpdateIndex = chats.findIndex(
        message => message.id === incomingMessageUpdateID
      );

      if (incomingMessageUpdateIndex < 0) return;

      dispatch(
        fetchChats(
          {
            search: searchPhrase,
            groups: isAllGroupsSelected ? undefined : chatFilters,
            skip: incomingMessageUpdateIndex,
            take: 1,
          },
          false,
          incomingMessageUpdateIndex,
          () => handleScrollToPosition()
        )
      );
      if (chatFilters?.length) {
        setSelectedFilterGroups(chatFilters);
      }
    }
  }, [incomingMessageUpdateID]);

  const handler = useCallback(
    debounce(
      () => {
        if (chatListContainer.current) {
          chatListContainer.current.scrollBy(0, -200);
        }
        setSkip(prev => {
          dispatch(
            fetchChats(
              {
                search: searchPhrase,
                groups: isAllGroupsSelected ? undefined : chatFilters,
                take: initialPagingValues.take,
                skip: prev + initialPagingValues.take,
              },
              true
            )
          );
          return prev + initialPagingValues.take;
        });
      },
      3000,
      { trailing: false, leading: true }
    ),
    [skip, searchPhrase, chatFilters]
  );

  const onFilterClick = () => {
    setFilterOpen(!filterOpen);
  };

  const submitFilteredGroups = (selectedGroupsList: number[]) => {
    setFilterOpen(false);
    dispatch(setChatFilters(selectedGroupsList));
    setSelectedFilterGroups(selectedGroupsList);
    dispatch(
      fetchChats({
        search: searchPhrase,
        groups:
          selectedGroupsList.length === activeChatGroups.length ? undefined : selectedGroupsList,
        ...initialPagingValues,
      })
    );
    resetSkip();
  };

  const handleHide = (id: number) => {
    confirm({
      title: 'confirmLeaveGroupTitle',
      description: 'deleteMessage',
      onSubmit: () => {
        dispatch(hideAMessage(id, chats));
        setOptionsOpen(false);
      },
      onCancel: () => {
        setOptionsOpen(false);
      },
      confirmText: 'messages_hide',
      cancelText: 'cancel',
    });
  };

  const onclickDots = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: number) => {
    e.stopPropagation();
    setSelectedChatId(id);
    setOptionsOpen(!optionsOpen);
  };

  const handleSearch = (_searchText: string) => {
    dispatch(setIsChatsLoading(true));
    setSearchText(_searchText);
    dispatch(setSearchPhrase(_searchText));

    dispatch(
      fetchChats({
        search: _searchText,
        groups: isAllGroupsSelected ? undefined : chatFilters,
        ...initialPagingValues,
      })
    );
    resetSkip();
  };

  const scrolled = () => {
    if (!chatListContainer.current) {
      return;
    }
    if (skip >= chats.length) {
      return;
    }
    if (
      chatListContainer.current.offsetHeight + chatListContainer.current.scrollTop + 50 >=
      chatListContainer.current.scrollHeight
    ) {
      handler();
    }
  };

  const navigateToChat = (id: number) => {
    if (chatListContainer?.current?.scrollTop)
      dispatch(setScrollTopPosition(chatListContainer.current.scrollTop));

    navigate(`/message/${id}`, {
      replace: true,
    });
  };

  // Filter out all null values
  const groupsWithPhotos = activeChatGroups.filter(grp => !!grp.imageFileName);

  return (
    <SPage>
      <MessageFilterFilterBar
        onSearch={handleSearch}
        handleOpenFilter={() => onFilterClick()}
        forwardedRef={searchRef}
        value={searchText}
        isFilterApplied={!isAllGroupsSelected}
      />
      {!!activeChatGroups?.length && (
        <ChatListFilter
          searchbarTitle="documents_filter_groups"
          title="groups_all"
          label={'messages_filter'}
          isOpen={filterOpen}
          groupsList={activeChatGroups}
          setIsOpen={onFilterClick}
          selected={selectedFilterGroups}
          setSelected={setSelectedFilterGroups}
          onFilter={submitFilteredGroups}
        />
      )}
      {chats.length === 0 && !isLoading && (
        <EmptyListFallback listLength={chats.length} isLoading={false} emptyListTx={'noMessages'} />
      )}
      {isLoading && <Loader />}
      {chats.length > 0 && !isLoading && (
        <>
          <Options
            isOpen={optionsOpen}
            setIsOpen={setOptionsOpen}
            setTabBar
            items={[
              {
                name: 'messages_hide',
                icon: EyeSlash,
                callback: () => {
                  handleHide(selectedChatId || 0);
                },
              },
            ]}
          />

          <>
            <MessageList ref={chatListContainer} onScroll={scrolled} role="messageList">
              {chats.map((chat, key) => {
                const foundGroups = groupsWithPhotos.filter(grp => chat.groupIDs?.includes(grp.id));
                return (
                  <ChatListItem
                    key={key}
                    message={chat}
                    handleDots={onclickDots}
                    onClick={navigateToChat}
                    userId={user?.id || 0}
                    searchText={searchText}
                    groupPictures={foundGroups.map(grp => grp.imageFileName || '')}
                  />
                );
              })}
            </MessageList>
          </>
        </>
      )}
      <CreateMessageButtonWrapper role="createMessageButtonWrapper">
        <BigFloatButton link={'/createMessage'} tx={'messages_createMessage'} />
      </CreateMessageButtonWrapper>
    </SPage>
  );
};
