import { find, orderBy } from 'lodash';
import { CountryCode, CountryData } from '../types';

let instance: Country | null = null;

class Country {
  private countriesData: CountryData[] | null;

  private countries: CountryData[] | null;

  static getInstance() {
    if (!instance) {
      instance = new Country();
    }
    return instance;
  }

  constructor() {
    this.countries = null;
    this.countriesData = null;
  }

  setCustomCountriesData(json: CountryData[]) {
    this.countriesData = json;
  }

  getAll() {
    if (!this.countries) {
      this.countries = orderBy(
        // eslint-disable-next-line @typescript-eslint/no-require-imports
        this.countriesData || require('./countries.json'),
        ['name'],
        ['asc']
      );
    }

    return this.countries;
  }

  getCountryDataByCode(code: CountryCode) {
    return find(this.getAll(), country => country.code === code);
  }

  getDialCode(code: CountryCode) {
    return find(this.getAll(), country => country.code === code)?.dial_code;
  }

  getCountryByNumber(phone: string) {
    const allCountries = this.getAll();
    const foundCountries = allCountries.filter(country => phone.startsWith(country.dial_code));
    foundCountries.sort((a, b) => b.dial_code.length - a.dial_code.length);
    return foundCountries?.length ? foundCountries[0] : undefined;
  }

  countryCodeToDialCode(countryCode: CountryCode) {
    const allCountries = this.getAll();
    return allCountries.find(country => country.code === countryCode)?.dial_code || '';
  }
}
// these countries do Not have't have a flag png
// { "name": "Pitcairn", "dial_code": "+872", "code": "PN" },/
// { "name": "Greenland", "dial_code": "+299", "code": "GL" },
// { "name": "Netherlands Antilles", "dial_code": "+599", "code": "AN" },
const defaultInstance = Country.getInstance();
export { Country as CountryClass, defaultInstance as default };
