import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { MemberFilterTypes } from '..';
import { RootState } from '../../../store';
import { sortGroupMembers } from '../helpers';
import { GMLoadingState, GroupDetail, GroupDetailState, GroupMember } from './types';

const initialState: GroupDetailState = {
  groupDetail: null,
  members: null,
  isLoading: {
    all: false,
    member: false,
    groupCard: false,
    groupSettings: false,
    memberSettings: false,
  },
  edit: { location: null },
  error: null,
  groupSettingsError: null,
};

const groupDetailSlice = createSlice({
  name: 'groupDetail',
  initialState,
  reducers: {
    setGroupDetail: (state, action: PayloadAction<GroupDetail>) => {
      state.groupDetail = action.payload;
    },
    setGroupMembers: (state, action: PayloadAction<GroupMember[]>) => {
      state.members = action.payload;
    },

    setGDIsLoading: (state, action: PayloadAction<GMLoadingState>) => {
      state.isLoading = action.payload;
    },
    setIsError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    reset: state => {
      state.error = null;
      state.groupDetail = null;
    },
  },
});

export const { setGroupDetail, setGDIsLoading, setIsError, setGroupMembers, reset } =
  groupDetailSlice.actions;

// data selecting could be separated also
export const selectGroupDetail = (state: RootState) => state.groupDetail.groupDetail;
export const selectMemberById = (state: RootState, memberID: number) => {
  return state.groupDetail.members?.find(member => member.userID === memberID);
};
export const selectGroupDetailIsLoading = (state: RootState) => state.groupDetail.isLoading;

export const selectGroupMembersSortedSearched =
  (sort: MemberFilterTypes, searchTerm?: string) => (state: RootState) =>
    sortGroupMembers(state.groupDetail.members, sort, searchTerm);

export const selectGroupDetailError = (state: RootState) => state.groupDetail.error;

export default groupDetailSlice.reducer;
