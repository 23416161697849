import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const AlarmContainer = styled.div<{ color?: string }>`
  color: ${palette.white};
  text-decoration: none;
  background: ${props => props.color || palette.danger};
  border-radius: 10px;
  min-height: 5.25rem;
  padding: 0.6rem 1.4rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
  }
`;

export const SNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  padding-right: 1rem;
`;

export const SName = styled.p`
  font-family: 'Roboto-Regular';
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size:18px;
  min-width: 250px;
}
`;
