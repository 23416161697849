import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import VC from 'vanilla-calendar-pro';
import { IOptions } from 'vanilla-calendar-pro/types';
import 'vanilla-calendar-pro/build/vanilla-calendar.min.css';
import { SCalendar } from './VanillaCalendar.styles';

interface VanillaCalendarProps extends HTMLAttributes<HTMLDivElement> {
  config?: IOptions;
  initialDate?: string | null;
  handleSelected: (isoDate: string) => void;
}

const calendarSettings: IOptions = {
  type: 'default',
  date: {
    min: 'today',
  },
  settings: {
    visibility: {
      theme: 'dark',
      weekend: false,
      daysOutside: false,
    },
    range: {
      min: 'today',
    },
  },
  CSSClasses: {
    calendar: 'calendar',
    weekDay: 'weekDay',
    dayBtn: 'dayBtn',
    dayBtnDisabled: 'dayBtnDisabled',
    dayBtnSelected: 'dayBtnSelected',
    dayBtnToday: 'dayBtnToday',
  },
};

function VanillaCalendar({
  config,
  handleSelected,
  initialDate,
  children,
  ...attributes
}: VanillaCalendarProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [calendar, setCalendar] = useState<VC | null>(null);
  const initiallySelected = initialDate
    ? {
        dates: [initialDate],
        month: new Date(initialDate).getMonth(),
        year: new Date(initialDate).getFullYear(),
      }
    : {};

  useEffect(() => {
    if (!ref.current) return;
    setCalendar(
      new VC(ref.current, {
        ...calendarSettings,
        actions: {
          clickDay(event, self) {
            if (!self.selectedDates?.length) return;
            handleSelected(self.selectedDates[0]);
          },
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        settings: { ...calendarSettings.settings, ...{ selected: initiallySelected } },
        ...config,
      })
    );
  }, [ref, config]);

  useEffect(() => {
    if (!calendar) return;
    calendar.init();
  }, [calendar]);

  return <SCalendar {...attributes} ref={ref} />;
}

export default VanillaCalendar;
