import { Wrapper } from '@googlemaps/react-wrapper';
import isElectron from 'is-electron';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Map } from '../Map/Map';
import {
  SSearchWithButton,
  SCenterMarkerWrapper,
  SLocationButton,
  SMapView,
  SSearchBarBase,
} from './SearchableMap.styles';
import { ReactComponent as Geo } from '../../assets/icons/crosshair--yellow.svg';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import Geocoder, { defaultLng, defaultLat, apiKey } from '../../utils/geocoder';
import { ReactComponent as CustomMarker } from '../../assets/icons/location-marker--red.svg';
import { debounce } from 'lodash';
import { ipcRendererSend } from '../../utils/electronHooks';

interface SearchableMapProps {
  style?: React.CSSProperties;
  buttonText?: string;
  nextButtonCallback?: (location: Location) => void;
  forceShowLocation?: Location;
  isLoading?: boolean;
  buttonStyle?: React.CSSProperties;
  buttonTextStyle?: React.CSSProperties;
  lng?: number;
  lat?: number;
  location?: google.maps.LatLngLiteral;
  setLocation: React.Dispatch<React.SetStateAction<google.maps.LatLngLiteral | undefined>>;
  setLocName?: React.Dispatch<React.SetStateAction<string>>;
}

function SearchableMap(props: SearchableMapProps) {
  const { location, setLocation, setLocName } = props;

  const confirm = useConfirmation();

  const StandardZoom = 18; // initial zoom: ;
  const [zoom, setZoom] = useState(StandardZoom);
  const [mounted, setMounted] = useState(false);

  const [center, setCenter] = useState<google.maps.LatLngLiteral>();

  const [locationName, setLocationName] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  async function getLocationName(latLngLiteral: google.maps.LatLngLiteral) {
    if (!latLngLiteral?.lat) {
      return;
    }

    try {
      const res = await Geocoder({
        latitude: latLngLiteral.lat,
        longitude: latLngLiteral.lng,
      });
      if (!res) {
        return;
      }

      const locName = res.addressName ? res.addressName : '';
      setLocationName(locName);
      setLocName?.(locName);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (location) {
      getLocationName(location);
    }
  }, [location]);

  useEffect(() => {
    if (inputRef.current) inputRef.current.value = locationName;
  }, [locationName]);

  const showTurnOnLocationDialog = () => {
    if (isElectron()) {
      confirm({
        description: 'turn_on_location_intro',
        onSubmit: () => ipcRendererSend('location-services-enable'),
        onCancel: () => {},
        confirmText: 'ok',
      });
    }
  };

  const onCreated = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setLocation(pos);
          setCenter(pos);
        },
        e => {
          setLocation({ lat: defaultLat, lng: defaultLng });
          setCenter({ lat: defaultLat, lng: defaultLng });
          showTurnOnLocationDialog();
          console.error("Can't get location", e);
        },
        { maximumAge: 5000, enableHighAccuracy: true, timeout: 15000 }
      );
    } else {
      // Browser doesn't support Geolocation
      setLocation({ lat: defaultLat, lng: defaultLng });
      setCenter({ lat: defaultLat, lng: defaultLng });
      console.warn("Browser doesn't support Geolocation");
    }
    setMounted(true);
  };

  useEffect(() => {
    onCreated();
    return () => {
      setMounted(false);
    };
  }, []);

  // when user is not making any action in the map this is the function that will run
  const onIdle = (m: google.maps.Map) => {
    if (m) {
      const mapZoom = m.getZoom();
      if (mapZoom) {
        setZoom(mapZoom);
      }
      const mapValue = m.getCenter();
      if (mapValue) {
        setCenter(mapValue.toJSON());
      }
    }
  };

  const setUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async position => {
          if (mounted) {
            setLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            setZoom(StandardZoom);
          }
        },
        async error => {
          console.error('Geolocation Error' + error.code, error.message);
          showTurnOnLocationDialog();
        },
        { maximumAge: 5000, enableHighAccuracy: true, timeout: 15000 }
      );
    }
  };

  const onBoundsChange = (map: google.maps.Map) => {
    const latLng = map.getCenter()?.toJSON();
    if (mounted) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      setLocation({ lat: latLng?.lat!, lng: latLng?.lng! });
    }
  };

  const debounceChange = debounce(onBoundsChange, 500, {
    leading: false,
    trailing: true,
  });
  const debounceSetLocation = useCallback(
    debounce(setLocation, 500, {
      leading: false,
      trailing: true,
    }),
    []
  );

  return (
    <SMapView role="mapView">
      <SSearchWithButton className="searchWithButton" role="searchWithButton">
        <SSearchBarBase
          fallback={() => {}}
          placeholderTx="documents_search"
          forwardedRef={inputRef}
        />
        <SLocationButton onClick={setUserLocation} role="locationButton">
          <Geo role="locationIcon" />
        </SLocationButton>
      </SSearchWithButton>
      {/* The marker was eyeballed into center, when making changes compare to original marker */}
      <SCenterMarkerWrapper role="markerContainer">
        <CustomMarker role="marker" />
      </SCenterMarkerWrapper>
      <Wrapper apiKey={apiKey!} libraries={['places']}>
        <Map
          inputRef={inputRef}
          center={center}
          onIdle={onIdle}
          zoom={zoom}
          className="fullHeightMap"
          disableDefaultUI
          onSearch={(lat, lng) => debounceSetLocation({ lat: lat, lng: lng })}
          clickableIcons={false}
          onBoundsChange={debounceChange}
        >
          {/* <Marker position={{ lat: location.lat, lng: location.lng }} /> */}
        </Map>
      </Wrapper>
    </SMapView>
  );
}

export default SearchableMap;
