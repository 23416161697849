/* eslint-disable @typescript-eslint/no-use-before-define */
type Primitive = string | number | boolean | null | undefined;
type Comparable = Primitive | Primitive[] | Set<Primitive> | { [key: string]: Comparable };

export const deepEqualUnordered = (a: Comparable, b: Comparable): boolean => {
  if (a === b) {
    return true;
  }

  if (typeof a !== typeof b) {
    return false;
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    return arraysEqualUnordered(a, b);
  }

  if (a instanceof Set && b instanceof Set) {
    return setsEqualUnordered(a, b);
  }

  if (isObject(a) && isObject(b)) {
    return objectsEqualUnordered(a, b);
  }

  return false;
};

export const arraysEqualUnordered = (a: Comparable[], b: Comparable[]): boolean => {
  if (a.length !== b.length) {
    return false;
  }

  a = a.slice().sort();
  b = b.slice().sort();

  return a.every((value, index) => deepEqualUnordered(value, b[index]));
};

export const setsEqualUnordered = (a: Set<Comparable>, b: Set<Comparable>): boolean => {
  if (a.size !== b.size) {
    return false;
  }

  const arrayA = Array.from(a).sort();
  const arrayB = Array.from(b).sort();

  return arraysEqualUnordered(arrayA, arrayB);
};

export const objectsEqualUnordered = (
  a: Record<string, Comparable>,
  b: Record<string, Comparable>
): boolean => {
  const aKeys = Object.keys(a)
    .filter(key => a[key] !== undefined)
    .sort();
  const bKeys = Object.keys(b)
    .filter(key => a[key] !== undefined)
    .sort();

  if (!arraysEqualUnordered(aKeys, bKeys)) {
    return false;
  }

  return aKeys.every(key => deepEqualUnordered(a[key], b[key]));
};

export const isObject = (value: unknown): value is Record<string, Comparable> => {
  return (
    value !== null && typeof value === 'object' && !Array.isArray(value) && !(value instanceof Set)
  );
};
