import { UsersSelectionList } from '../../components/RecipientsSelection/UsersSelectionList';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  createMessageGetUsers,
  createMessageUsersLoading,
  getSelectedUsers,
  setUsers,
} from '../CreateMessage/createMessageSlice';
import { SelectListUser } from '../CreateMessage/createMessageSlice/types';
import {
  fetchUsers,
  setSelectedUsersAction,
} from '../CreateMessage/createMessageSlice/actionCreators';
import { EmptyListFallback } from '../../components/EmptyListFallback/EmptyListFallback';

interface AddRecipientsUsersListProps {
  isActive: boolean;
  showActionButton: boolean;
  searchText?: string;
  usersToHide?: number[];
}

const USERS_PER_PAGE = 20;
export const AddRecipientsUsersList = (props: AddRecipientsUsersListProps) => {
  const { isActive, searchText, usersToHide, showActionButton } = props;
  const dispatch = useAppDispatch();
  const selectedUsers = useAppSelector(getSelectedUsers);
  const users = useAppSelector(createMessageGetUsers);
  const usersLoading = useAppSelector(createMessageUsersLoading);
  const [page, setPage] = useState(0);
  const haveBottomMargin = showActionButton && (users?.length ?? 0) > 5;

  const selectedUsersIdsList = useMemo(() => {
    return [...selectedUsers].map(user => user.id);
  }, [selectedUsers]);

  useEffect(() => {
    if (isActive) {
      setPage(0);
      dispatch(setUsers([]));
      dispatch(
        fetchUsers({ limit: USERS_PER_PAGE, skip: 0, search: searchText }, [], () => {
          setPage(page + 1);
        })
      );
    }
  }, [searchText]);

  const loadUsersList = () => {
    dispatch(
      fetchUsers(
        { limit: USERS_PER_PAGE, skip: page * USERS_PER_PAGE, search: searchText },
        [],
        () => {
          setPage(page + 1);
        }
      )
    );
  };

  const onUserClick = (selectedUser: SelectListUser) => {
    const selectedUsersList = [...selectedUsers];
    const foundUserIndex = selectedUsers.findIndex(user => user.id === selectedUser.id);
    if (foundUserIndex > -1) {
      selectedUsersList.splice(foundUserIndex, 1);
    } else {
      selectedUsersList.push(selectedUser);
    }
    dispatch(setSelectedUsersAction(selectedUsersList));
  };

  return (
    <>
      {isActive && (
        <UsersSelectionList
          selectedUsersIdsList={selectedUsersIdsList}
          className={haveBottomMargin ? 'lastItemBottomPadding' : ''}
          users={users ?? []}
          isLoading={usersLoading}
          page={page}
          setPage={setPage}
          onUserClick={onUserClick}
          loadUsers={loadUsersList}
          emptyListFallbackComponent={
            <EmptyListFallback
              listLength={users?.length ?? 0}
              isLoading={usersLoading}
              searchTerm={searchText}
              emptyListTx={'all_users_added_as_recipients'}
              emptySearchTx={'users_not_found_by_search'}
            />
          }
          lockedUsersIds={usersToHide}
        />
      )}
    </>
  );
};
