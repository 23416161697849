import styled from 'styled-components';
import { palette } from '../../../theme/colors';
import ArrowRightImg from '../../../assets/icons/chevron-right-circle.svg';
import InfoCircleDark from '../../../assets/icons/exclamation-mark-circle--dark.svg';
import InfoCircleWhite from '../../../assets/icons/exclamation-mark-circle--white.svg';
import CloseWhite from '../../../assets/icons/cross--white.svg';
import { useAppDispatch } from '../../../hooks';
import { translate } from '../../../utils/translate';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertSecondsToTime } from '../../../utils/date';
import isElectron from 'is-electron';
import { LoneWorkerPersonalAlarmResponse } from '../../../containers/LoneWorkerTimer/loneWorkerTimerSlice/types';
import { calculateSecondsBetweenTwoDateTimes } from '../../../containers/LoneWorkerTimer/helpers';
import { setIsDeactivated } from '../../../containers/LoneWorkerTimer/loneWorkerTimerSlice';

const SContainer = styled.div<{ isDeactivated?: boolean }>`
  background-color: ${props => (props.isDeactivated ? palette.prussianBlue2 : palette.honeyYellow)};
  margin: 1rem 7% 0 7%;
  border-radius: 0.75rem;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  p {
    font-family: 'Roboto-Regular';
    font-weight: 400;
    font-size: 12px;
    line-height: 1rem;
    color: ${props => (props.isDeactivated ? palette.white : palette.raisinBlack3)};
  }
  .row {
    display: flex;
    align-items: center;
    p {
      padding-right: 10px;
      font-weight: 500;
    }
  }
  .alarmIcon {
    padding-right: 10px;
  }
  .learnMore {
    font-weight: 700;
    font-family: 'Roboto-Medium';
    cursor: pointer;
    text-decoration: none;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    display: inline-block;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
    }
    &:active {
      opacity: 0.8;
    }
  }
`;

interface IPersonalTimerBannerProps {
  className?: string | undefined;
  alarmData?: LoneWorkerPersonalAlarmResponse | null;
  isDeactivated?: boolean;
}

export const LoneWorkerTimerBanner = (props: IPersonalTimerBannerProps) => {
  const { className, alarmData, isDeactivated } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [seconds, setSeconds] = useState(0);

  function setTimer() {
    if (alarmData) {
      const secondsLeft = calculateSecondsBetweenTwoDateTimes(
        new Date(),
        new Date(alarmData.DispatchTime)
      );
      setSeconds(secondsLeft > 0 ? secondsLeft : 0);
    }
  }
  useEffect(() => {
    if (+seconds.toFixed(0) > 0) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(+seconds.toFixed(0) - 1);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [seconds]);

  useEffect(() => {
    if (alarmData && isElectron()) {
      localStorage.setItem('isLoneWorkTimerActive', JSON.stringify(true));
      setTimer();
    }
  }, [alarmData]);

  const onBannerClick = () => {
    if (!isDeactivated) {
      navigate('/aloneWorker/timer');
    }
  };

  const onCloseClick = () => {
    if (isDeactivated) {
      dispatch(setIsDeactivated(false));
    }
  };

  return (
    <SContainer
      onClick={onBannerClick}
      className={className}
      isDeactivated={isDeactivated}
      role="lwtBanner"
    >
      <div className="row" role="bannerInfoContainer">
        <img
          role="icon"
          src={isDeactivated ? InfoCircleWhite : InfoCircleDark}
          className="alarmIcon"
        />
        <p>{translate(isDeactivated ? 'alarm_deactivated_success' : 'alarm_banner_active_text')}</p>
      </div>
      <div className="row" role="bannerActionContainer">
        {!isDeactivated && <p role="timeText">{convertSecondsToTime(seconds)}</p>}
        <img role="icon" onClick={onCloseClick} src={isDeactivated ? CloseWhite : ArrowRightImg} />
      </div>
    </SContainer>
  );
};
