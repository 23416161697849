import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoaderContainer = styled.div<{ $size: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${props => `${props.$size ? props.$size + 20 : 80}`}px;
  height: ${props => `${props.$size ? props.$size + 20 : 80}`}px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${props => `${props.$size || 60}`}px;
    height: ${props => `${props.$size || 60}`}px;
    margin: 8px;
    border: 3px solid ${palette.honeyYellow};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${palette.honeyYellow} ${palette.loaderDark} ${palette.loaderDark}
      ${palette.loaderDark};
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
