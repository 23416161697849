import { batch } from 'react-redux';
import { AppThunk } from '../../../store';

import type { NavigateFunction } from 'react-router-dom';
import {
  deleteUser,
  resetAll,
  setAreAdditionalUsersLoading,
  setGroupMembers,
  setIsError,
  setIsLoading,
  setSelectedGroups,
  setSelectedUsers,
  setUsers,
  setUsersLoading,
} from '.';
import { createMessage, forwardMessage } from '../../../apis/chatAPI';
import { getSingleGroupsMembers } from '../../../apis/createMessageAPI';
import { GetUserQuery, getAllUsers, getUserById } from '../../../apis/userAPI/index';
import { CreateMessageModel } from '../../Chat/models';
import { setAreFilesSending } from '../../ChatsList/chatListSlice';
import { fetchChats } from '../../ChatsList/chatListSlice/actionCreators';
import { GroupMember } from '../../GroupDetail/groupDetailSlice/types';
import { setIsInternalLoading } from '../../Support/supportSlice';
import { SelectedGroup } from '../SelectGroupsList';
import { getSelectedUsersAfterGroupUnselection } from '../helpers';
import { SelectListUser } from './types';

export const fetchUsers =
  (query?: GetUserQuery, usersIdsToHide?: number[], increasePage?: () => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setUsersLoading(true));
      const currentUsers = getState().createMessage.users || [];
      let users: SelectListUser[];
      const queriedUsers = await getAllUsers(query);
      let additionalUsers = [...queriedUsers].filter(user => !usersIdsToHide?.includes(user.id));
      if (query?.skip) {
        additionalUsers = [...additionalUsers].filter(
          user => !currentUsers.find(u => u.id === user.id)
        );
        users = [...currentUsers, ...additionalUsers];
      } else {
        users = additionalUsers;
      }
      batch(() => {
        dispatch(setUsers(users));
        dispatch(setUsersLoading(false));
      });
      if (query?.limit && queriedUsers.length && users.length < query?.limit && increasePage) {
        increasePage();
      }
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setUsersLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const preselectAuthUser = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));
    const authUserId = getState().user.user?.id;
    if (authUserId) {
      const user = (await getUserById(authUserId)) as SelectListUser;
      user.isSelected = true;
      if (user) dispatch(setSelectedUsers([user]));
    }
    dispatch(setIsLoading(false));
  } catch (error) {
    console.log('error log ', error);
    batch(() => {
      dispatch(setIsLoading(false));
      dispatch(setIsError(`${error}`));
    });
  }
};

export const loadAdditionalUsersByPage =
  (query?: GetUserQuery): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setAreAdditionalUsersLoading(true));
      const currentUsers = getState().createMessage.users || [];
      const allUsers = [...currentUsers];
      const additionalUsers = await getAllUsers(query);
      additionalUsers.forEach(user => {
        const foundUser = allUsers.find(u => u.id === user.id);
        if (!foundUser) {
          allUsers.push(user);
        }
      });
      batch(() => {
        dispatch(setUsers(allUsers));
        dispatch(setAreAdditionalUsersLoading(false));
      });
    } catch (error) {
      console.error('error log ', error);
      batch(() => {
        dispatch(setAreAdditionalUsersLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

const getUserIndex = (userId: number, list: GroupMember[]) => {
  const foundIndex = list.findIndex(user => user?.userID === userId);
  return foundIndex;
};

const isUserAdded = (userId: number, list: GroupMember[]) => {
  const foundIndex = getUserIndex(userId, list);
  return foundIndex > -1;
};

export const fetchGroupMembers =
  (selectedGroups: (SelectedGroup | number)[]): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      const usersList: GroupMember[] = [];
      for await (const group of selectedGroups) {
        const groupId = typeof group === 'number' ? group : group.id;
        const usersInGroup = await getSingleGroupsMembers(groupId);
        usersInGroup.forEach(user => {
          if (!isUserAdded(user.userID, usersList)) {
            usersList.push({
              ...user,
              isSelected: true,
            });
          } else {
            const userIndex = getUserIndex(user.userID, usersList);
            const isAdmin = usersList[userIndex].admin;
            if (!isAdmin && user.admin) {
              usersList[userIndex] = {
                ...user,
                isSelected: true,
                admin: true,
              };
            }
          }
        });
      }
      batch(() => {
        dispatch(setGroupMembers(usersList));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const setSelectedGroupsAction =
  (groups: SelectedGroup[]): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      batch(() => {
        dispatch(setSelectedGroups(groups));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const setSelectedUsersAction =
  (users: SelectListUser[]): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      batch(() => {
        dispatch(
          setSelectedUsers(
            users.map(user => {
              return {
                ...user,
                isSelected: true,
              };
            })
          )
        );
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const deleteGroupAction =
  (id: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    try {
      const filteredGroups = getState().createMessage.selectedGroups.filter(
        group => group.id !== id
      );
      const usersList = getState().createMessage.selectedUsers;
      const newSelectedUsers = getSelectedUsersAfterGroupUnselection(usersList, id);
      dispatch(setSelectedGroups(filteredGroups));
      dispatch(setSelectedUsers(newSelectedUsers));
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      dispatch(setIsError(`${error}`));
    }
  };

export const deleteUserAction =
  (id: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(deleteUser(id));
    } catch (error) {
      dispatch(setIsError(`${error}`));
    }
  };

export const runForwardMessage =
  (
    messageID: number,
    navigate: NavigateFunction,
    groupsIDs: number[],
    userIDs: number[]
  ): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      const res = await forwardMessage(messageID, groupsIDs, userIDs);
      if (res) {
        batch(() => {
          dispatch(fetchChats({ search: '' }));
          dispatch(setIsLoading(true));
        });
      }
      navigate(`/message/${res.id}`);
      dispatch(resetAll());
    } catch (error) {
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsInternalLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const sendAMessage =
  (
    messageModel: CreateMessageModel,
    navigate: NavigateFunction,
    logNotes?: boolean,
    fromHoldingStatement?: boolean
  ): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      const res = await createMessage(messageModel);
      if (res) {
        batch(() => {
          dispatch(fetchChats({ search: '' }));
          dispatch(setIsLoading(true));
        });
        if (logNotes) {
          navigate(`/log-note/${res.id}`);
        } else if (fromHoldingStatement) {
          navigate(`/message/${res.id}/fromHoldingStatement`);
        } else {
          navigate(`/message/${res.id}`);
        }
      }
      dispatch(setAreFilesSending(false));
      dispatch(resetAll());
      dispatch(setIsLoading(false));
    } catch (error) {
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsInternalLoading(false));
        dispatch(setIsError(`${error}`));
        dispatch(setAreFilesSending(false));
      });
    }
  };
