import React from 'react';
import BigFloatButton from '../BigFloatButton/BigFloatButton';
import { palette } from '../../theme/colors';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { selectUserRoles } from '../../containers/Login/LoginSlice';
import { hasChecklistRole } from './helpers';
import ChecklistItem from './ChecklistItem';
import styled from 'styled-components';
import { EmptyListFallback } from '../EmptyListFallback/EmptyListFallback';
import { TabProps } from '../../containers/Checklists/Checklists.models';

const STemplateTab = styled.div`
  padding-bottom: 5rem;
`;

const SBigFloatButton = styled(BigFloatButton)`
  background-color: ${palette.navyBlue};
  color: ${palette.white};
  border: 1px solid ${palette.honeyYellow};
`;

type TemplateProps = TabProps;

function Template(props: TemplateProps) {
  const { checklists, onDotsClick, searchText } = props;

  const navigate = useNavigate();
  const confirm = useConfirmation();
  const roles = useAppSelector(selectUserRoles);

  const canSeeCreateBtn = hasChecklistRole(roles);

  const handleName = () => {
    confirm({
      description: 'checklist_name_checklist_template',
      onSubmit: (text?: string) => {
        if (text && text.length > 0) {
          navigate(`create/${encodeURIComponent(text)}`);
        }
      },
      onCancel: () => {},
      confirmText: 'proceed',
      inputBox: true,
      placeholderTx: 'checklist_name_template',
      isConfirmDisabledWhenEmpty: true,
    });
  };

  return (
    <STemplateTab role="templateChecklistsTab">
      {checklists.map(item => (
        <ChecklistItem
          checklist={item}
          key={`Template-${item.id}`}
          onDotsClick={onDotsClick}
          searchText={searchText}
        />
      ))}
      {checklists.length === 0 && (
        <EmptyListFallback
          listLength={checklists.length}
          isLoading={false}
          searchTerm={searchText}
          emptyListTx={'empty_checklists'}
          emptySearchTx={'messages_not_found_search'}
        />
      )}
      {canSeeCreateBtn && <SBigFloatButton tx={'checklist_new_template'} onClick={handleName} />}
    </STemplateTab>
  );
}

export default Template;
