import { useTranslation } from 'react-i18next';
import { MessageType } from '../../../utils/enums';
import MessageContainer from '../../MessageContainer/MessageContainer';
import HyperLinkText from '../../HyperlinkText/HyperLinkText';
import DeletedSign from '../../../assets/imgs/chats/circle-slash.svg';
import { ReactNode } from 'react';
import { SMessageText, SSenderText } from './TextMessage.styles';

interface TextMessageProps {
  messageText: string;
  sentDateTime: string;
  isSent: boolean;
  senderImage: string | null;
  senderName: string;
  isSameSender?: boolean;
  emergencyRecall?: boolean;
  messageTo: string | null;
  toggleModal?: () => void;
  emergencyTypeName?: string;
  hideMenu: boolean;
  showOnlyAttachment?: boolean;
  forwarded?: boolean;
  edited?: boolean;
  deleted?: boolean;

  renderReplyToMessage?: () => ReactNode;
  id?: number;
}

function TextMessage(props: TextMessageProps) {
  const {
    messageText,
    sentDateTime,
    isSent,
    senderImage,
    senderName,
    emergencyRecall,
    messageTo,
    toggleModal,
    emergencyTypeName,
    showOnlyAttachment = false,
    forwarded,
    edited,
    deleted,
    renderReplyToMessage,
    id,
    isSameSender,
  } = props;

  const { t } = useTranslation();

  const messageType = isSent ? MessageType.SentTextMessage : MessageType.ReceivedTextMessage;

  return (
    <MessageContainer
      hasReply={!!renderReplyToMessage}
      messageType={messageType}
      sentDateTime={sentDateTime}
      senderImage={senderImage}
      senderName={senderName}
      emergencyRecall={emergencyRecall}
      messageTo={messageTo}
      toggleModal={toggleModal}
      showOnlyAttachment={showOnlyAttachment}
      forwarded={forwarded}
      edited={edited}
      id={id}
      isSameSender={isSameSender}
    >
      <>
        {renderReplyToMessage && renderReplyToMessage()}
        {!emergencyRecall && !deleted && (
          <SMessageText margin role="messageText">
            <HyperLinkText text={messageText} />
            {/* Necessary to get space */}
            <span className="editedReplacer">----------{edited && `${t('messages_edited')}`}</span>
          </SMessageText>
        )}
        {!emergencyRecall && deleted && (
          <SMessageText margin deleted role="deletedMessageText">
            <img className="deletedSign" src={DeletedSign} alt="deleted" role="deletedIcon" />
            <HyperLinkText text={messageText} />
          </SMessageText>
        )}
        {emergencyRecall && (
          <div role="recallTextContainer">
            <SMessageText role="recallText">
              {emergencyTypeName + t(`messages_recall`)}
            </SMessageText>
            <SSenderText role="sender">{senderName}</SSenderText>
          </div>
        )}
      </>
    </MessageContainer>
  );
}

export default TextMessage;
