import { useMemo } from 'react';
import { SReactionsRow } from './MessageOptionsReactionsSelectionRow.styles';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../hooks';
import {
  selectMessageReactionTypes,
  selectReactionsListByMessageOrReply,
} from '../../containers/ChatsList/chatListSlice';
import { MessageReactionType } from '../../containers/ChatsList/chatListSlice/types';
import MessageOptionsReactionsSelectionRowItem from './MessageOptionsReactionsSelectionRowItem';
import { toggleMessageReaction } from '../../containers/ChatsList/chatListSlice/actionCreators';

interface MessageOptionsReactionsSelectionRowProps {
  selectedMessageId?: number;
  handleClose: () => void;
}

function MessageOptionsReactionsSelectionRow(props: MessageOptionsReactionsSelectionRowProps) {
  const { selectedMessageId, handleClose } = props;
  const dispatch = useDispatch();
  const messageReactionTypes = useSelector(selectMessageReactionTypes);
  const reactions = useAppSelector(
    selectReactionsListByMessageOrReply(selectedMessageId as number)
  );
  const selectedReactionType = useMemo(() => {
    const selectedReactionListItem = reactions?.find(item => !!item.currentUserReactionId);
    if (!selectedReactionListItem) return null;

    return messageReactionTypes.find(item => item._Id === selectedReactionListItem.reactionTypeId);
  }, [reactions]);

  const onClick = async (reactionType: MessageReactionType) => {
    await dispatch(toggleMessageReaction(selectedMessageId, reactionType._Id));
  };

  return (
    <div>
      <SReactionsRow>
        {messageReactionTypes.map((reactionType, index) => (
          <MessageOptionsReactionsSelectionRowItem
            key={index}
            reactionType={reactionType}
            isSelected={reactionType._Id === selectedReactionType?._Id}
            onClick={() => onClick(reactionType)}
            onRelease={() => handleClose()}
          />
        ))}
      </SReactionsRow>
    </div>
  );
}

export default MessageOptionsReactionsSelectionRow;
