import styled, { css } from 'styled-components';
import { AlertBanner } from '../../components/AlertBanner/AlertBanner';
import { Page } from '../../components/Page/Page';

export const TOP_PADDING_UNDER_FILTER_ALERT_BANNER = 84;
export const SAlertBanner = styled(AlertBanner)`
  width: calc(100% - 2rem);
  height: 3.25rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  & + .SPlaceHolder {
    flex-shrink: 0;
  }
`;
export const SPage = styled(Page)`
  padding: 0 1.25rem;
  position: relative;
`;
export const NoAlarmWrapper = styled.div`
  padding-bottom: 5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    mix-blend-mode: normal;
    opacity: 0.84;
    font-weight: 500;
    font-size: 16px;
    margin-top: 1.25rem;
  }
`;
export const AlarmWrapper = styled.div<{
  haveAlarmTitles?: boolean;
  $isFilterApplied?: boolean;
}>`
  position: relative;
  height: ${props =>
    `calc(100% - ${props.$isFilterApplied ? TOP_PADDING_UNDER_FILTER_ALERT_BANNER : 0}px)`};

  padding: ${props => (props?.haveAlarmTitles ? '0rem' : '1.25rem')} 0 2.7rem 0;

  ${props =>
    props.$isFilterApplied &&
    css`
      /* padding-top: 5.25rem; */
    `}
  display: flex;
  flex-direction: column;

  .item + * {
    margin-top: 10px;
  }

  /* vertical scrolling + hide scroller bar  */
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
const ScrollMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 23.5rem;
  height: 2.5rem;
  cursor: pointer;

  background: linear-gradient(0deg, rgba(33, 43, 61, 0) 0%, rgba(1, 26, 50, 0.67) 100%);
  backdrop-filter: blur(2px);

  transition:
    visibility 0.15s linear,
    opacity 0.15s linear;
`;
export const ScrollMoreTop = styled(ScrollMore)`
  opacity: 0;
  top: 4.8rem;
  visibility: hidden;
  z-index: 1001;
`;
export const ScrollMoreBottom = styled(ScrollMore)`
  opacity: 1;
  transform: rotate(180deg);
  bottom: 0;
`;
