import styled from 'styled-components';
import { Page } from '../../components/Page/Page';
import { SearchFilterBar } from '../../components/SearchFilterBar/SearchFilterBar';

export const SPage = styled(Page)`
  padding: 1.25rem 1.25rem 0;
  display: flex;
  flex-direction: column;
`;

export const MessageList = styled.div`
  margin: 1.25rem 0 0;
  padding-bottom: 5rem;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const MessageFilterFilterBar = styled(SearchFilterBar)<
  React.ComponentProps<typeof SearchFilterBar> & {
    margin?: boolean;
  }
>`
  width: 100%;
  margin-bottom: ${props => props.margin && '1.25rem'};
`;
