import { compact, flatten } from 'lodash';
import { useEffect, useState } from 'react';
import { getGroupMembers } from '../../apis/groupsAPI';
import { GroupType } from '../../utils/enums';
import { translate } from '../../utils/translate';
import { GroupsArr } from '../Alarm/AlarmSlice/types';
import { isHiddenCrossOrgOrCoAlert } from '../CreateMessage/helpers';
import { Group } from '../GroupsList/groupsSlice/types';
import { SelectableGroupMember } from '../../components/FilterOrSelectBottomSheet/types';

export const useMembersWitPic = (props: {
  ids: number[];
  setUserIds?: (set: Set<number>) => void;
}) => {
  const { ids, setUserIds } = props;

  const [isGettingMembers, setIsLoading] = useState<boolean>(false);

  const [members, setMembers] = useState<SelectableGroupMember[]>([]);

  const init = async () => {
    if (!ids) {
      return;
    }
    setIsLoading(true);
    const receivedMembers = await Promise.all(
      ids.map(groupId => {
        return getGroupMembers({ id: groupId });
      })
    );

    const uniqueIds: number[] = [];

    const uniqueMembers = compact(flatten(receivedMembers)).filter(element => {
      const isDuplicate = uniqueIds.includes(element.userID);

      if (!isDuplicate) {
        if (element.userID) uniqueIds.push(element.userID);
        return true;
      }
      return false;
    });

    const convertedMember: SelectableGroupMember[] = uniqueMembers.map(member => {
      return {
        ...member,
        name: member.userName,
        id: +member.userID,
        imageName: member.photoFileName ?? undefined,
        showAvailability: !member.available,
      };
    });
    const selected = new Set(uniqueMembers.map(member => member.userID));
    if (setUserIds) setUserIds(selected);
    setMembers(convertedMember);
    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, [ids.length]);

  return {
    members,
    isGettingMembers,
  };
};

export interface MessageSubjectForm {
  subject: string;
}

export const messageSubjectSchema = (input: string): string | null => {
  return !input ? translate('login_enterEmail') : null;
};

export const getGroupsNameById = (groupIds: number[], groups: Group[]): string => {
  return groups
    .filter(group => groupIds.includes(group.id))
    .map(g => g.name)
    .join(', ');
};

export const isCurrentUserTheOnlyMemberOfGroup = async (
  groupIds: number[],
  currentUserId: number
): Promise<{ is: boolean; singleCurrentUserGroupIds?: number[] }> => {
  const members = await Promise.all(groupIds.map(groupId => getGroupMembers({ id: groupId })));

  const singleCurrentUserGroupIds: number[] = [];

  members.forEach((groupMembers, index) => {
    if (groupMembers.length === 1 && groupMembers[0].userID === currentUserId) {
      singleCurrentUserGroupIds.push(groupIds[index]);
    }
  });

  return singleCurrentUserGroupIds.length !== 0
    ? { is: true, singleCurrentUserGroupIds }
    : { is: false };
};

const groupTypeTx: Record<GroupType, string> = {
  0: '',
  2: 'messages_hidden',
  3: 'groups_cossOrg',
  4: 'CoAlert',
};

export const getGroupsTypeOrMemberCount = (group: Group): string => {
  const type = isHiddenCrossOrgOrCoAlert(group)
    ? translate(groupTypeTx[group.groupType as GroupType])
    : undefined;
  const count = group.groupMembersCount
    ? group.groupMembersCount === 1
      ? `${group.groupMembersCount} ${translate('member')}`
      : `${group.groupMembersCount} ${translate('groups_members')}`
    : translate('cec_noMembers');

  return type || count;
};

interface DecideIfGroupDisabledParams {
  group: Group | GroupsArr;
  selectedGroupType: GroupType[];
  selectedGroups: number[];
  checkPreSelectedGroups?: { groupsArrayToCheck: GroupsArr[] };
}

export const decideIfGroupDisabled = (params: DecideIfGroupDisabledParams): boolean => {
  const { group, selectedGroupType, selectedGroups, checkPreSelectedGroups } = params;
  const isNotNormal =
    group.groupType === GroupType.Hidden ||
    group.groupType === GroupType.CrossOrg ||
    group.groupType === GroupType.CoAlert;

  const isFirstTimeSelecting = selectedGroupType.length === 0;
  const isSelectingGroupForAlarmAndFirstTime = isFirstTimeSelecting && !!checkPreSelectedGroups;

  const isGroupNotSelected = !selectedGroups.includes(group.id) && selectedGroups.length !== 0;

  const disableIfNotSameType =
    selectedGroupType.length > 0 && !selectedGroupType.includes(group.groupType);

  const disableCheckForNotNormal = isSelectingGroupForAlarmAndFirstTime
    ? // if it was the first time, the preslected groups for alarm is already added to selectedGroups making selectedGroups.length not 0
      // so we go around this issue with this trick
      disableIfNotSameType && isGroupNotSelected
    : // the default case: for groups types (hidden, coalert and crossorg) since only ONE group is allowed to be selected a time,
      // we will disable this not normal type group if it wasn't already selected
      // if not we need to make sure there was already some other groups is selected
      isGroupNotSelected;

  const disable = isNotNormal ? disableCheckForNotNormal : disableIfNotSameType;

  if (checkPreSelectedGroups) {
    const disableIfPreselected = !!checkPreSelectedGroups.groupsArrayToCheck.find(
      preGroup => preGroup.id === group.id
    );
    return disableIfPreselected || disable;
  }
  return disable;
};
