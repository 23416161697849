import { AppDispatch, AppThunk } from '../../../store';
import {
  ChangeReplyTypeParams,
  GetChatsProps,
  _changeReplyType,
  archiveMessage,
  deleteChat,
  deleteReply,
  editMessage,
  getChatReplies,
  getChats,
  getGroupsOfMessageList,
  getMessageReactionTypes,
  getMessageReactions,
  getMessageReplyReactions,
  getMessagesRecipients,
  getReplyReadReceipt,
  getSingleChat,
  getTotalUnread,
  messageRead,
  recallAlert,
  replyToMessage,
} from '../../../apis/chatAPI';
import {
  Chat,
  MessageInList,
  MessageReactions,
  MessageReactionsItem,
  MessageReactionsListItem,
  Reply,
} from './types';
import { MessageReplyType, ReplyPurpose, SentMessageType } from '../../../utils/enums';
import {
  setAreAllRepliesLoaded,
  setAreFilesSending,
  setAreRepliesLoading,
  setChats,
  setCurrentChat,
  setCurrentChatReplies,
  setCurrentReplyReadReceipt,
  setFilterGroups,
  setHoldingStatements,
  setIncomingMessages,
  setIsChatsLoading,
  setIsError,
  setIsMessageReactionsLoading,
  setLogNotes,
  setMessageReactionTypes,
  setMessagesRecipients,
  setScrollToBottom,
  setScrollTopPosition,
  setSearchPhrase,
  setSelectedReactionsMessageId,
  setSelectedMessageReactions,
  setShowLoadingReplies,
  setTotalUnread,
  setLatestThresholdLoadTime,
} from '.';
import {
  setForwardedMessageID,
  setGroupMembers,
  setSelectedGroupType,
  setSelectedGroups,
  setSelectedUsers,
} from '../../CreateMessage/createMessageSlice';

import { MessageReplyModel } from '../../Chat/models';
import type { NavigateFunction } from 'react-router-dom';
import { ReplyToMessageShorted } from '../../Chat/Chat';
import { batch } from 'react-redux';
import { checkIfNeedRead, removeDuplicatesByProperty } from '../../Chat/helpers';
import { translate } from '../../../utils/translate';
import { trunctateText } from '../../../utils/truncate';
import { isTruthyIncludingZero } from '../../../utils/isTruthyIncludingZero';
import {
  dispatchUpdateMessageList,
  getReactionsAfterDeletingReaction,
  getReactionsAfterEditingReaction,
  updateOneMessageInList,
} from '../helpers';

export const fetchChats =
  (
    props: GetChatsProps,
    isPaging = false,
    updateOneChatIndex?: number,
    callBack?: () => void
  ): AppThunk =>
  async (dispatch, getState) => {
    const prevChats = getState().chatList.chats;
    try {
      if (!isPaging) {
        dispatch(setIsChatsLoading(true));
      }
      const chats = await getChats({
        ...props,
        excludeTypes: [SentMessageType.LogNotes],
      });

      await batch(() => {
        // update only one chat item in the list
        // will be uses if the user open unread message/reply and navigate back to the list
        // there should be some props passed to only fetch the required message
        if (isTruthyIncludingZero(updateOneChatIndex)) {
          // we expect zero
          updateOneMessageInList(
            prevChats,
            // we can safely force asserts that is non-nullable since we are using isTruthyIncludingZero function
            updateOneChatIndex!,
            chats,
            dispatch as AppDispatch,
            props,
            fetchChats
          );
        } else {
          dispatch(setChats(isPaging ? [...prevChats, ...chats] : chats));
        }
        dispatch(setIsChatsLoading(false));
        dispatch(setIncomingMessages(false));
      });
      if (callBack) {
        callBack();
      }
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsChatsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const fetchMessagesListFilterGroups = (): AppThunk => async dispatch => {
  try {
    const filterGroups = await getGroupsOfMessageList();
    batch(() => {
      dispatch(setFilterGroups(filterGroups));
    });
  } catch (error) {
    console.log('error log ', error);
    batch(() => {
      dispatch(setIsError(`${error}`));
    });
  }
};

export const fetchLogNotes =
  (props: GetChatsProps): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsChatsLoading(true));
      const chats = await getChats({
        ...props,
        types: [SentMessageType.LogNotes],
      });

      batch(() => {
        dispatch(setLogNotes(chats));
        dispatch(setIsChatsLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsChatsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const fetchHoldingStatement =
  (props: GetChatsProps): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsChatsLoading(true));
      const chats = await getChats({
        ...props,
        types: [SentMessageType.HoldingStatement],
      });

      batch(() => {
        dispatch(setHoldingStatements(chats));
        dispatch(setIsChatsLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsChatsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const reset = (): AppThunk => (dispatch, state) => {
  const searchTerm = state().chatList.searchPhrase;

  if (searchTerm) {
    dispatch(setSearchPhrase(''));
    dispatch(fetchChats({ search: '' }));
  }
};

export const resetChat = (): AppThunk => dispatch => {
  batch(() => {
    dispatch(setForwardedMessageID(null));
    dispatch(setCurrentChat(undefined));
    dispatch(setSelectedGroups([]));
    dispatch(setSelectedUsers([]));
    dispatch(setGroupMembers([]));
    dispatch(setSelectedGroupType([]));
    // for reseting "Message is deleted" error popup
    dispatch(setIsError(''));
  });
};

const getNewRepliesList = (currentChatReplies: Reply[], newReplies: Reply[]): Reply[] => {
  const repliesList = [...currentChatReplies];
  if (newReplies.length) {
    newReplies.forEach(reply => {
      const alreadyAddedReplyIndex = repliesList.findIndex(rep => rep.id === reply.id);
      if (alreadyAddedReplyIndex > -1) {
        repliesList[alreadyAddedReplyIndex] = reply;
      } else {
        repliesList.push(reply);
      }
    });
    repliesList.sort((a, b) => new Date(a.sent).getTime() - new Date(b.sent).getTime());
  }
  return repliesList;
};

export const fetchSingleChat =
  (id: string): AppThunk =>
  async dispatch => {
    try {
      const newChat = await getSingleChat(id, { includeReplies: false });
      dispatch(setCurrentChat(newChat));
    } catch (error) {
      console.log('error log ', error);
    }
  };

export const fetchCurrentChatReplies =
  (
    skip = 0,
    take = 15,
    scrollToRef?: (id: number, isAnimationEnabled?: boolean, behavior?: string) => void,
    scrollToBottom?: boolean
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      const currentChat = getState().chatList.currentChat;
      dispatch(setAreAllRepliesLoaded(false));
      if (currentChat && currentChat.id && !getState().chatList.areRepliesLoading) {
        dispatch(setAreRepliesLoading(true));
        const newReplies = await getChatReplies(currentChat.id, { skip, take, direction: 1 });
        const oldReplies = [...getState().chatList.currentChatReplies];
        const repliesList = getNewRepliesList(oldReplies, newReplies);
        batch(() => {
          dispatch(setCurrentChatReplies(repliesList));
          dispatch(setAreRepliesLoading(false));
        });
        if (newReplies.length < take) {
          dispatch(setAreAllRepliesLoaded(true));
        }
        oldReplies.sort((a, b) => new Date(a.sent).getTime() - new Date(b.sent).getTime());
        if (scrollToBottom) {
          dispatch(setScrollToBottom(true));
        }
        if (scrollToRef && oldReplies.length) {
          scrollToRef(oldReplies[0].id, false, 'instant');
        }
        // Message items index/order will not change after the request
        checkIfNeedRead(currentChat, [...repliesList], dispatch as AppDispatch);
      }
    } catch (error) {
      batch(() => {
        dispatch(setCurrentChatReplies([]));
        dispatch(setIsError(`${error}`));
        dispatch(setAreRepliesLoading(false));
      });
    }
  };

export const fetchCurrentChatRepliesWithAttachments =
  (skip = 0, take = 15, hasAttachments: 0 | 1 | 2 = 1, scrollToBottom?: boolean): AppThunk =>
  async (dispatch, getState) => {
    try {
      const currentChat = getState().chatList.currentChat;
      dispatch(setAreAllRepliesLoaded(false));
      dispatch(setAreRepliesLoading(true));
      if (currentChat && currentChat.id) {
        const currentReplies = skip > 0 ? getState().chatList.currentChatReplies : [];
        const newReplies = await getChatReplies(currentChat.id, {
          skip,
          take,
          direction: 1,
          hasAttachments,
        });
        const repliesList = getNewRepliesList(currentReplies, newReplies);
        batch(() => {
          dispatch(setCurrentChatReplies(repliesList));
          dispatch(setAreRepliesLoading(false));
        });
        if (newReplies.length < take) {
          dispatch(setAreAllRepliesLoaded(true));
        }
        if (scrollToBottom) {
          dispatch(setScrollToBottom(true));
        }
        // Message items index/order will not change after the request
        checkIfNeedRead(currentChat, [...repliesList], dispatch as AppDispatch);
      }
    } catch (error) {
      batch(() => {
        dispatch(setCurrentChatReplies([]));
        dispatch(setIsError(`${error}`));
        dispatch(setAreRepliesLoading(false));
      });
    }
  };

export const fetchCurrentChat =
  (
    id: string,
    refetch?: boolean,
    navigate?: NavigateFunction,
    take = 15,
    hasAttachments?: 0 | 1 | 2,
    scrollToBottom?: boolean
  ): AppThunk =>
  async dispatch => {
    try {
      if (!refetch) {
        dispatch(setIsChatsLoading(true));
        dispatch(setCurrentChatReplies([]));
      }
      dispatch(setAreFilesSending(false));
      const currentChat = await getSingleChat(id, { includeReplies: false });
      batch(() => {
        dispatch(setCurrentChat(currentChat));
        dispatch(setIsChatsLoading(false));
        dispatch(setAreAllRepliesLoaded(false));
        if (hasAttachments && hasAttachments >= 0) {
          dispatch(fetchCurrentChatRepliesWithAttachments(0, take, 1, true));
        } else {
          dispatch(fetchCurrentChatReplies(0, take, undefined, scrollToBottom));
        }
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setCurrentChat(undefined));
        dispatch(setIsChatsLoading(false));
        dispatch(setIsError(`${error}`));
      });
      if (navigate) {
        navigate('/chat');
      }
    }
  };

export const fetchCurrentChatAndScrollToReply =
  (id: string, onLoad: () => void, take = 15): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsChatsLoading(true));
      dispatch(setAreRepliesLoading(true));
      const currentChat = await getSingleChat(id, { includeReplies: false });
      batch(() => {
        dispatch(setCurrentChat(currentChat));
        dispatch(setIsChatsLoading(false));
      });
      const newReplies = await getChatReplies(id, { skip: 0, take, direction: 1 });
      newReplies.sort((a, b) => new Date(a.sent).getTime() - new Date(b.sent).getTime());
      dispatch(setCurrentChatReplies(newReplies));
      dispatch(setAreRepliesLoading(false));
      onLoad();
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setCurrentChat(undefined));
        dispatch(setIsChatsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const fetchTotalUnread =
  (read?: boolean): AppThunk =>
  async (dispatch, store) => {
    try {
      const lastTotalUnread = store().chatList.totalUnread.UnreadMessagesAndRepliesCount;
      const totalUnread = await getTotalUnread();
      if (
        isTruthyIncludingZero(lastTotalUnread) &&
        lastTotalUnread !== totalUnread.UnreadMessagesAndRepliesCount &&
        !read
      ) {
        dispatchUpdateMessageList(true, dispatch as AppDispatch);
      }
      dispatch(setTotalUnread(totalUnread));
    } catch (error) {
      console.log('error log ', error);
      dispatch(setIsError(`${error}`));
    }
  };

export const readMessage =
  (id: number, replyIds: number[], willMessagesListIndexesChange = false): AppThunk =>
  async (dispatch, getState) => {
    try {
      const res = await messageRead(id, replyIds);
      const currentChat = getState().chatList.currentChat;
      const newChat: Chat = { ...currentChat!, lastRead: res.lastRead };
      batch(() => {
        dispatch(fetchTotalUnread(true));
        dispatch(setCurrentChat(newChat));
        // update the messages list when the user returns to it
        dispatchUpdateMessageList(willMessagesListIndexesChange, dispatch as AppDispatch, id);
      });
    } catch (error) {
      console.log('error log ', error);
      dispatch(setIsError(`${error}`));
    }
  };

export const fetchCurrentChatRepliesByThreshold =
  ({
    threshold,
    userId,
    lastMessageReference,
  }: {
    threshold?: string;
    userId?: number;
    lastMessageReference?: React.MutableRefObject<HTMLDivElement | null>;
  }): AppThunk =>
  async (dispatch, getState) => {
    try {
      const currentChatId = getState().chatList.currentChat?.id;
      const latestThresholdLoadTime = getState().chatList.latestThresholdLoadTime;
      const selectedThreshold = threshold ?? latestThresholdLoadTime;
      if (currentChatId && selectedThreshold) {
        const chatReplies = [...getState().chatList.currentChatReplies];
        const repliesByThreshold = await getChatReplies(currentChatId, {
          threshold: selectedThreshold,
        });
        // Check if replies are sent after earliest loaded reply sent time or check if already exists by id
        const earliestLoadedReplySentTime = chatReplies[0]?.sent;
        const alreadyLoadedRepliesIds = chatReplies.map(reply => reply.id);

        const newReplies = earliestLoadedReplySentTime
          ? repliesByThreshold.filter(
              reply =>
                new Date(reply.sent).getTime() >= new Date(earliestLoadedReplySentTime).getTime() ||
                alreadyLoadedRepliesIds.includes(reply.id)
            )
          : [...repliesByThreshold];

        if (newReplies.length) {
          const newBlockedOrUnblockedReplies = newReplies.filter(
            reply =>
              reply.purpose &&
              [ReplyPurpose.blockedComment, ReplyPurpose.unBlockedComment].includes(reply.purpose)
          );
          if (newBlockedOrUnblockedReplies.length) {
            const currentChat = await getSingleChat(currentChatId.toString(), {
              includeReplies: false,
            });
            dispatch(setCurrentChat(currentChat));
          }
          newReplies.forEach(reply => {
            const editedReplyIndex = chatReplies.findIndex(rep => rep.id === reply.id);
            if (editedReplyIndex > -1) {
              chatReplies[editedReplyIndex] = reply;
            } else {
              chatReplies.push(reply);
            }
          });
          chatReplies.sort((a, b) => new Date(a.sent).getTime() - new Date(b.sent).getTime());
          dispatch(
            readMessage(
              currentChatId,
              chatReplies.map(reply => reply.id)
            )
          );
          dispatch(setCurrentChatReplies(chatReplies));
          const newlyReceivedReplies = chatReplies.filter(
            reply =>
              new Date(reply.sent).getTime() > new Date(selectedThreshold).getTime() &&
              reply.senderID !== userId
          );
          if (newlyReceivedReplies.length && lastMessageReference) {
            lastMessageReference.current?.scrollIntoView();
          }
          dispatch(setScrollTopPosition(null));
        }
        dispatch(setLatestThresholdLoadTime(new Date().toISOString().replace('Z', '')));
      }
    } catch (error) {
      batch(() => {
        dispatch(setCurrentChatReplies([]));
        dispatch(setIsError(`${error}`));
        dispatch(setAreRepliesLoading(false));
      });
    }
  };

export const onReplyToMessageClick =
  (
    replyData: ReplyToMessageShorted,
    scrollToRef?: (id: number, isAnimationEnabled?: boolean, behavior?: ScrollBehavior) => void,
    scrollBehavior: ScrollBehavior = 'smooth'
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      if (!getState().chatList.areAllRepliesLoaded) {
        dispatch(setAreRepliesLoading(true));
        dispatch(setShowLoadingReplies(true));
      }
      const currentChatId = getState().chatList.currentChat?.id;
      if (currentChatId) {
        const chatReplies = [...getState().chatList.currentChatReplies];
        const maxReplyId = chatReplies.length ? chatReplies[0].id : undefined;
        if (replyData.isReplyToInitialMessage) {
          const additionalReplies = await getChatReplies(currentChatId, { maxReplyId });
          const repliesList = getNewRepliesList([...chatReplies], additionalReplies);
          dispatch(setCurrentChatReplies(repliesList));
          dispatch(setAreAllRepliesLoaded(true));
          setTimeout(() => {
            if (scrollToRef) {
              scrollToRef(currentChatId, true, scrollBehavior);
            }
          }, 50);
        } else {
          const alreadyLoadedReply = chatReplies.find(reply => reply.id === replyData.messageId);
          if (!alreadyLoadedReply) {
            const additionalReplies = await getChatReplies(currentChatId, {
              maxReplyId,
              minReplyId: replyData.messageId,
            });
            const repliesList = getNewRepliesList([...chatReplies], additionalReplies);
            dispatch(setCurrentChatReplies(repliesList));
            setTimeout(() => {
              if (scrollToRef) {
                scrollToRef(replyData.messageId, true, scrollBehavior);
              }
            }, 50);
          } else {
            if (scrollToRef) {
              scrollToRef(replyData.messageId, true, scrollBehavior);
            }
          }
        }
      }
      batch(() => {
        dispatch(setShowLoadingReplies(false));
        dispatch(setAreRepliesLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setCurrentChatReplies([]));
        dispatch(setIsError(`${error}`));
        dispatch(setAreRepliesLoading(false));
        dispatch(setShowLoadingReplies(false));
      });
    }
  };

export const hideAMessage =
  (id: number, messages: MessageInList[]): AppThunk =>
  async dispatch => {
    try {
      setIsChatsLoading(true);
      await archiveMessage(id);
      const filterMessages = messages.filter(message => message.id !== id);
      batch(() => {
        dispatch(setChats(filterMessages));
      });
      setIsChatsLoading(false);
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const endAlertAction =
  (chatID: number): AppThunk =>
  async dispatch => {
    try {
      const res = await recallAlert(chatID);
      if (res) {
        dispatch(fetchCurrentChat(`${chatID}`));
      }
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const deleteAMessage =
  (
    chatID: number,
    navigate: NavigateFunction,
    logNotes?: boolean,
    fromHoldingStatement?: boolean
  ): AppThunk =>
  async dispatch => {
    try {
      setIsChatsLoading(true);
      await deleteChat(chatID);
      setIsChatsLoading(false);
      dispatch(fetchChats({}));
      if (logNotes) {
        dispatch(fetchLogNotes({}));
      } else if (fromHoldingStatement) {
        navigate('/holding-statement');
      } else {
        navigate('/chat');
      }
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const changeChatSubject =
  (newSubject: string): AppThunk =>
  async (dispatch, state) => {
    try {
      const currentChat = state().chatList.currentChat;
      if (!currentChat) return;

      dispatch(setIsChatsLoading(true));
      await editMessage({ id: currentChat.id, subject: newSubject });
      dispatch(setCurrentChat({ ...currentChat, subject: newSubject }));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setIsChatsLoading(false));
    }
  };

export const changeReplyType =
  (params: ChangeReplyTypeParams, messageId: string): AppThunk =>
  async dispatch => {
    try {
      setIsChatsLoading(true);
      await _changeReplyType(params);
      setIsChatsLoading(false);
      dispatch(fetchCurrentChat(messageId));
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const deleteHoldingStatement =
  (chatID: number, searchTerm?: string): AppThunk =>
  async dispatch => {
    try {
      setIsChatsLoading(true);
      await deleteChat(chatID);
      dispatch(fetchHoldingStatement({ search: searchTerm }));

      setIsChatsLoading(false);
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const deleteAReply =
  (messageID: number, replyID: number): AppThunk =>
  async (dispatch, getState) => {
    try {
      await deleteReply(messageID, replyID);
      let currentChatReplies = [...getState().chatList.currentChatReplies];
      currentChatReplies = [...currentChatReplies].map(reply => {
        const replyCopy = { ...reply };
        //Rewrites logic for deleted and isInitialReplyDeleted properties
        if (replyCopy.id === replyID) {
          replyCopy.deleted = true;
        }
        if (reply.replyId === replyID) {
          replyCopy.isInitialReplyDeleted = true;
        }
        return replyCopy;
      });
      currentChatReplies.sort((a, b) => new Date(a.sent).getTime() - new Date(b.sent).getTime());
      dispatch(setCurrentChatReplies(currentChatReplies));
      //Refetch the current chat
      if (currentChatReplies.length && currentChatReplies[currentChatReplies.length - 1].deleted) {
        // update the messages list when the user returns to it
        dispatchUpdateMessageList(false, dispatch as AppDispatch, messageID);
      }
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const messageReplyTextGenerator = (
  messageType: MessageReplyType,
  message: MessageInList,
  userId: number
) => {
  const {
    lastReplySenderId,
    senderID,
    searchReplyText,
    lastReplyText,
    lastReplyLocationName,
    locationID,
    lastReplyPhotoFileNames,
    photoFileNames,
    lastReplyAudioFileNames,
    audioFileNames,
    lastReplyDocumentFileNames,
    documentFileNames,
    emergencyTypeName,
    text: messageText,
  } = message;
  const lastSenderId = lastReplySenderId || senderID;
  const isSent = lastSenderId === userId;
  const isReply = messageType === MessageReplyType.Reply;
  const textMessage = isReply ? searchReplyText || lastReplyText : messageText;
  const locationMessage = isReply ? lastReplyLocationName : locationID;
  const photoFilesCount = isReply ? lastReplyPhotoFileNames?.length : photoFileNames?.length;
  const audioFilesCount = isReply ? lastReplyAudioFileNames?.length : audioFileNames?.length;
  const documentFilesCount = isReply
    ? lastReplyDocumentFileNames?.length
    : documentFileNames?.length;

  if (textMessage && textMessage.trim() !== '') {
    return `${trunctateText(textMessage, 50)}`;
  } else if (locationMessage) {
    return isSent ? translate('messages_location_sent') : translate('messages_location_received');
  } else if (photoFilesCount) {
    if (isSent) {
      return translate(photoFilesCount === 1 ? 'imageSent' : 'imagesSent', {
        count: photoFilesCount,
      });
    } else {
      return translate(photoFilesCount === 1 ? 'imageReceived' : 'imagesReceived', {
        count: photoFilesCount,
      });
    }
  } else if (audioFilesCount) {
    return translate(isSent ? 'audioSent' : 'audioReceived');
  } else if (documentFilesCount) {
    return `${documentFilesCount > 1 ? `${documentFilesCount} ` : ''}${
      documentFilesCount > 1
        ? translate(isSent ? 'messages_documents_sent' : 'messages_documents_received', {
            count: documentFilesCount,
          })
        : translate(isSent ? 'documentSent' : 'documentReceived', {
            count: documentFilesCount,
          })
    }`;
  } else if (!lastReplyText && emergencyTypeName) {
    return emergencyTypeName;
  } else return '';
};

export const editMessageAction =
  (text: string, messageID: number, replyID?: number): AppThunk =>
  async (dispatch, getState) => {
    try {
      let currentChatReplies = [...getState().chatList.currentChatReplies];
      if (replyID) {
        await editMessage({ id: replyID, text, isReply: true });
        currentChatReplies = [...currentChatReplies].map(reply => {
          const replyCopy = { ...reply };
          if (replyCopy.id == replyID) {
            replyCopy.text = text;
            replyCopy.edited = true;
          }
          if (replyCopy.initialReplyText) {
            replyCopy.initialReplyText = text;
          }
          return replyCopy;
        });
        currentChatReplies.sort((a, b) => new Date(a.sent).getTime() - new Date(b.sent).getTime());
        dispatch(setCurrentChatReplies(currentChatReplies));
      } else {
        await editMessage({ id: messageID, text });
        const currentChat = { ...getState().chatList.currentChat };
        if (!currentChat) {
          return;
        }
        currentChat.text = text;
        currentChat.edited = true;
        const repliesToMessage = currentChatReplies.filter(item => item.replyToInitialMessage);
        if (repliesToMessage.length) {
          currentChatReplies = [...currentChatReplies].map(reply => {
            const replyCopy = { ...reply };
            if (replyCopy.replyToInitialMessage) {
              replyCopy.initialReplyText = text;
            }
            return replyCopy;
          });
          currentChatReplies.sort(
            (a, b) => new Date(a.sent).getTime() - new Date(b.sent).getTime()
          );
          dispatch(setCurrentChatReplies(currentChatReplies));
        }
        dispatch(setCurrentChat(currentChat as Chat));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const replyToMessageAction =
  (messageReply: MessageReplyModel): AppThunk =>
  async (dispatch, getState) => {
    try {
      const response = (await replyToMessage(messageReply)) as Reply;
      if (response?.id) {
        const currentChatReplies = [...getState().chatList.currentChatReplies];
        currentChatReplies.push(response);
        currentChatReplies.sort((a, b) => new Date(a.sent).getTime() - new Date(b.sent).getTime());
        dispatch(setCurrentChatReplies(currentChatReplies));
        const currentChat = getState().chatList.currentChat;
        const currentChatList = getState().chatList.chats;
        if (currentChat) {
          const willMessagesListIndexesChange = currentChatList[0]?.id !== currentChat?.id;
          dispatch(
            readMessage(
              currentChat.id,
              currentChatReplies.map(reply => reply.id),
              willMessagesListIndexesChange
            )
          );
        }
      }
      batch(() => {
        dispatch(setIsChatsLoading(false));
        dispatch(setAreFilesSending(false));
      });
    } catch (error) {
      dispatch(setIsChatsLoading(false));
      dispatch(setAreFilesSending(false));
      console.log('error log ', error);
    }
  };

export const getMessagesRecipientsAction =
  (messageID: number): AppThunk =>
  async dispatch => {
    try {
      const messagesRecipients = await getMessagesRecipients(messageID);
      batch(() => {
        dispatch(setMessagesRecipients(messagesRecipients));
      });
    } catch (error) {
      console.log('error log ', error);
    }
  };

export interface FetchReadReceiptProps {
  messageId: number;
  replyId: number;
  isReplyExpected: boolean;
}

export const fetchReadReceipt =
  (props: FetchReadReceiptProps): AppThunk =>
  async dispatch => {
    const { isReplyExpected, messageId, replyId } = props;
    try {
      dispatch(setIsChatsLoading(true));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const [currentChat, readReceipt] = await Promise.all([
        getSingleChat(`${messageId}`),
        isReplyExpected
          ? getReplyReadReceipt({
              messageId: messageId,
              replyId: replyId,
            })
          : getMessagesRecipients(messageId),
      ]);
      batch(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(setCurrentChat(currentChat));
        dispatch(
          // making sure to Differentiate Types
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          'reads' in readReceipt
            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setCurrentReplyReadReceipt(readReceipt)
            : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setMessagesRecipients(readReceipt)
        );
        dispatch(setIsChatsLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setCurrentChat(undefined));
        dispatch(setIsChatsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const fetchMessageReactionTypes = (): AppThunk => async dispatch => {
  try {
    const fetchedMessageReactionTypes = await getMessageReactionTypes();
    const items = fetchedMessageReactionTypes.items ?? [];
    items.sort((a, b) => Number(a.order) - Number(b.order));
    dispatch(setMessageReactionTypes(items));
  } catch (error) {
    console.log('error log ', error);
    dispatch(setIsError(`${error}`));
  }
};

export const toggleMessageReaction =
  (messageOrReplyId?: number, reactionTypeId?: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      if (!messageOrReplyId || !reactionTypeId) return;

      const currentChat = getState().chatList.currentChat;
      const reactionTypes = getState().chatList.messageReactionTypes;
      const reactionType = reactionTypes.find(item => item._Id === reactionTypeId);
      const replies = [...getState().chatList.currentChatReplies];
      const chatId = currentChat?.id;
      if (!chatId || !reactionType) return;
      const isMessageReaction = chatId === messageOrReplyId;
      const replyIndex = replies.findIndex(reply => reply.id === messageOrReplyId);
      const replyOrMessage = isMessageReaction ? currentChat : replies[replyIndex];
      let messageReactions = replyOrMessage?.reactions ? [...replyOrMessage.reactions] : [];
      const currentReactionListItemIndex = messageReactions.findIndex(
        item => !!item.currentUserReactionId
      );
      const currentReactionListItem =
        currentReactionListItemIndex > -1 ? messageReactions[currentReactionListItemIndex] : null;

      messageReactions = await getReactionsAfterDeletingReaction(
        chatId,
        messageOrReplyId,
        reactionTypeId,
        currentReactionListItem,
        currentReactionListItemIndex,
        messageReactions
      );

      messageReactions = (await getReactionsAfterEditingReaction(
        chatId,
        messageOrReplyId,
        messageReactions,
        currentReactionListItem,
        reactionType
      )) as MessageReactionsListItem[];

      if (isMessageReaction) {
        dispatch(setCurrentChat({ ...currentChat, reactions: messageReactions } as Chat));
      } else {
        if (!replies[replyIndex]?.reactions) return;
        replies[replyIndex] = {
          ...replies[replyIndex],
          reactions: messageReactions,
        };
        dispatch(setCurrentChatReplies([...replies]));
      }
    } catch (error) {
      console.log('error log ', error);
      dispatch(setIsError(`${error}`));
    }
  };

export const fetchMessageReactions =
  (messageOrReplyId?: number | null, reactionTypeId?: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const isMessageReactionsLoading = getState().chatList?.isMessageReactionsLoading ?? false;
      if (!messageOrReplyId || isMessageReactionsLoading) return;
      dispatch(setIsMessageReactionsLoading(true));
      const selectedMessageReactions = getState().chatList.selectedMessageReactions;
      const currentItems = selectedMessageReactions?.items ?? [];
      const currentItemsCount = currentItems.length ?? 0;
      const currentTotalCount = selectedMessageReactions?.totalCount ?? 0;
      const currentChat = getState().chatList.currentChat;
      const itemsPerPage = 100;
      const messageId = currentChat?.id;
      if (!messageId) return;
      const isMessageId = messageId === messageOrReplyId;
      const currentItemsMessageId = isMessageId
        ? currentItems[0]?.messageId
        : currentItems[0]?.replyId;
      const currentItemsReactionTypeId = isMessageId
        ? currentItems[0]?.reactionTypeId
        : currentItems[0]?.reactionTypeId;

      const loadNextPage =
        currentItemsMessageId === messageOrReplyId && reactionTypeId === currentItemsReactionTypeId;
      if (loadNextPage && currentTotalCount <= currentItemsCount) return;
      const query = loadNextPage
        ? { skip: currentItemsCount, take: itemsPerPage, reactionTypeId }
        : { skip: 0, take: itemsPerPage, reactionTypeId };
      let reactions: MessageReactions | null = null;

      if (isMessageId) {
        reactions = await getMessageReactions({ messageId, ...query });
      } else {
        reactions = await getMessageReplyReactions({
          messageId,
          replyId: messageOrReplyId,
          ...query,
        });
      }
      const newReactionsItems = reactions?.items ?? [];
      const filteredReactionItems = removeDuplicatesByProperty(
        loadNextPage ? [...currentItems, ...newReactionsItems] : [...newReactionsItems],
        '_Id'
      ) as MessageReactionsItem[];
      const newReactions = {
        items: filteredReactionItems,
        totalCount: reactions?.totalCount ?? currentTotalCount,
      };
      dispatch(setSelectedMessageReactions(newReactions));
    } catch (error) {
      console.log('error log ', error);
      dispatch(setIsError(`${error}`));
    } finally {
      dispatch(setIsMessageReactionsLoading(false));
    }
  };

export const closeMessageReactionsBottomSheet = (): AppThunk => dispatch => {
  try {
    batch(() => {
      dispatch(setSelectedReactionsMessageId(null));
      dispatch(setSelectedMessageReactions(null));
    });
  } catch (error) {
    console.log('error log ', error);
    dispatch(setIsError(`${error}`));
  }
};
