import { objectToQueryParams } from './format';
import axios from 'axios';
import isElectron from 'is-electron';

// Defualt location will be stockholm in case of the swedish version
// and sao sao paulo in case of  brazilian version
export const defaultLat = Number(process.env.REACT_APP_DEFAULT_LOCATION_LAT);
export const defaultLng = Number(process.env.REACT_APP_DEFAULT_LOCATION_LNG);
export const apiKey = isElectron()
  ? process.env.REACT_APP_DESKTOP_GEOAPIKEY
  : process.env.REACT_APP_WEB_DEPLOYMENT_MAP_KEY;

const geoApiKey = isElectron()
  ? process.env.REACT_APP_DESKTOP_GEOAPIKEY
  : process.env.REACT_APP_WEB_DEPLOYMENT_GEO_KEY;

const GM_GEOCODE_BASE_URL = 'https://maps.googleapis.com/maps/api/geocode/';

enum GeocodeType {
  standerdGeocoding,
  reversGeocoding,
}

interface GeocoderCoordinats {
  longitude: number;
  latitude: number;
}

interface GeocoderResult {
  addressName: string;
  coordinates: GeocoderCoordinats;
}
type GeocoderParams = string | GeocoderCoordinats;

export default async function Geocoder(
  address: GeocoderParams
): Promise<GeocoderResult | null | undefined> {
  let geocodeOptions;
  let geocodeType: GeocodeType = GeocodeType.standerdGeocoding;
  const apiSouce = axios.create({
    baseURL: GM_GEOCODE_BASE_URL,
  });

  if (typeof address === 'string') {
    geocodeOptions = { address: address, key: geoApiKey };
    geocodeType = GeocodeType.standerdGeocoding;
  } else {
    if (address.latitude && address.longitude) {
      geocodeOptions = {
        latlng: `${address.latitude},${address.longitude}`,
        key: geoApiKey,
      };
      geocodeType = GeocodeType.reversGeocoding;
    } else {
      console.error(
        `Geocoder: ${JSON.stringify(address)} is not a valid object. Use 'longitude' & 'latitude'`
      );
    }
  }

  if (geocodeOptions) {
    try {
      const response = await apiSouce.get(`json?${objectToQueryParams(geocodeOptions)}`);
      if (response.data.results.length > 0) {
        // Use the first address in response since it's going to be most accurate
        const accurateAddress = response.data.results[0];

        const split: [string] =
          accurateAddress.formatted_address && accurateAddress.formatted_address.includes(',')
            ? accurateAddress.formatted_address.split(',')
            : accurateAddress.formatted_address.split('-');
        const addressName: string = split
          ? split.slice(0, split.length <= 2 ? split.length : split.length - 2).join(',')
          : 'N/A';

        return {
          addressName: addressName,
          coordinates: {
            longitude:
              geocodeType === GeocodeType.reversGeocoding
                ? (address as GeocoderCoordinats).longitude
                : accurateAddress.geometry.location.lng,
            latitude:
              geocodeType === GeocodeType.reversGeocoding
                ? (address as GeocoderCoordinats).latitude
                : accurateAddress.geometry.location.lat,
          },
        };
      }
    } catch (err) {
      // @ts-expect-error TODO: Fix by e.g. changing the return type and consuming functions OR change implementation to throw
      return err;
    }
  }
}
