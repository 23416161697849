import isElectron from 'is-electron';
import styled, { css } from 'styled-components';
import { palette } from '../../../theme/colors';

export const Sbackground = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 4px;
  background: ${palette.danger};
`;

export const FooterWrapper = styled.footer`
  position: fixed;
  bottom: 0;
  z-index: 997;
  width: 100%;
  background-color: ${({ theme }) =>
    isElectron() ? theme.palette.background.nav : palette.darkblack};
  box-shadow: ${isElectron() ? '0px -1px 9px rgba(211, 211, 211, 0.25)' : ''};
`;

export const SFooter = styled.div`
  background-color: ${({ theme }) => theme.palette.background.nav};
  box-shadow: ${!isElectron() ? '0px -1px 9px rgba(211, 211, 211, 0.25)' : ''};
  clip-path: inset(-9px 0 0);
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;

  max-width: 26rem;
  margin: auto;
  padding: 0 0.4em;

  height: 4.86rem;
`;

export const STabs = styled.div`
  font-size: 12px;
  width: 100%;
  padding-top: 0px;
`;

export const STabList = styled.div`
  list-style-type: none;
  display: flex;
  margin: 0;
`;

export const STab = styled.div<{ selected: boolean; isAlarm?: boolean }>`
  position: relative;
  width: 50%;
  text-align: center;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    cursor: pointer;
  }

  ${props =>
    props.selected &&
    !props.isAlarm &&
    css`
      path {
        fill: ${palette.honeyYellow};
      }
    `}

  &:focus {
    outline: none;
  }
`;
