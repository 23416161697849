import { palette } from '../../theme/colors';
import { SearchBarBase } from '../SearchBarBase/SearchBarBase';
import FilterCheckboxItem from '../FilterItem/FilterCheckboxItem';
import { toRem } from '../../utils/toRem';
import styled from 'styled-components';

export const SActionBtnWrapper = styled.div`
  padding-top: 1rem;
`;

export const SFilterCheckboxItem = styled(FilterCheckboxItem)`
  margin-bottom: 0.75rem;
`;

export const SFlexDiv = styled.div`
  flex: 1;
`;

export const SFilter = styled.div`
  position: fixed;
  left: 50%;
  bottom: 0;
  padding: 20px;
  padding-bottom: ${toRem(30)};
  transform: translate(-50%);
  background-color: ${palette.prussianBlue2};
  max-height: 75vh;
  width: 100%;
  max-width: 26rem;
  z-index: 999;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

export const SHeader = styled.svg`
  width: 135px;
  height: 5px;
  place-self: center;
  margin-bottom: 3.75rem;
  border-radius: 5px;
`;

export const SSearchBarBase = styled(SearchBarBase)`
  margin-bottom: 1.25rem;
`;

export const SLabelContainer = styled.div<{ $disable: boolean }>`
  display: ${props => (props.$disable ? 'none' : 'inline-flex')};
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1.25rem 0;
`;

export const SLabel = styled.p`
  font-family: 'Roboto-Regular';
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${palette.white};
`;

export const ScrollList = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
