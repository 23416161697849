import GroupsIcon from '../../assets/icons/silhouette-with-three--background.svg';
import Folder from '../../assets/icons/folder-with-papers--yellow.svg';
import OfflineFolder from '../../assets/icons/folder-with-cloud-and-arrow-down--yellow.svg';
import File from '../../assets/icons/file-without-text.svg';
import OfflineFile from '../../assets/icons/file-with-cloud-and-arrow-down--yellow.svg';

export type DocumentIconType =
  | 'GROUP_FOLDER'
  | 'OFFLINE_GROUP_FOLDER'
  | 'FOLDER'
  | 'OFFLINE_FOLDER'
  | 'FILE'
  | 'OFFLINE_FILE';

export const documentIcons: Record<DocumentIconType, string> = {
  GROUP_FOLDER: GroupsIcon,
  OFFLINE_GROUP_FOLDER: GroupsIcon,
  FOLDER: Folder,
  OFFLINE_FOLDER: OfflineFolder,
  FILE: File,
  OFFLINE_FILE: OfflineFile,
};
