import isElectron from 'is-electron';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { logoutRequest } from '../../apis/authApi/authAPI';
import BellIcon from '../../assets/icons/bell.svg';
import BrazilIcon from '../../assets/icons/flags/brazil-round.svg';
import NetherlandsIcon from '../../assets/icons/flags/netherlands-round.svg';
import QuestionIcon from '../../assets/icons/question-mark-round.svg';
import SettingsIcon from '../../assets/icons/cog--white.svg';
import SpainIcon from '../../assets/icons/flags/spain-round.svg';
import SwedishIcon from '../../assets/icons/flags/sweden-round.svg';
import EnglishIcon from '../../assets/icons/flags/uk-round.svg';
import FinnishIcon from '../../assets/icons/flags/finland-round.svg';
import UsIcon from '../../assets/icons/flags/us-round.svg';
import BigFloatButton from '../../components/BigFloatButton/BigFloatButton';
import { Layout } from '../../components/Layout/Layout';
import { Page } from '../../components/Page/Page';
import { ProfilePicture } from '../../components/ProfilePicture/ProfilePicture';
import { isDevRelease, isTestApiUrlApplied, removeTestApiUrl } from '../../configurations';
import { useAppDispatch, useAppSelector } from '../../hooks';
import i18n, { ELanguages } from '../../i18n';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { selectUser } from '../Login/LoginSlice';
import { refreshUserInfo } from '../Login/LoginSlice/actionCreators';
import {
  SContainer,
  SElementContainer,
  SFlexDiv,
  SInfo,
  SInfoContainer,
  SName,
  SRowContainers,
  SSidebar,
  SSidebarAvailabilityContainer,
} from './Sidebar.styles';
import SidebarElement from './SidebarElement';
import { logoutUser } from './actionCreators';
import SidebarAvailabilityButton from '../../components/SidebarAvailabilityButton/SidebarAvailabilityButton';

export interface SidebarSection {
  name: string;
  icon: string;
  to?: string;
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  onClick?: () => void | null;
}

function Sidebar() {
  const confirm = useConfirmation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const user = useAppSelector(selectUser);

  useEffect(() => {
    dispatch(refreshUserInfo());
  }, []);

  const flags = {
    [ELanguages.se]: SwedishIcon,
    [ELanguages.en]: EnglishIcon,
    [ELanguages.nl]: NetherlandsIcon,
    [ELanguages.fi]: FinnishIcon,
    [ELanguages.es]: SpainIcon,
    [ELanguages.pt]: BrazilIcon,
    [ELanguages['en-TT']]: UsIcon,
  };

  const flagKey = i18n.language as 'se' | 'en' | 'fi' | 'es' | 'pt' | 'en-TT';

  const sidebarElements: SidebarSection[] = [
    {
      name: 'mainMenu_settings',
      icon: SettingsIcon,
      to: 'settings',
    },
    {
      name: 'support',
      icon: QuestionIcon,
      to: 'support',
    },
    {
      name: 'language',
      icon: flags[flagKey],
      to: 'language',
    },
  ];

  if (isElectron()) {
    sidebarElements.push({
      name: 'profile_test_alarm',
      icon: BellIcon,
      to: 'test-alarm',
    });
  }

  const doLogout = async (_removeTestApiUrl?: boolean) => {
    await logoutRequest();
    dispatch(logoutUser());
    if (_removeTestApiUrl) {
      removeTestApiUrl();
    }
    location.reload();
  };

  const confirmLogout = () => {
    confirm({
      title: 'warning',
      description: 'login_logout_question',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onSubmit: doLogout,
      onCancel: () => {},
      confirmText: 'logout',
      cancelText: 'cancel',
    });
  };

  const handleRemoveTestApiUrl = () => doLogout(true);

  return (
    <Layout backBtn to="/dashboard">
      <Page>
        <SSidebar role="sidebarContainer">
          <SContainer role="profileInfoContainer">
            <div role="imageContainer">
              <ProfilePicture profilePictureFileName={user!.photoFileName ?? ''} diameter={50} />
            </div>
            <SRowContainers role="infoContainer">
              <SName role="name"> {user?.name} </SName>
              <SInfoContainer role="viewProfileContainer">
                <SInfo to="/profile" role="viewProfile">
                  {t(`viewProfile`)}
                </SInfo>
              </SInfoContainer>
            </SRowContainers>
          </SContainer>
          <SSidebarAvailabilityContainer role="availabilityContainer">
            <SidebarAvailabilityButton />
          </SSidebarAvailabilityContainer>
          <SElementContainer role="elementsList">
            {sidebarElements.map((element, key) => (
              <SidebarElement
                key={key}
                name={element.name}
                icon={element.icon}
                to={element.to}
                onClick={element.onClick}
              />
            ))}
          </SElementContainer>
          <SFlexDiv />

          {isDevRelease() && isTestApiUrlApplied() && (
            <BigFloatButton
              tx="Clear test API_URL & Log out"
              onClick={handleRemoveTestApiUrl}
              extraPadding={true}
            ></BigFloatButton>
          )}
          <BigFloatButton tx="logout" onClick={confirmLogout} extraPadding={false} />
        </SSidebar>
      </Page>
    </Layout>
  );
}

export default Sidebar;
