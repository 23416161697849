import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import styled, { css } from 'styled-components';
import { Button } from '../../components/Button/Button';
import Options from '../../components/Options/Options';
import { Page } from '../../components/Page/Page';
import { SearchFilterBar } from '../../components/SearchFilterBar/SearchFilterBar';
import { palette } from '../../theme/colors';
import PlusIcon from '../../assets/icons/plus.svg';
import { EmptyListFallback } from '../../components/EmptyListFallback/EmptyListFallback';

export const SEmptyListFallback = styled(EmptyListFallback)<{ $searchTerm: boolean }>`
  ${props =>
    props.$searchTerm &&
    css`
      p {
        white-space: pre-line;
      }
    `}
`;

interface SDocumentPlusButtonProps {
  $right?: number;
}

export const SFloatingDocumentAddButton = styled(Button)<SDocumentPlusButtonProps>`
  position: absolute;
  bottom: 20px;
  right: 16px;
  z-index: 11;
  ${props =>
    !!props.$right &&
    css`
      right: ${props.$right}px;
    `}

  width: 48px;
  height: 48px;
  // bg-color is already derived needed from Button style

  button {
    height: 100%;
    border-radius: 25px;

    background-image: url(${PlusIcon});
    background-position: center -4px;
    background-repeat: no-repeat;
  }
`;

export const SOptions = styled(Options)`
  padding: 0;
  .cancel {
    p {
      color: ${palette.honeyYellow};
    }
  }
  .SOptionsList {
    margin-bottom: 0.625rem;
  }
`;

export const SHiddenInput = styled.input`
  display: none;
`;

export const STabs = styled(Tabs)`
  width: 100%;
  padding-top: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const STabContainer = styled.div`
  padding-bottom: 1.5rem;
`;

export const STabList = styled(TabList)<{ $selectedIndex: number; $disabled?: boolean }>`
  display: ${props => (props.$disabled ? 'none' : 'flex')};
  list-style-type: none;
  margin: 0;
  & ~ .slider {
    ${({ $selectedIndex }) => css`
      transform: translateX(calc(100% * ${$selectedIndex}));
    `}
  }
`;

export const STab = styled(Tab)<{ disabled?: boolean }>`
  cursor: pointer;
  color: ${props => props?.disabled && palette.silver};
  width: 50%;
  user-select: none;
  padding: 0 0 0.625rem 0;
  border-bottom: 1px solid ${palette.queenBlue};
  font-family: 'Roboto-Regular';
  font-size: 0.875rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  &.is-selected {
    color: ${palette.honeyYellow};
    margin-bottom: -0.5px;

    svg {
      stroke: ${palette.honeyYellow};
      fill: ${palette.honeyYellow};
    }

    &:hover,
    &:focus,
    &:active {
      filter: none;
      svg {
        filter: none;
      }
    }
  }

  svg {
    fill: ${palette.offWhite};
    stroke: ${palette.offWhite};
    transition: all 0.3s ease-in-out;
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
  }

  .svgContainer {
    padding-right: 0.5rem;
  }

  &:hover,
  &:focus,
  &:active {
    transition: none;
    outline: none;
    filter: brightness(80%);
    svg {
      transition: none;
      filter: brightness(80%);
    }
  }
`;

export const STabPanel = styled(TabPanel)`
  &.is-selected {
    height: 100%;
    min-height: 0;
    display: block;
  }
`;

export const SSlider = styled.div`
  position: relative;
  width: 50%;
  transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
  margin-top: -1px;
  .indicator {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    height: 2px;
    background: ${palette.honeyYellow};
    border-radius: 1px;
  }
`;

export const SPage = styled(Page)`
  padding: 1.25rem 0 1.25rem 0;
  /* to make only the list overflow */
  position: relative;
  height: 100%;
`;

export const SList = styled.ul`
  width: 90%;
  margin: 0.1875rem auto 1.25rem auto;

  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  overflow-y: auto;

  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SSearchFilterBar = styled(SearchFilterBar)<{
  $withoutFilterButton?: boolean;
}>`
  width: 90%;
  margin: auto;
  .SSearchBarBase {
    input {
      ::placeholder {
        color: ${palette.silver};
        opacity: 1;
      }

      :-ms-input-placeholder {
        color: ${palette.silver};
        font-size: 1rem;
      }

      ::-ms-input-placeholder {
        color: ${palette.silver};
        font-size: 1rem;
      }
    }
  }
  ${props =>
    props.$withoutFilterButton &&
    css`
      .SSearchBarBase {
        width: 100%;
      }
    `}
`;
