import { compact } from 'lodash';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Options, { OptionItemProps } from '../../components/Options/Options';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  MessageRefsContextProvider,
  useMessageRefsContext,
} from '../../utils/customHooks/MessageRefsContext';
import {
  CreateMessageReplyType,
  GroupType,
  MessageSubType,
  ReceivedMessageType,
  ReplyPurpose,
  SentMessageType,
} from '../../utils/enums';
import {
  isChatsLoading,
  selectAreAllRepliesLoaded,
  selectAreFilesSending,
  selectAreRepliesLoading,
  selectCurrentChat,
  selectCurrentChatReplies,
  selectError,
  selectScrollToBottom,
  selectShowLoadingReplies,
  setLatestThresholdLoadTime,
  setScrollToBottom,
} from '../ChatsList/chatListSlice';
import {
  deleteAReply,
  editMessageAction,
  fetchCurrentChat,
  fetchCurrentChatAndScrollToReply,
  fetchCurrentChatReplies,
  fetchCurrentChatRepliesByThreshold,
  fetchMessageReactionTypes,
  fetchSingleChat,
  onReplyToMessageClick,
  replyToMessageAction,
} from '../ChatsList/chatListSlice/actionCreators';
import { Chat as ChatType, Reply } from '../ChatsList/chatListSlice/types';
import { selectUser, selectUserRoles } from '../Login/LoginSlice';
import {
  SChat,
  SChatContainer,
  SFilesLoaderWrapper,
  SRepliesLoader,
  SScrollDownBtw,
} from './Chat.styles';
import {
  checkHasReply,
  generateMessageReplyToMessage,
  generateReplyToMessageBodyText,
  generateReplyToMessageIcon,
  getMessageHeaderSubTitle,
  isReplyInSameDay,
} from './helpers';

import { decode } from 'html-entities';
import { useTranslation } from 'react-i18next';
import { saveDocumentToServer } from '../../apis/mediaAPI';
import BlockedAlarmIcon from '../../assets/imgs/chats/blocked-alarm-banner-icon.svg';
import Copy from '../../assets/imgs/chats/copy.svg';
import Delete from '../../assets/icons/trash-can--white.svg';
import Pencil from '../../assets/imgs/chats/edit-white.svg';
import Forward from '../../assets/imgs/chats/forward.svg';
import GreenCheck from '../../assets/imgs/chats/green-check.svg';
import ReadReceiptsIcon from '../../assets/imgs/chats/read-receipts.svg';
import ReplyIcon from '../../assets/imgs/chats/reply.svg';
import { ReactComponent as ScrollDownArrow } from '../../assets/imgs/chats/scroll-down-arrow.svg';
import CallAlertPhone from '../../assets/icons/warning-triangle.svg';
import ChatBox from '../../components/Chat/ChatBox';
import { ChatScreenTopBar } from '../../components/ChatScreenTopBar/ChatScreenTopBar';
import Loader from '../../components/Loader/Loader';
import { BlockedStateBanner } from '../../components/MessageItem/BlockedStateBanner/BlockedStateBanner';
import { BlockedStateBottomSheet } from '../../components/MessageItem/BlockedStateBottomSheet/BlockedStateBottomSheet';
import { AlarmBlockedStateSeparator } from '../../components/MessageItem/BlockedStateSeparator/AlarmBlockedStateSeparator';
import { BlockedStateSeparator } from '../../components/MessageItem/BlockedStateSeparator/BlockedStateSeparator';
import ChatReply from '../../components/MessageItem/ChatReply/ChatReply';
import { Disclaimer } from '../../components/MessageItem/Disclaimer/Disclaimer';
import { MessageItem } from '../../components/MessageItem/MessageItem';
import { Modal } from '../../components/Modal/Modal';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import { useMapModal } from '../../utils/customHooks/useMapModal';
import { isDesktop } from '../../utils/isDesktop';
import { setForwardedMessageID } from '../CreateMessage/createMessageSlice';
import { updateUser } from '../Login/LoginSlice/actionCreators';
import { MessageReplyModel } from './models';
import { MessageReactionsBottomSheet } from '../../components/MessageReactionsBottomSheet/MessageReactionsBottomSheet';
import MessageOptionsReactionsSelectionRow from '../../components/MessageOptionsReactionsSelectionRow/MessageOptionsReactionsSelectionRow';

export interface ReplyToMessageIcon {
  src: string;
  classPresets?: string;
}
export interface ReplyToMessageShorted {
  senderName: string;
  messageText: string;
  messageId: number;
  messageIcon?: ReplyToMessageIcon;
  isReplyToInitialMessage?: boolean;
  isDeleted?: boolean;
}

interface ChatProps {
  id: string;
  setIsAlarmActive?: React.Dispatch<React.SetStateAction<boolean>>;
  setSubMessage?: boolean;
  fromHoldingStatment?: boolean;
  setSubMessageText?: React.Dispatch<string>;
}

export const Chat = (props: ChatProps) => {
  const {
    id,
    setIsAlarmActive,
    fromHoldingStatment: fromHoldingStatement = false,
    setSubMessage,
    setSubMessageText,
  } = props;
  const REPLIES_PER_PAGE = 15;
  const message = useAppSelector(selectCurrentChat);
  const replies = useAppSelector(selectCurrentChatReplies);
  const loading = useAppSelector(isChatsLoading);
  const user = useAppSelector(selectUser);
  const error = useAppSelector(selectError);
  const roles = useAppSelector(selectUserRoles);
  const areFilesSending = useAppSelector(selectAreFilesSending);
  const areRepliesLoading = useAppSelector(selectAreRepliesLoading);
  const showLoadingReplies = useAppSelector(selectShowLoadingReplies);
  const areAllRepliesLoaded = useAppSelector(selectAreAllRepliesLoaded);
  const needScrollToBottom = useAppSelector(selectScrollToBottom);
  const areAllRepliesLoadedRef = useRef(areAllRepliesLoaded); //[Web version] Is used to load chat info in interval only when  areAllRepliesLoaded = true

  const confirm = useConfirmation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { scrollToReplyId, scrollToMessage } = location.state || {};
  const { setMessage, setSubTitle, isMapOpen } = useLayoutContext();

  const [activeAudio, setActiveAudio] = useState<number | null>(null);
  const [showBottomTabs, setShowBottomTabs] = useState(true);
  const [isBlockedBottomSheetOpen, setIsBlockedBottomSheetOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState<ChatType | Reply | null>(null);
  const [copyState, setCopyState] = useState<string>(Copy);
  const [editText, setEditText] = useState<string | undefined>(undefined);
  const [replyMessageState, setReplyMessageState] = useState<ReplyToMessageShorted | undefined>(
    undefined
  );
  const { renderMapModal, toggleMapModal } = useMapModal(message);

  const scrollBtnRef = useRef<HTMLButtonElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const firstReplyRef = useRef<HTMLDivElement>(null);

  const { scrollToRef } = useMessageRefsContext();

  const isPersonalAlarm = message?.subType === MessageSubType.personalAlarm;
  const isHoldingStatementMessage = message?.type === SentMessageType.HoldingStatement;
  const isIamOkMessage = !!message?.musterID;
  const isOnCallAlertMessage = !!message?.onCallAlertID;

  const onCreated = async () => {
    if (scrollToReplyId || scrollToMessage) {
      const replyData: ReplyToMessageShorted = {
        messageId: scrollToReplyId,
        isReplyToInitialMessage: scrollToMessage ?? false,
        senderName: '',
        messageText: '',
      };
      dispatch(
        fetchCurrentChatAndScrollToReply(
          id,
          () => {
            dispatch(onReplyToMessageClick(replyData, scrollToRef, 'auto'));
          },
          REPLIES_PER_PAGE
        )
      );
    } else {
      dispatch(fetchCurrentChat(id, undefined, navigate, REPLIES_PER_PAGE, undefined, true));
    }
    dispatch(fetchMessageReactionTypes());
    dispatch(setLatestThresholdLoadTime(new Date().toISOString().replace('Z', '')));
  };

  const handleMessageHeader = (messageObj: ChatType) => {
    let subText = '';
    const isAlarmActive = messageObj.type === 2 && !messageObj.recalled;
    const haveSubject = messageObj.subject && messageObj.subject.length;
    if (setIsAlarmActive) setIsAlarmActive(isAlarmActive);

    if (messageObj.emergencyTypeName !== null) {
      setMessage(messageObj.emergencyTypeName);
    } else {
      setMessage(t(`imOk_message`));
    }
    if (haveSubject) setMessage(messageObj?.subject?.toString());
    if (isPersonalAlarm) setMessage(t('alarm_personal_alarm'));
    if (isIamOkMessage) setMessage(t('home_muster'));
    if (isOnCallAlertMessage) setMessage(t('onCallAlert_screen'));
    if (isHoldingStatementMessage) {
      setMessage(haveSubject ? messageObj?.subject?.toString() : t('home_holdingStatement'));
    }
    if (messageObj?.type === 10) setMessage(t('logNote'));
    if (messageObj?.type === ReceivedMessageType.Broadcast && !messageObj?.subject) {
      setMessage(t('broadcast'));
    }
    subText = getMessageHeaderSubTitle(messageObj, t);
    if (setSubMessage) setSubTitle(subText);
  };

  useEffect(() => {
    onCreated();
  }, [id]);

  useEffect(() => {
    areAllRepliesLoadedRef.current = areAllRepliesLoaded;
  }, [areAllRepliesLoaded]);

  useEffect(() => {
    if (areFilesSending) {
      messagesEndRef.current?.scrollIntoView();
    }
  }, [areFilesSending]);

  useEffect(() => {
    if (message && location.state?.fromDetails) {
      handleMessageHeader(message);
      return;
    }
  }, [location.state]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;

    const getRepliesByThreshold = () => {
      if (areAllRepliesLoadedRef.current) dispatch(fetchSingleChat(id.toString()));
      dispatch(
        fetchCurrentChatRepliesByThreshold({
          userId: user?.id ?? 0,
          lastMessageReference: messagesEndRef,
        })
      );
    };
    if (user) {
      if (!isDesktop()) {
        interval = setInterval(getRepliesByThreshold, 10000);
      } else {
        getRepliesByThreshold();
      }
    }
    return () => {
      if (interval) clearInterval(interval);
      dispatch(updateUser());
    };
    // DONT CHANGE DEPENDENCIES! this will lead to infinite rerendering
    // since `user` updates itself on updateUser()
  }, [id]);

  useEffect(() => {
    if (error === 'Message is deleted') {
      confirm({
        title: 'message_not_found_title',
        description: 'message_deleted',
        onSubmit: () => navigate('/chat'),
        confirmText: 'ok',
        blockOutsideClick: true,
      });
    } else if (error === 'Request failed with status code 404') {
      confirm({
        title: 'message_not_found_title',
        description: 'message_not_found',
        onSubmit: () => navigate('/chat'),
        confirmText: 'ok',
        blockOutsideClick: true,
      });
    }
    // DONT CHANGE DEPENDENCIES! this will lead to infinite rerendering
    // since `confirm` updates itself on use
  }, [message, error]);

  useEffect(() => {
    if (needScrollToBottom) {
      messagesEndRef.current?.scrollIntoView();
    }
    dispatch(setScrollToBottom(false));
  }, [needScrollToBottom]);

  useLayoutEffect(() => {
    if (message && !isMapOpen) {
      handleMessageHeader(message);
      if (setSubMessageText && message?.subject) setSubMessageText(message.subject);
    }
  }, [message, location.key, isMapOpen]);

  // Options Handler - start

  const copyText = () => {
    if (selectedChat?.text) {
      navigator.clipboard.writeText(decode(selectedChat?.text));
      setCopyState(GreenCheck);

      setTimeout(() => {
        setSelectedChat(null);
        setOptionsOpen(false);
        setCopyState(Copy);
      }, 1000);
    }
  };

  const forward = () => {
    if (!selectedChat) return;
    if (message && selectedChat.id !== message.id) {
      navigate('forward', { state: { selectedReply: selectedChat } });
    } else {
      dispatch(setForwardedMessageID(selectedChat.id));
      navigate('forward');
    }
  };

  const deleteReply = () => {
    if (message && selectedChat) {
      dispatch(deleteAReply(message.id, selectedChat.id));
      setSelectedChat(null);
      setOptionsOpen(false);
    }
  };

  const dismissReplyToMessage = () => {
    setReplyMessageState(undefined);
  };

  const replyToMessage = () => {
    if (message && selectedChat) {
      setEditText(undefined);
      setReplyMessageState({
        messageIcon: generateReplyToMessageIcon(selectedChat),
        messageText: generateReplyToMessageBodyText(selectedChat),
        senderName: selectedChat.senderName,
        messageId: selectedChat.id,
      });
      setOptionsOpen(false);
    }
  };

  const editMessage = () => {
    if (message && selectedChat) {
      setReplyMessageState(undefined);
      setEditText(decode(selectedChat.text));
      setOptionsOpen(false);
    }
  };

  const handleReadReceipts = () => {
    if (message && selectedChat) {
      navigate(`read-receipts/${selectedChat.id}`, {
        state: { prevPath: location.pathname },
      });
    }
  };

  // Options Handler - end
  // is user admin in at least one group in the associated groups
  const isUserAdmin = compact(message?.groups?.map(group => group.admin)).length !== 0;
  const isMessageSender = message?.senderID === user?.id;

  const canReplyWhenBlocked =
    isUserAdmin || isMessageSender || roles?.includes('ChangeMessageType');

  const isRepliesEmpty = replies.length === 0;
  const isNoReplyTypeInitially =
    message?.replyType === CreateMessageReplyType.noReply && isRepliesEmpty;

  const isBlocked = message?.replyType === CreateMessageReplyType.noReply && !canReplyWhenBlocked;
  const canReply = !(
    isNoReplyTypeInitially ||
    isBlocked ||
    message?.type === ReceivedMessageType.Broadcast
  );

  const isBroadcastMessage = message?.type === ReceivedMessageType.Broadcast;
  const isLogNoteMessage = message?.type === ReceivedMessageType.LogFile;
  const canHoldingStatementInteract = isHoldingStatementMessage ? fromHoldingStatement : true;
  const isAlarmMessage = selectedChat?.type === ReceivedMessageType.Alarm;
  const isActiveAlarm = isAlarmMessage && !(selectedChat as ChatType)?.ended;
  const isSender = selectedChat?.senderID === user?.id;
  const isReply = (selectedChat as ChatType)?.replies === undefined;
  const showChatBox = editText?.length || (canHoldingStatementInteract && canReply);

  // const hasImages = selectedChat?.photoFileName || selectedChat?.photoFileNames?.length > 0
  // no longer needed for now since message can be copied if it has text regardless of images
  const hasText = !!selectedChat?.text?.trim();

  const isUserAllowedToDeleteAlarms = true;
  // according to: https://www.notion.so/catsycatstudio/Functionality-in-messages-for-features-21c093f5335f4a15aea804e6156165e9
  // not specified what is "relevant permission"
  // todo: update with the relevant permission property
  const canDelete =
    isReply && isSender && !(isLogNoteMessage || isActiveAlarm || !isUserAllowedToDeleteAlarms);
  const canEdit =
    isSender && !(isBroadcastMessage || isHoldingStatementMessage || isAlarmMessage || !hasText);
  // check if the linked group is hidden , if so disable reply to the (reply or message)
  const canForward = true;
  const canCopy = hasText && !(isAlarmMessage && !isReply);
  const canReadReceipts = !(isBroadcastMessage || isLogNoteMessage);
  const isAssociatedGroupHidden =
    !!message?.groups?.length && message.groups[0].type === GroupType.Hidden;

  const canReplyToMessage = message
    ? !(
        isBroadcastMessage ||
        isHoldingStatementMessage ||
        isAssociatedGroupHidden ||
        [CreateMessageReplyType.senderOnly, CreateMessageReplyType.noReply].includes(
          message?.replyType
        )
      )
    : false;

  const showBlockingAlarmBanner = message?.hasBlockingAlarm && !message?.recalled;

  const showOnCallAlertTopBar = isOnCallAlertMessage && !message?.OnCallAlert?.deleted;
  const showIamOkTopBar = isIamOkMessage && !message?.musterDeleted;

  // the order of keys here means the order of options in the menu
  /* eslint-disable @typescript-eslint/no-shadow */
  // we don't care if OptionIds property is equal to existing const since we don't call them without OptionIds name
  enum OptionIds {
    ReplyToMessage,
    Forward,
    Edit,
    ReadReceipts,
    Copy,
    Delete,
  }
  /* eslint-enable @typescript-eslint/no-shadow */

  const options: { [K in OptionIds]: OptionItemProps } = {
    // the order of objects here does not matter now, since keys are used in optionsToShow
    [OptionIds.ReplyToMessage]: {
      name: 'message_reply',
      icon: ReplyIcon,
      callback: replyToMessage,
    },
    [OptionIds.Forward]: {
      name: 'forward_message_text',
      icon: Forward,
      callback: forward,
    },
    [OptionIds.Edit]: {
      name: 'messages_edit',
      icon: Pencil,
      callback: editMessage,
    },
    [OptionIds.ReadReceipts]: {
      name: 'message_read_receipts',
      icon: ReadReceiptsIcon,
      callback: handleReadReceipts,
    },
    [OptionIds.Copy]: {
      name: 'messages_copy',
      icon: copyState,
      callback: copyText,
    },
    [OptionIds.Delete]: {
      name: 'messages_delete',
      icon: Delete,
      callback: deleteReply,
    },
  };

  const optionsToShow = () => {
    const returnedOptions: OptionItemProps[] = [];
    if (canForward) returnedOptions.push(options[OptionIds.Forward]);
    if (canEdit) returnedOptions.push(options[OptionIds.Edit]);
    if (canCopy) returnedOptions.push(options[OptionIds.Copy]);
    if (canDelete) returnedOptions.push(options[OptionIds.Delete]);
    if (canReplyToMessage) returnedOptions.push(options[OptionIds.ReplyToMessage]);
    if (canReadReceipts) returnedOptions.push(options[OptionIds.ReadReceipts]);
    return returnedOptions;
  };

  const playAudio = (_id: number | null) => {
    setActiveAudio(_id);
  };

  const editChatMessage = (text: string, reply: boolean) => {
    if (message?.id) {
      if (!reply) {
        dispatch(editMessageAction(text, message?.id));
      } else {
        dispatch(editMessageAction(text, message?.id, selectedChat?.id));
      }
      setSelectedChat(null);
      setEditText(undefined);
    }
  };

  const replyToChatMessage = (
    textMessage?: string,
    photoFiles: string[] = [],
    documentFiles: string[] = [],
    audioFiles: string[] = [],
    locationId?: number
  ) => {
    if (message) {
      const messageModel: MessageReplyModel = {
        messageId: message.id,
        text: textMessage,
        photoFileNames: photoFiles,
        audioFileNames: audioFiles,
        documentFileNames: documentFiles,
        locationId: locationId,
      };

      if (replyMessageState) {
        if (replyMessageState?.messageId === message.id) {
          messageModel.replyToInitialMessage = true;
        } else {
          messageModel.replyId = replyMessageState?.messageId;
        }

        setSelectedChat(null);
        setReplyMessageState(undefined);
      }
      dispatch(replyToMessageAction(messageModel));
    }
  };

  const onPhotosSend = async (imagesList: string[], messageText: string) => {
    if (imagesList.length > 0) {
      const text = messageText && messageText.length > 0 ? messageText : undefined;
      replyToChatMessage(text, imagesList);
    }
  };

  const onDocumentsSend = async (documentsList: File[], messageText: string) => {
    const documentsFilesNamesArray = [];
    let document: File;
    for await (document of documentsList) {
      const formData = new FormData();
      formData.append('document', document);
      const result = await saveDocumentToServer(formData);
      if (result) {
        documentsFilesNamesArray.push(result);
      }
    }
    const text = messageText && messageText.length > 0 ? messageText : undefined;
    if (documentsFilesNamesArray.length > 0) {
      replyToChatMessage(text, [], documentsFilesNamesArray);
    }
  };

  const changeRepliesPage = async () => {
    if (
      firstReplyRef.current &&
      firstReplyRef.current.getBoundingClientRect().y > 0 &&
      !areRepliesLoading &&
      !areAllRepliesLoaded
    ) {
      dispatch(fetchCurrentChatReplies(replies.length, REPLIES_PER_PAGE, scrollToRef));
    }
  };

  const scrolled = () => {
    changeRepliesPage();
    if (!messagesEndRef.current || !scrollBtnRef.current) {
      return;
    }
    const diff =
      messagesEndRef.current.getBoundingClientRect().y -
      scrollBtnRef.current.getBoundingClientRect().y;
    if (diff > 250) {
      scrollBtnRef.current.style.visibility = 'visible';
      scrollBtnRef.current.style.opacity = '1';
    } else {
      scrollBtnRef.current.style.visibility = 'hidden';
      scrollBtnRef.current.style.opacity = '0';
    }
  };

  const onWheel = async () => {
    changeRepliesPage();
  };

  const handleScrollDown = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onBannerLinkClick = () => {
    setIsBlockedBottomSheetOpen(true);
  };

  const renderScrollDownBtn = () => {
    return (
      <SScrollDownBtw ref={scrollBtnRef} onClick={handleScrollDown} role="scrollDownButton">
        <ScrollDownArrow role="arrowDown" />
      </SScrollDownBtw>
    );
  };

  if (!message || message.id !== Number(id) || loading || !user) {
    return (
      <SChat id="chat">
        <Loader />
      </SChat>
    );
  }

  return (
    <SChat id="chat">
      {renderScrollDownBtn()}

      {showIamOkTopBar && (
        <ChatScreenTopBar
          link={`/muster/${message.musterID}`}
          title={message?.ended ? t('imOk_ended') : t('imOk_updatedStatus')}
          isGrayColor={message?.ended || message?.goodStatus || false}
        />
      )}

      {showOnCallAlertTopBar && (
        <ChatScreenTopBar
          link={`/oncall/${message.onCallAlertID}`}
          title={message?.ended ? t('onCallAlert_ended') : t('imOk_updatedStatus')}
          isGrayColor={message?.ended || message?.goodStatus || false}
          endSrc={CallAlertPhone}
        />
      )}

      {showBlockingAlarmBanner && (
        <BlockedStateBanner
          titleTx="messages_alarm_blocked_popup"
          linkTx="messages_alarm_blocked_popup_learn"
          onLinkClick={onBannerLinkClick}
          iconSource={BlockedAlarmIcon}
        />
      )}

      {showBlockingAlarmBanner && (
        <BlockedStateBottomSheet
          isOpen={isBlockedBottomSheetOpen}
          setIsOpen={setIsBlockedBottomSheetOpen}
        />
      )}
      {message && (
        <MessageRefsContextProvider>
          <SChatContainer
            topPadding={showIamOkTopBar || showOnCallAlertTopBar}
            onScroll={scrolled}
            onWheel={onWheel}
            role="chatContainer"
          >
            {renderMapModal()}
            {areAllRepliesLoaded && (
              <MessageItem
                key={message.id}
                alert={message.type === 2}
                message={message}
                onPlayClick={playAudio}
                isAudioActive={message.id === activeAudio}
                toggleOptions={() => {
                  setSelectedChat(message);
                  setOptionsOpen(!optionsOpen);
                }}
                onMapClick={toggleMapModal}
              />
            )}
            {message.hasBlockingAlarm && message.type === ReceivedMessageType.Alarm && (
              <AlarmBlockedStateSeparator
                key={`${message?.id}-alarm-blocked-separator`}
                isActivated
              />
            )}

            {areRepliesLoading && (
              <SRepliesLoader>
                <Loader size={40} />
              </SRepliesLoader>
            )}
            <div ref={firstReplyRef}></div>
            {replies.map((reply: Reply, index) => {
              const thisReplySenderID = reply.senderID;

              const prevReply = index > 0 ? replies[index - 1] : null;
              let previousSenderID: number | null = message.senderID;
              if (prevReply) {
                previousSenderID = prevReply.deleted ? null : prevReply.senderID;
              }

              const isThreadBLockStateSeparator =
                reply.purpose === ReplyPurpose.blockedComment ||
                reply.purpose === ReplyPurpose.unBlockedComment;
              const isThreadBlocked = reply.purpose === ReplyPurpose.blockedComment;

              const isAlarmBlockedSeparator =
                message.hasBlockingAlarm &&
                (reply.purpose === ReplyPurpose.crisis || reply.emergencyRecall);
              const isAlarmEndedReply = reply.emergencyRecall;

              if (isThreadBLockStateSeparator)
                return (
                  // eslint-disable-next-line react/jsx-key
                  <div>
                    <BlockedStateSeparator
                      key={`${reply.id}${index}`}
                      isBlocked={isThreadBlocked}
                    />
                  </div>
                );

              if (isAlarmEndedReply) {
                return (
                  <div key={`${reply.id}-${index}`}>
                    <Disclaimer
                      subject={message.emergencyTypeName ?? ''}
                      by={reply.senderName}
                      byLink={`/message-details/${message.id}/memberSettings/${reply.senderID}`}
                      byLinkState={{ prevPath: location.pathname }}
                      isSameUser={reply.senderID === user.id}
                    />
                    {isAlarmBlockedSeparator && (
                      <AlarmBlockedStateSeparator key={`${reply.id}${index}`} isActivated={false} />
                    )}
                  </div>
                );
              }
              const isSameSender =
                isReplyInSameDay(message, reply, index, replies) &&
                thisReplySenderID === previousSenderID;
              return (
                <div key={reply.id}>
                  <ChatReply
                    reply={reply}
                    userId={user.id}
                    isSameDay={isReplyInSameDay(message, reply, index, replies)}
                    isSameSender={isSameSender}
                    emergencyTypeName={message.emergencyTypeName}
                    setShowBottomTabs={setShowBottomTabs}
                    onPlayClick={playAudio}
                    isAudioActive={reply.id === activeAudio}
                    toggleOptions={() => {
                      setSelectedChat(reply);
                      setOptionsOpen(!optionsOpen);
                    }}
                    onMapClick={toggleMapModal}
                    hasReply={checkHasReply(reply)}
                    ReplyToMessage={generateMessageReplyToMessage(reply, message)}
                  />
                </div>
              );
            })}
            <br />
            <div ref={messagesEndRef} />
            {areFilesSending && (
              <SFilesLoaderWrapper>
                <Loader />
              </SFilesLoaderWrapper>
            )}
          </SChatContainer>
          {showChatBox && (
            <ChatBox
              setEditText={setEditText}
              editText={editText}
              // replying
              replyMessage={replyMessageState}
              dismissReplyToMessage={dismissReplyToMessage}
              //---------
              tabBar={showBottomTabs}
              setTabBar={setShowBottomTabs}
              onTextSend={
                editText
                  ? text => {
                      editChatMessage(text, isReply);
                    }
                  : replyToChatMessage
              }
              onPhotosSend={onPhotosSend}
              onDocumentsSend={onDocumentsSend}
              onLocationSend={(locationId, text) =>
                replyToChatMessage(text, [], [], [], locationId)
              }
            />
          )}
          <Options
            renderHeader={() => (
              <MessageOptionsReactionsSelectionRow
                selectedMessageId={selectedChat?.id}
                handleClose={() => setOptionsOpen(false)}
              />
            )}
            items={optionsToShow()}
            isOpen={optionsOpen}
            setIsOpen={setOptionsOpen}
          />
          <Modal isOpen={showLoadingReplies} setIsOpen={() => {}}>
            <Loader />
          </Modal>
          <MessageReactionsBottomSheet />
        </MessageRefsContextProvider>
      )}
    </SChat>
  );
};
