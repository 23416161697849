import { GetChecklistProps } from '../../apis/checklistsAPI/types';
import { CheckListFilter } from './Checklists.models';
import { Checklist } from './checklistsSlice/types';

export interface ChecklistAccessEditPrepareData {
  checklist: Checklist;
  selectedGroups: number[];
  selectedUsers: number[];
  singleSelectedUsers: number[];
}

export const transformCheckListFilters = (filters: CheckListFilter): GetChecklistProps => {
  const initialExpected: Required<Pick<GetChecklistProps, 'my' | 'others' | 'shared'>> = {
    my: false,
    others: false,
    shared: false,
  };

  const reduceCheckListType = Array.from(filters.checklistsType).reduce((transformed, type) => {
    return { ...transformed, [type]: true };
  }, initialExpected);

  return {
    sort: filters.sort,
    ...reduceCheckListType,
  };
};

export const prepareChecklistAccessEditData = (data: ChecklistAccessEditPrepareData) => {
  const { checklist, selectedGroups, selectedUsers, singleSelectedUsers } = data;

  // eslint-disable-next-line no-unsafe-optional-chaining
  const unselectedGroupsIds = [...checklist?.sharedGroups].filter(
    groupId => !selectedGroups.find(selectedGroupId => selectedGroupId === groupId)
  );
  const selectedGroupIds = [...selectedGroups].filter(
    groupId => !checklist?.sharedGroups.includes(groupId)
  );

  // Get all selected users from checklist
  let allChecklistsSelectedUsers = checklist?.userSharesViaGroups
    ? // eslint-disable-next-line no-unsafe-optional-chaining
      [...checklist?.userSharesViaGroups]
    : [];

  const directUserShares = checklist?.directUserShares ?? [];
  if (directUserShares.length) {
    allChecklistsSelectedUsers = [...allChecklistsSelectedUsers, ...directUserShares];
  }

  const unSelectedUsersIds = [...allChecklistsSelectedUsers]
    .filter(user => !selectedUsers.includes(user.id))
    .map(user => user.id);

  const selectedUsersIds = [...singleSelectedUsers].filter(
    userId => !directUserShares.find(u => u.id === userId)
  );

  return {
    checklistID: Number(checklist.id),
    selectedUsersIds,
    unSelectedUsersIds,
    selectedGroupIds,
    unselectedGroupsIds,
  };
};
