import isElectron from 'is-electron';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import message from '../../assets/icons/envelope-with-pen.svg';
import EndOnCallAlertIcon from '../../assets/icons/cross.svg';
import Delete from '../../assets/icons/trash-can--white.svg';
import Edit from '../../assets/icons/pen-with-line-under.svg';
import Loader from '../../components/Loader/Loader';
import OnCallAlertMessage from '../../components/OnCallAlert/OnCallAlertMessage';
import OnCallAlertResponseButtonGroup from '../../components/OnCallAlert/OnCallAlertResponseButtonGroup';
import { OnCallAlertResponseList } from '../../components/OnCallAlert/OnCallAlertResponseList';
import OnCallAlertStatusMessage from '../../components/OnCallAlert/OnCallAlertStatusMessage';
import { OptionItemProps } from '../../components/Options/Options';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { OnCallAlertkMessageMembersType } from '../../routes/OnCallAlert';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import { OnCallAlertStatusType } from '../../utils/enums';
import { getItem } from '../../utils/storage';
import { translate } from '../../utils/translate';
import { checkOnCallAlertIfNotZeroMembers } from '../OnCallAlertList/createMessageOnCallAlertSummary.helpers';
import { SOptions, SPage } from '../OnCallAlertList/on-call-alert.styles';
import {
  isOnCallAlertBottomModalOpen,
  selectOnCallAlertDocument,
  selectOnCallAlertIsLoading,
  setShowOnCallAlertBottomModal,
} from '../OnCallAlertList/onCallAlertSlice';
import {
  deleteOnCallAlertMessage,
  fetchOnCallAlertDocument,
  setOnCallAlertEnd,
  toggleOnCallAlertBottomModal,
} from '../OnCallAlertList/onCallAlertSlice/actionCreators';

const OnCallAlertDocument = (props: {
  setShowDots: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { setShowDots } = props;
  const confirm = useConfirmation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    setTabsState,
    setMessage: setHeaderTitle,
    setSubTitle: setHeaderSubTitle,
  } = useLayoutContext();

  const [activeButton, setActiveButton] = useState(OnCallAlertStatusType.NotAvailable);
  const MINUTE_MS = 10000;
  const [createMessagesModalOpen, setCreateMessagesModalOpen] = useState(false);
  const onCallAlertDocument = useAppSelector(selectOnCallAlertDocument);
  const isLoading = useAppSelector(selectOnCallAlertIsLoading);
  const user = getItem('user');

  const hasOnCallAlertRole = user?.roles?.includes('OnCallAlerts');
  const isEnded = onCallAlertDocument?.ended;
  const isSameSender = onCallAlertDocument?.senderId === user?.id;

  const showSettingsModal = useAppSelector(isOnCallAlertBottomModalOpen);

  const [messageHeight, setMessageHeight] = useState<number | undefined>();
  const [buttonsGroupHeight, setButtonsGroupRef] = useState<number | undefined>();

  const [messagToggled, setMessagToggled] = useState<boolean>(false);

  const measuredButtonGroupsRef = useCallback(node => {
    if (node !== null) {
      setButtonsGroupRef(node.getBoundingClientRect().height);
    }
  }, []);
  const measuredMessageRef = useCallback(
    node => {
      if (node !== null) {
        setMessageHeight(node.getBoundingClientRect().height);
      }
    },
    [messagToggled]
  );

  const fetchOnCallAlert = async () => {
    let id = window.location.pathname.split('/').pop();
    if (isElectron()) {
      id = window.location.hash.split('/').pop();
    }
    dispatch(fetchOnCallAlertDocument(Number(id)));
  };

  useEffect(() => {
    if (!isEnded) {
      const interval = setInterval(() => {
        fetchOnCallAlert();
      }, MINUTE_MS);

      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    fetchOnCallAlert();
  }, []);

  useEffect(() => {
    setHeaderTitle(onCallAlertDocument?.subject || translate('onCallAlert_screen'));
    setHeaderSubTitle(onCallAlertDocument?.groupName || undefined);
    return () => {
      setHeaderTitle(undefined);
      setHeaderSubTitle(undefined);
    };
  }, [onCallAlertDocument?.subject, onCallAlertDocument?.groupName]);

  const reloadData = () => {
    fetchOnCallAlert();
  };

  const handleEnd = () => {
    confirm({
      title: 'confirmLeaveGroupTitle',
      description: 'onCallAlert_endText',
      onSubmit: () => {
        if (onCallAlertDocument) dispatch(setOnCallAlertEnd(onCallAlertDocument.id));
        dispatch(toggleOnCallAlertBottomModal(false));
      },
      onCancel: () => {
        dispatch(toggleOnCallAlertBottomModal(false));
      },
      confirmText: 'imOk_end_modal',
      cancelText: 'cancel',
    });
  };

  const handleDelete = () => {
    confirm({
      title: 'messages_confirmation',
      description: 'onCallAlert_delete_modal_description',
      onSubmit: () => {
        if (onCallAlertDocument) {
          dispatch(deleteOnCallAlertMessage(onCallAlertDocument.id, navigate));
        }
        dispatch(toggleOnCallAlertBottomModal(false));
      },
      onCancel: () => {
        dispatch(toggleOnCallAlertBottomModal(false));
      },
      confirmText: 'delete',
      cancelText: 'cancel',
    });
  };

  const openCreateMessagesModal = () => {
    dispatch(toggleOnCallAlertBottomModal(false));
    setCreateMessagesModalOpen(false);
  };

  const usersNotAvailable =
    onCallAlertDocument?.users.filter(u => u.status === OnCallAlertStatusType.NotAvailable) || [];
  const usersAvailable =
    onCallAlertDocument?.users.filter(u => u.status === OnCallAlertStatusType.Available) || [];
  const usersNoStatus =
    onCallAlertDocument?.users.filter(u => u.status === OnCallAlertStatusType.NoStatus) || [];

  const contactMemberByType = (type: OnCallAlertkMessageMembersType) => {
    dispatch(setShowOnCallAlertBottomModal(false));
    setCreateMessagesModalOpen(false);
    if (onCallAlertDocument && !checkOnCallAlertIfNotZeroMembers(type, onCallAlertDocument)) {
      setTabsState(false);
      navigate(`createMessageSummary/${type}`);
    } else {
      confirm({
        title: 'cec_noMembers',
        description: 'cec_noMembers',
        onSubmit: () => {},
        confirmText: 'done',
        cancelText: 'cancel',
      });
    }
  };

  const sendMessagesModalButtons = [
    {
      name: 'imOk_contact_all',
      callback: () => contactMemberByType('ALL'),
      hidden: false,
    },
    {
      name: 'onCallAlert_contact_no_participate',
      callback: () => contactMemberByType('NOT_AVAILANLE'),
      hidden: usersNotAvailable.length === 0,
    },
    {
      name: 'onCallAlert_contact_can_participate',
      callback: () => contactMemberByType('AVIALABLE'),
      hidden: usersAvailable.length === 0,
    },
    {
      name: 'onCallAlert_contact_noAnswer',
      callback: () => contactMemberByType('NO_STATUS'),
      hidden: usersNoStatus.length === 0,
    },
  ];

  const settingsModal: OptionItemProps[] | { hidden: boolean } = [
    {
      name: 'onCallAlert_end',
      callback: () => handleEnd(),
      icon: EndOnCallAlertIcon,
      hidden: !hasOnCallAlertRole || !isSameSender || isEnded,
    },
    {
      name: 'imOk_contact',
      callback: () => setCreateMessagesModalOpen(true),
      icon: message,
      hidden: !hasOnCallAlertRole || !isSameSender || isEnded,
    },
    {
      name: 'messages_edit',
      callback: () => {
        navigate('edit');
        dispatch(toggleOnCallAlertBottomModal(false));
      },
      icon: Edit,
      hidden: !hasOnCallAlertRole || !isSameSender || isEnded,
    },
    {
      name: 'messages_delete',
      callback: () => handleDelete(),
      icon: Delete,
      hidden: !hasOnCallAlertRole || !isSameSender,
      disabled: !isEnded,
      disabledTx: 'onCallAlert_block_delete_decription',
    },
  ];

  const userFromList = onCallAlertDocument?.users.find(item => item.userId === user.id);

  const hideResponseButtonsForUser = !userFromList;
  const userIsImOkCreator = onCallAlertDocument?.senderId === user.id;

  const filteredShowSettingsModal = settingsModal.filter((item: OptionItemProps) => !item?.hidden);
  const filteredSendMessagesModalButtons = sendMessagesModalButtons.filter(item => !item.hidden);

  useEffect(() => {
    if (filteredShowSettingsModal.length === 0) {
      setShowDots(false);
    } else {
      setShowDots(true);
    }
  }, [filteredShowSettingsModal.length]);

  if (!onCallAlertDocument && isLoading) return <Loader />;
  return (
    <SPage $paddingBottom={buttonsGroupHeight}>
      <SOptions
        isOpen={showSettingsModal || createMessagesModalOpen}
        setIsOpen={() => openCreateMessagesModal()}
        setTabBar
        items={
          createMessagesModalOpen ? filteredSendMessagesModalButtons : filteredShowSettingsModal
        }
      />
      {onCallAlertDocument && (
        <OnCallAlertMessage
          onMessagedToggled={setMessagToggled}
          ref={measuredMessageRef}
          onCallAlertDocument={onCallAlertDocument}
        />
      )}
      {onCallAlertDocument && (
        <OnCallAlertResponseList
          messageHeight={messageHeight}
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          onCallAlertDocument={onCallAlertDocument}
        />
      )}

      {onCallAlertDocument && !isEnded && (userIsImOkCreator || !hideResponseButtonsForUser) && (
        <OnCallAlertResponseButtonGroup
          contactMemberByType={contactMemberByType}
          onCallAlertDocument={onCallAlertDocument}
          reloadData={() => reloadData()}
          hideResponseButtonsForUser={hideResponseButtonsForUser}
          userFromList={userFromList}
          userIsImOkCreator={userIsImOkCreator}
          setCreateMessagesModalOpen={setCreateMessagesModalOpen}
          ref={measuredButtonGroupsRef}
        />
      )}
      {isEnded && <OnCallAlertStatusMessage ref={measuredButtonGroupsRef} />}
    </SPage>
  );
};

export default OnCallAlertDocument;
