import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { MeetingRoom, MeetingRoomState } from './types';

const initialState: MeetingRoomState = {
  meetingRooms: [],
  isLoading: false,
};

const MeetingRoomSlice = createSlice({
  name: 'meetingRoom',
  initialState,
  reducers: {
    setMeetingRooms: (state, action: PayloadAction<MeetingRoom[]>) => {
      state.meetingRooms = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setMeetingRooms, setIsLoading } = MeetingRoomSlice.actions;

export const getMeetingRooms = (state: RootState) => state.meetingRoom.meetingRooms;
export const getIsLoading = (state: RootState) => state.meetingRoom.isLoading;

export default MeetingRoomSlice.reducer;
