import { batch } from 'react-redux';
import { setIsLoading, setError, setOrgs, removeOrg, setSelectedOrgs, setBroadcastMsg } from '.';
import { AppThunk } from '../../../store';
import { getOrganizations, postBroadcastMessage } from '../../../apis/broadcast';
import { CreateMessageModel } from '../../Chat/models';
import { NavigateFunction } from 'react-router-dom';
import { setAreFilesSending } from '../../ChatsList/chatListSlice';
import { Chat, Org } from '../../ChatsList/chatListSlice/types';

export const fetchOrganizations = (): AppThunk => async dispatch => {
  try {
    dispatch(setIsLoading(true));
    const orgs = await getOrganizations();
    batch(() => {
      dispatch(setOrgs(orgs));
      dispatch(setIsLoading(false));
    });
  } catch (error) {
    console.log('error log ', error);
    batch(() => {
      dispatch(setIsLoading(false));
      dispatch(setError(`${error}`));
    });
  }
};

export const setSelectedOrgsAction =
  (orgs: Org[]): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      batch(() => {
        dispatch(setSelectedOrgs(orgs));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setError(`${error}`));
      });
    }
  };

export const removeSelectedOrgAction =
  (id: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      batch(() => {
        dispatch(removeOrg(id));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setError(`${error}`));
      });
    }
  };

const setBroadcastMsgAction =
  (msg: Chat): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      batch(() => {
        dispatch(setBroadcastMsg(msg));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setError(`${error}`));
      });
    }
  };

export const sendBroadcastMsg =
  (messageModel: CreateMessageModel, navigate: NavigateFunction): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      const msg = await postBroadcastMessage(messageModel);
      if (msg) {
        batch(() => {
          dispatch(setBroadcastMsgAction(msg));
          dispatch(setIsLoading(false));
          dispatch(setAreFilesSending(false));
        });
        navigate(`/message/${msg.id}`);
      }
    } catch (error) {
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setError(`${error}`));
      });
    }
  };
