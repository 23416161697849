import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import { Backdrop } from '../Backdrop/Backdrop';
import { SCancel, SOption, SOptions, SOptionsList } from './Options.styles';

export interface OptionItemProps {
  id?: number;
  name?: string | number;
  icon?: string;
  callback?: () => void;
  disabled?: boolean;
  disabledTx?: string;
  hidden?: boolean;
}

export interface OptionProps {
  items: OptionItemProps[];
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>> | ((isOpen: boolean) => void);
  setTabBar?: boolean;
  setChatBar?: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string | undefined;
  renderInfo?: () => ReactNode;
  renderHeader?: () => ReactNode;
  withoutOptions?: boolean;
  maxHeight?: boolean;
}

// TODO: Create portal like in ConfirmDialog.tsx to avoid nesting issues. If changing, test out eveywhere
const Options = (props: OptionProps) => {
  const {
    isOpen,
    setIsOpen,
    items,
    setTabBar,
    setChatBar,
    className,
    renderInfo,
    renderHeader,
    withoutOptions = false,
    maxHeight = true,
  } = props;

  const layout = useLayoutContext();

  useEffect(() => {
    if (setTabBar) layout.setTabsState(!isOpen);
    if (setChatBar) setChatBar(!isOpen);
  }, [isOpen, setTabBar]);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const { t } = useTranslation();

  if (!isOpen) return <></>;

  return (
    <>
      <SOptions className={className} haveMaxHeight={maxHeight} role="optionsContainer">
        <>
          {renderInfo && renderInfo()}
          {!withoutOptions && (
            <SOptionsList className="SOptionsList" role="optionsList">
              <>
                {renderHeader && renderHeader()}
                {items.map((item, key) => (
                  <SOption
                    disabled={item.disabled}
                    $disabled={item.disabled}
                    onClick={item.callback}
                    key={key}
                    role={`option-${item.name}`}
                  >
                    <div className="optionTitleContainer">
                      <p role="optionName">{t(`${item.name}`)}</p>{' '}
                      {item.disabled && (
                        <p className="disabledDescription">{t(item.disabledTx!)}</p>
                      )}
                    </div>
                    <img
                      id={`options_item_${item.name}`}
                      src={item.icon}
                      alt=""
                      role="optionIcon"
                    />
                  </SOption>
                ))}
              </>
            </SOptionsList>
          )}
          <SCancel onClick={handleCancel} className="cancel" role="cancelButton">
            <p role="cancelButtonText">{t(`cancel`)}</p>
          </SCancel>
        </>
      </SOptions>
      <Backdrop setModal={setIsOpen} />
    </>
  );
};

export default Options;
