/* eslint-disable @typescript-eslint/naming-convention */

import * as React from 'react';
import { palette } from '../../theme/colors';
import Drawer from 'react-bottom-drawer';
import { useSelectlist } from '../../utils/customHooks/useSelectList';
import { translate } from '../../utils/translate';
import { AccountList } from '../../containers/Documents/helpers';
import { isAccountSelectedAllOrPartial } from '../../common/groupsHelpers';
import { ArrowCollapsibleCard } from '../ArrowCollapsibleCard/ArrowCollapsibleCard';
import { CollapsibleCheckBox } from '../CollapsibleCheckBox/CollapsibleCheckBox';
import {
  SButton,
  SCheckBoxWithSubTitle,
  SCustomList,
  SDrawerWrapper,
  SMainTitle,
  SOverriddenTitle,
} from './DocumentFilter.styles';
import { getSelectedDataValue } from './helpers';
import { ResetFiltersButton } from '../Button/ResetFiltersButton';
import { checkIfFiltersApplied } from '../../utils/customHooks/useLocalStorageFilters';

export type SelectedAllType = 'SELECTED_ALL' | 'UNSELECTED_ALL';
export type MemberFilterType = ('MEMBER' | 'NOT_MEMBER')[];

export interface DataSelectableListType {
  id: number;
  name: string;
  imageName?: string;
}

export interface DocumentFilters {
  // selectedData should be Groups ids ids for Documents list filters
  // selectedData should be Accounts for Old documents list filters (EX: currently still visible on manageOffline page)
  selectedData?: Set<number> | SelectedAllType;
  // showEmptyFolders should mean actual folders for Documents list filters
  // showEmptyFolders should mean actual groups folders for Old documents list filters (EX: currently still visible on manageOffline page)
  showEmptyFolders: boolean;
  memberFilter?: MemberFilterType;
}
export interface DocumentFiltersV2 extends DocumentFilters {
  showFilesOnly: boolean;
}

interface IDocumentFilterProps<T> {
  data?: T[];
  displayData: AccountList;

  initialShowEmptyFolders: boolean;
  initialShowFilesOnly: boolean;
  initialMemberFilter?: Set<'MEMBER' | 'NOT_MEMBER'>;
  initialSelectedData?: Set<number>;

  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

  onFilter: (filters: DocumentFiltersV2) => void;
  defaultFilters: DocumentFiltersV2;
  resetStaged: () => void;
  onChange?: (filters: DocumentFiltersV2) => void;
}

export const DocumentFilterV2 = <T extends DataSelectableListType>(
  props: IDocumentFilterProps<T>
) => {
  const {
    isOpen,
    setIsOpen,

    onFilter,
    onChange,
    resetStaged,

    data,

    initialShowEmptyFolders,
    initialShowFilesOnly,
    initialMemberFilter,
    initialSelectedData,
    displayData,
    defaultFilters,
  } = props;

  const [showEmptyFolders, setShowEmptyFolders] = React.useState<boolean>(initialShowEmptyFolders);
  const [stagedShowEmptyFolders, setStagedShowEmptyFolders] =
    React.useState<boolean>(initialShowEmptyFolders);

  const [showFilesOnly, setShowFilesOnly] = React.useState<boolean>(initialShowFilesOnly);
  const [stagedShowFilesOnly, setStagedShowFilesOnly] =
    React.useState<boolean>(initialShowFilesOnly);

  const {
    handleSelect: handleMemberSelect,
    stagedSelectedItems: stagedMemberSelectedItems,
    onFinishSelecting: onFinishMemberSelecting,
    setStagedSelectedItems: setStagedMemberSelect,
  } = useSelectlist({
    data: [{ id: 'MEMBER' }, { id: 'NOT_MEMBER' }],
    multiStage: true,
    initialSelected: initialMemberFilter,
  });

  interface HandleChangeParams {
    _stagedShowEmptyFolders?: boolean;
    _stagedMemberSelectedItems?: Set<string | number>;
    _stagedFilesOnly?: boolean;
  }

  const handleChange = (params: HandleChangeParams) => {
    const { _stagedMemberSelectedItems, _stagedShowEmptyFolders, _stagedFilesOnly } = params;
    if (onChange)
      onChange({
        showEmptyFolders: _stagedShowEmptyFolders ?? stagedShowEmptyFolders,
        showFilesOnly: _stagedFilesOnly ?? stagedShowFilesOnly,
        memberFilter: Array.from(
          _stagedMemberSelectedItems ?? stagedMemberSelectedItems
        ) as MemberFilterType,
      });
  };

  const toggleEmptyFolder = () => {
    setStagedShowEmptyFolders(prev => {
      // commented for now
      // handleChange(!prev);
      handleChange({ _stagedShowEmptyFolders: !prev });
      return !prev;
    });
  };

  const applyEmptyFolderChange = (apply: boolean, _stagedShowEmptyFolders?: boolean) => {
    if (apply) {
      if (_stagedShowEmptyFolders !== undefined) setShowEmptyFolders(_stagedShowEmptyFolders);
    } else {
      setStagedShowEmptyFolders(showEmptyFolders);
    }
  };

  const toggleFilesOnly = () => {
    setStagedShowFilesOnly(prev => {
      handleChange({ _stagedFilesOnly: !prev });
      return !prev;
    });
  };

  const applyFileOnlyChange = (apply: boolean, _stagedShowFilesOnly?: boolean) => {
    if (apply) {
      if (_stagedShowFilesOnly !== undefined) setShowFilesOnly(_stagedShowFilesOnly);
    } else {
      setStagedShowFilesOnly(showFilesOnly);
    }
  };

  const {
    handleSelect,
    isStagedSelectedAll,
    isNonStagedSelected,
    stagedSelectedItems,
    onFinishSelecting,
    handleMultiSelect,
    setStagedSelectedItems,
  } = useSelectlist({
    data: data ?? [],
    multiStage: true,
    initialSelected: initialSelectedData,
  });

  const onCloseHandle = () => {
    onFinishSelecting(false);
    onFinishMemberSelecting(false);

    applyEmptyFolderChange(false);
    applyFileOnlyChange(false);

    resetStaged();
    setIsOpen(false);
  };

  const handleFilter = () => {
    onFilter({
      selectedData: getSelectedDataValue({
        isAllDataSelected: isStagedSelectedAll,
        isNoneOfDataSelected: isNonStagedSelected,
        selectedData: stagedSelectedItems,
      }),
      showEmptyFolders: stagedShowEmptyFolders,
      showFilesOnly: stagedShowFilesOnly,
      memberFilter: Array.from(stagedMemberSelectedItems) as MemberFilterType,
    });

    onFinishSelecting(true);
    onFinishMemberSelecting(true);

    applyEmptyFolderChange(true, stagedShowEmptyFolders);
    applyFileOnlyChange(true, stagedShowFilesOnly);
  };

  const displayResetButton = checkIfFiltersApplied({
    defaultFilters,
    filters: {
      showEmptyFolders: stagedShowEmptyFolders,
      showFilesOnly: stagedShowFilesOnly,
      memberFilter: Array.from(stagedMemberSelectedItems) as MemberFilterType,
      selectedData: getSelectedDataValue({
        isAllDataSelected: isStagedSelectedAll,
        isNoneOfDataSelected: isNonStagedSelected,
        selectedData: stagedSelectedItems,
      }),
    },
  });

  const handleReset = () => {
    onFilter(defaultFilters);
    setStagedShowEmptyFolders(defaultFilters.showEmptyFolders);
    setStagedMemberSelect(new Set(defaultFilters.memberFilter));
    setStagedSelectedItems(new Set([]));
    setStagedShowFilesOnly(defaultFilters.showFilesOnly);
  };

  const isOnlyOneAccount = Object.keys(displayData).length === 1;
  return (
    <SDrawerWrapper $moreMaxHeight role="documentsFilterContainer">
      <Drawer className="profileDrawer" isVisible={isOpen} onClose={onCloseHandle} hideScrollbars>
        <SMainTitle role="title">{translate('document-filters-title')}</SMainTitle>
        <SOverriddenTitle role="subtitle">
          {translate('checklist_filter_groups_title')}
        </SOverriddenTitle>
        {initialMemberFilter && (
          <>
            <SCheckBoxWithSubTitle
              selected={stagedMemberSelectedItems.has('MEMBER')}
              title={translate('groups_filter_member')}
              onToggleCheck={() => {
                handleMemberSelect('MEMBER', changed =>
                  handleChange({ _stagedMemberSelectedItems: changed })
                );
              }}
              valueId={0}
              checkBoxType={'box'}
              withoutSeparator
              clickAll
              $lessPadding
            />
            <SCheckBoxWithSubTitle
              selected={stagedMemberSelectedItems.has('NOT_MEMBER')}
              title={translate('group_not_member')}
              onToggleCheck={() => {
                handleMemberSelect('NOT_MEMBER', changed =>
                  handleChange({ _stagedMemberSelectedItems: changed })
                );
              }}
              valueId={0}
              checkBoxType={'box'}
              separatorColor={palette.prussianBlue5}
              clickAll
            />
          </>
        )}
        <SCheckBoxWithSubTitle
          selected={stagedShowFilesOnly}
          title={translate('documents_show_only_documents')}
          onToggleCheck={toggleFilesOnly}
          valueId={0}
          checkBoxType={'box'}
          separatorColor={palette.prussianBlue5}
          clickAll
          $biggerFont
        />

        {displayData && (
          <ArrowCollapsibleCard title={translate('documents_show_content_of')}>
            <SCheckBoxWithSubTitle
              selected={isStagedSelectedAll}
              title={translate('documents_all_groups')}
              onToggleCheck={() => handleSelect()}
              valueId={0}
              checkBoxType={'box'}
              withoutSeparator
              clickAll
              $biggerFont
              $lessPadding={isOnlyOneAccount}
            />
            <SCustomList className="SList" role="showContentOfCardContainer">
              {Object.keys(displayData).map((accountId, index) => {
                const GroupIDs = displayData[+accountId].groups.map(g => g.id);
                const isAccountSelected = isAccountSelectedAllOrPartial(
                  GroupIDs,
                  stagedSelectedItems as Set<number>
                );
                const renderGroups = () =>
                  displayData[+accountId].groups.map((item, _index) => (
                    <SCheckBoxWithSubTitle
                      $lessPadding
                      selected={stagedSelectedItems.has(item.id)}
                      title={item.name}
                      onToggleCheck={handleSelect}
                      key={`${item.id}-${_index}`}
                      valueId={item.id}
                      checkBoxType={'box'}
                      withoutSeparator
                      withPhoto={true}
                      photoUrl={item.imageFileName}
                      clickAll
                    />
                  ));

                if (isOnlyOneAccount) return renderGroups();
                return (
                  <CollapsibleCheckBox
                    key={`${accountId}-${index}`}
                    title={displayData[+accountId].accountName}
                    selected={isAccountSelected}
                    handleSelect={() => {
                      handleMultiSelect(GroupIDs, !isAccountSelected.is);
                    }}
                  >
                    {renderGroups()}
                  </CollapsibleCheckBox>
                );
              })}
            </SCustomList>
          </ArrowCollapsibleCard>
        )}

        <SCheckBoxWithSubTitle
          selected={stagedShowEmptyFolders}
          title={translate('documents_show_empty_folders')}
          onToggleCheck={toggleEmptyFolder}
          valueId={0}
          checkBoxType={'box'}
          withoutSeparator={!data}
          separatorColor={palette.prussianBlue5}
          clickAll
        />
        <ResetFiltersButton isFullWidth onClick={handleReset} isDisplayed={displayResetButton} />
        <SButton $valid={!true} disabled={!true} tx={'messages_filter'} onClick={handleFilter} />
      </Drawer>
    </SDrawerWrapper>
  );
};
