import * as React from 'react';
import { translate } from '../../utils/translate';
import { OptionProps } from '../Options/Options';
import {
  SInfoContainer,
  SLine,
  SOptions,
  SRow,
  SSharedContainer,
} from './FilesAndFoldersOptions.styles';

export interface FilesAndFoldersOptionsInfo {
  title: string;
  size: string;
  type: string;
  creator: string;
  created: string;
  insideCount?: number;
  sharedWith: string[];
}

interface IFilesAndFoldersOptionsProps extends Omit<OptionProps, 'renderInfo'> {
  info: FilesAndFoldersOptionsInfo;
}

export const FilesAndFoldersOptions = (props: IFilesAndFoldersOptionsProps) => {
  const {
    isOpen,
    items,
    setIsOpen,
    setTabBar,
    info,

    ...rest
  } = props;
  const { created, creator, sharedWith, size, title, type, insideCount } = info;

  // renders
  const renderInfo = () => (
    <SInfoContainer role="optionsContainer">
      <p className="STitle" role="title">
        {' '}
        {title}
      </p>

      <SRow role="optionItem-size">
        <p className="SSubTitle" role="optionTitle">
          {translate('documents_size')}:
        </p>
        <p className="SSubTitleValue" role="optionValue">
          {size}
        </p>
      </SRow>
      <SRow role="optionItem-replyTo">
        <p className="SSubTitle" role="optionTitle">
          {translate('messages_replyTo')}
        </p>
        <p className="SSubTitleValue" role="optionValue">
          {type}
        </p>
      </SRow>
      {creator && (
        <SRow role="optionItem-creator">
          <p className="SSubTitle" role="optionTitle">
            {translate('modal_creator')}
          </p>
          <p className="SSubTitleValue" role="optionValue">
            {creator}
          </p>
        </SRow>
      )}
      {created && (
        <SRow role="optionItem-createdAt">
          <p className="SSubTitle" role="optionTitle">
            {translate('modal_created')}
          </p>
          <p className="SSubTitleValue" role="optionValue">
            {created}
          </p>
        </SRow>
      )}
      {insideCount !== undefined && (
        <SRow role="optionItem-documentsCount">
          <p className="SSubTitle" role="optionTitle">
            {translate('documents_document_count')}:
          </p>
          <p className="SSubTitleValue" role="optionValue">
            {insideCount}
          </p>
        </SRow>
      )}
      <SLine role="line" />
      <SRow $scroll role="sharedWithList">
        <p className="SSubTitle" role="sharedWithTitle">
          {translate('modal_shared_with')}
        </p>
        <SSharedContainer role="sharedWithContainer">
          {sharedWith.map((item, index) => (
            <p key={`${item}-${index}`} className="SSubTitleValue SharedItem" role="sharedWithItem">
              {item},{' '}
            </p>
          ))}
        </SSharedContainer>
      </SRow>
    </SInfoContainer>
  );

  return (
    <SOptions
      renderInfo={renderInfo}
      items={items}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      setTabBar={setTabBar}
      maxHeight={false}
      {...rest}
    />
  );
};
