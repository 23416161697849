import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { Button } from '../../components/Button/Button';

export const SContainer = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 3.75rem;
`;

export const STitle = styled.h1`
  font-size: 1.125rem;
  font-family: 'Roboto-Medium';
  color: ${palette.white};
  text-align: left;
`;

export const SSubTitle = styled.h2`
  font-size: 0.875rem;
  font-family: 'Roboto-Regular';
  color: ${palette.silver};
  line-height: 1.3;
  margin: 0.625rem 0rem 1.875rem;
  text-align: left;
`;

export const SStritchedSpace = styled.div`
  flex-grow: 1;
`;

export const SButton = styled(Button)`
  width: 100%;
  margin-top: 0.875rem;
  button {
    max-width: 200rem;

    width: 100%;
    font-size: 1rem;
    padding: 0.8125rem 0;
    font-family: 'Roboto-Medium';
    color: ${palette.raisinBlack3};
    margin-bottom: 3.5rem;
  }
`;
