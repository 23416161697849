import { ReactNode, useEffect, useRef, useState } from 'react';
import { MessageType } from '../../../utils/enums';
import MessageContainer from '../../MessageContainer/MessageContainer';
import PlayButton from '../../../assets/imgs/chats/play-button.svg';
import PauseButton from '../../../assets/imgs/chats/pause-button.svg';
import { getAudioLink } from '../../../utils/formatImageLink';
import axios from 'axios';
import {
  SAudioWave,
  SAudioWavesRow,
  SAudioPLayer,
  SRangeContainer,
  STime,
} from './AudioMessage.styles';

interface AudioMessageProps {
  audioFile: string;
  sentDateTime: string;
  isAudioActive: boolean;
  onPlayClick: (id: number | null) => void;
  isSent: boolean;
  isSameSender?: boolean;
  senderImage: string | null;
  senderName: string;
  messageTo: string | null;
  toggleModal?: () => void;
  messageId: number;
  showOnlyAttachment?: boolean;
  forwarded?: boolean;
  edited?: boolean;
  renderReplyToMessage?: () => ReactNode;
  id?: number;
}
const wavesCount = 50;
function AudioMessage(props: AudioMessageProps) {
  const {
    sentDateTime,
    audioFile,
    isAudioActive,
    onPlayClick,
    isSent,
    senderImage,
    isSameSender = false,
    senderName,
    messageTo,
    messageId,
    toggleModal,
    showOnlyAttachment = false,
    forwarded,
    edited,
    renderReplyToMessage,
    id,
  } = props;
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [audioBlobUrl, setAudioBlobUrl] = useState<string | null>(null);
  // references
  const audioPlayer = useRef<HTMLAudioElement>(null); // reference our audio component
  const animationRef = useRef<number>(); // reference the animation
  const audioWavesHeightsRef = useRef<number[] | null>(null);
  const audioWavesRowRef = useRef<HTMLDivElement>(null);
  const holdingRef = useRef<boolean>(false);

  const getRandomWaveHeight = () => {
    return Math.floor(Math.random() * (16 - 2 + 1)) + 2;
  };

  const initialLoad = async () => {
    audioWavesHeightsRef.current = Array.from({ length: wavesCount }, () => getRandomWaveHeight());

    // We need to get blobUrl to be able to seek audio position https://stackoverflow.com/a/63911655
    const audioLink = getAudioLink(audioFile);
    try {
      const response = await axios.get(audioLink, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'audio/mp3',
        },
      });

      const blob = new Blob([response.data], { type: 'audio/mpeg' });
      setAudioBlobUrl(URL.createObjectURL(blob));
    } catch (error) {
      console.error(error);
      setAudioBlobUrl(audioLink);
    }
  };
  useEffect(() => {
    initialLoad();
  }, []);

  const onCanPlay = () => {
    if (audioPlayer.current) {
      const seconds = Math.floor(audioPlayer.current.duration);
      setDuration(seconds);
    }
  };

  const resetAudio = () => {
    setCurrentTime(0);
    if (audioPlayer.current) {
      audioPlayer.current.currentTime = 0;
    }
    setIsAudioPlaying(false);
  };

  const pauseTrack = () => {
    if (audioPlayer.current) {
      audioPlayer.current.pause();
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      setIsAudioPlaying(false);
    }
  };

  useEffect(() => {
    if (!isAudioActive) {
      if (isAudioPlaying) {
        pauseTrack();
      }
      return;
    }
  }, [isAudioActive]);

  const whilePlaying = () => {
    if (audioPlayer.current) {
      setCurrentTime(audioPlayer.current.currentTime);
      animationRef.current = requestAnimationFrame(whilePlaying);
    }
  };

  const playTrack = () => {
    if (audioPlayer.current) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    }
  };

  const togglePlayPause = () => {
    if (audioPlayer.current) {
      const prevValue = isAudioPlaying;
      setIsAudioPlaying(!prevValue);
      onPlayClick(!prevValue ? messageId : null);
      if (!prevValue) {
        playTrack();
      } else {
        pauseTrack();
      }
    }
  };

  const formatTime = (dur: number) => {
    const minutes = Math.floor(dur / 60);
    const seconds = Math.floor(dur % 60);
    const twoDigit = seconds >= 10;

    return `${minutes}:${!twoDigit ? '0' : ''}${seconds}`;
  };

  const messageType = isSent ? MessageType.SentAudioMessage : MessageType.ReceivedAudioMessage;
  const displayedTime = currentTime > duration ? formatTime(0) : formatTime(duration - currentTime);

  const isAudioPassed = (index: number) => {
    const newIndex = index + 1;
    return currentTime > newIndex * (duration / wavesCount);
  };

  const handleMouseDown = (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => {
    if (!isAudioPlaying) return;

    if (audioWavesRowRef.current && audioPlayer.current) {
      const containerWidth = audioWavesRowRef.current.offsetWidth;
      const rect = audioWavesRowRef.current.getBoundingClientRect();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const clientX = e?.nativeEvent?.changedTouches?.length
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          e?.nativeEvent.changedTouches[0].clientX
        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          e?.clientX;
      const x = clientX - rect.left;
      holdingRef.current = true;
      if (x < containerWidth) {
        audioPlayer.current.pause();
        const newTime = (x / containerWidth) * duration;
        audioPlayer.current.currentTime = newTime;
      }
    }
  };

  const handleMouseMove = (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => {
    if (!holdingRef.current) return;

    if (audioWavesRowRef.current && audioPlayer.current) {
      const containerWidth = audioWavesRowRef.current.offsetWidth;
      const rect = audioWavesRowRef.current.getBoundingClientRect();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const clientX = e?.nativeEvent?.changedTouches?.length
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          e?.nativeEvent.changedTouches[0].clientX
        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          e?.clientX;
      const x = clientX - rect.left;
      if (x < containerWidth) {
        audioPlayer.current.pause();
        const newTime = (x / containerWidth) * duration;
        audioPlayer.current.currentTime = newTime;
      }
    }
  };

  const handleMouseUp = () => {
    if (!holdingRef.current) return;

    if (audioWavesRowRef.current && audioPlayer.current) {
      holdingRef.current = false;
      audioPlayer.current.play();
    }
  };

  return (
    <div role="audioMessageContainer">
      <MessageContainer
        hasReply={!!renderReplyToMessage}
        messageType={messageType}
        senderImage={senderImage}
        senderName={senderName}
        sentDateTime={sentDateTime}
        messageTo={messageTo}
        toggleModal={!isAudioPlaying && !!toggleModal ? toggleModal : undefined}
        isSameSender={isSameSender}
        showOnlyAttachment={showOnlyAttachment}
        forwarded={forwarded}
        edited={edited}
        id={id}
      >
        {renderReplyToMessage && renderReplyToMessage()}
        {audioBlobUrl && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <audio
            ref={audioPlayer}
            onLoadedMetadata={onCanPlay}
            src={audioBlobUrl}
            preload="metadata"
            onEnded={() => {
              if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
              }
              resetAudio();
            }}
            role="audio"
          />
        )}
        <SAudioPLayer role="audioPlayer">
          <img
            src={isAudioPlaying ? PauseButton : PlayButton}
            alt="play/pause"
            onClick={togglePlayPause}
            className="SPlayPauseButton"
            role="playPauseButton"
          />
          <SRangeContainer role="rangeContainer">
            {audioWavesHeightsRef?.current && (
              <SAudioWavesRow
                ref={audioWavesRowRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onTouchStart={handleMouseDown}
                onTouchMove={handleMouseMove}
                onTouchEnd={handleMouseUp}
                isPlaying={isAudioPlaying}
              >
                {audioWavesHeightsRef?.current.map((height, index) => (
                  <SAudioWave height={height} isPassed={isAudioPassed(index)} key={index} />
                ))}
              </SAudioWavesRow>
            )}
            <STime role="timeContainer">
              <p role="currentTime">{displayedTime}</p>
            </STime>
          </SRangeContainer>
        </SAudioPLayer>
      </MessageContainer>
    </div>
  );
}

export default AudioMessage;
