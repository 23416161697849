import { BASE_URL, changeBaseUrl, getApiBaseUrl } from './apis/utils/provider';
import { deleteItem, getItem, saveItem } from './utils/storage';

const ApiUrl = process.env.REACT_APP_API_URL;
const TEST_API_URL_KEY = 'TEST_API_URL';

export const isDevRelease = (): boolean => {
  const isDevUrl =
    ApiUrl === 'https://api.awsdev.cosafe.se' || ApiUrl === 'https://devapi.cosafe.se';
  const isLocal = window.location.host.includes('localhost');
  return !isLocal && isDevUrl;
};

const getApiURL = async () => {
  const savedUrl = await getItem(TEST_API_URL_KEY);
  return savedUrl;
};

export const isTestApiUrlApplied = () => {
  return BASE_URL !== getApiBaseUrl();
};

export const setTestApiUrl = async (_url: string) => {
  await saveItem(TEST_API_URL_KEY, _url);
  changeBaseUrl(_url);
};

export const updateTestApiUrl = async () => {
  const url = await getApiURL();
  if (isDevRelease() && !!url) {
    changeBaseUrl(url);
  }
};

export const removeTestApiUrl = async () => {
  deleteItem(TEST_API_URL_KEY);
  changeBaseUrl(ApiUrl!);
};
