import React, { useEffect, useMemo } from 'react';
import { SDrawerWrapper } from '../cec/CecTextTemplatesBottomSheet/CecTextTemplatesBottomSheet';
import Drawer from 'react-bottom-drawer';
import { FilterSection } from '../SearchFilterBar/SearchFilterBar';
import { palette } from '../../theme/colors';
import styled from 'styled-components';
import { translate } from '../../utils/translate';
import FilterCheckboxItem from '../FilterItem/FilterCheckboxItem';
import FilterRadioItem from '../FilterItem/FilterRadioItem';
import { ActionButton } from '../ActionButtons/ActionButtons.style';
import { useSelectlist } from '../../utils/customHooks/useSelectList';
import { ChecklistCreatedByFilters, GetChecklistSort } from '../../apis/checklistsAPI/types';
import {
  CheckListFilter,
  defaultCheckListFiltersByStatus,
} from '../../containers/Checklists/Checklists.models';
import { ResetFiltersButton } from '../Button/ResetFiltersButton';
import { ChecklistStatus } from '../../utils/enums';

const Sfilter = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const SLabel = styled.p`
  font-family: 'Roboto-Regular';
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${palette.white};
  margin-bottom: 1.5rem;
`;

const SLine = styled.hr`
  border: 1px solid ${palette.davysGrey10};
  margin-top: 18px;
  margin-bottom: 18px;
`;

interface ChecklistFilterProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  filtersSections: FilterSection[];
  initialSelected: CheckListFilter;
  onFilter: (filters: CheckListFilter) => void;
  checklistsStatus: ChecklistStatus;
}

function ChecklistFilter(props: ChecklistFilterProps) {
  const { isOpen, setIsOpen, onFilter, filtersSections, initialSelected, checklistsStatus } = props;

  const { handleSelect, stagedSelectedItems, onFinishSelecting } = useSelectlist({
    data: Object.values(ChecklistCreatedByFilters).map(value => ({ id: value })),
    multiStage: true,
    initialSelected: initialSelected.checklistsType,
  });

  const [sortOption, setSortOption] = React.useState<GetChecklistSort>(initialSelected.sort);
  const [sortOptionStaged, setSortOptionStaged] = React.useState<GetChecklistSort>(
    initialSelected.sort
  );

  const displayResetButton = useMemo(() => {
    const { sort, checklistsType } = defaultCheckListFiltersByStatus(checklistsStatus);
    const isSortByChanged = sortOptionStaged !== sort;
    const isCreatedByChanged = stagedSelectedItems.size !== checklistsType.size;
    return isSortByChanged || isCreatedByChanged;
  }, [stagedSelectedItems, sortOptionStaged, checklistsStatus]);

  // This is necessary because Active tab have different sorting options
  useEffect(() => {
    setSortOptionStaged(initialSelected.sort);
    setSortOption(initialSelected.sort);
  }, [initialSelected]);

  const selectSortOption = (option: GetChecklistSort) => {
    setSortOptionStaged(option);
  };
  const applyEmptyFolderChange = (apply: boolean, _sortOptionStaged?: GetChecklistSort) => {
    if (apply) {
      if (_sortOptionStaged !== undefined) setSortOption(_sortOptionStaged);
    } else {
      setSortOptionStaged(sortOption);
    }
  };

  const onCloseHandle = () => {
    applyEmptyFolderChange(false);
    onFinishSelecting(false);
    setIsOpen(false);
  };

  const handleFilter = () => {
    onFilter({
      checklistsType: stagedSelectedItems as Set<ChecklistCreatedByFilters>,
      sort: sortOptionStaged,
    });
    onFinishSelecting(true);
    applyEmptyFolderChange(true, sortOptionStaged);
  };

  const handleReset = () => {
    const { sort, checklistsType } = defaultCheckListFiltersByStatus(checklistsStatus);
    selectSortOption(sort);
    onFilter({ checklistsType, sort });
  };

  return (
    <SDrawerWrapper>
      <Drawer className="profileDrawer" isVisible={isOpen} onClose={onCloseHandle} hideScrollbars>
        <Sfilter role="checklistFilterDrawer">
          {filtersSections.map((filter, key) => (
            <div key={key} role={`filterSection-${key}`}>
              {key !== 0 && <SLine role="line" />}
              <SLabel role="title">{translate(`${filter.title}`)} </SLabel>
              <ul role="checkboxItemsList">
                {filter.type === 'checkbox'
                  ? filter.content.map(option => (
                      <FilterCheckboxItem
                        type="box"
                        checked={stagedSelectedItems.has(option.name!)}
                        name={option.nameTx}
                        setSelected={() => handleSelect(option.name)}
                        key={option.id}
                        id={option.id}
                      />
                    ))
                  : filter.type === 'radio' &&
                    filter.content.map(option => (
                      <FilterRadioItem
                        checked={option.name === sortOptionStaged}
                        name={option.nameTx}
                        setSelected={() => selectSortOption(option.name as GetChecklistSort)}
                        key={option.id}
                        id={option.id}
                      />
                    ))}
              </ul>
            </div>
          ))}
        </Sfilter>
        <ResetFiltersButton
          onClick={handleReset}
          isDisplayed={displayResetButton}
          marginBottom="0.8125rem"
        />
        <ActionButton type="button" tx="checklist_filter" onClick={handleFilter} />
      </Drawer>
    </SDrawerWrapper>
  );
}

export default ChecklistFilter;
