import { useEffect, useState } from 'react';
import { Layout } from '../../components/Layout/Layout';
import { SFilterButton } from '../../components/SearchFilterBar/SearchFilterBar.styles';
import Alarm from '../../containers/Alarm/Alarm';
import { ReactComponent as FilterIcon } from '../../assets/icons/sliders.svg';
import { ReactComponent as FilterIconSelected } from '../../assets/icons/sliders--yellow.svg';
import { useDispatch } from 'react-redux';
import { setActiveAlarmsModalData } from '../../containers/Alarm/AlarmSlice';
function AlarmPage() {
  const dispatch = useDispatch();
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [renderFilterButton, setRenderFilterButton] = useState<boolean>(false);
  const toggleFilterOpen = () => setFilterOpen(prev => !prev);

  useEffect(() => {
    dispatch(setActiveAlarmsModalData({ isOpen: false, items: [] }));
  }, []);

  return (
    <Layout
      isAlarmCreateLayout
      renderCustomRightButton={
        renderFilterButton
          ? () => (
              <SFilterButton
                $isFilterApplied={isFilterApplied}
                $svgOnly
                onClick={toggleFilterOpen}
                role="filterButton"
              >
                {isFilterApplied ? <FilterIconSelected role="icon" /> : <FilterIcon role="icon" />}
              </SFilterButton>
            )
          : undefined
      }
      isMessageLayout
      to="/dashboard"
      backBtn
      message={`alarm_send_alarm`}
    >
      <Alarm
        filterButtonProps={{
          setFilterOpen,
          filterOpen,
          setIsFilterApplied,
          setRenderFilterButton,
          renderFilterButton,
        }}
      />
    </Layout>
  );
}

export default AlarmPage;
