import * as React from 'react';
import Drawer from 'react-bottom-drawer';
import { ReactComponent as ArrowIcon } from '../../assets/icons/chevron-down.svg';
import DrawerFilter from '../../components/DrawerFilter/DrawerFilter';
import { EmptyListFallback } from '../../components/EmptyListFallback/EmptyListFallback';
import Loader from '../../components/Loader/Loader';
import { NewsItemCard } from '../../components/NewsItemCard/NewsItemCard';
import { SDrawerWrapper } from '../../components/cec/CecTextTemplatesBottomSheet/CecTextTemplatesBottomSheet';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  LocalStorageFiltersKeys,
  useLocalStorageFilters,
} from '../../utils/customHooks/useLocalStorageFilters';
import { translate } from '../../utils/translate';
import { SDrawer, SFilterButton, SNewsList, SPage } from './News.styles';
import { selectNews, selectNewsIsLoading, selectNewsListSource, setNews } from './newsSlice';
import { fetchNews } from './newsSlice/actionCreators';
import { NewsItem, SourceItem } from './newsSlice/types';

interface NewsFilters {
  selectedSources: SourceItem[];
}

export const News = () => {
  const dispatch = useAppDispatch();
  const listNews = useAppSelector(selectNews);
  const listSources = useAppSelector(selectNewsListSource);
  const isLoading = useAppSelector(selectNewsIsLoading);

  const [filters, setFilters] = useLocalStorageFilters<NewsFilters>(LocalStorageFiltersKeys.news, {
    selectedSources: [],
  });
  const setSelectedSource = (_selectedSources: SourceItem[]) =>
    setFilters(prev => ({ ...prev, selectedSources: _selectedSources }));

  const [filteredNews, setFilteredNews] = React.useState<NewsItem[]>([]);
  const [selectedNewsindex, setSelectedNewsIndex] = React.useState<number>(-1);
  const [isDrawerVisible, setDrawerVisible] = React.useState<boolean>(false);

  const onFilterNews = (sources: SourceItem[]) => {
    const sourceIds = sources.map(s => s.id);
    if (sourceIds.length === 0 || sourceIds.includes(-1)) {
      setFilteredNews([...listNews]);
    } else {
      const news = listNews.filter(n => sourceIds.includes(n.feedID));
      setFilteredNews([...news]);
    }
  };

  const onOpenDrawer = React.useCallback(() => setDrawerVisible(true), []);
  const onCloseDrawer = React.useCallback(() => setDrawerVisible(false), []);

  React.useEffect(() => {
    dispatch(fetchNews());

    return () => {
      dispatch(setNews([]));
      setFilteredNews([]);
    };
  }, [dispatch]);

  React.useEffect(() => {
    onFilterNews(filters.selectedSources);
  }, [listSources, filters.selectedSources]);

  const onSelectSource = (sources: SourceItem[]) => {
    setSelectedSource(sources);
    onCloseDrawer();
  };

  return (
    <SPage noBottomPadding>
      <SFilterButton onClick={onOpenDrawer} role="filterButton">
        {filters.selectedSources.length > 0
          ? filters.selectedSources.sort((a, b) => a.id - b.id)[0].name
          : translate('news_filter_all')}
        <ArrowIcon />
      </SFilterButton>

      {isLoading && <Loader />}

      {!isLoading &&
        (filteredNews.length === 0 ? (
          <EmptyListFallback
            listLength={filteredNews.length}
            isLoading={false}
            emptyListTx={'news-empty'}
          />
        ) : (
          <SNewsList role="newsList">
            {filteredNews.map((news, index) => (
              <NewsItemCard
                key={index}
                item={news}
                currentItemIndex={index}
                selectedItemIndex={selectedNewsindex}
                onSelectItem={i => setSelectedNewsIndex(i)}
              />
            ))}
          </SNewsList>
        ))}

      <SDrawer>
        <Drawer
          isVisible={isDrawerVisible}
          onClose={onCloseDrawer}
          mountOnEnter={true}
          hideScrollbars={false}
          duration={250}
          className="customDrawer"
        >
          <SDrawerWrapper role="newsFilterDrawerWrapper">
            <DrawerFilter
              sources={listSources}
              chosenSources={filters.selectedSources}
              setChooseSource={setSelectedSource}
              onApply={onSelectSource}
              onCancel={onCloseDrawer}
            />
          </SDrawerWrapper>
        </Drawer>
      </SDrawer>
    </SPage>
  );
};
