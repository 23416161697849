import styled from 'styled-components';
import BlockedAlarmIcon from '../../assets/icons/exclamation-mark-circle--white.svg';
import { BlockedStateBanner } from '../MessageItem/BlockedStateBanner/BlockedStateBanner';
import { palette } from '../../theme/colors';
import { useNavigate } from 'react-router-dom';
import { toRem } from '../../utils/toRem';

const SBanner = styled(BlockedStateBanner)<{ margin: string }>`
  margin: ${props => props.margin};
  background-color: ${palette.prussianBlue};
  p {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${palette.white};
    fontsize: ${toRem(12)};
    button {
      color: ${palette.honeyYellow};
    }
  }
`;

export const NotificationsDisabledBanner = ({ margin = '0' }: { margin?: string }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/sidebar/enable-not-available-mode');
  };

  return (
    <SBanner
      titleTx="profile_current_status_not_available"
      linkTx="profile_view_my_status"
      onLinkClick={handleNavigate}
      iconSource={BlockedAlarmIcon}
      margin={margin}
    />
  );
};
