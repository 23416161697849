import React, { SyntheticEvent, useRef, useState } from 'react';
import Camera from '../../assets/icons/camera.svg';
import SendIcon from '../../assets/icons/paper-plane--yellow.svg';
import { saveImageToServer } from '../../apis/mediaAPI';
import Delete from '../../assets/imgs/chats/delete-round.svg';
import { translate } from '../../utils/translate';
import { isCommentSending } from '../../containers/Checklists/checklistsSlice';
import { useAppSelector } from '../../hooks';
import Loader from '../Loader/Loader';
import {
  SBalanceSpace,
  SChatBox,
  SChatBoxWrapper,
  SFileInput,
  SIcon,
  SImageContainer,
  SImg,
  SLoaderContainer,
  STextContainer,
  STextInput,
  STop,
} from './ChecklistInput.styles';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { acceptedImageUploadFormatsText } from '../../utils/global';
import { getUnsupportedImageUploadWarningText } from '../../utils/helperFunctions';

interface ChecklistInputProps {
  onSend: (textMessage: string, photoFile?: string) => void;
}

function ChecklistInput(props: ChecklistInputProps) {
  const { onSend } = props;

  const confirm = useConfirmation();
  const isCommentSendingProcess = useAppSelector(isCommentSending);
  const [text, setText] = useState('');
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const imageUploadRef = useRef<any>(null);
  const showSendIcon = !isCommentSendingProcess && (text.trim()?.length || !!image);

  const onImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageToUpload = e.target.files?.length ? e.target.files[0] : null;
    if (!imageToUpload) return;

    const imagePreviewObj = URL.createObjectURL(imageToUpload);
    setImage(imageToUpload);
    setImagePreview(imagePreviewObj);
  };

  const saveImage = async (res: Blob) => {
    const formData = new FormData();
    formData.append('image', res);
    const result = await saveImageToServer(formData);
    if (result) {
      return result;
    }
  };

  const onCancelImages = () => {
    setImage(null);
    setImagePreview(null);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    imageUploadRef.current.value = null;
  };

  const onMessageSend = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (isCommentSendingProcess) return;

    if (!!image && !!imagePreview) {
      const result = await saveImage(image);
      if (result) {
        onSend(text, result);
      } else {
        confirm({
          title: 'unsupported_image_format',
          description: getUnsupportedImageUploadWarningText(),
          onSubmit: () => onCancelImages(),
          confirmText: 'remove_image',
          confirmStyle: 'red',
        });
      }
    } else {
      onSend(text);
    }
  };

  const onCameraIconClick = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    imageUploadRef.current.click();
  };

  return (
    <>
      <SChatBoxWrapper margin={!!image} role="inputContainer">
        <SChatBox role="chatBox">
          <STop role="topContainer">
            {image ? (
              <SIcon
                className="left"
                onClick={onCancelImages}
                src={Delete}
                alt="Delete"
                role="deleteIcon"
              />
            ) : (
              <SIcon
                className="left"
                onClick={onCameraIconClick}
                src={Camera}
                alt="camera"
                role="cameraIcon"
              />
            )}
            <STextContainer onSubmit={onMessageSend} role="textContainer">
              <STextInput
                value={text}
                placeholder={translate('messages_writeYourMessage')}
                onChange={e => setText(e.target.value)}
                role="textInput"
              />
            </STextContainer>
            {showSendIcon ? (
              <SIcon onClick={onMessageSend} src={SendIcon} alt="send" role="senIcon" />
            ) : isCommentSendingProcess ? (
              <SLoaderContainer>
                <Loader size={24} />
              </SLoaderContainer>
            ) : (
              <SBalanceSpace role="space" />
            )}
          </STop>
          {imagePreview !== null && (
            <SImageContainer role="imageContainer">
              {imagePreview && (
                <SImg role="imageItem">
                  <img role="image" className="picture" src={imagePreview} alt="" />
                </SImg>
              )}
            </SImageContainer>
          )}
        </SChatBox>
      </SChatBoxWrapper>

      <SFileInput
        onChange={onImageUpload}
        ref={imageUploadRef}
        id="imageUpload"
        type="file"
        accept={acceptedImageUploadFormatsText}
      />
    </>
  );
}

export default ChecklistInput;
