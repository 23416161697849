import { SelectListUser } from '../../containers/CreateMessage/createMessageSlice/types';
import { objectToQueryParams } from '../../utils/format';
import { indexInterface } from '../groupsAPI/types';
import { userApi } from '../utils/core';
import { UserById } from './types';

export interface GetUserQuery extends indexInterface {
  sort?: string;
  search?: string;
  skip?: number;
  menuitem?: string;
  limit?: number;
}

export const getUserById = (id: number) => {
  return userApi.getSingle<UserById>(id);
};

export const getAllUsers = (query?: GetUserQuery) => {
  return userApi.performExtra<SelectListUser[]>({
    method: 'get',
    extraResource: `?${objectToQueryParams(query)}`,
  });
};
