import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { NewsItem, NewsState, SourceItem } from './types';

const initialState: NewsState = {
  news: [],
  listNews: [],
  listSource: [],
  isLoading: false,
  isListLodaing: false,
  error: null,
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNews: (state, action: PayloadAction<NewsItem[]>) => {
      state.news = action.payload;
    },
    setListSource: (state, action: PayloadAction<SourceItem[]>) => {
      state.listSource = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { setNews, setListSource, setIsLoading, setIsError } = newsSlice.actions;

export default newsSlice.reducer;

export const selectNews = (state: RootState) => state.news.news;
export const selectNewsListSource = (state: RootState) => state.news.listSource;
export const selectNewsIsLoading = (state: RootState) => state.news.isLoading;
