import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { UserSelectItem } from '../../components/Chat/UserSelectItem';
import Loader from '../../components/Loader/Loader';
import {
  getActiveChecklist,
  handleSelectUser,
  isUsersLoading,
  selectUsers,
  selectUserSelection,
} from './checklistsSlice';
import { selectGroupsIsLoading } from '../GroupsList/groupsSlice';
import { HighlightSearchTerm } from '../../components/Checklists/HighlightSearchTerm/HighlightSearchTerm';
import { fetchAdditionalUsersByPage, fetchUsers } from './checklistsSlice/actionCreators';
import { isInViewport } from '../../utils/helperFunctions';
import { AdditionalUsersLoaderContainer } from '../CreateMessage/CreateMessageSummary.styles';
import { selectUser } from '../Login/LoginSlice';
import { SCustomList } from './ChecklistSelectGroupsList';

interface ChecklistSelectUsersListProps {
  searchText: string;
  start?: boolean;
  isActive: boolean;
}

const USERS_PER_PAGE = 20;
export const ChecklistSelectUsersList = (props: ChecklistSelectUsersListProps) => {
  const { searchText, start, isActive } = props;

  const dispatch = useAppDispatch();

  const { isUserLocked, isUserSelected } = useAppSelector(selectUserSelection);

  const user = useAppSelector(selectUser);
  const users = useAppSelector(selectUsers);
  const isLoading = useAppSelector(selectGroupsIsLoading);
  const usersLoading = useAppSelector(isUsersLoading);
  const checklist = useAppSelector(getActiveChecklist);

  const [page, setPage] = useState(0);
  const usersListRef = useRef<HTMLDivElement>(null);
  const [lastReachedUserIndex, setLastReachedUserIndex] = useState<number>(0);

  useEffect(() => {
    dispatch(
      fetchUsers({ menuitem: 'checklists', limit: USERS_PER_PAGE }, checklist?.userIds, !!start)
    );
  }, []);

  useEffect(() => {
    if (!start) {
      dispatch(fetchUsers({ menuitem: 'checklists', search: searchText, limit: USERS_PER_PAGE }));
    }
  }, [searchText]);

  useEffect(() => {
    if (page > 0 && !start) {
      dispatch(
        fetchAdditionalUsersByPage({
          menuitem: 'checklists',
          search: searchText,
          limit: USERS_PER_PAGE,
          skip: page * USERS_PER_PAGE || 0,
        })
      );
    }
  }, [page]);

  const onUserClick = (usersId: number) => () => {
    dispatch(handleSelectUser({ usersId }));
  };

  const handleMouseWheel = () => {
    const lastUserIndex = users.length;
    const haveReachedEnd = isInViewport(document.getElementById(`user-${lastUserIndex}`));
    if (haveReachedEnd && !usersLoading && lastReachedUserIndex !== lastUserIndex) {
      setLastReachedUserIndex(lastUserIndex);
      setPage(page + 1);
    }
  };

  const isAuthUser = (userId: number) => {
    return userId == user?.id;
  };

  if (isLoading) return <Loader />;

  if (!isActive) return <></>;

  return (
    <SCustomList onScroll={handleMouseWheel} ref={usersListRef}>
      {users.map((tempUser, index) => {
        return (
          <UserSelectItem
            id={`user-${index + 1}`}
            user={tempUser}
            key={tempUser.id}
            name={HighlightSearchTerm(tempUser.displayName, searchText)}
            isSelected={isUserSelected(tempUser.id)}
            isLocked={isUserLocked(tempUser.id) || isAuthUser(tempUser.id)}
            onCardPress={onUserClick(tempUser.id)}
          />
        );
      })}
      {usersLoading && (
        <AdditionalUsersLoaderContainer role="additionalUsersLoader">
          <Loader />
        </AdditionalUsersLoaderContainer>
      )}
    </SCustomList>
  );
};
