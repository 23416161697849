import ChecklistItem from './ChecklistItem';
import { EmptyListFallback } from '../EmptyListFallback/EmptyListFallback';
import { TabProps } from '../../containers/Checklists/Checklists.models';

function Active(props: TabProps) {
  const { checklists, onDotsClick, searchText } = props;
  return (
    <div role="activeTab">
      {checklists.map(item => (
        <ChecklistItem
          checklist={item}
          key={`Active-${item.id}`}
          onDotsClick={onDotsClick}
          searchText={searchText}
        />
      ))}
      {checklists.length === 0 && (
        <EmptyListFallback
          listLength={checklists.length}
          isLoading={false}
          searchTerm={searchText}
          emptyListTx={'empty_checklists'}
          emptySearchTx={'messages_not_found_search'}
        />
      )}
    </div>
  );
}

export default Active;
