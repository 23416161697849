import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SimpleText = styled.p<{ margin?: string; fontSize: string; gray?: boolean }>`
  margin: ${props => props.margin};
  color: ${props =>
    props.gray ? props.theme.palette.text.documentPrimary : props.theme.palette.text.menuPrimary};
  font-family: 'Roboto-Regular';
  font-size: ${props => props.fontSize};
  padding-left: 0.3rem;
  max-width: 18rem;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const LeftContainer = styled.p`
  margin-right: 0.5rem;
  max-height: 45px;
`;

export const MessagesTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  overflow: hidden;
  margin-right: 0.5rem;
  text-overflow: ellipsis;
`;

export const BottomRow = styled.div`
  display: flex;
  align-items: center;
`;
