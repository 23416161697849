import { useEffect, useState } from 'react';
import { getImage, GetImageProps } from '../../apis/mediaAPI';

export const useImage = (props: GetImageProps) => {
  const { imageName, size, svg } = props;

  const [img, setImg] = useState<string>('');
  const [isImageLoading, setIsImageLoading] = useState<boolean>();

  let mounted = true;

  const getThatImage = async () => {
    setIsImageLoading(true);
    try {
      const image = await getImage({ imageName, size, svg });
      if (mounted) {
        setImg(image);
        setIsImageLoading(false);
      }
      return image;
    } catch (error) {
      return null;
    }
  };
  useEffect(() => {
    getThatImage();
    return () => {
      mounted = false;
      setIsImageLoading(false);
    };
  }, []);

  return { isImageLoading, img };
};
