import axios from 'axios';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { setIsAppLoading } from './containers/App/AppSlice';
import { store } from './store';
import { getItem } from './utils/storage';

export enum ELanguages {
  'se' = 'se',
  'fi' = 'fi',
  'pt' = 'pt',
  'nl' = 'nl',
  'es' = 'es',
  'en' = 'en',
  'en-TT' = 'en-TT',
}
export const EPhoneCodes: Record<string, string> = {
  se: 'SE',
  fi: 'FI',
  nl: 'NL',
  pt: 'BR',
  es: 'MX',
  'en-TT': 'BR',
};

export const SupportLanguageCodes: Record<string, string> = {
  se: 'sv',
  fi: 'fi',
  en: 'en',
  'en-TT': 'en',
  nl: 'nl',
  es: 'es',
  pt: 'pt-BR',
};

export const defaultLanguage =
  // eslint-disable-next-line @typescript-eslint/no-confusing-non-null-assertion
  process.env.REACT_APP_DEFAULT_LANGUAGE! === 'SE' ? ELanguages.se : ELanguages.pt;

i18n.use(initReactI18next).init({
  lng: getItem('language') || defaultLanguage,
  defaultNS: 'translations',
  fallbackLng: defaultLanguage,
  resources: {},
  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

// there is an issue happening when we run the tests, this function depend on the env variables,
// for getting the translations links, and the env are not available when we run the tests
// even though we are mocking the env variables in the setupTests.js file but seems that this file is loading first
// before the mock took place/applied, so we are using a fallback url for the tests
const fallbackURL = 'https://translation-tool.cosafe.com/Cosafe/texts_export.php?lang=en';

const translationUrls: Record<ELanguages, string | undefined> = {
  [ELanguages.en]: process.env.REACT_APP_TRANSLATION_URL_EN,
  [ELanguages.es]: process.env.REACT_APP_TRANSLATION_URL_ES,
  [ELanguages.fi]: process.env.REACT_APP_TRANSLATION_URL_FI,
  [ELanguages.nl]: process.env.REACT_APP_TRANSLATION_URL_NL,
  [ELanguages.pt]: process.env.REACT_APP_TRANSLATION_URL_PT,
  [ELanguages.se]: process.env.REACT_APP_TRANSLATION_URL_SE,
  [ELanguages['en-TT']]: process.env.REACT_APP_TRANSLATION_URL_EN,
};

const getTranslation = async (lang: ELanguages) => {
  const translationsUrl = translationUrls[lang] ?? fallbackURL;
  const res = await axios.get(translationsUrl ?? fallbackURL);
  return res.data;
};

export const addTranslations = async () => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-confusing-non-null-assertion
    if (process.env.REACT_APP_DEFAULT_LANGUAGE! === 'SE') {
      const [enTranslations, seTranslations, nlTranslations, fiTranslations] = await Promise.all([
        getTranslation(ELanguages.en),
        getTranslation(ELanguages.se),
        getTranslation(ELanguages.nl),
        getTranslation(ELanguages.fi),
      ]);
      i18n.addResourceBundle(ELanguages.en, 'translations', enTranslations);
      i18n.addResourceBundle(ELanguages.se, 'translations', seTranslations);
      i18n.addResourceBundle(ELanguages.nl, 'translations', nlTranslations);
      i18n.addResourceBundle(ELanguages.fi, 'translations', fiTranslations);
    } else {
      const [ptTranslations, esTranslations, enTranslations] = await Promise.all([
        getTranslation(ELanguages.pt),
        getTranslation(ELanguages.es),
        getTranslation(ELanguages['en-TT']),
      ]);
      i18n.addResourceBundle(ELanguages.pt, 'translations', ptTranslations);
      i18n.addResourceBundle(ELanguages.es, 'translations', esTranslations);
      i18n.addResourceBundle(ELanguages['en-TT'], 'translations', enTranslations);
    }
  } catch (error) {
    console.error(error);
  }
  store.dispatch(setIsAppLoading(false));
};

export const getResourceBundle = async (lang: ELanguages = ELanguages.en) => {
  const response = await i18n.getResourceBundle(lang, 'translations');
  return response;
};

addTranslations();

export default i18n;
