import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

interface ChatDetailsState {
  isLoading: boolean;
  isError: string | null;
}

const initialState: ChatDetailsState = {
  isLoading: false,
  isError: null,
};

const messageDetailsSlice = createSlice({
  name: 'messageDetailsSlice',
  initialState: initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsError: (state, action: PayloadAction<string>) => {
      state.isError = action.payload;
    },
  },
});

export const { setIsLoading, setIsError } = messageDetailsSlice.actions;

export const messageDetailsIsLoading = (state: RootState) => state.messageDetails.isLoading;

export default messageDetailsSlice.reducer;
