import { useAppSelector } from '../../hooks';
import {
  getActiveAlarmsModalData,
  isAlarmLoading,
  setActiveAlarmsModalData,
} from '../../containers/Alarm/AlarmSlice';
import { useDispatch } from 'react-redux';
import { fetchActiveAlarms } from '../../containers/Alarm/AlarmSlice/actionCreators';
import { t } from 'i18next';

const DISPLAYED_ALARMS_COUNT = 2;
export const useActiveAlarms = () => {
  const dispatch = useDispatch();
  const activeAlarmsModalData = useAppSelector(getActiveAlarmsModalData);
  const isLoading = useAppSelector(isAlarmLoading);
  const activeAlarms = activeAlarmsModalData?.items ?? [];
  const isOpen = activeAlarmsModalData?.isOpen ?? false;
  const sendAlarmProps = activeAlarmsModalData?.sendAlarmProps;
  const displayedAlarms =
    activeAlarms.length > DISPLAYED_ALARMS_COUNT
      ? activeAlarms.slice(0, DISPLAYED_ALARMS_COUNT)
      : activeAlarms;
  const hiddenAlarmsCount = activeAlarms.length - DISPLAYED_ALARMS_COUNT;
  const moreText =
    hiddenAlarmsCount === 1
      ? t('alarm_1_more_alarm')
      : hiddenAlarmsCount > 1
        ? `${hiddenAlarmsCount} ${t('alarm_more_alarms')}`
        : null;

  const loadActiveAlarms = async (groupIds: number[]) => {
    dispatch(fetchActiveAlarms(groupIds));
  };

  const changeIsOpen = (state: boolean) => {
    const newObject = { ...activeAlarmsModalData, isOpen: state };
    dispatch(setActiveAlarmsModalData(newObject));
  };

  return {
    activeAlarms,
    sendAlarmProps,
    isOpen,
    displayedAlarms,
    moreText,
    isLoading,
    loadActiveAlarms,
    changeIsOpen,
  };
};
