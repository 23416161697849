import styled from 'styled-components';
import { Page } from '../../components/Page/Page';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SLine = styled.hr`
  margin: 0;
  height: 1px;
  border: none;
  background-color: ${palette.queenBlue};
  border-radius: 49px;
`;

export const SItem = styled.div`
  display: flex;
  padding: 0.6rem 0;
  align-items: center;

  &.input {
    cursor: default;
  }
  .left {
    width: 5rem;
  }
  .right {
    max-width: 100%;
    flex: 1;
  }
`;

export const SClickableItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
`;

export const SInput = styled.input`
  flex: 1;
  background-color: transparent;
  border: none;
  font-family: 'Roboto-Regular';
  font-size: 14px;
  color: ${palette.white};
  :focus-visible {
    outline: none;
  }
`;

export const SIcon = styled.img`
  &.pencil {
    cursor: text;
  }
`;

export const SPage = styled(Page)`
  padding: 1.25rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;
export const AdditionalUsersLoaderContainer = styled.div`
  display: flex;
  margin: 10px auto;
`;

export const SMoreText = styled.p`
  font-family: 'Roboto-Medium';
  color: ${palette.earthYellow};
  text-decoration: none;
  cursor: pointer;
  align-self: center;
  font-size: ${toRem(14)};
  line-height: ${toRem(20)};
  min-height: ${toRem(30)};
  padding-left: ${toRem(10)};
`;
