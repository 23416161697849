import React, { useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { translate } from '../../utils/translate';
import Loader from '../../components/Loader/Loader';
import InfoCircle from '../../assets/icons/exclamation-mark-circle--grey.svg';
import {
  getAlarmCreateModel,
  isAlarmLoading,
  selectAlarmGroups,
  setAlarmCustomText,
} from './AlarmSlice';
import BigFloatButton from '../../components/BigFloatButton/BigFloatButton';
import { handleNavigate } from './AlarmSlice/actionCreators';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AlarmStep } from '../../utils/enums';
import { selectUser } from '../Login/LoginSlice';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import {
  SPage,
  SNewAlarmText,
  SInputContainer,
  STextInput,
  SDescriptionWrapper,
  SDescriptionContainer,
  SDescription,
} from './AlarmCustomText.styles';

const AlarmCustomText = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isAlarmLoading);
  const user = useAppSelector(selectUser);
  const alarmType = useAppSelector(getAlarmCreateModel);
  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const selectedGroups = useAppSelector(selectAlarmGroups);
  const [customText, setCustomText] = useState('');
  const navigate = useNavigate();
  const confirm = useConfirmation();

  if (isLoading) return <Loader />;

  return (
    <SPage>
      <SDescriptionWrapper>
        <SDescriptionContainer>
          <img src={InfoCircle} alt="" />
          <SDescription>{t('alarm_new_alarm_custom_text_desc')}</SDescription>
        </SDescriptionContainer>
      </SDescriptionWrapper>
      <SInputContainer onSubmit={e => e.preventDefault()}>
        <SNewAlarmText>{t('alarm_new_alarm_custom_text')}</SNewAlarmText>
        <STextInput
          ref={textInputRef}
          value={customText}
          placeholder={translate('messages_writeYourMessage') as string}
          onChange={e => {
            setCustomText(e.target.value);
          }}
        />
      </SInputContainer>
      <BigFloatButton
        onClick={() => {
          dispatch(setAlarmCustomText(customText));
          dispatch(
            handleNavigate(
              user?.id,
              selectedGroups,
              alarmType,
              navigate,
              AlarmStep.AddCustomText,
              confirm,
              undefined,
              customText
            )
          );
        }}
        tx={'messages_proceed'}
        extraPadding={false}
      />
    </SPage>
  );
};

export default AlarmCustomText;
