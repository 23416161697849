import { useAppDispatch, useAppSelector } from '../../hooks';
import { getUsers, getUsersLoading } from './loneWorkerTimerSlice';
import { fetchUsers } from './loneWorkerTimerSlice/actionCreator';
import { UsersSelectionList } from '../../components/RecipientsSelection/UsersSelectionList';
import { useEffect, useState } from 'react';
import { selectUser } from '../Login/LoginSlice';
import { EmptyListFallback } from '../../components/EmptyListFallback/EmptyListFallback';
import { SelectListUser } from '../CreateMessage/createMessageSlice/types';

interface LoneWorkerTimerUsersListProps {
  showActionButtons: boolean;
  selectedUsersIdsList: number[];
  isActive?: boolean;
  searchText?: string;
  onUserClick: (user: SelectListUser) => void;
}

const USERS_PER_PAGE = 20;
export const LoneWorkerTimerUsersList = (props: LoneWorkerTimerUsersListProps) => {
  const {
    showActionButtons,
    selectedUsersIdsList,
    isActive = true,
    searchText,
    onUserClick,
  } = props;
  const dispatch = useAppDispatch();
  const authUser = useAppSelector(selectUser);
  const users = useAppSelector(getUsers);
  const usersLoading = useAppSelector(getUsersLoading);
  const [page, setPage] = useState(0);

  const loadUsersList = (pageNumber?: number, search?: string) => {
    const activePage = pageNumber ?? page ?? 0;
    const activeSearch = search ?? searchText ?? '';
    dispatch(
      fetchUsers({
        limit: USERS_PER_PAGE,
        skip: activePage * USERS_PER_PAGE,
        search: activeSearch,
      })
    );
  };

  useEffect(() => {
    if (isActive) {
      setPage(0);
      loadUsersList(0);
    }
  }, [searchText]);

  useEffect(() => {
    if (!isActive) {
      setPage(0);
      loadUsersList(0, '');
    }
  }, [isActive]);

  return (
    <>
      {isActive && (
        <UsersSelectionList
          selectedUsersIdsList={selectedUsersIdsList}
          users={users}
          isLoading={usersLoading}
          className={showActionButtons && users.length > 5 ? 'lwtBottomPadding' : undefined}
          page={page}
          lockedUsersIds={authUser ? [authUser.id] : undefined}
          setPage={setPage}
          onUserClick={onUserClick}
          loadUsers={loadUsersList}
          searchText={searchText}
          emptyListFallbackComponent={
            <EmptyListFallback
              listLength={users?.length ?? 0}
              searchTerm={searchText}
              emptyListTx={'no_users_found'}
              emptySearchTx={'users_not_found_by_search'}
            />
          }
        />
      )}
    </>
  );
};
