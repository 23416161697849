import { useImage } from '../../utils/customHooks/useImage';
import userImg from '../../assets/icons/silhouette--dark.svg';
import avatar from '../../assets/icons/silhouette-with-three.svg';
import { PlaceHolderWrapper, SImg } from './ProfilePicture.styles';

interface ProfilePictureProps {
  profilePictureFileName: string | null;
  readyPhotoSource?: string;
  isGroup?: boolean;
  diameter?: number;
  zIndex?: number;
}

export const ProfilePicture = (props: ProfilePictureProps) => {
  const { readyPhotoSource, profilePictureFileName, isGroup, diameter = 45, zIndex } = props;

  const noImage = isGroup ? avatar : userImg;

  const { img, isImageLoading } = useImage({
    imageName: profilePictureFileName,
    size: 'small',
  });

  if (readyPhotoSource)
    return (
      <SImg
        role="profilePicture"
        src={readyPhotoSource}
        alt=""
        diameter={diameter}
        zIndex={zIndex}
      />
    );
  if (!profilePictureFileName && isImageLoading) return <></>;

  if (profilePictureFileName && img !== '') {
    return <SImg role="profilePicture" src={img} alt="" diameter={diameter} zIndex={zIndex} />;
  } else {
    return (
      <PlaceHolderWrapper
        src={noImage}
        alt=""
        diameter={diameter}
        zIndex={zIndex}
        role="placeholderPicture"
      />
    );
  }
};
