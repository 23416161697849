import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Arrow from '../../assets/imgs/chats/arrow.svg';
import Loader from '../../components/Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { translate } from '../../utils/translate';
import { SIcon } from '../CreateMessage/CreateMessageSummary.styles';
import {
  SActivateButton,
  SActivateButtonContainer,
  SBannerText,
  SFeatureButton,
  SFeatureButtonTextBox,
  SFeatureTitle,
  SInfoBanner,
  SLearnMoreText,
  SMessageInput,
  SMessageInputWrapper,
  SPage,
  SQuestionIcon,
  SRow,
} from './panicButton.style';
import QuestionIcon from '../../assets/icons/question-mark-round.svg';
import {
  getIsLoading,
  getIsLocationDenied,
  getLocationData,
  getMessageText,
  getSelectedGroups,
  getSelectedUsers,
  setIsLocationDenied,
  setLocationData,
  setMessageText,
} from './PanicButtonSlice';
import { SDrawerWrapper } from '../../components/cec/CecTextTemplatesBottomSheet/CecTextTemplatesBottomSheet';
import { PanicDescriptionBottomSheet } from '../../components/PanicDescriptionBottomSheet/PanicDescriptionBottomSheet';
import MapAlert from '../../components/Map/MapAlert';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import { tryToActivateAlarm } from './PanicButtonSlice/actionCreators';
import { getLocationName } from '../Alarm/helpers';
import { selectUser } from '../Login/LoginSlice';
import { PanicPreperationData, getRecipientsText, preparePanicData } from './helpers';

const PanicAlarmCreate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const layout = useLayoutContext();
  const authUser = useAppSelector(selectUser);
  const selectedGroups = useAppSelector(getSelectedGroups);
  const selectedUsers = useAppSelector(getSelectedUsers);
  const isLoading = useAppSelector(getIsLoading);
  const isLocationDenied = useAppSelector(getIsLocationDenied);
  const messageText = useAppSelector(getMessageText);
  const selectedLocation = useAppSelector(getLocationData);

  const [messageInputFocus, setMessageInputFocus] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [locationAlarmModalOpen, setLocationAlarmModalOpen] = useState(false);
  const [location, setLocation] = useState<google.maps.LatLngLiteral | undefined>();
  const [locName, setLocName] = useState('');

  useEffect(() => {
    if (!locationAlarmModalOpen && location?.lat != undefined) {
      dispatch(
        setLocationData({
          lng: location?.lng,
          lat: location?.lat,
          name: locName || `${location?.lat} | ${location?.lng}`,
        })
      );
    }
  }, [location, locName]);

  const assignLocationName = async (_location: google.maps.LatLngLiteral) => {
    if (_location?.lat != undefined) {
      const locationName = await getLocationName(_location);
      setLocName(locationName ?? `${_location?.lat} | ${_location?.lng}`);
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setLocation(pos);
          assignLocationName(pos);
        },
        () => {
          console.error("Can't get location");
          setLocationAlarmModalOpen(true);
        },
        { maximumAge: 5000, enableHighAccuracy: true, timeout: 15000 }
      );
    } else {
      setLocationAlarmModalOpen(true);
    }
  }, []);

  const recipientsText = useMemo(() => {
    return getRecipientsText(selectedGroups, selectedUsers, authUser?.id ?? 0);
  }, [authUser?.id, selectedGroups, selectedUsers]);

  const canActivate = useMemo(() => {
    return !!messageText.length && !!selectedUsers.length;
  }, [selectedUsers, messageText]);

  const activateAlarm = () => {
    if (canActivate) {
      const data: PanicPreperationData = { selectedGroups, selectedUsers, messageText };
      const alarmData = preparePanicData(data, authUser?.id ?? 0);
      if (alarmData) {
        dispatch(tryToActivateAlarm(navigate, alarmData));
      }
    }
  };

  const onLocationConfirm = async () => {
    layout.setMessage('home_panic_button');
    if (!location?.lat || !location?.lng) {
      return;
    }
    dispatch(
      setLocationData({
        lng: location?.lng,
        lat: location?.lat,
        name: locName || `${location?.lat} | ${location?.lng}`,
      })
    );
    setLocationAlarmModalOpen(false);
  };

  const onLocationDecline = () => {
    setLocationAlarmModalOpen(false);
    dispatch(setIsLocationDenied(true));
    layout.setMessage('home_panic_button');
  };

  if (isLoading) {
    return <Loader />;
  }

  if (locationAlarmModalOpen && !selectedLocation && !isLocationDenied) {
    return (
      <MapAlert
        location={location}
        setLocation={setLocation}
        setLocName={setLocName}
        onClickDecline={onLocationDecline}
        onClickAccept={onLocationConfirm}
      />
    );
  }

  return (
    <SPage>
      <div className="mainContainer" role="createLwtContainer">
        <SInfoBanner role="infoBanner">
          <SRow role="textRow">
            <SQuestionIcon src={QuestionIcon} role="questionIcon" />
            <SBannerText role="bannerText">{t('find_out_how_panic_works')}</SBannerText>
          </SRow>
          <SLearnMoreText onClick={() => setIsDescriptionOpen(true)} role="learnMoreButton">
            {t('learn_more')}
          </SLearnMoreText>
        </SInfoBanner>
        <SFeatureTitle role="timerLabel">{t('send_panic_to')}</SFeatureTitle>
        <SFeatureButton
          onClick={() => navigate('/panic/create/select-recipients')}
          role="recipientsFeatureButton"
        >
          <SFeatureButtonTextBox isFilled={recipientsText.length > 0} role="recipientsFeatureText">
            <p role="recipientsText">
              {recipientsText.length ? recipientsText : t('choose_groups_and_recipients')}
            </p>
          </SFeatureButtonTextBox>
          <SIcon src={Arrow} alt="" role="arrowIcon" />
        </SFeatureButton>
        <SFeatureTitle role="messageLabel">{t('panic_message')}</SFeatureTitle>
        <SMessageInputWrapper haveFocus={messageInputFocus} role="messageInputContainer">
          <SMessageInput
            onFocus={() => setMessageInputFocus(true)}
            onBlur={() => setMessageInputFocus(false)}
            value={messageText}
            onChange={e => {
              dispatch(setMessageText(e.target.value));
            }}
            placeholder={translate('emergency_help_msg')}
            role="messageInput"
          ></SMessageInput>
        </SMessageInputWrapper>
        <SDrawerWrapper role="drawerWrapper">
          <PanicDescriptionBottomSheet
            isOpen={isDescriptionOpen}
            setIsOpen={setIsDescriptionOpen}
          />
        </SDrawerWrapper>
      </div>
      <SActivateButtonContainer role="activateButtonContainer">
        <SActivateButton isActive={canActivate} onClick={activateAlarm} role="activateButton">
          {t('activate_panic')}
        </SActivateButton>
      </SActivateButtonContainer>
    </SPage>
  );
};

export default PanicAlarmCreate;
