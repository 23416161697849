import { batch } from 'react-redux';
import { setIsError, setIsLoading } from '.';
import {
  addDocumentToFolders,
  addDocumentToGroups,
  createGroupFolders,
  removeDocumentFromFolder,
  removeDocumentFromGroup,
  setOffline,
  shareDocumentWithMoreFolders,
  shareDocumentWithMoreGroup,
} from '../../../apis/documentsAPI';
import { AppThunk } from '../../../store';
import { fetchFolders } from '../../Documents/documentsSlice/actionCreators';
import { FolderItem } from '../../Documents/documentsSlice/types';
import { OfflineData } from './types';

export const fetchCreateFolders =
  (group: object): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      await createGroupFolders(group);

      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(fetchFolders());
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const fetchSetOffline =
  (files: OfflineData[]): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      await setOffline(files);
      dispatch(fetchFolders());
      batch(() => {
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const fetchAddDocumentGroupsAndFolders =
  (groupID: number, foldersInGroup: FolderItem[], formData: FormData): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      const id = await addDocumentToGroups(groupID, formData);
      batch(() => {
        foldersInGroup.forEach((folder: FolderItem) => {
          shareDocumentWithMoreFolders(id.id, folder.ID);
        });
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const fetchAddDocumentToGroups =
  (groupIds: number[], formData: FormData): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      await addDocumentToGroups(groupIds, formData);
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(fetchFolders());
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const fetchAddDocumentFolders =
  (folderIds: number[], formData: FormData): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      await addDocumentToFolders(folderIds, formData);
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(fetchFolders());
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

// share document start =============================================

export const fetchShareDocumentWithMoreGroup =
  (documentID: number, groupID: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      await shareDocumentWithMoreGroup(documentID, groupID);
      batch(() => {
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };
export const fetchShareDocumentWithMoreFolders =
  (documentID: number, folderID: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      await shareDocumentWithMoreFolders(documentID, folderID);
      batch(() => {
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };
export const fetchRemoveDocumentFromGroup =
  (documentID: number, groupID: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      await removeDocumentFromGroup(documentID, groupID);
      batch(() => {
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };
export const fetchRemoveDocumentFromFolder =
  (documentID: number, folderID: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      await removeDocumentFromFolder(documentID, folderID);
      batch(() => {
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };
