import { SDrawerWrapper } from './DrawerModal.styles';
import Drawer from 'react-bottom-drawer';

// a wrapper around react-bottom-drawer

interface IDrawerModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  children: React.ReactNode;
  className?: string | undefined;
}

export const DrawerModal = (props: IDrawerModalProps) => {
  const { isOpen, setIsOpen, onClose, children, className, ...rest } = props;
  const onCloseHandle = () => {
    onClose?.();
    setIsOpen(false);
  };
  return (
    <SDrawerWrapper className={className}>
      <Drawer
        hideScrollbars
        {...rest}
        className="profileDrawer"
        isVisible={isOpen}
        onClose={onCloseHandle}
      >
        {children}
      </Drawer>
    </SDrawerWrapper>
  );
};
