import { PanicAlarmActivationData } from '../../containers/PanicButton/PanicButtonSlice/types';
import { getAntiForgeryToken } from '../authApi/authAPI';
import { ApiCore } from '../utils/core';

const ApiPanicAlarm = new ApiCore({
  getAll: false,
  getSingle: false,
  post: true,
  put: false,
  patch: false,
  remove: true,
  singleExtra: true,
  url: '',
});

export const activatePanicAlarm = async (data: PanicAlarmActivationData) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiPanicAlarm.performExtra<unknown>({
    extraResource: `Groups/activateAlarms`,
    method: 'post',
    model: data,
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const deactivatePanicAlarm = async (data: PanicAlarmActivationData) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiPanicAlarm.performExtra<unknown>({
    extraResource: `Groups/deactivateAlarms`,
    method: 'post',
    model: data,
    headers: { 'X-XSRF-Token': csrfToken },
  });
};
