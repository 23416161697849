import React, { useEffect, useMemo, useState } from 'react';
import Add from '../../assets/icons/plus--yellow.svg';
import Subtract from '../../assets/icons/minus.svg';
import { translate } from '../../utils/translate';
import {
  SContainer,
  SNumbersColumnBox,
  SOperationBtn,
  SOperationBtnIcon,
  STitle,
} from './VerticalNumberCounter.styles';

interface VerticalNumberCounterProps {
  titleTx: string;
  onNumberChange: (num: number | string) => void;
  minValue?: number;
  maxValue?: number;
  isTimeSelection?: boolean;
  initialValue?: number;
}

enum Operations {
  None,
  Increment,
  Decrement,
}

export const VerticalNumberCounterWithPrevAndNext = (props: VerticalNumberCounterProps) => {
  const {
    titleTx,
    onNumberChange,
    maxValue = 59,
    minValue = 0,
    isTimeSelection = false,
    initialValue = minValue,
  } = props;

  const [count, setCount] = useState<number | string>(initialValue || minValue);
  const [longPressInterval, setLongPressInterval] = useState<NodeJS.Timeout>();
  const [isMouseScrolled, setIsMouseScrolled] = useState(false);
  const nextNumber = useMemo(() => {
    return count == maxValue ? 0 : Number(count) + 1;
  }, [count]);
  const prevNumber = useMemo(() => {
    return count == 0 ? maxValue : Number(count) - 1;
  }, [count]);

  let pressTimer: NodeJS.Timeout;
  let currentOperation = Operations.None;
  let isLongPress = false;

  const handleOperation = (num: number | string) => {
    let value = ~~num;
    // Incrementing
    if (currentOperation === Operations.Increment) {
      value = value == maxValue ? 0 : Number(value) + 1;
    }
    // Decrementing
    if (currentOperation === Operations.Decrement) {
      value = value == 0 ? maxValue : Number(value) - 1;
    }
    return value;
  };

  useEffect(() => {
    onNumberChange(~~count);
  }, [count]);

  const handleMouseDown = (e: any, operation: Operations) => {
    if (e.button !== 0) return;
    currentOperation = operation;
    pressTimer = setTimeout(() => {
      isLongPress = true;
      clearInterval(longPressInterval);
      setLongPressInterval(
        setInterval(() => {
          setCount(num => handleOperation(num));
        }, 100)
      );
    }, 300);
  };

  const handleMouseUp = (e: any) => {
    if (e.button !== 0) return;
    if (!isLongPress) setCount(handleOperation(count));
    clearTimeout(pressTimer);
    clearInterval(longPressInterval);
    isLongPress = false;
  };

  const handleMouseWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    if (!isMouseScrolled) {
      setIsMouseScrolled(true);
      currentOperation = e.deltaY > 0 ? Operations.Increment : Operations.Decrement;
      setCount(handleOperation(count));
      setTimeout(() => {
        setIsMouseScrolled(false);
      }, 75);
    }
  };

  const displayNumber = (num: number | string) => {
    const value = ~~num;
    return isTimeSelection && value < 10 ? `0${value}` : value;
  };

  return (
    <SContainer role="verticalNumberCounter">
      <STitle role="title">{translate(titleTx)}</STitle>
      <SOperationBtn
        onMouseDown={e => handleMouseDown(e, Operations.Increment)}
        onMouseUp={e => handleMouseUp(e)}
        onMouseLeave={e => handleMouseUp(e)}
        role="incrementBtn"
      >
        <SOperationBtnIcon src={Add} alt="plus" role="plusIcon" />
      </SOperationBtn>
      <SNumbersColumnBox id="numbersColumn" onWheel={handleMouseWheel}>
        <p className="inactive-number" role="prevNumber">
          {displayNumber(prevNumber)}
        </p>
        <p role="count">{displayNumber(count)}</p>
        <p className="inactive-number" role="nextNumber">
          {displayNumber(nextNumber)}
        </p>
        <p className="scrollDesc" role="scrollHelperIcon">
          ↕
        </p>
      </SNumbersColumnBox>
      <SOperationBtn
        onMouseDown={e => handleMouseDown(e, Operations.Decrement)}
        onMouseUp={e => handleMouseUp(e)}
        onMouseLeave={e => handleMouseUp(e)}
        role="decrementBtn"
      >
        <SOperationBtnIcon src={Subtract} alt="minus" role="minusIcon" />
      </SOperationBtn>
    </SContainer>
  );
};
