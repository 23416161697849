import { useEffect, useState } from 'react';
import { UserById } from '../../apis/userAPI/types';
import { getUserById } from '../../apis/userAPI';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectUser } from '../Login/LoginSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ActionButtons, { UserAction } from '../../components/ActionButtons/ActionButtons';
import { setSelectedUsersAction } from '../CreateMessage/createMessageSlice/actionCreators';
import {
  MemberSettingsOptionsStateType,
  SAvailabilityChipContainer,
  SPage,
  SProfilePictureWrapper,
  SSmallText,
  SUserName,
} from '../MemberSettings/MemberSettings';
import { ProfilePicture } from '../../components/ProfilePicture/ProfilePicture';
import MemberSettingsDynamicForm, {
  DynamicFormFieldType,
} from '../../components/MemberSettingsDynamicForm/MemberSettingsDynamicForm';
import { getNumbersBasedDateTextByLanguage } from '../../utils/date';
import { translate } from '../../utils/translate';
import { isNil, isEmpty } from 'lodash';
import Loader from '../../components/Loader/Loader';
import {
  handleMemberSettingsEmailClick,
  handleMemberSettingsPhoneClick,
} from '../MemberSettings/helpers';
import Options from '../../components/Options/Options';
import { resetAll, setActiveTab, setSelectedUsers } from '../CreateMessage/createMessageSlice';
import { ActiveTab } from '../../utils/enums';
import { batch } from 'react-redux';
import styled from 'styled-components';
import { SelectListUser } from '../CreateMessage/createMessageSlice/types';
import AvailabilityChip from '../../components/AvailabilityChip/AvailabilityChip';
import { mapUserByIdToSelectUser } from './helpers';

const SMemberSettingsDynamicFormWrapper = styled.div`
  margin: 1rem 0 !important;
`;

const SFlexDiv = styled.div`
  flex: 1;
`;

function Member() {
  const { memberID } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector(selectUser);

  const [isUserProfile, setIsUserProfile] = useState(false);
  const [member, setMember] = useState<UserById | null>(null);
  const [optionsState, setOptionsState] = useState<MemberSettingsOptionsStateType>({
    isOpen: false,
  });

  const getMember = async () => {
    setMember(await getUserById(Number(memberID)));
  };

  useEffect(() => {
    if (`${user?.id}` === memberID) {
      setIsUserProfile(true);
    }
    getMember();
    return () => {
      setOptionsState({ isOpen: false });
    };
  }, []);

  if (!member) return <Loader />;

  const addUserToCreateMessage = (selectListUser: SelectListUser) =>
    new Promise<void>(resolve => {
      batch(() => {
        dispatch(resetAll());
        dispatch(setActiveTab(ActiveTab.Users));
        dispatch(setSelectedUsers([selectListUser]));
      });
      resolve();
    });

  const actions: Record<string, UserAction[]> = {
    member: [
      {
        label: 'messages_createMessage',
        fireAction: async () => {
          await addUserToCreateMessage(
            mapUserByIdToSelectUser(member, { isSelected: true, admin: false })
          );
          navigate(`/createMessage/new`, {
            state: { prevPath: location.pathname, disableRecipientsChange: true },
          });
        },
      },
    ],
    ownProfile: [
      {
        label: 'groups_editProfile',
        fireAction: () => {
          dispatch(
            setSelectedUsersAction([
              mapUserByIdToSelectUser(member, {
                isSelected: true,
                title: null,
                photoFileName: null,
                email: '',
                creatorId: null,
                phoneNumber: null,
              }),
            ])
          );
          navigate(`/profile`);
        },
      },
    ],
  };

  const checkIfNullOrEmpty = (value?: string | null) => {
    if (isNil(value)) {
      return false;
    } else if (isEmpty(value)) {
      return false;
    } else {
      return true;
    }
  };

  const setOptionsOpen = (isOpen: boolean) => {
    setOptionsState(prev => ({ ...prev, isOpen }));
  };

  const emailField: DynamicFormFieldType = {
    visible: checkIfNullOrEmpty(member?.email),
    placeHolder: 'Email Address ',
    value: member?.email || '',
    onClick: handleMemberSettingsEmailClick(member?.email || '', setOptionsState, setOptionsOpen),
    editable: false,
  };
  const numberField: DynamicFormFieldType = {
    visible: checkIfNullOrEmpty(member?.phoneNumber?.split(',')[0]),
    placeHolder: 'Number Field ',
    value: member?.phoneNumber?.split(',')[0] || '',
    onClick: handleMemberSettingsPhoneClick(
      member?.phoneNumber?.split(',')[0] || '',
      setOptionsState,
      setOptionsOpen
    ),
    editable: false,
  };
  const numberField2: DynamicFormFieldType = {
    visible: checkIfNullOrEmpty(member?.phoneNumber?.split(',')[1]),
    placeHolder: 'Number Field ',
    value: member?.phoneNumber?.split(',')[1] || '',
    onClick: handleMemberSettingsPhoneClick(
      member?.phoneNumber?.split(',')[1] || '',
      setOptionsState,
      setOptionsOpen
    ),
    editable: false,
  };
  const additionalInfoField: DynamicFormFieldType = {
    visible: checkIfNullOrEmpty(member?.title),
    placeHolder: 'additionalInfo  ',
    value: member?.title || '',
    editable: false,
  };

  return (
    <SPage>
      <SProfilePictureWrapper role="profilePictureWrapper">
        <ProfilePicture profilePictureFileName={member.photoFileName || null} diameter={112} />
      </SProfilePictureWrapper>
      <SUserName role="userName">{member.displayName}</SUserName>
      <SAvailabilityChipContainer>
        <AvailabilityChip isAvailable={member.available} />
      </SAvailabilityChipContainer>
      <SMemberSettingsDynamicFormWrapper>
        <MemberSettingsDynamicForm
          fields={[additionalInfoField, emailField, numberField, numberField2]}
        />
      </SMemberSettingsDynamicFormWrapper>
      <SSmallText role="lastVisited">
        {translate(`groups_lastVisited`)}{' '}
        {getNumbersBasedDateTextByLanguage({ dateText: member?.lastAccess })}
      </SSmallText>
      <SFlexDiv />
      <ActionButtons actions={isUserProfile ? actions.ownProfile : actions?.member} />
      {optionsState.options && (
        <Options
          items={optionsState.options}
          isOpen={optionsState.isOpen}
          setIsOpen={setOptionsOpen}
          setTabBar
        />
      )}
    </SPage>
  );
}

export default Member;
