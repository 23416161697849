import styled, { css } from 'styled-components';
import { Button } from '../Button/Button';
import { palette } from '../../theme/colors';
import { CheckBoxWithSubTitle } from '../CheckBoxWithSubTitle/CheckBoxWithSubTitle';
import { DrawerModal } from '../DrawerModal/DrawerModal';

interface SButtonParams {
  $valid?: boolean;
  $resetButton?: boolean;
}

export const SButton = styled(Button)<SButtonParams>`
  width: 100%;

  margin-top: 0.8125rem;
  button {
    max-width: 100rem;
    width: 100%;
    font-size: 1rem;
    padding: 0.8125rem 0;
    font-family: 'Roboto-Medium';
    color: ${palette.raisinBlack3};
    ${props =>
      props.$valid &&
      css`
        opacity: 0.5;
        cursor: not-allowed;
      `}
  }
  ${props =>
    props.$resetButton &&
    css`
      margin-top: 1.5rem;
      button {
        background-color: ${palette.transparent};
        color: ${palette.white};
        border: 1px solid ${palette.honeyYellow};
      }
    `}
`;

export const STitle = styled.h1`
  font-weight: 500;
  font-size: 1.125rem;
  font-family: 'Roboto-Medium';
  color: ${palette.white};
  line-height: 1.5rem;

  align-self: flex-start;
`;
export const SDescription = styled.h2`
  color: ${palette.white};
  align-self: flex-start;

  margin-top: 1.5rem;

  font-family: 'Roboto-Regular';
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
`;

interface SCheckBoxWithSubTitleParams {
  $lessPadding?: boolean;
  $lessFont?: boolean;
  $withPhoto?: boolean;
}
export const SCheckBoxWithSubTitle = styled(CheckBoxWithSubTitle)<SCheckBoxWithSubTitleParams>`
  ${props =>
    props.$lessPadding &&
    css`
      padding: 1.5rem 0rem 0rem 0rem;
    `}
  .STitle {
    ${props =>
      props.$lessFont &&
      css`
        font-family: 'Roboto-Regular';
        font-weight: 400;
        margin-left: 0;
      `}
  }
  .externalPlaceHolderWrapper {
    height: 1.75rem;
    width: 1.75rem;
  }

  ${props =>
    props.$withPhoto &&
    css`
      .SImgPlaceHolderContainer {
        height: 1.75rem;
        width: 1.75rem;
        svg {
          width: 52%;
          height: 48%;
        }
      }
      .SLeftTextContainer {
        max-width: 80%;
      }
    `}
`;

export const SSeparator = styled.div`
  margin: 0 1rem;
  border-bottom: 1px solid ${palette.prussianBlue5};
`;
export const SDrawerModal = styled(DrawerModal)`
  .profileDrawer {
    max-height: 90vh;
  }
  .profileDrawer__content {
    padding: 1.25rem 0;
    max-height: calc(85vh - 25px);
  }

  .buttonWrappers {
    margin: 0 1rem;
  }
`;
export const SList = styled.ul`
  padding: 0 1rem 1.6rem 1rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: auto;
  overflow-x: visible;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
