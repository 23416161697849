import { css } from 'styled-components';
import RobotoRegular from './roboto/Roboto-Regular.woff';
import RobotoBlack from './roboto/Roboto-Black.woff';
import RobotoMedium from './roboto/Roboto-Medium.woff';
import RobotoBold from './roboto/Roboto-Bold.woff';
import RobotoThin from './roboto/Roboto-Thin.woff';
import AppleColorEmoji from './AppleColorEmoji/AppleColorEmoji.woff';

export const Fonts = css`
  @font-face {
    font-family: 'Roboto-Regular';
    src:
      local('Roboto-Regular'),
      url(${RobotoRegular}) format('woff');
  }
  @font-face {
    font-family: 'Roboto-Black';
    src:
      local('Roboto-Black'),
      url(${RobotoBlack}) format('woff');
  }
  @font-face {
    font-family: 'Roboto-Thin';
    src:
      local('Roboto-Thin'),
      url(${RobotoThin}) format('woff');
  }
  @font-face {
    font-family: 'Roboto-Medium';
    src:
      local('Roboto-Medium'),
      url(${RobotoMedium}) format('woff');
  }
  @font-face {
    font-family: 'Roboto-Bold';
    src:
      local('Roboto-Bold'),
      url(${RobotoBold}) format('woff');
  }
  @font-face {
    font-family: 'AppleColorEmoji';
    src:
      local('AppleColorEmoji' ;),
      url(${AppleColorEmoji}) format('woff');
  }
`;
