/* eslint-disable @typescript-eslint/naming-convention */

import { ReactComponent as Arrow } from '../../../assets/icons/chevron-down.svg';
import i18n, { defaultLanguage, ELanguages } from '../../../i18n';
import { useLayoutEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as BackBtn } from '../../../assets/icons/arrow-left--yellow.svg';
import { ReactComponent as Logo } from '../../../assets/brand/logo-with-text.svg';
import { ReactComponent as FlagGreatBritain } from '../../../assets/icons/flags/uk.svg';
import { ReactComponent as FlagSweden } from '../../../assets/icons/flags/sweden.svg';
import { ReactComponent as FlagHolland } from '../../../assets/icons/flags/netherlands.svg';
import { ReactComponent as FlagFinland } from '../../../assets/icons/flags/finland.svg';
import { ReactComponent as FlagUS } from '../../../assets/icons/flags/us.svg';
import { ReactComponent as FlagBrazil } from '../../../assets/icons/flags/brazil.svg';
import { ReactComponent as FlagSpain } from '../../../assets/icons/flags/spain.svg';
import { saveItem } from '../../../utils/storage';
import { SLink } from './Header.styles';
import {
  SHeaderWrapper,
  SHeader,
  LanguageSelector,
  SBalanceSpace,
} from './UnauthorizedHeader.styles';

interface IHeaderProps {
  to?: string;
  backBtn?: boolean;
}

/**
 * It's kinda a bad workaround for preventing screens from being reset after lang change;
 * In some screens the whole screen is being re-translated without Navigator update; in some they're only being updated partially.
 * Wrapping Login2fa into useCallback wont help neither, because this behaviours is not linked to state-updade, but to navigate() inside handleLanguage().
 * Using navigate(location?.pathname ?? '');  wont work neither somewhy.
 */
const langScreenRefresh_blacklist = ['/2fa'];

export const UnauthorizedHeader = (props: IHeaderProps) => {
  const { backBtn, to } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const [language, setLanguage] = useState<ELanguages>(defaultLanguage);
  const [languageList, setLanguageList] = useState([
    ...(defaultLanguage === ELanguages.se
      ? [
          {
            name: ELanguages.se,
            icon: FlagSweden,
          },
          {
            name: ELanguages.en,
            icon: FlagGreatBritain,
          },
          {
            name: ELanguages.nl,
            icon: FlagHolland,
          },
          {
            name: ELanguages.fi,
            icon: FlagFinland,
          },
        ]
      : [
          {
            name: ELanguages.pt,
            icon: FlagBrazil,
          },
          {
            name: ELanguages.es,
            icon: FlagSpain,
          },
          {
            name: ELanguages['en-TT'],
            icon: FlagUS,
          },
        ]),
  ]);

  const changeFirstLanguage = (lang: ELanguages) => {
    const foundLang = languageList.find(item => item.name === lang);
    if (foundLang) {
      const newLangListState = languageList.filter(item => item.name !== lang);
      newLangListState.unshift(foundLang);
      setLanguageList(newLangListState);
    }
  };

  useLayoutEffect(() => {
    const lang = i18n.language;
    if (lang !== null) {
      const langValue: ELanguages = (ELanguages as any)[lang];
      setLanguage(langValue);
      changeFirstLanguage(langValue);
    }
  }, [language]);

  const handleLanguage = (lang: ELanguages) => {
    sessionStorage.setItem('selectedLang', lang);
    i18n.changeLanguage(lang);
    saveItem('language', lang);
    setLanguage(lang);

    if (!langScreenRefresh_blacklist.includes(location?.pathname)) navigate('');
  };

  const SelectedLanIcon =
    languageList.find(item => item.name === language)?.icon || languageList[1].icon;

  return (
    <SHeaderWrapper>
      <SHeader>
        {backBtn && to ? (
          <SLink to={to} replace>
            <BackBtn />
          </SLink>
        ) : (
          <SBalanceSpace />
        )}
        <Logo />
        <LanguageSelector>
          <div className="lang-select">
            <SelectedLanIcon className="flag" />
            <div className="gap" />
            <Arrow className="arrow" />
          </div>
          <div className="lang-menu">
            {languageList.map(lang => (
              <span className="flag-select" key={lang.name}>
                <lang.icon
                  className="flag"
                  onClick={() => {
                    handleLanguage(lang.name);
                  }}
                />
              </span>
            ))}
          </div>
        </LanguageSelector>
      </SHeader>
    </SHeaderWrapper>
  );
};

UnauthorizedHeader.defaultProps = {
  isMessageLayout: false,
  message: null,
};
