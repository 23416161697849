import styled from 'styled-components';
import { Page } from '../../components/Page/Page';
import { SearchBarBase } from '../../components/SearchBarBase/SearchBarBase';
import { SearchFilterBar } from '../../components/SearchFilterBar/SearchFilterBar';

export const SPage = styled(Page)`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
`;

export const SListPage = styled(Page)`
  padding-top: 0rem !important;
  display: flex;
  flex-direction: column;
`;

export const SSelect = styled.div`
  min-height: 0;
  height: 100%;
`;

export const SSearchFilterBar = styled(SearchFilterBar)`
  margin: 1.25rem 0;
`;

export const SSearchBarBase = styled(SearchBarBase)`
  margin: 0 0 1.25rem 0;
`;

export const SList = styled.div`
  overflow-y: auto;
  padding-bottom: 1rem;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;
`;
