import styled from 'styled-components';
import { palette } from '../../../theme/colors';
import { toRem } from '../../../utils/toRem';

export const SAudioWave = styled.div<{ height: number; isPassed: boolean }>`
  height: ${props => toRem(props.height)};
  width: ${toRem(2)};
  border-radius: ${toRem(2)};
  background: ${props => (props.isPassed ? palette.white : palette.navy40)};
`;

export const SAudioWavesRow = styled.div<{ isPlaying?: boolean }>`
  flex: 1;
  flex-direction: row;
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;
  min-width: ${toRem(176)};
  height: ${toRem(16)};
  cursor: ${props => (props.isPlaying ? 'pointer' : 'auto')};
`;

export const SAudioPLayer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  svg {
    cursor: pointer;
  }

  .SPlayPauseButton {
    align-self: flex-start;
    width: ${toRem(20)};
    height: ${toRem(20)};
    position: relative;
    top: ${toRem(6)};
    cursor: pointer;
  }
`;

export const SRangeContainer = styled.div`
  margin-left: 0.5rem;
  position: relative;
  width: 100%;
`;

export const STime = styled.div`
  margin-top: ${toRem(6)};
  right: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;

  font-family: 'Roboto-Medium';
  font-weight: 500;
  font-size: ${toRem(11)};
  line-height: ${toRem(14.3)};
  margin-bottom: 1px;

  .sentTime {
    color: ${palette.navy20};
  }
`;
