import { useLocation, useParams } from 'react-router-dom';
import { Layout } from '../../components/Layout/Layout';
import CreateMessageList from '../../containers/CreateMessage/CreateMessageList';
import { Chat as ChatType, Reply } from '../../containers/ChatsList/chatListSlice/types';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setSelectedUsersAction } from '../../containers/CreateMessage/createMessageSlice/actionCreators';
import { selectUser } from '../../containers/Login/LoginSlice';
import { SelectListUser } from '../../containers/CreateMessage/createMessageSlice/types';
// i don't want to create entire slice with redux actions for only one property
interface ForwardPageState {
  state: {
    replyMessageText?: string;
    selectedReply: ChatType | Reply | null;
  };
}
function ForwardPage(props: { fromHoldingStatement?: boolean; fromLogNote?: boolean }) {
  const { fromHoldingStatement, fromLogNote } = props;
  const location = useLocation() as ForwardPageState;
  const dispatch = useAppDispatch();
  const authtUser = useAppSelector(selectUser);
  const createMessageProps = {
    forward: true,
    selectedReply: location.state ? location.state.selectedReply : undefined,
  };
  const { id } = useParams();

  useEffect(() => {
    const selectedAuthtUser = authtUser as SelectListUser;
    dispatch(setSelectedUsersAction(selectedAuthtUser ? [selectedAuthtUser] : []));
  }, [dispatch]);
  return (
    <Layout
      to={
        fromHoldingStatement
          ? `/message/${id}/fromHoldingStatement`
          : fromLogNote
            ? `/log-note/${id}`
            : `/message/${id}`
      }
      isMessageLayout
      message="forward_message_text"
    >
      <CreateMessageList {...createMessageProps} />
    </Layout>
  );
}

export default ForwardPage;
