import React from 'react';
import { STab, SRowContainers, SIcon } from './SidebarElement.styles';
import { SidebarSection } from './Sidebar';
import Arrow from '../../assets/icons/chevron-right--grey.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SidebarElement(props: SidebarSection) {
  const { name, icon, to, onClick } = props;
  const { t } = useTranslation();

  const nameTx = t(`${name}`);
  if (to) {
    return (
      <STab as={Link} to={to} role={`elementContainer-${name}`}>
        <SRowContainers role="content">
          <SIcon className="icon" src={icon} alt="" role="elementIcon" />
          {nameTx}
        </SRowContainers>
        <SIcon className="arrow" src={Arrow} alt="" role="arrowIcon" />
      </STab>
    );
  } else {
    return (
      <STab onClick={onClick} role={`elementContainer-${name}`}>
        <SRowContainers role="content">
          <SIcon className="icon" src={icon} alt="" role="elementIcon" />
          {nameTx}
        </SRowContainers>
        <SIcon className="arrow" src={Arrow} alt="" role="arrowIcon" />
      </STab>
    );
  }
}

export default SidebarElement;
