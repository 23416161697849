import styled from 'styled-components';

export const SimpleText = styled.p<{ gray?: boolean; fontSize: string }>`
  color: ${props =>
    props.gray ? props.theme.palette.text.documentPrimary : props.theme.palette.text.menuPrimary};
  font-family: 'Roboto-Regular';
  font-size: ${props => props.fontSize};
  padding-left: 0.3rem;
  max-width: 18rem;
  text-overflow: ellipsis;
  margin: 3px 0;
  &.selectAll {
    font-size: 1rem;
    font-weight: bold;
  }
`;

export const BottomRow = styled.div`
  display: flex;
  align-items: center;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
`;

export const OrgList = styled.div<{ height: string }>`
  margin: 0 1rem;
  height: ${props => props.height};
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
