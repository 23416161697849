import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  editChecklistGroups,
  fetchGroups,
  fetchOneChecklist,
  startChecklistAction,
} from './checklistsSlice/actionCreators';
import {
  getActiveChecklist,
  isChecklistsLoading,
  selectHasMultipleAccounts,
  selectGroupsAccountsWithFilter,
  selectChecklistGroupsWithSearchFilter,
  selectUserSelection,
  selectGroupsSelection,
  handleInitialMassSelect,
  handleSelectUser,
} from './checklistsSlice';
import BigFloatButton from '../../components/BigFloatButton/BigFloatButton';
import { useNavigate, useParams } from 'react-router-dom';
import { STab, STabList, STabPanel, STabs } from '../CreateMessage/CreateMessageList';
import { ActiveTab } from '../../utils/enums';
import { ChecklistSelectGroupsList } from './ChecklistSelectGroupsList';
import { ChecklistSelectUsersList } from './ChecklistSelectUsersList';
import { selectUser, selectUserRoles } from '../Login/LoginSlice';
import { MessageFilterFilterBar } from '../ChatsList/ChatsList.styles';
import ChecklistFilter, { ChecklistFilters, SelectedAllType } from './ChecklistFilter';
import Loader from '../../components/Loader/Loader';
import { translate } from '../../utils/translate';
import { Checklist } from './checklistsSlice/types';
import { SSearchBarBase, SPage } from './Checklists.styles';
import { SButtonWrapper, STutorialText, STabContainer } from './CreateChecklist.styles';
import { prepareChecklistAccessEditData } from './helpers';

// we are using this component in three different cases, creating new checklist template,
// starting existed checklist template and editing existed checklist template
interface CreateChecklistProps {
  start?: boolean;
  edit?: boolean;
  id?: string;
}

function CreateChecklist(props: CreateChecklistProps) {
  const { start, edit, id } = props;
  const { checklistName } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // global states required
  const roles = useAppSelector(selectUserRoles);
  const isMultipleAccounts = useAppSelector(selectHasMultipleAccounts);
  const user = useAppSelector(selectUser);

  // Computed properties
  const isSeeOrgGroups = roles?.includes('SeeOrgGroups');
  const checklist = useAppSelector(getActiveChecklist);

  const { selectedUsers, singleSelected } = useAppSelector(selectUserSelection);
  const { selectedGroups } = useAppSelector(selectGroupsSelection);

  // [UI STATES]
  const isLoading = useAppSelector(isChecklistsLoading);
  const [activeTab, setActiveTab] = useState(ActiveTab.Groups);
  const [filterOpen, setFilterOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const canSeeFilter = roles?.includes('SeeOrgGroups') && activeTab === ActiveTab.Groups && !start;

  // [STATE INFLUENCE DATA]
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = React.useState<ChecklistFilters>({
    memberFilter: isSeeOrgGroups ? ['MEMBER'] : undefined,
    selectedData: isMultipleAccounts ? 'SELECTED_ALL' : undefined,
  });

  // influenced by filters
  const checklistGroups = useAppSelector(
    selectChecklistGroupsWithSearchFilter(filters, searchText)
  );
  const groupAccounts = useAppSelector(selectGroupsAccountsWithFilter(filters));

  const updateInitialMemberFilter = () =>
    setFilters(prev => ({
      ...prev,
      memberFilter: isSeeOrgGroups ? ['MEMBER', 'NOT_MEMBER'] : undefined,
    }));

  const checkIfIsPreSelectedGroups = (_checkList: Checklist) =>
    _checkList.sharedGroups.some(
      groupId => !checklistGroups.map(group => group.id).includes(groupId)
    );

  const isChecklistAvailable = !!checklist && checklist.id === +(id || 0);
  const isNeedPrepareCase = start || edit;

  const prepareSelection = useCallback(async () => {
    if (isNeedPrepareCase && isChecklistAvailable) {
      // in case of start/edit checklist sometimes, there are preselected groups is not showing
      // because of the current filters , like the not member groups, they will be shown as selected
      // and it will be considered on the groups counter but not visible on the list, so in that case
      // we will change the filters programmatically, fortunately the accounts filter is "SELECTED_ALL"
      // be default
      if (checkIfIsPreSelectedGroups(checklist as Checklist)) updateInitialMemberFilter();
      const groupsUsersIds = checklist?.userSharesViaGroups.map(u => u.id) ?? [];
      const singleUsersIds =
        checklist?.directUserShares.map(u => u.id).filter(_id => !groupsUsersIds.includes(_id)) ??
        [];
      dispatch(
        handleInitialMassSelect({
          groupIds: checklist?.sharedGroups ?? [],
          groupsUsersIds: groupsUsersIds,
          singleUserIds: singleUsersIds,
        })
      );
    }
  }, [isChecklistAvailable]);

  useEffect(() => {
    const query = {
      menuitem: 'checklists',
      ...(!isSeeOrgGroups && { member: true }),
    };
    dispatch(fetchGroups(query, checklist?.sharedGroups, !!start));
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(handleSelectUser({ usersId: user.id }));
    }
  }, [user]);

  useEffect(() => {
    // FOR START, EDIT cases
    if (isNeedPrepareCase && !isChecklistAvailable) {
      dispatch(fetchOneChecklist(Number(id)));
    }
    prepareSelection();
  }, [checklist]);

  // work around initial isMultipleAccounts is undefined since it takes time to load
  useEffect(() => {
    if (isMultipleAccounts && filters.selectedData === undefined) {
      setFilters(prev => ({
        ...prev,
        selectedData: isMultipleAccounts ? 'SELECTED_ALL' : undefined,
      }));
    }
  }, [isMultipleAccounts]);

  // Navigation Guards, maybe we can add a confirmation popup Here
  useEffect(() => {
    if (!checklistName && !edit) navigate('/checklists');
    if (edit && !checklist) navigate('/checklists');
  }, [checklistName, edit]);

  // [STATE INFLUENCE DATA]
  const handleFilter = (_filters: ChecklistFilters) => {
    setFilters(_filters);
    setFilterOpen(!filterOpen);
  };
  // Handlers
  const handleEditAccess = () => {
    if (!checklist) {
      return;
    }

    const shareRequestData = prepareChecklistAccessEditData({
      checklist,
      selectedGroups,
      selectedUsers,
      singleSelectedUsers: singleSelected,
    });

    dispatch(
      editChecklistGroups({ ...shareRequestData, onSuccess: () => navigate('/checklists') })
    );
  };
  const handleStartChecklist = () => {
    setDisabled(true);
    dispatch(
      startChecklistAction(
        Number(id),
        { groupIds: selectedGroups, newName: checklistName ?? '', userIds: singleSelected },
        navigate
      )
    );
  };

  const groupsToShow = start
    ? checklistGroups.filter(e => checklist?.sharedGroups.includes(e.id))
    : checklistGroups;

  const areFiltersApplied = useMemo(() => {
    const { memberFilter, selectedData } = filters;
    const isMemberTypeChanged =
      !memberFilter || memberFilter?.length !== 1 || memberFilter[0] !== 'MEMBER';
    const isSelectedAccountsChanged =
      selectedData && !['SELECTED_ALL', 'UNSELECTED_ALL'].includes(selectedData as SelectedAllType);

    return isMemberTypeChanged || isSelectedAccountsChanged;
  }, [filters]);

  // renders
  const renderSearchAndFilterBar = () => {
    if (canSeeFilter) {
      return (
        <MessageFilterFilterBar
          onSearch={setSearchText}
          handleOpenFilter={() => setFilterOpen(!filterOpen)}
          isFilterApplied={areFiltersApplied}
          value={searchText}
          margin
        />
      );
    }
    return (
      <SSearchBarBase
        fallback={setSearchText}
        placeholderTx="documents_search"
        value={searchText}
      />
    );
  };

  const renderButton = () => {
    if (!!selectedUsers?.length || !!selectedGroups?.length) {
      return (
        <SButtonWrapper role="buttonsContainer">
          {edit ? (
            <BigFloatButton className="proceedButton" onClick={handleEditAccess} tx={'proceed'} />
          ) : start ? (
            <BigFloatButton
              className="proceedButton"
              onClick={handleStartChecklist}
              tx={'proceed'}
              disabled={disabled}
            />
          ) : (
            <BigFloatButton
              link={`/checklists/new/${encodeURIComponent(checklistName ?? '')}`}
              tx={'proceed'}
            />
          )}
        </SButtonWrapper>
      );
    }
    return <></>;
  };

  const initialSelectedData =
    filters.selectedData === 'SELECTED_ALL'
      ? new Set(groupAccounts.map(g => g.id))
      : filters.selectedData !== 'UNSELECTED_ALL'
        ? filters.selectedData
        : undefined;

  if (isLoading) return <Loader />;

  return (
    <SPage noBottomPadding>
      {renderSearchAndFilterBar()}
      <STutorialText role="tutorialTitle">
        {translate(`checklists_template_create_users_intro`)}
      </STutorialText>
      <STabContainer role="tabsContainer">
        <STabs
          selectedTabClassName="is-selected"
          selectedTabPanelClassName="is-selected"
          forceRenderTabPanel
        >
          <STabList>
            <STab className="groupsTab" onClick={() => setActiveTab(ActiveTab.Groups)}>
              {translate('documents_groups')} ({selectedGroups.length})
            </STab>
            <STab className="usersTab" onClick={() => setActiveTab(ActiveTab.Users)}>
              {translate('messages_users')} ({selectedUsers.length})
            </STab>
          </STabList>
          <STabPanel>
            <ChecklistSelectGroupsList
              groups={groupsToShow}
              searchText={searchText}
              isActive={activeTab === ActiveTab.Groups}
            />
          </STabPanel>
          <STabPanel>
            <ChecklistSelectUsersList
              start={start}
              searchText={searchText}
              isActive={activeTab === ActiveTab.Users}
            />
          </STabPanel>
        </STabs>
      </STabContainer>
      {renderButton()}
      <ChecklistFilter
        isOpen={filterOpen}
        setIsOpen={() => setFilterOpen(!filterOpen)}
        onFilter={handleFilter}
        data={isMultipleAccounts ? groupAccounts : undefined} // if subbaccounts
        initialMemberFilter={new Set(filters.memberFilter)}
        initialSelectedData={initialSelectedData}
      />
    </SPage>
  );
}

// [TODO] turn it to a named export component
export default CreateChecklist;
