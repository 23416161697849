import { batch } from 'react-redux';
import { AppThunk } from '../../store';
import { APP_FILTERS_KEY } from '../../utils/customHooks/useLocalStorageFilters';
import { deleteItem } from '../../utils/storage';
import { resetAlarmSlice } from '../Alarm/AlarmSlice';
import { resetCompleteChecklistStore } from '../Checklists/checklistsSlice';
import { resetDocumentSlice } from '../Documents/documentsSlice';
import { resetDocumentGroups } from '../GroupsList/groupsSlice';
import { resetUser } from '../Login/LoginSlice';

const resetStores = (): AppThunk => async dispatch => {
  batch(() => {
    dispatch(resetDocumentSlice());
    dispatch(resetDocumentGroups());
    dispatch(resetAlarmSlice());
  });
};

export const logoutUser = (): AppThunk => async dispatch => {
  deleteItem('user');
  deleteItem('csrf');
  deleteItem('organizationLogo');
  deleteItem(APP_FILTERS_KEY);

  batch(() => {
    dispatch(resetStores());
    dispatch(resetUser());
    dispatch(resetCompleteChecklistStore());
  });
};
