import {
  SReactionContainer,
  SReactionCount,
  SReactionEmoji,
  SReactionsRow,
} from './MessageContainerReactionsRow.styles';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks';
import {
  selectReactionsListByMessageOrReply,
  setSelectedReactionsMessageId,
} from '../../containers/ChatsList/chatListSlice';
import { MessageReactionsListItem } from '../../containers/ChatsList/chatListSlice/types';

interface MessageContainerReactionsRowProps {
  id: number;
  handleReactionTouched: (val: boolean) => void;
}

function MessageContainerReactionsRow(props: MessageContainerReactionsRowProps) {
  const { handleReactionTouched, id } = props;
  const dispatch = useDispatch();
  const reactions = useAppSelector(
    selectReactionsListByMessageOrReply(id)
  ) as MessageReactionsListItem[];

  const handleStateChange = (newState: boolean) => {
    handleReactionTouched?.(newState);
  };

  const openReactionsBottomSheet = () => {
    dispatch(setSelectedReactionsMessageId(id));
  };

  return (
    <div>
      <SReactionsRow
        onClick={openReactionsBottomSheet}
        onMouseDown={() => handleStateChange(true)}
        onMouseUp={() => handleStateChange(false)}
        onTouchStart={() => handleStateChange(true)}
        onTouchEnd={() => handleStateChange(false)}
      >
        {reactions.map((item, index) => (
          <SReactionContainer key={index}>
            <SReactionEmoji>{item.reactionTypeUnicode}</SReactionEmoji>
            <SReactionCount>{item.count}</SReactionCount>
          </SReactionContainer>
        ))}
      </SReactionsRow>
    </div>
  );
}

export default MessageContainerReactionsRow;
