import isElectron from 'is-electron';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import styled from 'styled-components';
import { ActionButton } from '../../components/ActionButtons/ActionButtons.style';
import { Page } from '../../components/Page/Page';
import { palette } from '../../theme/colors';
import { SearchBarBase } from '../../components/SearchBarBase/SearchBarBase';

export const SPage = styled(Page)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  font-family: 'Roboto-Regular';
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0;
  .mainContainer {
    padding-bottom: ${isElectron() ? '100px' : '0px'};
  }
  &.recipientsSelection {
    padding: 1.25rem;
  }
`;

export const SOutlinedActionButton = styled(ActionButton)`
  button {
    background: ${palette.transparent};
    border: 1px solid ${palette.earthYellow};
    color: ${palette.white};
  }
`;

export const SInfoBanner = styled.div`
  width: calc(100% - 32px);
  display: flex;
  padding: 8px;
  margin: 16px auto;
  border-radius: 12px;
  justify-content: space-between;
  background-color: ${palette.prussianBlue2};
`;

export const SScreenTitle = styled.h2`
  color: ${palette.white};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
  padding: 0 16px;
`;

export const SFeatureTitle = styled.h3`
  color: ${palette.white};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 10px;
  padding: 0 16px;
`;

export const SFeatureButton = styled.div<{ isActive?: boolean }>`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid ${props => (props.isActive ? palette.earthYellow : palette.queenBlue)};
  background: ${palette.prussianBlue};
  box-shadow: 0px 20px 20px 0px ${palette.davysGrey1};
  margin: 0 16px 16px 16px;
  cursor: pointer;
`;

export const SFeatureButtonTextBox = styled.div<{ isFilled?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${props => (props.isFilled ? palette.white : palette.grayx11gray)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const SIcon = styled.img`
  height: 30px;
  width: 30px;
`;

export const SRow = styled.div`
  display: flex;
  align-items: center;
`;
export const SQuestionIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

export const SBannerText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
`;

export const SLearnMoreText = styled.p`
  font-size: 14px;
  font-family: 'Roboto-Bold';
  line-height: 140%;
  color: ${palette.earthYellow};
  cursor: pointer;
`;

export const SMessageInputWrapper = styled.div<{ haveFocus?: boolean }>`
  width: calc(100% - 32px);
  margin: auto;
  height: 240px;
  border: 1px solid ${props => (props.haveFocus ? palette.earthYellow : palette.queenBlue)};
  background: ${palette.prussianBlue};
  box-shadow: 0px 20px 20px 0px ${palette.davysGrey1};
  padding: 16px 16px 12px 16px;
  align-self: stretch;
  border-radius: 12px;
`;

export const SMessageInput = styled.textarea`
  background: ${palette.prussianBlue};
  border: none;
  width: 100%;
  height: 198px;
  color: ${palette.white};
  font-size: 14px;
  resize: none;
  border: none;
  font-family: 'Roboto-Regular';
  ::placeholder {
    font-style: italic;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  :focus-visible {
    outline: none;
  }

  &::placeholder {
    color: ${palette.grayx11gray};
  }
`;

export const SDivider = styled.div`
  width: calc(100% - 32px);
  background: ${palette.queenBlue};
  padding: 0.5px 16px;
  margin: 16px;
`;
export const SNotifySwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px;
  margin-bottom: 40px;
  cursor: pointer;
  h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding-right: 16px;
  }
`;
export const SNotifySwitch = styled.div<{ isEnabled?: boolean }>`
  display: flex;
  align-items: center;
  width: 51px;
  height: 30px;
  background: ${palette.prussianBlue};
  border-radius: 15px;
  justify-content: ${props => (props.isEnabled ? 'flex-end' : 'flex-start')};
  .switch-circle {
    background: ${props => (props.isEnabled ? palette.earthYellow : palette.white)};
    height: 26px;
    width: 26px;
    border-radius: 50%;
  }
`;

export const SActivateButtonContainer = styled.div<{ isActive?: boolean }>`
  width: ${isElectron() ? '100vw' : '100%'};
  position: ${isElectron() ? 'absolute' : 'relative'};
  bottom: 0;
  left: 0;
  padding: 24px 16px;
  z-index: 2;
  background: ${props => (props.isActive ? palette.transparent : palette.prussianBlue2)};
`;

export const SActivateButton = styled.button<{ isActive?: boolean }>`
  opacity: ${props => (props.isActive ? 1 : 0.5)};
  max-width: calc(26rem - 32px);
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 0;
  z-index: 1;
  background: ${palette.earthYellow};
  font-size: 16px;
  font-weight: 500;
  color: ${palette.raisinBlack3};
  border-radius: 25.5px;
`;

export const STabs = styled(Tabs)`
  font-size: 12px;
  width: 100%;
  padding-top: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const STabList = styled(TabList)<{ $disabled?: boolean }>`
  position: fixed;
  max-width: calc(26rem - 2.5rem);
  padding-top: 16px;
  width: 100%;
  display: ${props => (props.$disabled ? 'none' : 'flex')};
  list-style-type: none;
  margin: 0;
  z-index: 5;
  background: ${palette.navyBlue};
`;

export const STab = styled(Tab)<{ disabled?: boolean }>`
  cursor: pointer;
  color: ${props => props.disabled && palette.silver};
  width: 50%;
  text-align: center;
  user-select: none;
  padding: 0px 0 10px 0;
  border-bottom: 1px solid ${palette.queenBlue};
  font-family: 'Roboto-Regular';
  font-size: 14px;

  &.is-selected {
    border-bottom: 2px solid ${palette.honeyYellow};
    color: ${palette.honeyYellow};
    margin-bottom: -0.5px;
  }

  &:focus {
    outline: none;
  }
`;

export const STabPanel = styled(TabPanel)<{ $disabled?: boolean }>`
  display: none;
  &.is-selected {
    padding-top: ${props => (props.$disabled ? '1.25rem' : '4rem')};
    min-height: 0;
    display: block !important;
  }
`;

export const SRecipientsButtonsContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 1.5rem 1rem;

  background: ${palette.prussianBlue2};
`;
export const STimeAddedBanner = styled.div`
  background: ${palette.prussianBlue};
  top: calc(4.81rem - 10px);
  padding: 20px 20px 10px 20px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 26rem;
  width: 100%;
  z-index: 10;
  p {
    font-size: 12px;
  }
  img {
    cursor: pointer;
  }
`;
export const STimerContainer = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 162px; //footer height
  margin: 0 auto;
  max-width: 26rem;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
`;
export const STimerCircle = styled.button`
  height: 280px;
  width: 280px;
  border-radius: 50%;
  background: ${palette.prussianBlue2};
  position: relative;
  svg {
    transform: rotate(-90deg);
  }
  h1 {
    color: ${palette.white};
    font-size: 60px;
    position: absolute;
    top: 110px;
    justify-content: center;
    width: 100%;
  }
`;
export const STimerTitleText = styled(SScreenTitle)`
  padding: 0 72px;
  text-align: center;
  margin-bottom: 24px;
`;
export const STimerActionsContainer = styled.div<{ isTransparent?: boolean }>`
  background: ${props => (props.isTransparent ? palette.transparent : palette.prussianBlue2)};
  padding: 32px 0px 40px 0px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;

  .content {
    padding: 0 40px;
    max-width: 26rem;
    margin: auto;
  }

  border-radius: 12px 12px 0px 0px;
`;
export const STimeAddRow = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;
export const STimeAddButton = styled(SOutlinedActionButton)`
  width: 50%;
  height: 35px;
  button {
    height: 35px;
  }
`;
export const SDeactivateButton = styled(ActionButton)`
  height: 39px;
  button {
    height: 39px;
  }
`;
export const SNotifyInfoRow = styled(SRow)`
  margin-top: 38px;
  padding-bottom: 62px;
  p {
    font-size: 12px;
    color: ${palette.grayx11gray};
    padding-left: 4px;
  }
`;
export const SNavigateToChatButton = styled(ActionButton)`
  margin-top: 38px;
`;

export const SSearchBar = styled(SearchBarBase)<{ margin?: boolean }>`
  margin-bottom: 0.8125rem;
`;

export const RecipientsTabsListWrapper = styled.div`
  height: 100%;
  min-height: 0;
`;
