import { decode } from 'html-entities';
import React, { ReactNode, useEffect, useState } from 'react';
import { getImage } from '../../../apis/mediaAPI';
import { MessageType } from '../../../utils/enums';
import MessageContainer from '../../MessageContainer/MessageContainer';
import { SMessageText } from '../TextMessage/TextMessage.styles';
import ImagesModal from './ImagesModal';
import MessagesImagesGrid from './MessagesImagesGrid';
import { SMessageTextContainer } from './ImageMessage.styles';
import { t } from 'i18next';

interface ImageMessageProps {
  style?: React.CSSProperties;
  photoFileNames: string[];
  messageText?: string;
  isSent: boolean;
  sentDateTime?: string;
  senderImage: string | null;
  senderName: string;
  isSameSender?: boolean;
  messageTo: string | null;
  toggleModal?: () => void;
  showOnlyAttachment?: boolean;
  forwarded?: boolean;
  edited?: boolean;

  renderReplyToMessage?: () => ReactNode;
  id?: number;

  didFetchPhoto?: () => void;
}

function ImageMessage(props: ImageMessageProps) {
  const {
    toggleModal,
    photoFileNames,
    isSent,
    sentDateTime,
    senderImage,
    isSameSender = false,
    senderName,
    messageText,
    messageTo,
    showOnlyAttachment = false,
    forwarded,
    edited,
    renderReplyToMessage,
    id,
    didFetchPhoto,
  } = props;

  const [imagesToShow, setImagesToShow] = useState<string[]>([]);
  const [isLightBoxOpen, setIsLightBoxOpen] = useState<string | null>(null);
  const [imgKey, setImgKey] = useState(-1);
  const haveMessageText = !!messageText?.length;

  const getImages = async () => {
    const imgArr = [];
    for (const img of photoFileNames) {
      const res = await getImage({ imageName: img, size: 'large', svg: false });
      if (res) {
        imgArr.push(res);
      }
    }
    setImagesToShow(imgArr);
    didFetchPhoto?.();
  };

  useEffect(() => {
    getImages();
  }, [photoFileNames]);

  const messageType = isSent ? MessageType.SentImageMessage : MessageType.ReceivedImageMessage;

  const handleImageChange = (index: number, next: boolean) => {
    let nextImage = '';
    if (index > -1) {
      if (next && imagesToShow.length !== index + 1) {
        nextImage = imagesToShow[index + 1];
        setImgKey(imgKey + 1);
        setIsLightBoxOpen(nextImage);
      }
      if (!next && index !== 0) {
        nextImage = imagesToShow[index - 1];
        setImgKey(imgKey - 1);
        setIsLightBoxOpen(nextImage);
      }
    }
  };

  return (
    <MessageContainer
      hasReply={!!renderReplyToMessage}
      messageType={messageType}
      sentDateTime={haveMessageText ? sentDateTime : undefined}
      senderImage={senderImage}
      senderName={senderName}
      isSameSender={isSameSender}
      messageTo={messageTo}
      toggleModal={!isLightBoxOpen && !!toggleModal ? toggleModal : undefined}
      showOnlyAttachment={showOnlyAttachment}
      forwarded={forwarded}
      edited={edited}
      id={id}
    >
      {renderReplyToMessage && renderReplyToMessage()}

      <div>
        <MessagesImagesGrid
          photoFileNames={imagesToShow}
          setIsLightBoxOpen={setIsLightBoxOpen}
          setKey={setImgKey}
          // temporarily fix the white the chat message screen
          // photoFileNames is sometimes = undefined and make the app crash
          amount={photoFileNames?.length}
          didFetchPhoto={didFetchPhoto}
          sentDateTime={haveMessageText ? undefined : sentDateTime}
        />
        {haveMessageText && (
          <SMessageTextContainer>
            <SMessageText margin role="messageText" className="imageMessage">
              {decode(messageText)}
              {/* Necessary to get space */}
              <span className="editedReplacer">
                --{sentDateTime}
                {edited && `${t('messages_edited')}`}
              </span>
            </SMessageText>
          </SMessageTextContainer>
        )}
      </div>
      <ImagesModal
        imgKey={imgKey}
        img={isLightBoxOpen}
        isOpen={isLightBoxOpen !== null}
        closeModal={() => setIsLightBoxOpen(null)}
        handleImageChange={handleImageChange}
        imagesCount={imagesToShow.length}
      />
    </MessageContainer>
  );
}

export default ImageMessage;
