import styled, { css } from 'styled-components';
import { palette } from '../../theme/colors';

const MAX_MEDIA_COUNT = 3;

export const SEmptyTitleContainer = styled.div`
  height: 1.25rem;
`;

export const SContainer = styled.div`
  font-family: 'Roboto-Regular';
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${palette.white};
  .subTitleName {
    color: ${palette.silver};
  }
  .subTitle {
    span {
      font-size: 0.75rem;
      font-family: 'Roboto-Regular';
    }
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  flex-grow: 1;
  max-width: 90%;
`;

export const SWrapper = styled.div`
  cursor: pointer;
  padding: 1.25rem;
  :not(:last-child) {
    margin-bottom: 0.5rem;
  }
  width: 90%;

  background-color: ${palette.prussianBlue};
  border-radius: 0.625rem;

  display: flex;
  flex-direction: row;
`;

export const SCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .statment-date {
    max-width: 30%;
  }
`;

export const SSubjectText = styled.p`
  padding: 3px 5px;
  font-family: 'Roboto-Medium';
  font-weight: 600;
  font-size: 0.8125rem;
  line-height: 0.93rem;

  border-radius: 0.25rem;
  border: 1px solid ${palette.queenBlue};
  max-width: 50%;
`;

export const STitle = styled.p`
  font-family: 'Roboto-Medium';
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

export const SCardBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.6rem;

  .statment-time {
    align-self: flex-end;
    max-width: 30%;
    min-width: 10%;
    font-size: 0.7125rem;
  }
`;

export const SMediaContainer = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
`;

export const SImage = styled.img<{ index: number }>`
  display: inline-block;
  height: 2.5rem;
  width: 2.5rem;
  aspect-ratio: 1;

  ${props => css`
    grid-area: 1 / 1 / 2 / 2;
    z-index: ${4 - props.index};
    margin-left: ${6 * props.index}px;
  `};
`;

export const STitlesContainer = styled.div<{
  mediaLenght?: number;
}>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  min-width: 0;

  margin: 0 0.5rem 0 0rem;
  ${props => css`
    margin-left: ${0.375 * (props.mediaLenght ?? MAX_MEDIA_COUNT) + 0.5}rem;
  `}
`;

export const PlaceHolderWrapper = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${palette.nightSkyBlue};
  border-radius: 999rem;
  padding: 0.5rem;
`;

export const SMoreOptionBtn = styled.button`
  background: none;
  border: none;
  padding: 0 0.6rem;
  margin-left: 0.6rem;
  cursor: pointer;
`;
