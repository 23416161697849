import { translate } from '../../utils/translate';
import { EmptyListFallbackWrapper } from './EmptyListFallback.styles';
import defaultImage from '../../assets/images/box-open.svg';

interface IEmptyListFallbackProps {
  image?: string;
  listLength: number;
  isLoading?: boolean;
  searchTerm?: string;
  emptyListTx: string;
  emptySearchTx?: string;
  className?: string;
  ignoreSearchTermInFallbackText?: boolean;
}

export const EmptyListFallback: React.FC<IEmptyListFallbackProps> = ({
  image = defaultImage,
  listLength,
  isLoading = false,
  searchTerm,
  emptyListTx,
  emptySearchTx = emptyListTx,
  className,
  ignoreSearchTermInFallbackText = false,
}: IEmptyListFallbackProps) => {
  if (listLength > 0 || isLoading) {
    return <></>;
  }

  const showSearchTerm = !!searchTerm && !ignoreSearchTermInFallbackText;
  return (
    <EmptyListFallbackWrapper className={className}>
      <img role="image" src={image} alt="" />
      <p role="emptyText">
        {translate(searchTerm ? emptySearchTx : emptyListTx)}
        {showSearchTerm ? ' ' + searchTerm : ''}
      </p>
    </EmptyListFallbackWrapper>
  );
};
