import { isEqual } from 'lodash';
import { batch } from 'react-redux';
import { setIsError, setIsLoading } from '.';
import { addGroupToMessage, addRecipientsToMessage } from '../../../apis/chatAPI';
import { getGroupMembers } from '../../../apis/groupsAPI';
import { AppThunk } from '../../../store';
import { fetchCurrentChat } from '../../ChatsList/chatListSlice/actionCreators';
import { SelectedGroup } from '../../CreateMessage/SelectGroupsList';

export const addRecipients =
  (
    messageId: number,
    groups: SelectedGroup[],
    usersIds: number[],
    onFinish: () => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      const currentRecipients = getState().chatList.messagesRecipients;
      const currentRecipientsIdsList = currentRecipients.map(rec => rec.userID);
      const groupMembersIDsList: number[] = [];
      const user = getState().user.user;
      dispatch(setIsLoading(true));
      for await (const group of groups) {
        const groupMembers = await getGroupMembers({ id: group.id });
        const currentGroupMembersIds: number[] = [];
        groupMembers.forEach(member => {
          if (
            !isEqual(user?.id, member.userID) &&
            !currentRecipientsIdsList.includes(member.userID)
          ) {
            currentGroupMembersIds.push(member.userID);
            groupMembersIDsList.push(member.userID);
          }
        });
        await addGroupToMessage(messageId, group.id, currentGroupMembersIds);
      }
      if (usersIds?.length) {
        usersIds = usersIds.filter(
          id => !groupMembersIDsList.includes(id) && !currentRecipientsIdsList.includes(id)
        );
        if (usersIds?.length) {
          await addRecipientsToMessage(messageId, usersIds);
        }
      }
      batch(() => {
        dispatch(fetchCurrentChat(`${messageId}`));
        dispatch(setIsLoading(false));
      });
      onFinish();
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };
