import { SelectListUser } from '../../CreateMessage/createMessageSlice/types';
import { Group } from '../../GroupsList/groupsSlice/types';

export interface PanicButtonState {
  selectedGroups: Group[];
  selectedUsers: SelectListUser[];
  groups: Group[];
  users: SelectListUser[];
  usersLoading: boolean;
  groupsLoading: boolean;
  isLoading: boolean;
  messageText: string;
  locationData: LocationData | null;
  alarmData: PanicAlarmData | null;
  error: string | null;
  isLocationDenied: boolean;
}

export interface LocationData {
  lat: number | null;
  lng: number | null;
  name?: string | null;
  address?: string | null;
}

export interface PanicAlarmData {
  groupIds: number[];
  recipientIds: number[];
  alarmStatus: PanicAlarmState;
  text: string;
  messageId?: number;
}

export interface PanicAlarmActivationData {
  text: string;
  groupIds: number[];
  userIds: number[];
}

export enum PanicAlarmState {
  Active,
  Unlocked,
  Sent,
}
