import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Layout } from '../components/Layout/Layout';
import { BrowseFolder } from '../containers/BrowseFolder/BrowseFolder';
import { selectDocumentGroupById, selectFolderById } from '../containers/Documents/documentsSlice';
import { selectUser } from '../containers/Login/LoginSlice';

import { useAppSelector } from '../hooks';
import { SOptions } from '../containers/Documents/styles';

export const BrowseFolderPage = () => {
  const navigate = useNavigate();

  const { folderId, groupDocumentId } = useParams();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const groupFolder = useAppSelector(selectDocumentGroupById(Number(groupDocumentId)));
  const user = useAppSelector(selectUser);
  const isManager = user?.roles?.includes('DocumentsManager');
  const folder = useAppSelector(selectFolderById(Number(folderId)));

  const handleManageOffline = () => navigate('/documents/edit/manageOffline');

  const menuOptions = [
    {
      name: 'documents_manage_offline_files',
      callback: handleManageOffline,
    },
  ];

  const handleDots = useCallback(() => setIsMenuOpen(true), [folderId, groupDocumentId]);

  return (
    <Layout
      isMessageLayout
      message={folder?.Name || 'documents'}
      to={'/documents'}
      subMessageText={groupFolder?.name}
      dotsCallBack={handleDots}
      showDots={isManager}
      showBottomTabs
    >
      <>
        <BrowseFolder />
        <SOptions items={menuOptions} isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} setTabBar />
      </>
    </Layout>
  );
};
