import { useEffect, useRef, useState } from 'react';
import {
  AdditionalUsersLoaderContainer,
  SList,
  SLoaderContainer,
  SRecipientItemContainer,
} from './RecipientsSelection.styles';
import Loader from '../Loader/Loader';
import { isInViewport } from '../../utils/helperFunctions';
import { Group } from '../../containers/GroupsList/groupsSlice/types';
import { GroupSelectItem } from '../Chat/GroupSelectItem';
import { HighlightSearchTerm } from '../Checklists/HighlightSearchTerm/HighlightSearchTerm';
import { checkGroupsType } from '../../containers/CreateMessage/helpers';

// page and setPage is not used locally, so it an be changed from parent component.
// For example when search text will change setPage(0) will be set from parent
interface GroupsSelectionListProps {
  selectedGroupsIdsList: number[];
  notSelectableGroupsIdsList?: number[];
  groups: Group[];
  isLoading?: boolean;
  searchText?: string;
  className?: string;
  page: number;
  emptyListFallbackComponent?: React.ReactElement;
  showGroupType?: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onGroupClick: (user: Group) => void;
  loadGroups: () => void;
}

export const GroupsSelectionList = (props: GroupsSelectionListProps) => {
  const {
    selectedGroupsIdsList,
    notSelectableGroupsIdsList = [],
    groups,
    isLoading = false,
    searchText,
    className,
    page,
    emptyListFallbackComponent,
    showGroupType = false,
    setPage,
    onGroupClick,
    loadGroups,
  } = props;
  const groupsListRef = useRef<HTMLDivElement>(null);
  const [lastReachedItemIndex, setLastReachedItemIndex] = useState<number>(0);

  const handleMouseWheel = () => {
    const lastItemIndex = groups.length;
    const haveReachedEnd = isInViewport(document.getElementById(`group-${lastItemIndex}`));
    if (haveReachedEnd && !isLoading && lastReachedItemIndex !== lastItemIndex) {
      setLastReachedItemIndex(lastItemIndex);
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (!groups.length) loadGroups();
  }, []);

  useEffect(() => {
    handleMouseWheel();
  }, [groups]);

  useEffect(() => {
    if (page > 0) {
      loadGroups();
    }
  }, [page]);

  useEffect(() => {
    if (!groupsListRef?.current) return;

    groupsListRef.current.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, [searchText]);

  const isGroupSelected = (itemId: number) => {
    return [...selectedGroupsIdsList].includes(itemId);
  };

  const shownGroupType = (group: Group) => {
    return showGroupType ? checkGroupsType(group.groupType!) : null;
  };

  if (!groups.length && isLoading) {
    return (
      <SLoaderContainer>
        <Loader />
      </SLoaderContainer>
    );
  }

  return (
    <SList onScroll={handleMouseWheel} ref={groupsListRef} className={className}>
      <>
        {!groups.length && emptyListFallbackComponent ? emptyListFallbackComponent : null}
        {groups.map((group, index) => (
          <SRecipientItemContainer
            key={`group-${group.id}`}
            haveDivider={index < groups.length - 1}
            role={`groupItem-${group.name}`}
            id={`group-${index + 1}`}
          >
            <GroupSelectItem
              name={HighlightSearchTerm(group.name, searchText)}
              membersCount={group.groupMembersCount}
              type={shownGroupType(group)}
              isSelected={isGroupSelected(group.id)}
              photoFileName={group.imageFileName || undefined}
              onCardPress={() => onGroupClick(group)}
              disable={notSelectableGroupsIdsList.includes(group.id)}
            />
          </SRecipientItemContainer>
        ))}
        {groups.length && isLoading ? (
          <AdditionalUsersLoaderContainer role="additionalUsersLoader">
            <Loader />
          </AdditionalUsersLoaderContainer>
        ) : null}
      </>
    </SList>
  );
};
