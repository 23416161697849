import styled from 'styled-components';
import { palette } from '../../../theme/colors';

export const SChecklistSettingsContainer = styled.div`
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translate(-50%);
  max-height: 100vh;
  width: 100%;
  padding: 0 1rem;
  max-width: 26rem;
  z-index: 999;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .wrapper + * {
    margin-top: 0.5rem;
  }
`;

export const SChecklistSettingsWrapper = styled.div<{ button?: boolean }>`
  cursor: ${props => props.button && 'pointer'};
  background-color: ${palette.prussianBlue2};
  border-radius: 14px;
  max-height: 24rem;
  height: 100%;
  width: 100%;
  padding: ${props => (props.button ? '0.5rem 0' : '1.5rem')};
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
`;

export const SChecklistSettings = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;
}
`;

export const STitle = styled.h1`
  margin-bottom: 1.25rem;
  margin-left: 2px;
  text-align: left;
  font-size: 1rem;
  font-family: 'Roboto-Medium';
  color: ${palette.white};
  line-height: 19px;
`;

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  div + * {
    margin-top: 0.5rem;
  }
`;

export const SBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
`;

export const SRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  min-height: 0;
`;

export const SName = styled.span`
  color: ${palette.silver};
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 14px;
  min-width: 5rem;
`;

export const SValue = styled.span`
  text-align: end;
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 14px;
  min-height: 1rem;
`;

export const SSharedContainer = styled.div`
  display: flex;
  flex-direction: column;
  * + * {
    margin-top: 10px;
  }
  text-align: right;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SChecklistButton = styled.div`
  font-family: 'Roboto-Regular';
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 1.5rem;
`;

export const SLine = styled.hr`
  margin: 1.25rem 0;
  width: 100%;
  border: 1px solid ${palette.tinyBorder};
`;
