import styled from 'styled-components';
import { palette } from '../../../theme/colors';
import { toRem } from '../../../utils/toRem';

export const SGridItem = styled.div<{
  isLoading?: boolean;
  singleImage: boolean;
  showWideImage: boolean;
  haveMoreThanThree: boolean;
  twoImages: boolean;
}>`
  cursor: pointer;
  border-radius: ${toRem(10)};
  overflow: hidden;
  width: 100%;
  min-height: ${props => props.isLoading && props.singleImage && '18rem'};
  grid-area: ${props =>
    props.singleImage
      ? '1/1/span1/span2'
      : props.showWideImage
        ? 'auto / 1 / span 1 / span 2'
        : ''};
  height: ${props =>
    props.haveMoreThanThree ? '97px' : props.singleImage || props.twoImages ? '100%' : '77px'};
`;

export const SImageContainer = styled.div`
  height: 100%;
  position: relative;
`;

export const SImage = styled.img<{ backdrop: boolean }>`
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 24rem;
  filter: ${props => props.backdrop && 'brightness(0.45)'};
`;

export const SMoreImagesText = styled.p`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: ${palette.white};
  font-size: 34px;
  font-family: 'Roboto-Regular';
  font-weight: 400;
`;
