import _ from 'lodash';
import { useState } from 'react';
import Loader from '../../Loader/Loader';
import ImagesMessagesItem from './ImagesMessagesItem';
import { STime, SGrid, SImageLoadingReplacer } from './MessagesImagesGrid.styles';

interface MessagesImagesGridProps {
  photoFileNames: string[];
  setIsLightBoxOpen: (img: string | null) => void;
  setKey: React.Dispatch<React.SetStateAction<number>>;
  amount: number;
  sentDateTime?: string;
  didFetchPhoto?: () => void;
}

function MessagesImagesGrid(props: MessagesImagesGridProps) {
  const { photoFileNames, setIsLightBoxOpen, setKey, sentDateTime, didFetchPhoto } = props;

  const [expanded, setExpanded] = useState(false);
  const showMoreImages = photoFileNames.length > 4 && !expanded;

  const toggleExpand = () => {
    if (didFetchPhoto) didFetchPhoto();
    setExpanded(true);
  };

  if (_.isEmpty(photoFileNames)) {
    return (
      <SImageLoadingReplacer>
        <Loader />
      </SImageLoadingReplacer>
    );
  }

  return (
    <SGrid role="imagesGrid" $isExpanded={expanded}>
      {_.map(expanded ? photoFileNames : photoFileNames.slice(0, 4), (photo, index) => (
        <ImagesMessagesItem
          key={`imagesMessagesItem-${index}`}
          setIsLightBoxOpen={img => {
            setIsLightBoxOpen(img);
            setKey(index);
          }}
          photo={photo}
          imagesLength={photoFileNames.length}
          showMoreImages={showMoreImages && index === 3}
          isLastInList={photoFileNames.length === index + 1}
          expanded={expanded}
          handleExpand={toggleExpand}
        />
      ))}
      {sentDateTime && <STime>{sentDateTime}</STime>}
    </SGrid>
  );
}

export default MessagesImagesGrid;
