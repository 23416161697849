import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { palette } from '../../theme/colors';
import { Button } from './Button';

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  to?: string;
  text?: string;
  tx?: string;
  marginBottom?: string;
  marginTop?: string;
  isDisplayed?: boolean;
  isFullWidth?: boolean;
}

interface FilterButtonProps {
  readonly $mBottom?: string;
  readonly $mTop?: string;
  readonly $isFullWidth?: boolean;
}

const SResetFiltersButton = styled(Button)<FilterButtonProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => props?.$mBottom ?? 0};
  margin-top: ${props => props?.$mTop ?? 0};
  height: 45px;
  button {
    width: 100%;
    max-width: ${props => (props.$isFullWidth ? '100rem' : undefined)};
    font-size: 1rem;
    padding: 0.8125rem 0;
    font-family: 'Roboto-Medium';
    color: ${palette.raisinBlack3};
    background-color: ${palette.transparent};
    color: ${palette.white};
    border: 1px solid ${palette.honeyYellow};
  }
`;

export const ResetFiltersButton = (props: ButtonProps) => {
  const {
    text,
    onClick,
    tx,
    className,
    marginBottom,
    marginTop,
    isFullWidth = false,
    isDisplayed = true,
  } = props;

  const { t } = useTranslation();

  if (!isDisplayed) return <></>;

  return (
    <SResetFiltersButton
      tx={tx}
      text={text ?? t('documents_reset_filters')}
      onClick={onClick}
      className={className}
      $mTop={marginTop}
      $mBottom={marginBottom}
      $isFullWidth={isFullWidth}
    />
  );
};
