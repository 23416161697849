import { GroupDetail, GroupMember } from '../../containers/GroupDetail/groupDetailSlice/types';
import { Group } from '../../containers/GroupsList/groupsSlice/types';
import { objectToQueryParams } from '../../utils/format';
import { getAntiForgeryToken } from '../authApi/authAPI';
import { ApiCore } from '../utils/core';
import { getGroupMembersParams, GetGroupsQuery } from './types';

const url = 'Groups';

const ApiGroups = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: false,
  patch: true,
  remove: false,
  singleExtra: true,
  url: url,
});

export const getGroups = (query?: GetGroupsQuery) => {
  return ApiGroups.performExtra<Group[]>({
    method: 'get',
    extraResource: `?${objectToQueryParams(query)}`,
  });
};

export const deleteMember = async () => {
  await getAntiForgeryToken();
};

export const getGroupById = (id: number) => {
  return ApiGroups.getSingle<GroupDetail>(id);
};

export const getGroupMembers = (params: getGroupMembersParams) => {
  const { id, query } = params;
  return ApiGroups.performExtra<GroupMember[]>({
    extraResource: `members?${objectToQueryParams(query)}`,
    method: 'GET',
    id: id,
  });
};
