import * as React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import alarmTriangle from '../../assets/icons/warning-triangle--red.svg';
import { EAlertType } from '../../notifications/types';
import {
  SCancelButton,
  SConfirmButton,
  SModalWrapper,
  SOverLay,
  SDescription,
} from '../ConfirmDialog/style';
import {
  SButtonWrapper,
  SModalContainer,
  SNotificationIcon,
  STextContainer,
  STitle,
} from './NotificationModal.styles';

interface INotificationModalProps {
  isOpen: boolean;
  title: string;
  description: string;
  onSubmit: () => void;
  onCancel: () => void;
  confirmText: string;
  cancelText: string;
  titleColor: 'white' | 'green';
  showIcon: boolean | undefined;
  showModal: boolean;
  closeBtnOnly: boolean | undefined;
  stylePreset: string | undefined;
}

const NotificationModal = (props: INotificationModalProps) => {
  const {
    isOpen,
    title,
    description,
    onSubmit,
    onCancel,
    confirmText,
    cancelText,
    titleColor,
    showIcon,
    closeBtnOnly,
    stylePreset,
  } = props;

  const { t } = useTranslation();

  return isOpen
    ? ReactDOM.createPortal(
        <React.Fragment>
          <SOverLay />
          <SModalWrapper>
            <SModalContainer>
              {showIcon && (
                <SNotificationIcon src={alarmTriangle} alt="alarm icon"></SNotificationIcon>
              )}
              <STextContainer>
                <STitle className={showIcon ? 'activatedAlarmTitle' : ''} titleColor={titleColor}>
                  {title}
                </STitle>
                <SDescription
                  className={
                    stylePreset === EAlertType.selfAlarmClosedApp ? 'test-alarm-description' : ''
                  }
                >
                  {description}
                </SDescription>
              </STextContainer>
              <SButtonWrapper closeBtnOnly={closeBtnOnly}>
                {onCancel && (
                  <SCancelButton
                    className={
                      stylePreset === EAlertType.selfAlarmClosedApp ? 'failedBtn' : 'button'
                    }
                    onClick={onCancel}
                  >
                    {t(cancelText)}
                  </SCancelButton>
                )}
                {onSubmit && !closeBtnOnly && (
                  <SConfirmButton
                    className={
                      stylePreset === EAlertType.selfAlarmClosedApp ? 'workedBtn' : 'button'
                    }
                    onClick={() => onSubmit()}
                  >
                    {t(confirmText)}
                  </SConfirmButton>
                )}
              </SButtonWrapper>
            </SModalContainer>
          </SModalWrapper>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default NotificationModal;
