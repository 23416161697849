import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { Page } from '../../components/Page/Page';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { ActionButton } from '../../components/ActionButtons/ActionButtons.style';
import isElectron from 'is-electron';
import { SearchBarBase } from '../../components/SearchBarBase/SearchBarBase';

export const SPage = styled(Page)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  font-family: 'Roboto-Regular';
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0;
  .mainContainer {
    padding-bottom: ${isElectron() ? '100px' : '0px'};
    flex: ${isElectron() ? undefined : 1};
  }

  &.recipientsSelection {
    padding: 1.25rem;
  }
`;

export const SOutlinedActionButton = styled(ActionButton)`
  button {
    background: ${palette.transparent};
    border: 1px solid ${palette.earthYellow};
    color: ${palette.white};
  }
`;

export const SInfoBanner = styled.div`
  width: calc(100% - 32px);
  display: flex;
  padding: 8px;
  margin: 16px auto;
  border-radius: 12px;
  justify-content: space-between;
  background-color: ${palette.prussianBlue2};
`;

export const SFeatureTitle = styled.h3`
  color: ${palette.white};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 10px;
  padding: 0 16px;
`;

export const SFeatureButton = styled.div<{ isActive?: boolean }>`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid ${props => (props.isActive ? palette.earthYellow : palette.queenBlue)};
  background: ${palette.prussianBlue};
  box-shadow: 0px 20px 20px 0px ${palette.davysGrey1};
  margin: 0 16px 16px 16px;
  cursor: pointer;
`;

export const SFeatureButtonTextBox = styled.div<{ isFilled?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${props => (props.isFilled ? palette.white : palette.grayx11gray)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const SRow = styled.div`
  display: flex;
  align-items: center;
`;
export const SQuestionIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

export const SBannerText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
`;

export const SLearnMoreText = styled.p`
  font-size: 14px;
  font-family: 'Roboto-Bold';
  line-height: 140%;
  color: ${palette.earthYellow};
  cursor: pointer;
`;

export const SMessageInputWrapper = styled.div<{ haveFocus?: boolean }>`
  width: calc(100% - 32px);
  margin: auto;
  height: 240px;
  border: 1px solid ${props => (props.haveFocus ? palette.earthYellow : palette.queenBlue)};
  background: ${palette.prussianBlue};
  box-shadow: 0px 20px 20px 0px ${palette.davysGrey1};
  padding: 16px 16px 12px 16px;
  align-self: stretch;
  border-radius: 12px;
`;

export const SMessageInput = styled.textarea`
  background: ${palette.prussianBlue};
  border: none;
  width: 100%;
  height: 198px;
  color: ${palette.white};
  font-size: 14px;
  resize: none;
  border: none;
  font-family: 'Roboto-Regular';
  ::placeholder {
    font-style: italic;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  :focus-visible {
    outline: none;
  }

  &::placeholder {
    color: ${palette.grayx11gray};
  }
`;

export const SActivateButtonContainer = styled.div<{ isActive?: boolean }>`
  width: ${isElectron() ? '100vw' : '100%'};
  position: ${isElectron() ? 'absolute' : 'relative'};
  bottom: 0;
  left: 0;
  padding: 24px 16px;
  z-index: 2;
  background: ${props => (props.isActive ? palette.transparent : palette.prussianBlue2)};
`;

export const SActivateButton = styled.button<{ isActive?: boolean }>`
  opacity: ${props => (props.isActive ? 1 : 0.5)};
  cursor: ${props => (props.isActive ? 'pointer' : 'auto')};
  max-width: calc(26rem - 32px);
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 0;
  z-index: 1;
  background: ${palette.earthYellow};
  font-size: 16px;
  font-weight: 500;
  color: ${palette.raisinBlack3};
  border-radius: 25.5px;
`;

export const STabs = styled(Tabs)`
  font-size: 12px;
  width: 100%;
  padding-top: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const STabList = styled(TabList)<{ $disabled?: boolean }>`
  position: fixed;
  max-width: calc(26rem - 2.5rem);
  padding-top: 16px;
  width: 100%;
  display: ${props => (props.$disabled ? 'none' : 'flex')};
  list-style-type: none;
  margin: 0;
  z-index: 5;
  background: ${palette.navyBlue};
`;

export const STab = styled(Tab)<{ disabled?: boolean }>`
  cursor: pointer;
  color: ${props => props.disabled && palette.silver};
  width: 50%;
  text-align: center;
  user-select: none;
  padding: 0px 0 10px 0;
  border-bottom: 1px solid ${palette.queenBlue};
  font-family: 'Roboto-Regular';
  font-size: 14px;

  &.is-selected {
    border-bottom: 2px solid ${palette.honeyYellow};
    color: ${palette.honeyYellow};
    margin-bottom: -0.5px;
  }

  &:focus {
    outline: none;
  }
`;

export const STabPanel = styled(TabPanel)<{ $disabled?: boolean }>`
  display: none;
  &.is-selected {
    padding-top: ${props => (props.$disabled ? '1.25rem' : '4rem')};
    min-height: 0;
    display: block !important;
  }
`;

export const SRecipientsButtonsContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 1.5rem 1rem;

  background: ${palette.prussianBlue2};
`;

export const SSearchBar = styled(SearchBarBase)<{ margin?: boolean }>`
  margin-bottom: 0.8125rem;
`;

export const RecipientsTabsListWrapper = styled.div`
  height: 100%;
  min-height: 0;
`;

//////////////////////////
/// Panic Timer Page
/////////////////////////
export const STimerScreenContainer = styled.div`
  height: 100vh;
  background-color: ${palette.darkblack};
  max-width: 26rem;
  margin: auto;
  padding: 40vh 0 60px 0;
`;

export const SLoaderContainer = styled.div`
  height: 100vh;
  background-color: ${palette.darkblack};
  max-width: 26rem;
  margin: auto;
`;

export const STimerContent = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const SMsgText = styled.p`
  display: flex;
  justify-content: center;
  font-size: 48px;
  font-family: 'Roboto-Regular';
`;

export const STimerText = styled.p`
  display: flex;
  justify-content: center;
  font-size: 64px;
  font-family: 'Roboto-Regular';
`;

export const STimerOperationsContainer = styled.div`
  width: calc(100% - 40px);

  .timerBtnRowMargin {
    margin-bottom: 60px;
  }
  .add-btn {
    color: ${palette.white};
    background-color: transparent;
  }
  .reduce-btn {
    color: ${palette.mirage};
  }
  .recall-btn {
    border-radius: 25px;
    background-color: transparent;
    border: 1px solid ${palette.tartOrange};
    color: ${palette.tartOrange};
  }
  .deactive-btn {
    border-radius: 25px;
    background-color: transparent;
    border: 1px solid ${palette.white};
    color: ${palette.white};
  }
`;

export const STimerBtn = styled.button`
  height: 45px;
  width: calc(100% - 50px);
  color: ${palette.mirage};
  border: 1px solid ${palette.earthYellow};
  background-color: ${palette.earthYellow};
  border-radius: 25px;
  margin-top: 20px;
  font-size: 16px;
  font-family: 'Roboto-Medium';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
  cursor: pointer;
`;
